import React, { Fragment, useEffect, useState } from "react";
import { Autocomplete, Box, Button, Container, Divider, Grid, Paper, styled, Typography } from "@mui/material";
import { useTitle } from "react-use";
import { useDispatch, useSelector } from "react-redux";

import dayjs from "dayjs";

import TodayIcon from "@mui/icons-material/Today";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import QrCodeIcon from '@mui/icons-material/QrCode';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import Backdrop from "../shared/general/Backdrop";

import AlertDialog from "./AlertDialog";
import { useHistory } from "react-router-dom";
import ButtonBlue from "../shared/general/ButtonBlue";
import { getGroupGpsLocationsById } from "../../../actions/gpsLocations";
import { addAttendance } from "../../../actions/attendance";
import { getUserFullName } from "../../../utils/userData";

const StyledRoot = styled(Box)({
  paddingBottom: 100,
  "& .MuiTypography-h6": {
    fontSize: "1.5rem",
  },
  "& .MuiSvgIcon-root": {
    marginRight: "5px",
  },
  "& .MuiTypography-body1": {
    fontSize: "1.1rem",
  },
  "&.page": {
    backgroundColor: "#FFFFFF",
  },
  "& .headingPage": {
    marginBottom: "16px",
  },
  "& .paper": {
    padding: "16px",
    borderRadius: "20px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  },
  "& .paperCheckIn": {
    padding: "24px",
    borderRadius: "20px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  },
  "& .wrapIcon": {
    display: "flex",
    alignItems: "center",
  },
  "& .dateTime": {
    color: "#16243D",
    borderRadius: "10px",
    marginBottom: "20px",
  },
  "& .checkInBtn": {
    color: "#fff",
    width: "100%",
    height: "50px",
    backgroundColor: "#66bb6a",
    border: "1px solid #66bb6a",
    "&:hover": {
      borderColor: "#4caf50",
      backgroundColor: "#4caf50",
    },
  },
  "& .checkOutBtn": {
    color: "#fff",
    width: "100%",
    height: "50px",
    backgroundColor: "#ef5350",
    border: "1px solid #ef5350",
    "&:hover": {
      borderColor: "#e53935",
      backgroundColor: "#e53935",
    },
  },
  "& .headPanel": {
    fontWeight: 600,
  },
  "& .divider": {
    marginTop: "16px",
    marginBottom: "16px",
  },
  "& .locationMessage": {
    display: "flex",
    justifyContent: "center",
    marginBottom: "24px",
  },
  "& .messagePassWrapper": {
    display: "flex",
    alignItems: "center",
    color: "#388e3c",
  },
  "& .messageFailWrapper": {
    display: "flex",
    alignItems: "center",
    color: "#d32f2f",
  },
})

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledBoxSearch = styled(Box)({
  marginTop: 20,
  marginBottom: 18,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const GpsCheckInOutQRCodePage = (props) => {

  const { match } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  useTitle("QR Code บันทึกเวลา - UniHR");
  const [time, setTime] = useState(new Date());

  const [mode, setMode] = useState("");

  const [isFetchingGroupGpsLocationData, setIsFetchingGroupGpsLocationData] = useState(true);
  // const [isFetchingUserProfileData, setIsFetchingUserProfileData] = useState(true);

  const [groupGpsLocations, setGroupGpsLocations] = useState(null)

  const [modalStatusConfig, setModalStatusConfig] = useState({
    open: false,
    title: "",
    content: "",
    type: "success",
    callBack: null,
  });

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { isLoggedIn } = useSelector((state) => state.auth);
  
  const fetchGroupGpsLocation = async () => {

    const result = await dispatch(getGroupGpsLocationsById(match.params.idGroupGpsLocations));
    if (result) {
      if (result.status === 404) {
        setIsFetchingGroupGpsLocationData(false);
        setModalStatusConfig({
          ...modalStatusConfig,
          open: true,
          type: "error",
          content: result.message,
        })
      } else {
        setGroupGpsLocations(result);
        setIsFetchingGroupGpsLocationData(false);
      }
    } else {
      setModalStatusConfig({
        ...modalStatusConfig,
        open: true,
        type: "error",
        content: "เกิดข้อผิดพลาด กรุณาติดต่อเจ้าหน้าที่",
      })
    }
  }

  const onClickCheckInOut =  async (mode) => {

    const dataForm = {
      attendanceDateTime: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      isCheckIn: mode,
      idAttendanceType: 5,
      idGroupGpsLocations: groupGpsLocations.idGroupGpsLocations,
      idEmployees: userProfile.idEmployees,
      idCompany: groupGpsLocations.idCompany,
    };

    const result = await dispatch(addAttendance(dataForm));
    if (result) {
      console.log(result)
      if (result.success) {
        
        setModalStatusConfig({
          ...modalStatusConfig,
          open: true,
          type: "success",
          content: mode === 1
            ? "ลงเวลาเข้างานสำเร็จ"
            : "ลงเวลาออกงานสำเร็จ",
        })
        setMode("")
      } else {
        setModalStatusConfig({
          ...modalStatusConfig,
          open: true,
          type: "error",
          content: result.message
        })
      }
    } else {
      setModalStatusConfig({
        ...modalStatusConfig,
        open: true,
        type: "error",
        content: "เกิดข้อผิดพลาดโปรดติดต่อเจ้าหน้าที่"
      })
    }
  }

  useEffect(() => {
    const interval = setInterval(() => setTime(new Date()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, [time]);

  useEffect(() => {
    if(!isLoggedIn){
      history.push("/login", {redirect: `/qrcode-check/${match.params.idGroupGpsLocations}`})
    }
  }, [])

  useEffect(() => {
    fetchGroupGpsLocation()
  }, [])

  return (
    <StyledRoot className="page">
      {(!userProfile || isFetchingGroupGpsLocationData)? (
        <Backdrop open={(!userProfile || isFetchingGroupGpsLocationData)}/>
      ) : ( 
        <Fragment>
          {userProfile && groupGpsLocations && <Container maxWidth="lg">
            <Grid container spacing={2} justifyContent="center" alignItems="center">
              <Grid item xs={12} md={4} lg={4}>
                <Paper className="paperCheckIn">
                  <Box className="dateTime">
                    <Box sx={{ marginBottom: "20px" }}>
                      <Typography className="headPanel" align="center" style={{fontSize: "32px"}}>
                        <QrCodeIcon style={{verticalAlign: "middle", fontSize: "36px"}}/> QR Code
                      </Typography>
                    </Box>
                    <Box sx={{ marginBottom: "20px" }}>
                      <Typography align="center">
                        บันทึกเวลาเข้า-ออก
                      </Typography>
                    </Box>
                    <Box sx={{ marginBottom: "10px" }}>
                      <Grid container>
                        <Grid item xs={4} md={4} lg={4}>
                          <Typography
                            variant="body1"
                            className="wrapIcon"
                            color="text.secondary"
                          >
                            <TodayIcon />
                            วันที่
                          </Typography>
                        </Grid>
                        <Grid item xs>
                          <Typography variant="body1">
                            {dayjs(new Date()).format("DD MMMM YYYY")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box sx={{ marginBottom: "10px" }}>
                      <Grid container>
                        <Grid item xs={4} md={4} lg={4}>
                          <Typography
                            variant="body1"
                            className="wrapIcon"
                            color="text.secondary"
                          >
                            <AccessTimeIcon />
                            เวลา
                          </Typography>
                        </Grid>
                        <Grid item xs>
                          <Typography variant="body1">
                            {dayjs(time).format("HH:mm:ss")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box sx={{ marginBottom: "20px" }}>
                      <Grid container>
                        <Grid item xs={4} md={4} lg={4}>
                          <Typography
                            variant="body1"
                            className="wrapIcon"
                            color="text.secondary"
                          >
                            <LocationOnIcon />
                            สถานที่
                          </Typography>
                        </Grid>
                        <Grid item xs>
                          <Typography variant="body1">
                            {groupGpsLocations.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                  <Divider className="divider"/>
                  {userProfile && (
                    <div style={{ marginBottom: 16 }}>
                      <Typography variant="h6" color="text.third">
                        สวัสดี...
                      </Typography>
                      <Typography variant="h5" align="center">
                        {getUserFullName(userProfile)}
                      </Typography>
                    </div>
                  )}
                  <Box>
                    {mode === "" ? (
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                          <Button
                            variant="outlined"
                            className="checkInBtn"
                            startIcon={<LoginIcon />}
                            onClick={() => {setMode("in")}}
                            disabled={!userProfile}
                          >
                            ลงเวลาเข้างาน
                          </Button>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                          <Button
                            variant="outlined"
                            className="checkOutBtn"
                            startIcon={<LogoutIcon />}
                            onClick={() => {setMode("out")}}
                            disabled={!userProfile}
                          >
                            ลงเวลาออกงาน
                          </Button>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container spacing={2}>
                        {mode === "in" && (
                          <Grid item xs={12} md={12} lg={12}>
                            <Button
                              variant="outlined"
                              className="checkInBtn"
                              startIcon={<LoginIcon />}
                              onClick={() => {onClickCheckInOut(1)}}
                            >
                              ยืนยันลงเวลาเข้างาน
                            </Button>
                          </Grid>
                        )}
                        {mode === "out" && (
                          <Grid item xs={12} md={12} lg={12}>
                            <Button
                              variant="outlined"
                              className="checkOutBtn"
                              startIcon={<LogoutIcon />}
                              onClick={() => {onClickCheckInOut(0)}}
                            >
                              ยืนยันลงเวลาออกงาน
                            </Button>
                          </Grid>
                        )}
                        <Grid item xs={12} container justifyContent="center">
                          <ButtonBlue
                            onClick={() => {
                              setMode("")
                            }}
                          >
                            ยกเลิก
                          </ButtonBlue>
                        </Grid>
                      </Grid>
                    )}
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Container>}
        </Fragment>
      )}

      <AlertDialog
        open={modalStatusConfig.open}
        title={modalStatusConfig.title}
        type={modalStatusConfig.type}
        content={modalStatusConfig.content}
        callBack={modalStatusConfig.callBack}
        onClose={()=>{
          setModalStatusConfig({
            ...modalStatusConfig,
            open: false,
            callBack: null,
          });
        }}
      />
    </StyledRoot>
  )
}

export default GpsCheckInOutQRCodePage;