import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  styled,
  Card,
  Button,
  Tabs,
  Tab,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Grid,
  TextField,
  Dialog,
  InputAdornment,
  Chip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrganizationObjectives,
  getAllIndividualObjectiveByIdEmp,
  getTeamObjective,
} from "../../../../../actions/okrs";
import ObjectiveCard from "../../../admin/okrs/OKRManagementForAdmin/components/ObjectiveCard";
import exportToExcel from "../../../admin/okrs/OKRManagementForAdmin/components/exportToExcel";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import IndividualPlanningAddFormPage from "../IndividualPlanning/addForm";
import EvaluationCard from "./EvaluationCard";

const StyledRoot = styled(Box)({
  backgroundColor: "#ffffff !important",
  paddingBottom: "150px",
});

const StyledObjectiveCard = styled(Card)({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  flexGrow: 1,
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)",
  borderRadius: 20,
  padding: "24px",
  background: `linear-gradient(to right, rgba(235, 148, 192, 0.5), rgba(188, 144, 213, 0.5))`,
  "& .cardTitle": {
    fontSize: 22,
    fontWeight: "bold",
    textAlign: "center",
    wordWrap: "break-word",
  },
  "& .percentage": {
    fontSize: 48,
    fontWeight: "bold",
    textAlign: "center",
  },
});

const StyledTeamObjectiveCard = styled(Card)({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  flexGrow: 1,
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
  borderRadius: 20,
  padding: "24px",
  background: `linear-gradient(to right, rgba(144, 238, 144, 0.5), rgba(60, 179, 113, 0.5))`,
  "& .cardTitle": {
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
    wordWrap: "break-word",
  },
  "& .percentage": {
    fontSize: 40,
    fontWeight: "bold",
    textAlign: "center",
  },
});

const StyledPersonalObjectiveCard = styled(Card)({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  flexGrow: 1,
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
  borderRadius: 20,
  padding: "24px",
  background: `linear-gradient(to right, rgba(135, 206, 250, 0.5), rgba(70, 130, 180, 0.5))`,
  "& .cardTitle": {
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
    wordWrap: "break-word",
  },
  "& .percentage": {
    fontSize: 36,
    fontWeight: "bold",
    textAlign: "center",
  },
});

const ResponsiveButton = styled(Button)(({ theme }) => ({
  backgroundColor: "rgba(219, 65, 120, 1)",
  fontSize: "14px",
  fontWeight: "bold",
  borderRadius: "7px",
  padding: "10px 20px",
  color: "#ffffff",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  display: "flex",
  alignItems: "center",
  "&:hover": {
    backgroundColor: "#C53B6C",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
  },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: "bold",
  padding: theme.spacing(1),
  borderRadius: "8px",
  backgroundColor: "#DB4178",
  color: "#fff",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
}));

const UserDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(0);
  const [objectiveTab, setObjectiveTab] = useState(0);
  const [showAddForm, setShowAddForm] = useState(false);
  const [selectYear, setSelectYear] = useState(dayjs().get("year"));
  const [selectedCompany, setSelectedCompany] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [personalObjectives, setPersonalObjectives] = useState([]);
  const [teamObjectives, setTeamObjectives] = useState([]);
  const [selectQuarter, setSelectQuarter] = useState("");
  const [evaluateObjectives, setEvaluateObjectives] = useState([]);

  const { result: userProfile } = useSelector((state) => state.userProfile);

  const { result: organizationObjectives } = useSelector(
    (state) => state.organizationObjective
  );

  const [objectivesUpdated, setObjectivesUpdated] = useState(false);

  const fetchObjectives = async () => {
    if (userProfile && userProfile.idCompany) {
      setSelectedCompany(userProfile.idCompany.toString());
      dispatch(getOrganizationObjectives());
      const response = await dispatch(
        getAllIndividualObjectiveByIdEmp(userProfile.idEmployees)
      );
      setPersonalObjectives(response.data);
      const today = dayjs().startOf("day");
      const objectivesForEvaluation = response.data
        .map((objective) => {
          const keyResultsForEvaluation = objective.keyResults.filter((kr) => {
            const isSuccessNull = kr.isSuccess == null;
            const endDatePassed = dayjs(kr.endDate)
              .startOf("day")
              .isSameOrBefore(today);
            const progressComplete = kr.progressPercent === 100;

            return isSuccessNull && (endDatePassed || progressComplete);
          });
          if (keyResultsForEvaluation.length > 0) {
            return {
              ...objective,
              keyResults: keyResultsForEvaluation,
            };
          } else {
            return null;
          }
        })
        .filter((objective) => objective !== null);

      setEvaluateObjectives(objectivesForEvaluation);

      dispatch(getTeamObjective()).then((res) => {
        if (res.status === 200) {
          setTeamObjectives(res.data);
        }
      });
    }
  };

  console.log(evaluateObjectives);

  useEffect(() => {
    fetchObjectives();
  }, [userProfile, objectivesUpdated]);

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
    setSearchQuery("");
  };

  const handleChangeObjectiveTab = (event, newValue) => {
    setObjectiveTab(newValue);
    setSearchQuery("");
  };

  const handleChangeYear = (event) => {
    setSelectYear(event.target.value);
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const handleExportClick = () => {
    if (objectiveTab === 0) {
      exportToExcel(organizationObjectives, t);
    } else if (objectiveTab === 1) {
      exportToExcel(teamObjectives, t);
    } else if (objectiveTab === 2) {
      exportToExcel(personalObjectives, t);
    }
  };

  const filterObjectivesBySearch = (objectives) => {
    return objectives.filter((objective) =>
      objective.objectiveName.toLowerCase().includes(searchQuery)
    );
  };

  const handleObjectiveUpdated = () => {
    setObjectivesUpdated((prev) => !prev);
  };

  let averageRating = "N/A";
  let totalScore = "N/A";

  if (activeTab === 1) {
    const filteredObjectives = personalObjectives
      .filter((objective) => {
        if (objective.year !== selectYear) return false;
        if (selectQuarter && objective.quarter !== Number(selectQuarter))
          return false;
        return true;
      })
      .sort((a, b) => {
        const dateA = a.latestUpdateDate
          ? new Date(a.latestUpdateDate)
          : new Date(0);
        const dateB = b.latestUpdateDate
          ? new Date(b.latestUpdateDate)
          : new Date(0);
        return dateB - dateA;
      });

    let totalObjectiveRating = 0;
    let numOfObjectives = 0;

    filteredObjectives.forEach((objective) => {
      const achievedKRs = objective.keyResults.filter(
        (kr) => kr.isAchieve === 1 && kr.managerRating != null
      );

      if (achievedKRs.length > 0) {
        const sumManagerRating = achievedKRs.reduce((sum, kr) => {
          return sum + kr.employeeRating;
        }, 0);

        const objectiveRating = sumManagerRating / achievedKRs.length;

        totalObjectiveRating += objectiveRating;
        numOfObjectives += 1;
      }
    });

    if (numOfObjectives > 0) {
      totalScore = totalObjectiveRating.toFixed(2);
      averageRating = (totalObjectiveRating / numOfObjectives).toFixed(2);
    } else {
      totalScore = "N/A";
      averageRating = "N/A";
    }
  }

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        {/* Header Section */}
        <Box
          className="header-box"
          sx={{
            padding: "16px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            background:
              "linear-gradient(to right, rgba(235, 148, 192, 0.5), rgba(188, 144, 213, 0.5))",
            borderRadius: "16px",
          }}
        >
          <Typography
            className="header-text"
            sx={{ fontSize: "48px", fontWeight: "bold" }}
          >
            {t("OKR Management")}
          </Typography>
        </Box>

        {/* Main Tabs */}
        <Box
          marginTop={2}
          marginBottom={4}
          sx={{
            borderBottom: "2px",
            boxShadow: "0",
          }}
        >
          <Tabs
            value={activeTab}
            onChange={handleChangeTab}
            indicatorColor="primary"
          >
            <Tab label={t("Home")}></Tab>
            <Tab label={t("UpdateIndividualGoals")}></Tab>
            <Tab label={t("EvaluateKR")}></Tab>
          </Tabs>
        </Box>

        {/* Home Tab Content */}
        {activeTab === 0 && (
          <>
            {/* Organizational Objectives Header */}
            <Box
              display="flex"
              alignItems="center"
              marginBottom={2}
              padding="16px"
              borderRadius="20px"
              backgroundColor="#ffffff"
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="flex-start"
                gap={1}
                mb={2}
              >
                <Typography variant="h6" gutterBottom>
                  {t("OrganizationalGoals")}
                </Typography>
                <Typography variant="h5" fontWeight="bold">
                  {userProfile ? userProfile.companyName : ""}
                </Typography>
              </Box>

              <FormControl
                className="filter-year"
                sx={{ marginLeft: "24px", minWidth: 150 }}
              >
                <InputLabel>{t("SelectYear")}</InputLabel>
                <Select
                  label={t("SelectYear")}
                  value={selectYear}
                  onChange={handleChangeYear}
                  sx={{ borderRadius: "10px", backgroundColor: "#ffffff" }}
                >
                  {[0, 1, 2].map((item) => (
                    <MenuItem key={item} value={dayjs().get("year") - item}>
                      {dayjs().get("year") - item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {/* Organizational Objectives Header Cards */}
            <Grid container spacing={3} marginBottom={4}>
              {organizationObjectives &&
                organizationObjectives
                  .filter((objective) => objective.year === selectYear)
                  .sort(
                    (a, b) => new Date(b.updateDate) - new Date(a.updateDate)
                  )
                  .slice(0, 3) // Limit to top 3 objectives
                  .map((objective) => (
                    <Grid
                      item
                      xs={12}
                      md={4}
                      key={objective.idOrganizationObjective}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <StyledObjectiveCard>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                        >
                          <Typography
                            className="percentage"
                            style={{ color: "#DB4178" }}
                          >{`${objective.progressPercent}%`}</Typography>
                          <Typography className="cardTitle" align="center">
                            {objective.objectiveName}
                          </Typography>
                        </Box>
                      </StyledObjectiveCard>
                    </Grid>
                  ))}
            </Grid>

            {/* Team Objectives Header */}
            <Box
              display="flex"
              alignItems="center"
              marginBottom={0}
              padding="8px"
              borderRadius="20px"
              backgroundColor="#ffffff"
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="flex-start"
                gap={1}
                mb={2}
              >
                <Typography variant="h6" gutterBottom>
                  {t("TeamGoals")}
                </Typography>
              </Box>
            </Box>

            {/* Team Objectives Header Cards */}
            <Grid container spacing={3} marginBottom={4}>
              {teamObjectives &&
                teamObjectives
                  .filter((objective) => objective.year === selectYear)
                  .sort(
                    (a, b) => new Date(b.updateDate) - new Date(a.updateDate)
                  )
                  .slice(0, 3) // Limit to top 3 team objectives
                  .map((objective) => (
                    <Grid
                      item
                      xs={12}
                      md={4}
                      key={objective.idTeamObjective}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <StyledTeamObjectiveCard>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                        >
                          <Typography
                            className="percentage"
                            style={{ color: "#3CB371" }}
                          >{`${objective.progressPercent}%`}</Typography>
                          <Typography className="cardTitle" align="center">
                            {objective.objectiveName}
                          </Typography>
                        </Box>
                      </StyledTeamObjectiveCard>
                    </Grid>
                  ))}
            </Grid>

            {/* Personal Objectives Header */}
            <Box
              display="flex"
              alignItems="center"
              marginBottom={0}
              padding="8px"
              borderRadius="20px"
              backgroundColor="#ffffff"
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="flex-start"
                gap={1}
                mb={2}
              >
                <Typography variant="h6" gutterBottom>
                  {t("PersonalGoals(Manager)")}
                </Typography>
              </Box>
            </Box>

            {/* Personal Objectives Header Cards */}
            <Grid container spacing={3} marginBottom={4}>
              {personalObjectives &&
                personalObjectives
                  .filter((objective) => objective.year === selectYear)
                  .sort(
                    (a, b) => new Date(b.updateDate) - new Date(a.updateDate)
                  )
                  .slice(0, 3) // Limit to top 3 personal objectives
                  .map((objective) => (
                    <Grid
                      item
                      xs={12}
                      md={4}
                      key={objective.idObjective}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <StyledPersonalObjectiveCard>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                        >
                          <Typography
                            className="percentage"
                            style={{ color: "#4682B4" }}
                          >{`${objective.progressPercent}%`}</Typography>
                          <Typography className="cardTitle" align="center">
                            {objective.objectiveName}
                          </Typography>
                        </Box>
                      </StyledPersonalObjectiveCard>
                    </Grid>
                  ))}
            </Grid>

            {/* Objective Sub-Tabs */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginBottom={4}
              marginTop={8}
            >
              <Tabs
                value={objectiveTab}
                onChange={handleChangeObjectiveTab}
                indicatorColor="primary"
              >
                <Tab label={t("OrganizationalGoals")}></Tab>
                <Tab label={t("TeamGoals")}></Tab>
                {/* My Goal not PersonalGoals(Manager) translation not correct */}
                <Tab label={t("PersonalGoals(Manager)")}></Tab>
              </Tabs>

              {objectiveTab === 2 && (
                <ResponsiveButton onClick={() => setShowAddForm(true)}>
                  <AddIcon style={{ marginRight: "8px" }} />{" "}
                  {t("CreateObjective")}
                </ResponsiveButton>
              )}
            </Box>

            {/* Search Bar */}
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
              marginBottom={4}
            >
              <Box
                display="flex"
                alignItems="center"
                flexWrap="wrap"
                gap={2}
                sx={{ flexGrow: 1, minWidth: "300px" }}
              >
                {/* <TextField
                  variant="outlined"
                  placeholder={t("SearchObjectives")}
                  value={searchQuery}
                  onChange={handleSearchQueryChange}
                  sx={{
                    width: "300px",
                    borderRadius: "10px",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                /> */}
                {/* Quarter Filter */}
                <FormControl sx={{ minWidth: 200 }}>
                  <InputLabel shrink>{t("SelectQuarter")}</InputLabel>
                  <Select
                    label={t("SelectQuarter")}
                    value={selectQuarter || ""}
                    onChange={(e) => setSelectQuarter(e.target.value)}
                    sx={{ borderRadius: "10px", backgroundColor: "#ffffff" }}
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected === "") {
                        return <span>{t("All")}</span>;
                      }
                      return t(`Q${selected}`);
                    }}
                  >
                    <MenuItem value="">{t("All")}</MenuItem>
                    <MenuItem value="1">{t("Q1")}</MenuItem>
                    <MenuItem value="2">{t("Q2")}</MenuItem>
                    <MenuItem value="3">{t("Q3")}</MenuItem>
                    <MenuItem value="4">{t("Q4")}</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Box>
                <Button
                  variant="outlined"
                  sx={{
                    borderColor: "rgba(219, 65, 120, 1)",
                    color: "rgba(219, 65, 120, 1)",
                    fontWeight: "bold",
                    "&:hover": {
                      borderColor: "#C53B6C",
                      color: "#C53B6C",
                    },
                  }}
                  onClick={handleExportClick}
                >
                  {t("ExportToExcel")}
                </Button>
              </Box>
            </Box>

            {/* Rendering Objectives Based on Objective Tab */}
            {objectiveTab === 0 && (
              <Grid container spacing={3}>
                {filterObjectivesBySearch(
                  (organizationObjectives || [])
                    .filter((objective) => {
                      if (objective.year !== selectYear) return false;
                      if (
                        selectedCompany &&
                        objective.idCompany !== Number(selectedCompany)
                      )
                        return false;
                      if (
                        selectQuarter &&
                        objective.quarter !== Number(selectQuarter)
                      )
                        return false;
                      return true;
                    })
                    .sort((a, b) => {
                      const dateA = a.latestUpdateDate
                        ? new Date(a.latestUpdateDate)
                        : new Date(0);
                      const dateB = b.latestUpdateDate
                        ? new Date(b.latestUpdateDate)
                        : new Date(0);
                      return dateB - dateA;
                    })
                ).map((objective) => (
                  <Grid item xs={12} key={objective.idOrganizationObjective}>
                    <ObjectiveCard objective={objective} />
                  </Grid>
                ))}
              </Grid>
            )}

            {objectiveTab === 1 && (
              <Grid container spacing={3}>
                {(teamObjectives || [])
                  .filter((objective) => {
                    if (objective.year !== selectYear) return false;

                    if (
                      selectQuarter &&
                      objective.quarter !== Number(selectQuarter)
                    )
                      return false;
                    return true;
                  })
                  .sort((a, b) => {
                    const dateA = a.latestUpdateDate
                      ? dayjs(a.latestUpdateDate)
                      : dayjs(0);
                    const dateB = b.latestUpdateDate
                      ? dayjs(b.latestUpdateDate)
                      : dayjs(0);
                    return dateB.diff(dateA);
                  })
                  .map((objective) => (
                    <Grid item xs={12} key={objective.idTeamObjective}>
                      <ObjectiveCard objective={objective} />
                    </Grid>
                  ))}
              </Grid>
            )}

            {objectiveTab === 2 && (
              <Grid container spacing={3}>
                {filterObjectivesBySearch(
                  personalObjectives
                    .filter((objective) => {
                      if (objective.year !== selectYear) return false;
                      if (
                        selectedCompany &&
                        objective.idCompany !== Number(selectedCompany)
                      )
                        return false;
                      if (
                        selectQuarter &&
                        objective.quarter !== Number(selectQuarter)
                      )
                        return false;
                      return true;
                    })
                    .sort((a, b) => {
                      const dateA = a.latestUpdateDate
                        ? new Date(a.latestUpdateDate)
                        : new Date(0);
                      const dateB = b.latestUpdateDate
                        ? new Date(b.latestUpdateDate)
                        : new Date(0);
                      return dateB - dateA;
                    })
                ).map((objective) => (
                  <Grid item xs={12} key={objective.idObjective}>
                    <ObjectiveCard objective={objective} />
                  </Grid>
                ))}
              </Grid>
            )}
          </>
        )}

        {/* Update Individual Goals Tab Content */}
        {activeTab === 1 && (
          <>
            {/* Search Bar */}
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
              marginBottom={4}
            >
              <Box
                display="flex"
                alignItems="center"
                flexWrap="wrap"
                gap={2}
                sx={{ flexGrow: 1, minWidth: "300px" }}
              >
                {/* Quarter Filter */}
                <FormControl sx={{ minWidth: 200 }}>
                  <InputLabel shrink>{t("SelectQuarter")}</InputLabel>
                  <Select
                    label={t("SelectQuarter")}
                    value={selectQuarter || ""}
                    onChange={(e) => setSelectQuarter(e.target.value)}
                    sx={{ borderRadius: "10px", backgroundColor: "#ffffff" }}
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected === "") {
                        return <span>{t("All")}</span>;
                      }
                      return t(`Q${selected}`);
                    }}
                  >
                    <MenuItem value="">{t("All")}</MenuItem>
                    <MenuItem value="1">{t("Q1")}</MenuItem>
                    <MenuItem value="2">{t("Q2")}</MenuItem>
                    <MenuItem value="3">{t("Q3")}</MenuItem>
                    <MenuItem value="4">{t("Q4")}</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box display="flex" alignItems="center" gap={2}>
                <StyledChip label={`${t("AverageScore")}: ${averageRating}`} />
                <StyledChip label={`${t("TotalScore")}: ${totalScore}`} />
              </Box>
            </Box>

            <Grid container spacing={3}>
              {filterObjectivesBySearch(
                personalObjectives
                  .filter((objective) => {
                    if (objective.year !== selectYear) return false;
                    if (
                      selectQuarter &&
                      objective.quarter !== Number(selectQuarter)
                    )
                      return false;
                    return true;
                  })
                  .sort((a, b) => {
                    const dateA = a.latestUpdateDate
                      ? new Date(a.latestUpdateDate)
                      : new Date(0);
                    const dateB = b.latestUpdateDate
                      ? new Date(b.latestUpdateDate)
                      : new Date(0);
                    return dateB - dateA;
                  })
              ).map((objective) => (
                <Grid item xs={12} key={objective.idObjective}>
                  <ObjectiveCard
                    objective={objective}
                    isEditable={true}
                    onObjectiveUpdated={handleObjectiveUpdated}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        )}

        {activeTab === 2 && (
          <>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
              marginBottom={4}
            >
              <Typography variant="h6">
                {t("Evaluate Your Key Results")}
              </Typography>
            </Box>

            <EvaluationCard
              objectives={evaluateObjectives}
              onEvaluationSubmitted={handleObjectiveUpdated}
            />
          </>
        )}

        {/* Add Objective Form Dialog */}
        {showAddForm && (
          <Dialog
            open={showAddForm}
            onClose={() => setShowAddForm(false)}
            fullWidth
            maxWidth="lg"
          >
            <IndividualPlanningAddFormPage />
          </Dialog>
        )}
      </Container>
    </StyledRoot>
  );
};

export default UserDashboard;
