import React, { useEffect, useState } from "react";
import DrawerCustom from "../../../../shared/general/Drawer";
import {
  Box,
  Grid,
  MenuItem,
  Typography,
  styled,
  TextField,
  Button,
} from "@mui/material";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { addCostCenterByIdEmployees, getCostCenterByEmployees, getCostCenterLists } from "../../../../../../actions/cost-center";
import { useTranslation } from "react-i18next";
import DatePickerCustom from "../../../../shared/date/datePicker";
import { getEmployeeProfile } from "../../../../../../actions/employee";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
  "& .field-label": {
    paddingBottom: "4px",
    fontWeight: "500",
    fontSize: "14px",
  },
});

const DialogCostCenter = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { drawerConfig, onClose } = props;

  const { result: employeeProfile } = useSelector((state) => state.employeeProfile);
  const [costCenterList, setCostCenterList] = useState({});
  const [formValues, setFormValues] = useState({
    costcenter: null,
    idSection: null,
    idDepartment: null,
    idDivision: null,
    idBusinessUnit: null,
    start: null,
    end: null
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const fetchedCostCenterList = async () => {
    try {
      const response = await getCostCenterLists({ idCompany: employeeProfile && employeeProfile.idCompany });
      if (response && response.data) {
        setCostCenterList(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchedEmployeeCostCenter = async () => {
    try {
      const response = await getCostCenterByEmployees({ idEmployees: employeeProfile && employeeProfile.idEmployees });
      if (response && response.data) {
        const data = response.data;
        // Update form values with the fetched data
        setFormValues({
          costcenter: data.idSection || data.idDepartment || data.idDivision || data.idBusinessUnit,
          idSection: data.idSection,
          idDepartment: data.idDepartment,
          idDivision: data.idDivision,
          idBusinessUnit: data.idBusinessUnit,
          start: dayjs(data.start).format("YYYY-MM-DD"),
          end: dayjs(data.end).format("YYYY-MM-DD"),
          costCenterCharge: data.costCenterCharge
        });

        setStartDate(dayjs(data.start));
        setEndDate(dayjs(data.end));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (employeeProfile) {
      fetchedCostCenterList();
      fetchedEmployeeCostCenter();
    }
  }, [employeeProfile]);

  useEffect(() => {
    if (drawerConfig.isOpen) {
      const costcenter = formValues.costcenter;
      const resetValues = {
        idSection: null,
        idDepartment: null,
        idDivision: null,
        idBusinessUnit: null,
      };

      if (costcenter) {
        if (costCenterList.section && costCenterList.section.some(item => item.idSection === costcenter)) {
          resetValues.idSection = costcenter;
        } else if (costCenterList.department && costCenterList.department.some(item => item.idDepartment === costcenter)) {
          resetValues.idDepartment = costcenter;
        } else if (costCenterList.division && costCenterList.division.some(item => item.idDivision === costcenter)) {
          resetValues.idDivision = costcenter;
        } else if (costCenterList.businessUnit && costCenterList.businessUnit.some(item => item.idBusinessUnit === costcenter)) {
          resetValues.idBusinessUnit = costcenter;
        }
      }

      setFormValues(prevValues => ({ ...prevValues, ...resetValues }));
    }
  }, [drawerConfig.isOpen, costCenterList]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues(prevValues => {
      const updatedValues = {
        ...prevValues,
        [name]: value,
      };

      if (name === "costcenter") {
        const costcenter = value;
        const resetValues = {
          idSection: null,
          idDepartment: null,
          idDivision: null,
          idBusinessUnit: null,
          start: startDate ? dayjs(startDate).format("YYYY-MM-DD") : null,
          end: endDate ? dayjs(endDate).format("YYYY-MM-DD") : null
        };

        if (costcenter) {
          if (costCenterList.section && costCenterList.section.some(item => item.idSection === costcenter)) {
            resetValues.idSection = costcenter;
          } else if (costCenterList.department && costCenterList.department.some(item => item.idDepartment === costcenter)) {
            resetValues.idDepartment = costcenter;
          } else if (costCenterList.division && costCenterList.division.some(item => item.idDivision === costcenter)) {
            resetValues.idDivision = costcenter;
          } else if (costCenterList.businessUnit && costCenterList.businessUnit.some(item => item.idBusinessUnit === costcenter)) {
            resetValues.idBusinessUnit = costcenter;
          }
        }

        return {
          ...updatedValues,
          ...resetValues,
        };
      }

      if (name === "start") {
        return {
          ...updatedValues,
          start: startDate ? dayjs(startDate).format("YYYY-MM-DD") : null
        };
      }

      if (name === "end") {
        return {
          ...updatedValues,
          end: endDate ? dayjs(endDate).format("YYYY-MM-DD") : null
        };
      }

      return updatedValues;
    });
  };

  const handleDateChange = (name, newValue) => {
    if (name === "start") {
      setStartDate(newValue);
      setFormValues(prevValues => ({
        ...prevValues,
        start: newValue ? dayjs(newValue).format("YYYY-MM-DD") : null
      }));
    }

    if (name === "end") {
      setEndDate(newValue);
      setFormValues(prevValues => ({
        ...prevValues,
        end: newValue ? dayjs(newValue).format("YYYY-MM-DD") : null
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      ...formValues,
      idEmployees: employeeProfile.idEmployees,
    };

    if (formData.costcenter) {
      const splitData = formData.costcenter.split("-");

      if (splitData[0] === "section") {
        formData.idSection = parseInt(splitData[1]);
      } else if (splitData[0] === "department") {
        formData.idDepartment = parseInt(splitData[1]);
      } else {
        formData.idDivision = parseInt(splitData[1]);
      }
    };

    Object.keys(formData).forEach((k) => {
      if (formData[k] === "") {
        formData[k] = null;
      }
    });

    try {
      const result = await addCostCenterByIdEmployees(formData);

      if (result && (result.status === 200 || result.status === 201)) {
        dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
        onClose();
      }
    } catch (error) {
      console.error('Error submitting form data:', error);
    }
  };

  return (
    <DrawerCustom
      title={`${t("EditData")} Cost Center`}
      anchor="right"
      open={drawerConfig.isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <StyledRoot>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className="field-label">
                Cost Center Charge
              </Typography>
              {/* {costCenterList && costCenterList.length > 0 ? (
                <TextFieldTheme
                  select
                  name="costcenter"
                  value={formValues.costcenter || ""}
                  onChange={handleChange}
                  fullWidth
                  disabled
                >
                  {costCenterList.section && costCenterList.section.map((item) => (
                    <MenuItem key={item.idSection} value={`section-${item.idSection}`}>
                      {item.costCenter} {"(" + `${item.sectionName}` + ")"}
                    </MenuItem>
                  ))}
                  {costCenterList.department && costCenterList.department.map((item) => (
                    <MenuItem key={item.idDepartment} value={`department-${item.idDepartment}`}>
                      {item.costCenter} {"(" + `${item.departmentName}` + ")"}
                    </MenuItem>
                  ))}
                  {costCenterList.division && costCenterList.division.map((item) => (
                    <MenuItem key={item.idDivision} value={`division-${item.idDivision}`}>
                      {item.costCenter} {"(" + `${item.divisionName}` + ")"}
                    </MenuItem>
                  ))}
                  {costCenterList.businessUnit && costCenterList.businessUnit.map((item) => (
                    <MenuItem key={item.idBusinessUnit} value={`BusinessUnit-${item.idBusinessUnit}`}>
                      {item.costCenter} {"(" + `${item.businessUnitName}` + ")"}
                    </MenuItem>
                  ))}
                </TextFieldTheme>
              ) : ( */}
              <TextFieldTheme
                name="costCenterCharge"
                value={formValues.costCenterCharge || ""}
                onChange={handleChange}
                fullWidth
                disabled
              />
              {/* )} */}
            </Grid>

            <Grid item xs={6}>
              <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">
                {t("StartDate")}
              </Typography>
              <div className="search-date">
                <DatePickerCustom
                  minDate={new Date(new Date().getFullYear() - 4, 0, 1)}
                  inputFormat="DD/MM/YYYY"
                  value={startDate}
                  name="start"
                  disabled
                  onChange={(newValue) => handleDateChange("start", newValue)}
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">
                {t("EndDate")}
              </Typography>
              <div className="search-date">
                <DatePickerCustom
                  minDate={startDate}
                  inputFormat="DD/MM/YYYY"
                  value={endDate}
                  name="end"
                  disabled
                  onChange={(newValue) => handleDateChange("end", newValue)}
                />
              </div>
            </Grid>

            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue variant="text" onClick={onClose}>
                {t("Cancel")}
              </ButtonBlue>
              <ButtonBlue type="submit" variant="contained" disabled>
                {t("Save")}
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogCostCenter;