import React, { useEffect, useState, Fragment } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as XLSX from "xlsx";

import { useFormik } from "formik";

import { styled } from "@mui/material/styles";

import Link from "@mui/material/Link";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import DrawerCustom from "../../../../shared/general/Drawer";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import ButtonLoadingBlue from "../../../../shared/general/ButtonLoadingBlue";

import {
  createPayruns,
  getSsofile,
  getPnd1file,
} from "../../../../../../actions/payruns";
import { allPayTypes } from "../../../../../../actions/paytypes";
import { getAffiliateOrganization } from "../../../../../../actions/company";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const StyledContent = styled("div")({
  width: 550,
  padding: 24,
  "& .textField": {
    marginBottom: 16,
    width: "100%",
  },
  "& .radio": {
    width: "100%",
  },
  "& .MuiFormControl-root": {
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
    },
  },
  "& .wrap-upload": {
    display: "flex",
    justifyContent: "flex-end",
  },
});

const StyledFooter = styled("div")({
  marginTop: 16,
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiOutlinedInput-root": {
    height: 50,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledAutocompleteMultiple = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiOutlinedInput-root": {
    padding: "0px 12px",
    paddingTop: 8,
    paddingBottom: 8,
    borderRadius: 8,
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledBoxSearch = styled(Box)({
  marginBottom: 8,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      padding: "8px 16px",
      margin: "0 8px",
      borderRadius: 8,
    },
    [`& .${autocompleteClasses.groupLabel}`]: {
      lineHeight: "32px",
    },
  },
});

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const DialogExportFileGov = (props) => {
  const {
    open,
    handleClose,
    isReadOnly,
    handleOpenAlert,
    handleChangeAlertType,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const option = [
    {
      id: 1,
      label: `${t("SSO")}`,
    },
    {
      id: 2,
      label: `${t("P.N.D.1")}`,
    },
  ];
  const { result: payRunList } = useSelector((state) => state.payRun);
  const { result: paytypesList } = useSelector((state) => state.paytypes);
  const [fileSelected, setFileSelected] = useState({
    ot: null,
    additionDeduction: null,
  });
  const [payrunSelected, setPayrunSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dataForm, setDataForm] = useState({
    mode: option[0],
    payRunList: [],
  });

  useEffect(() => {
    if (payRunList) {
      let temp = payRunList.resultData.filter(
        (item) => item.isDraft === 0 && item.isApprove === 1
      );

      setDataForm({ ...dataForm, payRunList: temp });
    }
  }, [payRunList]);

  const handleOnClickDownload = async () => {
    console.log("dataForm: ", dataForm);
    let idPayrunList = payrunSelected.map((value) => value.idPayrun).join(",");
    if (idPayrunList && idPayrunList.length > 0) {
      let result = null;
      if (dataForm.mode.id === 1) {
        result = await dispatch(getSsofile(idPayrunList));
      } else {
        result = await dispatch(getPnd1file(idPayrunList));
      }

      if (result) {
        if (result.status === 200) {
          let elementList = [];
          if (result.data && result.data.length > 0) {
            let data = result.data;
            if (dataForm.mode.id === 1) {
              for (let index = 0; index < data.length; index++) {
                let element = null;

                element = {
                  เลขประจำตัวประชาชน: data[index].ssoID,
                  คำนำหน้าชื่อ: data[index].title_TH,
                  ชื่อผู้ประกันตน: data[index].firstname_TH,
                  นามสกุลผู้ประกันตน: data[index].lastname_TH,
                  ค่าจ้าง: data[index].ssoActual,
                  จำนวนเงินสมทบ: data[index].sso,
                };
                elementList.push(element);
              }

              const ws = XLSX.utils.json_to_sheet(elementList);
              var workbook = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(workbook, ws, "sheet1");
              XLSX.writeFile(workbook, `SSO.xlsx`, { type: "file" });
            } else {
              for (let index = 0; index < data.length; index++) {
                let element = null;

                element = {
                  ลำดับที่: index + 1,
                  เลขประจำตัวผู้เสียภาษี: data[index].taxID,
                  คำนำหน้า: data[index].title_TH,
                  ชื่อ: data[index].firstname_TH,
                  สกุล: data[index].lastname_TH,
                  เงินได้ตามมาตรา: "401N",
                  วันเดือนปีที่จ่าย: dayjs(data[index].payDate).format(
                    "DD/MM/BBBB"
                  ),
                  จำนวนเงินได้: data[index].tax,
                  "ภาษีหัก ณ ที่จ่าย": data[index].taxActual,
                  เงื่อนไข: 1,
                };
                elementList.push(element);
              }

              const ws = XLSX.utils.json_to_sheet(elementList);
              var workbook = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(workbook, ws, "sheet1");
              XLSX.writeFile(workbook, `PND1.xlsx`, { type: "file" });
            }
          } else {
            handleChangeAlertType("error");
            handleOpenAlert();
          }
          handleClose();
        } else {
          handleChangeAlertType("error");
          handleOpenAlert();
        }
      }
    }
  };

  return (
    <DrawerCustom
      open={open}
      onClose={handleClose}
      title="Export file government"
      anchor={"right"}
    >
      <StyledContent>
        <StyledBoxSearch>
          <Typography className="label" color="text.third">
            ประเภทไฟล์
          </Typography>
          <StyledAutocomplete
            options={option}
            onChange={(event, newValue) => {
              console.log("newValue :", newValue);
              setDataForm({ ...dataForm, mode: newValue });
            }}
            value={dataForm.mode}
            disableClearable={true}
            popupIcon={<i class="fa-light fa-chevron-down"></i>}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
            PopperComponent={StyledPopper}
            noOptionsText={`${t("NoData")}`}
          />
        </StyledBoxSearch>
        <StyledBoxSearch>
          <Typography className="label" color="text.third">
            รายการ
          </Typography>
          <StyledAutocompleteMultiple
            multiple
            id="checkboxes-tags-demo"
            options={dataForm.payRunList}
            disableCloseOnSelect
            getOptionLabel={(option) => option.payrunName}
            popupIcon={<i class="fa-light fa-chevron-down"></i>}
            renderOption={(props, option, { selected }) => {
              const { key, ...optionProps } = props;
              return (
                <li key={key} {...optionProps}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.payrunName}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
            onChange={(event, newValue) => {
              console.log("newValue :", newValue);
              setPayrunSelected(newValue);
            }}
          />
        </StyledBoxSearch>

        <StyledFooter>
          <ButtonBlue className="cancel" onClick={handleClose}>
            {t("Cancel")}
          </ButtonBlue>
          <ButtonLoadingBlue
            loading={loading}
            variant="contained"
            onClick={handleOnClickDownload}
          >
            {t("Download")}
          </ButtonLoadingBlue>
        </StyledFooter>
      </StyledContent>
    </DrawerCustom>
  );
};

export default DialogExportFileGov;
