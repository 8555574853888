import { httpClient } from "./httpClient";

const exportExcelLeaveReport = (query) => {
  return httpClient.get(`/export-excel/all-leave-report`, {params: query, responseType: 'blob'});
};

const exportExcelLeaveAbsentLateReport = (query) => {
  return httpClient.get(`/export-excel/leave-absent-late-report`, {params: query, responseType: 'blob'});
};

const exportExcelRequestListReport = (query) => {
  return httpClient.get(`/export-excel/request-list-report`, {params: query, responseType: 'blob'});
};

const exportExcelEmployeeInformationReport = (query) => {
  return httpClient.get(`/export-excel/employee-information-report`, {params: query, responseType: 'blob'});
};

const exportExcelAttendanceMonthlyReport = (query) => {
  return httpClient.get(`/export-excel/attendance-monthly-report`, {params: query, responseType: 'blob'});
};

export default {
  exportExcelLeaveReport,
  exportExcelLeaveAbsentLateReport,
  exportExcelRequestListReport,
  exportExcelEmployeeInformationReport,
  exportExcelAttendanceMonthlyReport,
};