import dayjs from 'dayjs';
import ExcelJS from 'exceljs';

export const DetailedTimeStampDepartment = (t,data, leaveData, start, end) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet1 = workbook.addWorksheet(t("WorkTimeSummaryByDepartment"));

    let DateStart = dayjs(start).add(543, 'year').format("DD-MM-YYYY");
    let DateEnd = dayjs(end).add(543, 'year').format("DD-MM-YYYY")

    function formatMonthToThai(month) {
        switch (month) {
            case '01':
                return t("Months.January");
            case '02':
                return t("Months.February");
            case '03':
                return t("Months.March");
            case '04':
                return t("Months.April");
            case '05':
                return t("Months.May");
            case '06':
                return t("Months.June");
            case '07':
                return t("Months.July");
            case '08':
                return t("Months.August");
            case '09':
                return t("Months.September");
            case '10':
                return t("Months.October");
            case '11':
                return t("Months.November");
            case '12':
                return t("Months.December");
            default:
                return '';
        }
    }

    const ThaiDateStart = DateStart.split('-').map((value, index) => (index === 1 ? formatMonthToThai(value) : value)).join(' ');
    const ThaiDateEnd = DateEnd.split('-').map((value, index) => (index === 1 ? formatMonthToThai(value) : value)).join(' ');

    const leaveTypeNames = leaveData.map(leaveType => `${t("Total")}${leaveType.name}`);

    const headerValues = [
        t("Department"),
        t("EmpTotal"),
        t("DataStartDate"),
        t("DataEndDate"),
        t("TotalWorkingHours"),
        t("TotalLeaveHours"),
        t("TotalOvertimeHours"),
        t("TotalAbsenceHours"),
        t("TotalLateEarlyLeaveHours"),
        ...leaveTypeNames
    ];

    const headerRow = worksheet1.addRow(headerValues);
    headerRow.height = 50;

    const headerStyle1 = {
        font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
        alignment: { horizontal: "center", vertical: 'middle' },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '002060' }
        },
    }

    headerRow.eachCell((cell) => {
        cell.style = headerStyle1;
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
        };
    });

    let leaveTypeColWidths = [];
    leaveData.forEach(leaveType => {
        leaveTypeColWidths.push({
            key: `leaveType_${leaveType.idLeaveType}`,
            width: leaveType.name.length * 2
        });
    });

    const colWidths = [
        { key: "department", width: 30 },
        { key: "totalEmployee", width: 30 },
        { key: "start", width: 30 },
        { key: "end", width: 30 },
        { key: "totalWorkingHour", width: 30 },
        { key: "totalLeaveHour", width: 30 },
        { key: "totalOTHour", width: 30 },
        { key: "absent", width: 20 },
        { key: "late", width: 30 },
        ...leaveTypeColWidths
    ];

    function formatNumber(number) {
        if (Number.isInteger(number)) {
            return number.toString();
        } else {
            return parseFloat(number.toFixed(2));
        }
    }

    let row = [];

    data && data.map((item) => {
        row = [
            item.departmentName ? item.departmentName : "-",
            item.departmentEmployeeCount ? item.departmentEmployeeCount : "-",
            ThaiDateStart ? ThaiDateStart : "-",
            ThaiDateEnd ? ThaiDateEnd : "-",
            item.totalWorkingHoursByDepartment && item.totalWorkingHoursByDepartment > 0 ? item.totalWorkingHoursByDepartment.toFixed(0) : "-",
            item.totalSumLeaveByDepartment && item.totalSumLeaveByDepartment > 0 ? item.totalSumLeaveByDepartment.toFixed(0) : "-",
            item.totalotHourstotalByDepartment && item.totalotHourstotalByDepartment > 0 ? item.totalotHourstotalByDepartment.toFixed(0) : "-",
            "-",
            "-"
        ];

        const leaveHours = {}; // Object to store used hours for each leave type

        // Initialize leaveHours object with 0 values for all leave types
        leaveData.forEach(leaveType => {
            leaveHours[leaveType.idLeaveType] = 0;
        });

        // Add used hours for each leave type to the leaveHours object
        item.totalLeaveByDepartment.forEach(leave => {
            leaveHours[leave.idLeaveType] = formatNumber(leave.used);
        });

        // Add used hours for each leave type to the row
        leaveData.forEach(leaveType => {
            row.push(leaveHours[leaveType.idLeaveType]);
        });

        const excelRow = worksheet1.addRow(row);
        const contentStyle = {
            font: { size: 18, name: 'TH SarabunPSK' },
            alignment: {
                vertical: 'middle',
                horizontal: 'center'
            }
        };

        colWidths.map((col, index) => {
            worksheet1.getColumn(index + 1).width = col.width;
        });

        excelRow.eachCell((cell) => {
            cell.style = contentStyle;
            cell.border = {
                top: { style: 'thin', color: { argb: '000000' } },
                left: { style: 'thin', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'thin', color: { argb: '000000' } }
            };
        });
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${t("WorkTimeSummaryByDepartment")}.xlsx`;
        a.click();
        URL.revokeObjectURL(url);
    });
}
