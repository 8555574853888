import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Chip,
  Collapse,
  IconButton,
  Link,
  Grid,
  styled,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

import FingerprintIcon from "@mui/icons-material/Fingerprint";
import MoreTimeRoundedIcon from "@mui/icons-material/MoreTimeRounded";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import QrCodeIcon from "@mui/icons-material/QrCode2";
import GpsFixedRoundedIcon from "@mui/icons-material/GpsFixedRounded";
import PlaylistAddCircleRoundedIcon from "@mui/icons-material/PlaylistAddCircleRounded";
import Modal from '@mui/material/Modal';
import StatusRequest from "../general/stausRequest";
import { useSelector } from "react-redux";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getTranslationDateLabel } from "../../../../utils/constant";

const StyledTableCellContent = styled(TableCell)({
  textAlign: "center",
  borderBottom: "none",
  padding: "8px 16px",
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  "&.status": {
    minWidth: 50,
    "& .MuiBox-root": {
      margin: "auto",
      "&:hover": {
        cursor: "pointer",
        transform: "scale(1.15)",
      },
    },
  },
  "& .fullname": {
    width: 250,
    "& .MuiAvatar-root": {
      marginRight: 16,
    },
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    "& .name": {
      textAlign: "left",
      fontWeight: 600,
      fontSize: 14,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: 300,
    },
  },
  "& .actual-plan": {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    maxWidth: 200,
    margin: "auto",
    padding: "0 20px",
  },
  "& .actual": {
    fontWeight: 600,
    fontSize: 24,
  },
  "& .plan": {
    fontWeight: 600,
    color: "#919eab",
  },
  "&:first-of-type": {
    paddingLeft: 16,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
});

function ImagePopup({ openImage, handleCloseImage, imageUrl }) {
  return (
    <Modal
      open={openImage}
      onClose={handleCloseImage}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <img src={imageUrl} alt="Popup" style={{ maxHeight: '90%', maxWidth: '90%' }} onClick={handleCloseImage} />
      </div>
    </Modal>
  );
}

const Row = (props) => {
  let today = dayjs().toDate();
  dayjs.extend(isSameOrAfter);
  const { t, i18n } = useTranslation();

  const StyledChip = styled(Chip)(({ status }) => ({
    margin: 0,
    fontSize: 14,
    fontWeight: 600,
    height: 26,
    ...(status === `${t("WorkingTimeRequest")}` && {
      backgroundColor: "#fffcd6",
      color: "#eebb00",
      "& .MuiSvgIcon-root": {
        color: "#eebb00",
      },
      "&.MuiButtonBase-root:hover": {
        backgroundColor: "#fff9ab",
      },
    }),
    ...(status === `${t("Overtime")}` && {
      backgroundColor: "#e9f0ff",
      color: "#1e4db6",
      "& .MuiSvgIcon-root": {
        color: "#1e4db6",
      },
      "&.MuiButtonBase-root:hover": {
        backgroundColor: "#d4e0f9",
      },
    }),
    ...(status === `${t("Absent")}` && {
      backgroundColor: "#fdf3f5",
      color: "#fc4b6c",
    }),
    ...(status === `${t("Leave")}` && {
      backgroundColor: "#ecfff5",
      color: "#38ca7f",
    }),
    ...(status === `${t("Late")}` && {
      backgroundColor: "#ddf0ff",
      color: "#1997f5",
    }),
    ...(status === `${t("AnnualHoliday")}` && {
      backgroundColor: "#f7f5ff",
      color: "#7451ff",
    }),
    ...(status === `CompensationDay` && {
      backgroundColor: "#fbeff7",
      color: "#D862BC",
    }),
    ...((status === `${t("SickLeave")}` ||
      status === `${t("PersonalLeave")}` ||
      status === `${t("VacationLeave")}`) && {
      backgroundColor: "#ecfff5",
      color: "#38ca7f",
    }),
  }));

  const { row, handleClickOpenAddNewTimeline, handleClickEdit, mode, handleClickDelete, handleClickShiftChange, handleOpenAttendanceMenu } = props;

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: payrollSettingList } = useSelector(
    (state) => state.payrollSetting
  );
  const [open, setOpen] = useState(false);
  const [displayCheckInOut, setDisplayCheckInOut] = useState([]);
  const [openImage, setOpenImage] = React.useState(false);
  const [isOpenImage, setIsOpenImage] = React.useState();

  const handleOpenImage = () => {
    setOpenImage(true);
  };

  const handleCloseImage = () => {
    setOpenImage(false);
  };

  useEffect(() => {
    if (row && row.attendanceDisplay && row.attendanceDisplay.length > 0) {
      setDisplayCheckInOut(row.attendanceDisplay);
    } else {
      setDisplayCheckInOut([]);
      setIsOpenImage(false)
    }
  }, [row]);

  useEffect(() => {
    if (displayCheckInOut && displayCheckInOut.length > 0) {
      displayCheckInOut.map((checkInOut, index) => {
        let checkIn = checkInOut.checkIn;
        let checkOut = checkInOut.checkOut;
        //console.log(checkInOut)
        if((checkIn && checkIn.fileName!= null) || (checkOut && checkOut.fileName!= null)) {
          setIsOpenImage(true)
        }
      })
    } 
  }, [displayCheckInOut]);

  const whatRequestTimeLevel = (request) => {
    if (request.isDoubleApproval) {
      if (request.isManagerLV1Approve === 1) {
        return 2;
      } else {
        return 1;
      }
    } else {
      return null;
    }
  };

  const isRequestApprove = (request) => {
    if (request.isActive === 0 || request.isWithdraw === 1) {
      return 3;
    }
    if (request.isDoubleApproval === 1) {
      if (
        request.isManagerLV1Approve === 0 ||
        request.isManagerLV2Approve === 0
      ) {
        return 0;
      } else if (
        request.isManagerLV1Approve === null ||
        request.isManagerLV2Approve === null
      ) {
        return null;
      } else if (
        request.isManagerLV1Approve === 1 &&
        request.isManagerLV2Approve === 1
      ) {
        return 1;
      }
    } else {
      if (request.approvalLevel === 1) {
        return request.isManagerLV1Approve;
      } else if (request.approvalLevel === 2) {
        return request.isManagerLV2Approve;
      }
    }
  };

  const renderOT = (row) => {
    if (payrollSettingList) {
      const payrollSetting = payrollSettingList;

      let textRow = [];
      if (payrollSetting) {
        if (row.xOT > 0) {
          textRow.push(
            <Typography
              style={{ fontWeight: 500 }}
              color="text.third"
              key="xOT"
            >
              {`OT x ${payrollSetting.xOT}`} ={" "}
              {parseFloat(row.xOT / 60).toFixed(2)} {t("Unit.ShortHours")}
            </Typography>
          );
        }
        if (row.xWorkingDaily > 0) {
          textRow.push(
            <Typography
              style={{ fontWeight: 500 }}
              color="text.third"
              key="xWorkingDaily"
            >
              {`OT x 1`} ={" "}
              {parseFloat(row.xWorkingDaily / 60).toFixed(2)} {t("Unit.ShortHours")}
            </Typography>
          );
        }
        if (row.xWorkingDailyHoliday > 0) {
          textRow.push(
            <Typography
              style={{ fontWeight: 500 }}
              color="text.third"
              key="xWorkingDailyHoliday"
            >
              {`OT x ${payrollSetting.xWorkingDailyHoliday}`} ={" "}
              {parseFloat(row.xWorkingDailyHoliday / 60).toFixed(2)} {t("Unit.ShortHours")}
            </Typography>
          );
        }
        if (row.xWorkingMonthlyHoliday > 0) {
          textRow.push(
            <Typography
              style={{ fontWeight: 500 }}
              color="text.third"
              key="xWorkingMonthlyHoliday"
            >
              {`OT x ${payrollSetting.xWorkingMonthlyHoliday}`} ={" "}
              {parseFloat(row.xWorkingMonthlyHoliday / 60).toFixed(2)} {t("Unit.ShortHours")}
            </Typography>
          );
        }
        if (row.xOTHoliday > 0) {
          textRow.push(
            <Typography
              style={{ fontWeight: 500 }}
              color="text.third"
              key="xOTHoliday"
            >
              {`OT x ${payrollSetting.xOTHoliday}`} ={" "}
              {parseFloat(row.xOTHoliday / 60).toFixed(2)} {t("Unit.ShortHours")}
            </Typography>
          );
        }
        if (textRow.length === 0) {
          return <Typography color="text.third">{""}</Typography>;
        } else {
          return textRow;
        }
      }
    } else {
      return (
        <Typography style={{ fontWeight: 500 }} color="text.third" key="xOT">
          {t("NoPayrollSettings")}
        </Typography>
      );
    }
  };

  return (
    <Fragment>
      <TableRow>
        <TableCell className="tableCell">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowUpRoundedIcon />
            ) : (
              <KeyboardArrowDownRoundedIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell className={`tableCell`}>
          <Typography>
            {dayjs(row.date).format(i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY")}
          </Typography>
          <Typography
            component={"span"}
            style={{ fontSize: 14 }}
            color="text.secondary"
          >
            {dayjs(row.date).locale(getTranslationDateLabel(i18n.language)).format("(dddd)")}
          </Typography>
        </TableCell>
        <TableCell className={`tableCell`}>
          <Box
            sx={{
              cursor: "pointer",
              userSelect: "none",
            }}
            onClick={() => {
              if (mode === "admin") {
                handleClickShiftChange(row)
              }
            }}
          >
            <Typography>
              {row.pattern.idWorkingType === 1
                ? `${row.pattern.shiftGroupName}  (${row.pattern.shiftName})`
                : row.pattern.idHoliday
                  ? t("PublicHoliday")
                  : `${row.pattern.shiftGroupName}`}
            </Typography>
          </Box>
        </TableCell>
        <TableCell className={`tableCell`}>
          <Box
            sx={{
              cursor: "pointer",
              userSelect: "none",
            }}
            onClick={() => {
              if (mode === "admin") {
                handleClickShiftChange(row)
              }
            }}
          >
            <Typography
              color="text.third"
              style={{ fontStyle: "oblique", fontWeight: "normal" }}
            >
              {row.pattern.isWorkingDay === 1
                ? row.pattern.isTimeFrame === 1
                  ? `${dayjs(row.pattern.timeIn, "HH:mm:ss").format(
                    i18n.resolvedLanguage === "th" ? "HH:mm" : "h:mm A"
                  )} - ${dayjs(row.pattern.timeOut, "HH:mm:ss").format(
                    i18n.resolvedLanguage === "th" ? "HH:mm" : "h:mm A"
                  )}`
                  : "-"
                : row.pattern.shiftTypeName}
            </Typography>
          </Box>
        </TableCell>
        {displayCheckInOut.length <= 0 ? (
          <TableCell colSpan={2}>
            <Link
              component="button"
              variant="body2"
              disabled={dayjs(row.date).isBefore(
                dayjs(today).subtract(1, "month"),
                "month"
              )}
              onClick={() => {
                handleClickOpenAddNewTimeline(row);
              }}
            >
              {`${t("NoWorkingHours")}`}
            </Link>
          </TableCell>
        ) : (
          <TableCell colSpan={2} className="tableCell cellStartStop">
            <Grid container spacing={2}>
              {displayCheckInOut.map((checkInOut, index) => {
                let checkIn = checkInOut.checkIn;
                let checkOut = checkInOut.checkOut;

                return (
                  <Grid item container xs={12} key={index}>
                    <Grid
                      item
                      xs={6}
                      style={{ height: "fit-content", minHeight: "100%" }}
                    >
                      <div
                        key={index}
                        disabled={dayjs(row.date).isBefore(
                          dayjs(today).subtract(1, "month"),
                          "month"
                        )}
                        onClick={() => {
                          if (
                            dayjs(row.date).isSameOrAfter(
                              dayjs(today).subtract(1, "month"),
                              "month"
                            )
                          ) {
                            handleClickOpenAddNewTimeline(row);
                          }
                        }}
                      >
                        {checkIn ? (
                          <Fragment>
                            <div
                              onContextMenu={(event) => {
                                handleOpenAttendanceMenu(event, checkIn)
                              }}
                              style={{ cursor: 'context-menu' }}
                            >
                              <Typography className="time" style={{
                                color: checkIn.isNotActive ? "#FF0000" : "#000000"
                              }}>
                                {checkIn.idAttendanceType === 1 && (
                                  <LocationOnIcon />
                                )}
                                {checkIn.idAttendanceType === 2 && (
                                  <FingerprintIcon />
                                )}
                                {checkIn.idAttendanceType === 4 && (
                                  <GpsFixedRoundedIcon />
                                )}
                                {checkIn.idAttendanceType === 5 && <QrCodeIcon />}
                                {checkIn.idAttendanceType === 8 && <MoreTimeRoundedIcon />}
                                {checkIn.attendanceTextTime}
                              </Typography>
                              {checkIn.idAttendanceType === 5 ? (
                                <Typography
                                  variant="caption"
                                  color="text.secondary"
                                  className="location"
                                >
                                  {checkIn.groupGpsLocationsName || ""}
                                </Typography>
                              ) : checkIn.idAttendanceType === 4 ? (
                                <Typography
                                  variant="caption"
                                  color="text.secondary"
                                  className="location"
                                >
                                  {checkIn.gpsAddress || ""}
                                </Typography>
                              ) : (
                                <Typography
                                  variant="caption"
                                  color="text.secondary"
                                  className="location"
                                >
                                  {checkIn.gpsLocationsName || ""}
                                </Typography>
                              )}
                            </div>
                          </Fragment>
                        ) : (
                          <Link
                            component="button"
                            variant="body2"
                            onClick={() => {
                              if (
                                dayjs(row.date).isSameOrAfter(
                                  dayjs(today).subtract(1, "month"),
                                  "month"
                                )
                              ) {
                                handleClickOpenAddNewTimeline(row);
                              }
                            }}
                          >
                            {`${t("NoTimeIn")}`}
                          </Link>
                        )}
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{ height: "fit-content", minHeight: "100%" }}
                    >
                      <div
                        key={index}
                        onClick={() => {
                          if (
                            dayjs(row.date).isSameOrAfter(
                              dayjs(today).subtract(1, "month"),
                              "month"
                            )
                          ) {
                            handleClickOpenAddNewTimeline(row);
                          }
                        }}
                      >
                        {checkOut ? (
                          <Fragment>
                            <div
                              onContextMenu={(event) => {
                                handleOpenAttendanceMenu(event, checkOut)
                              }}
                              style={{ cursor: 'context-menu' }}
                            >
                              <Typography className="time" style={{
                                color: checkOut.isNotActive ? "#FF0000" : "#000000"
                              }}>
                                {checkOut.idAttendanceType === 1 && (
                                  <LocationOnIcon />
                                )}
                                {checkOut.idAttendanceType === 2 && (
                                  <FingerprintIcon />
                                )}
                                {checkOut.idAttendanceType === 4 && (
                                  <GpsFixedRoundedIcon />
                                )}
                                {checkOut.idAttendanceType === 5 && (
                                  <QrCodeIcon />
                                )}
                                {checkOut.idAttendanceType === 8 && <MoreTimeRoundedIcon />}
                                {checkOut.attendanceTextTime}
                              </Typography>
                              {checkOut.idAttendanceType === 5 ? (
                                <Typography
                                  variant="caption"
                                  color="text.secondary"
                                  className="location"
                                >
                                  {checkOut.groupGpsLocationsName || ""}
                                </Typography>
                              ) : checkOut.idAttendanceType === 4 ? (
                                <Typography
                                  variant="caption"
                                  color="text.secondary"
                                  className="location"
                                >
                                  {checkOut.gpsAddress || ""}
                                </Typography>
                              ) : (
                                <Typography
                                  variant="caption"
                                  color="text.secondary"
                                  className="location"
                                >
                                  {checkOut.gpsLocationsName || ""}
                                </Typography>
                              )}
                            </div>
                          </Fragment>
                        ) : (
                          <Link
                            component="button"
                            variant="body2"
                            onClick={() => {
                              if (
                                dayjs(row.date).isSameOrAfter(
                                  dayjs(today).subtract(1, "month"),
                                  "month"
                                )
                              ) {
                                handleClickOpenAddNewTimeline(row);
                              }
                            }}
                          >
                            {`${t("NoTimeOut")}`}
                          </Link>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </TableCell>
        )}

        <TableCell align="center" className={`tableCell cellStatusAction`}>
          <div className="wrap-status">
            {row.requestTime && row.requestTime.length > 0 && (
              <div className="item">
                <PlaylistAddCircleRoundedIcon style={{ color: "#eebb00" }} />
              </div>
            )}
            {row.ot && row.ot.length > 0 && (
              <div className="item">
                <PlaylistAddCircleRoundedIcon style={{ color: "#1e4db6" }} />
              </div>
            )}
            {row.requestCompensate && row.requestCompensate.length > 0 && (
              <div className="item">
                <PlaylistAddCircleRoundedIcon style={{ color: "#00C169" }} />
              </div>
            )}
            {
              row.leave
              && row.leave.filter(l => l.isActive === 1 && (l.isWithdraw !== 1 ? true : l.isApproveWithdraw !== 1)).length > 0
              && (
                <div className="item">
                  <PlaylistAddCircleRoundedIcon style={{ color: "#ff6d00" }} />
                </div>
              )}
            {(
              !row.pattern.isShiftDaily && row.holiday && !row.isCompensation
            ) && (
                <div className="item">
                  <StyledChip
                    label={`${t("AnnualHoliday")}`}
                    status={t("AnnualHoliday")}
                  />
                </div>
              )}
            {(
              (row.pattern.idWorkingType === 2 && row.isCompensation)
            ) && (
                <div className="item">
                  <StyledChip
                    label={`${t("CompensationDay")}`}
                    status={"CompensationDay"}
                  />
                </div>
              )}
            {row.isLate && (
              <div className="item">
                <StyledChip label={`${t("Late")}`} status={t("Absent")} />
              </div>
            )}
            {row.isEarlyOut && (
              <div className="item">
                <StyledChip label={`${t("LeaveEarly")}`} status={t("Absent")} />
              </div>
            )}
            {row.absent && (
              <div className="item">
                <StyledChip label={`${t("Absent")}`} status={t("Absent")} />
              </div>
            )}
            {!row.absent &&
              row.isNotFullDay &&
              row.pattern.isTimeFrame === 0 &&
              row.pattern.workingHours && (
                <div className="item">
                  <StyledChip label={t("IncompleteWorkingHours")} status={t("Absent")} />
                </div>
              )}
            {row.pattern.idWorkingType === 1 &&
              // row.pattern.isCompensationHoliday === 1 
              row.isCompensation
              && (
                <div className="item">
                  <StyledChip
                    label={`${t("AnnualHolidayShift")}`}
                    status={t("AnnualHoliday")}
                  />
                </div>
              )}
            {row.pattern.holidayName && (
              <div className="item">
                <Typography color="text.third" variant="body2">
                  {row.pattern.holidayName}
                </Typography>
              </div>
            )}
          </div>
        </TableCell>
        {displayCheckInOut.length <= 0 ? (
          <TableCell colSpan={2}>
            <div
              //component="button"
              variant="body2"
              disabled={dayjs(row.date).isBefore(
                dayjs(today).subtract(1, "month"),
                "month"
              )}
            >
              {`${t("noPictureCheckInOut")}`}
            </div>
          </TableCell>
        ) : (
          (isOpenImage ?
            <TableCell colSpan={2} className="tableCell cellStartStop">
              <Grid container spacing={2}>
                {displayCheckInOut.map((checkInOut, index) => {
                  let checkIn = checkInOut.checkIn;
                  let checkOut = checkInOut.checkOut;

                  return (

                    <Grid item container xs={12} key={index}>
                      <Grid
                        item
                        xs={6}
                        style={{ height: "fit-content", minHeight: "100%" }}
                      >
                        <div
                          key={index}
                          disabled={dayjs(row.date).isBefore(
                            dayjs(today).subtract(1, "month"),
                            "month"
                          )}
                        >
                          {checkIn && checkIn.fileUrl ? (
                            <Fragment>
                              <div
                                onContextMenu={(event) => {
                                  handleOpenAttendanceMenu(event, checkIn)
                                }}
                                onClick={() => {
                                  handleOpenImage();
                                }}
                                style={{ cursor: 'context-menu' }}
                              >
                                <Typography className="time" style={{
                                  color: checkIn.isNotActive ? "#FF0000" : "#000000"
                                }}>
                                  <PhotoCameraIcon />
                                  {checkIn.attendanceTextTime}
                                </Typography>
                              </div>

                              <ImagePopup openImage={openImage} handleCloseImage={handleCloseImage} imageUrl={checkIn.fileUrl} />
                            </Fragment>
                          ) : (
                            <div
                              variant="body2"
                            >
                              {`${t("noPictureCheckIn")}`}
                            </div>
                          )}
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{ height: "fit-content", minHeight: "100%" }}
                      >
                        <div
                          key={index}
                        >
                          {checkOut && checkOut.fileUrl ? (
                            <Fragment>
                              <div
                                onContextMenu={(event) => {
                                  handleOpenAttendanceMenu(event, checkOut)
                                }}
                                onClick={() => {
                                  handleOpenImage();
                                }}
                                style={{ cursor: 'context-menu' }}
                              >
                                <Typography className="time" style={{
                                  color: checkOut.isNotActive ? "#FF0000" : "#000000"
                                }}>
                                  <PhotoCameraIcon />
                                  {checkOut.attendanceTextTime}
                                </Typography>
                              </div>
                              <ImagePopup openImage={openImage} handleCloseImage={handleCloseImage} imageUrl={checkOut.fileUrl} />
                            </Fragment>
                          ) : (
                            <div
                              variant="body2"
                            >
                              {`${t("noPictureCheckOut")}`}
                            </div>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </TableCell> :
            <TableCell colSpan={2}>
              <div
                //component="button"
                variant="body2"
                disabled={dayjs(row.date).isBefore(
                  dayjs(today).subtract(1, "month"),
                  "month"
                )}
              >
                {`${t("noPictureCheckInOut")}`}
              </div>
            </TableCell>)
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box style={{ margin: 8 }}>
              <Typography align="left" variant="h6" gutterBottom>
                {`${t("List")}`}
              </Typography>
              {row.requestTime &&
                [...row.requestTime].map((request, index) => (
                  <TableRow key={index}>
                    <StyledTableCellContent align="center" className="status">
                      <StatusRequest
                        boxSize={40}
                        fontSize={22}
                        borderRadius={8}
                        step={whatRequestTimeLevel(request)}
                        status={isRequestApprove(request)}
                        active={request.isActive}
                      />
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Chip
                        variant="outlined"
                        icon={
                          <PlaylistAddCircleRoundedIcon
                            style={{ color: "#eebb00" }}
                          />
                        }
                        label={`${t("WorkingTimeRequest")}`}
                        onClick={() => {
                          if (![2, 3].includes(isRequestApprove(request)))
                            handleClickEdit(row, request);
                        }}
                      />
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography align="center" variant="h6">
                        {dayjs(request.startText, "DD/MM/YYYY HH:mm").format(
                          i18n.resolvedLanguage === "th" ? "HH:mm" : "h:mm A"
                        )}
                      </Typography>
                      <Typography
                        align="center"
                        variant="body2"
                        color="text.third"
                      >
                        {dayjs(request.startText, "DD/MM/YYYY HH:mm").format(
                          i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
                        )}
                      </Typography>
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography align="center" variant="h6">
                        {dayjs(request.endText, "DD/MM/YYYY HH:mm").format(
                          i18n.resolvedLanguage === "th" ? "HH:mm" : "h:mm A"
                        )}
                      </Typography>
                      <Typography
                        align="center"
                        variant="body2"
                        color="text.third"
                      >
                        {dayjs(request.endText, "DD/MM/YYYY HH:mm").format(
                          i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
                        )}
                      </Typography>
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography align="center">
                        {request.reasonName ? request.reasonName : ""}
                      </Typography>
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography align="center">
                        {request.otherReason ? request.otherReason : ""}
                      </Typography>
                    </StyledTableCellContent>
                  </TableRow>
                ))}
              {row.ot &&
                row.ot.map((ot, index) => (
                  <TableRow key={index}>
                    <StyledTableCellContent align="center" className="status">
                      <StatusRequest
                        boxSize={40}
                        fontSize={22}
                        borderRadius={8}
                        step={whatRequestTimeLevel(ot)}
                        status={isRequestApprove(ot)}
                        active={ot.isActive}
                      />
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Chip
                        variant="outlined"
                        icon={
                          <PlaylistAddCircleRoundedIcon
                            style={{ color: "#1e4db6" }}
                          />
                        }
                        onClick={() => {
                          if (![2, 3].includes(isRequestApprove(ot)))
                            handleClickEdit(row, ot);
                        }}
                        sx={{ "&.Mui-disabled": { opacity: 1 } }}
                        disabled={userProfile.isFillIn === 1}
                        label={`${t("OvertimeRequest")}`}
                      />
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography align="center" variant="h6">
                        {dayjs(ot.startText, "DD/MM/YYYY HH:mm").format(
                          i18n.resolvedLanguage === "th" ? "HH:mm" : "h:mm A"
                        )}
                      </Typography>
                      <Typography
                        align="center"
                        variant="body2"
                        color="text.third"
                      >
                        {dayjs(ot.startText, "DD/MM/YYYY HH:mm").format(
                          i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
                        )}
                      </Typography>
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography align="center" variant="h6">
                        {dayjs(ot.endText, "DD/MM/YYYY HH:mm").format(
                          i18n.resolvedLanguage === "th" ? "HH:mm" : "h:mm A"
                        )}
                      </Typography>
                      <Typography
                        align="center"
                        variant="body2"
                        color="text.third"
                      >
                        {dayjs(ot.endText, "DD/MM/YYYY HH:mm").format(
                          i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
                        )}
                      </Typography>
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      {renderOT(ot)}
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography align="center">
                        {ot.reasonName ? ot.reasonName : ""}
                      </Typography>
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography align="center">{ot.otherReason}</Typography>
                    </StyledTableCellContent>
                  </TableRow>
                ))}
              {row.requestCompensate &&
                row.requestCompensate.map((request, index) => (
                  <TableRow key={index}>
                    <StyledTableCellContent align="center" className="status">
                      <StatusRequest
                        boxSize={40}
                        fontSize={22}
                        borderRadius={8}
                        step={whatRequestTimeLevel(request)}
                        status={isRequestApprove(request)}
                        active={request.isActive}
                      />
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Chip
                        variant="outlined"
                        icon={
                          <PlaylistAddCircleRoundedIcon
                            style={{ color: "#00C169" }}
                          />
                        }
                        onClick={() => {
                          if (![2, 3].includes(isRequestApprove(request)))
                            handleClickEdit(row, request);
                        }}
                        sx={{ "&.Mui-disabled": { opacity: 1 } }}
                        disabled={userProfile.isFillIn === 1}
                        label={`${t("CompensateRequest")}`}
                      />
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography align="center" variant="h6">
                        {dayjs(request.startText, "DD/MM/YYYY HH:mm").format(
                          i18n.resolvedLanguage === "th" ? "HH:mm" : "h:mm A"
                        )}
                      </Typography>
                      <Typography
                        align="center"
                        variant="body2"
                        color="text.third"
                      >
                        {dayjs(request.startText, "DD/MM/YYYY HH:mm").format(
                          i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
                        )}
                      </Typography>
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography align="center" variant="h6">
                        {dayjs(request.endText, "DD/MM/YYYY HH:mm").format(
                          i18n.resolvedLanguage === "th" ? "HH:mm" : "h:mm A"
                        )}
                      </Typography>
                      <Typography
                        align="center"
                        variant="body2"
                        color="text.third"
                      >
                        {dayjs(request.endText, "DD/MM/YYYY HH:mm").format(
                          i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
                        )}
                      </Typography>
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography align="center">
                        {request.reasonName ? request.reasonName : ""}
                      </Typography>
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography align="center">
                        {request.otherReason ? request.otherReason : ""}
                      </Typography>
                    </StyledTableCellContent>
                  </TableRow>
                ))}
              {row.leave &&
                [...row.leave].map((request, index) => (
                  <TableRow key={index}>
                    <StyledTableCellContent align="center" className="status" >
                      <StatusRequest
                        boxSize={40}
                        fontSize={22}
                        borderRadius={8}
                        status={
                          request.isActive === 0
                            ? 3
                              : request.isDoubleApproval === 1 ? 
                                (
                                  (
                                    request.isManagerLV1Approve === 1 && request.isManagerLV2Approve === 1
                                  ) ?
                                    1 : (
                                      request.isManagerLV1Approve === 0 || request.isManagerLV2Approve === 0
                                    ) ? 
                                      0 : 2
                                )
                                : (
                                  request.approvalLevel === 2 ?
                                    (
                                      request.isManagerLV2Approve === 1 ? 
                                        1 : (
                                          request.isManagerLV2Approve === 0
                                        ) ? 0 : 2
                                    )
                                    : (
                                      request.isManagerLV1Approve === 1 ?
                                        1 : (
                                          request.isManagerLV1Approve === 0
                                        ) ? 0 : 2
                                    )
                                )
                        }
                        active={request.isActive}
                      />
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Chip
                        variant="outlined"
                        icon={
                          <PlaylistAddCircleRoundedIcon
                            style={{ color: "#ff6d00" }}
                          />
                        }
                        label={`${t("LeaveRequest")}`}
                        onClick={() => {
                          handleClickDelete(request)
                        }}
                      />
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      {request.isFullDay === 1 ? (
                        <Typography
                          align="center"
                          variant="h6"
                          style={{ fontSize: 16 }}
                        >
                          {dayjs(request.startText, "DD/MM/YYYY HH:mm").format(
                            i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
                          )}
                        </Typography>
                      ) : (
                        <Fragment>
                          <Typography align="center" variant="h6">
                            {dayjs(
                              request.startText,
                              "DD/MM/YYYY HH:mm"
                            ).format(i18n.resolvedLanguage === "th" ? "HH:mm" : "h:mm A")}
                          </Typography>
                          <Typography
                            align="center"
                            variant="body2"
                            color="text.third"
                          >
                            {dayjs(
                              request.startText,
                              "DD/MM/YYYY HH:mm"
                            ).format(i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY")}
                          </Typography>
                        </Fragment>
                      )}
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      {request.isFullDay === 1 ? (
                        <Typography
                          align="center"
                          variant="h6"
                          style={{ fontSize: 16 }}
                        >
                          {dayjs(request.endText, "DD/MM/YYYY HH:mm").format(
                            i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
                          )}
                        </Typography>
                      ) : (
                        <Fragment>
                          <Typography align="center" variant="h6">
                            {dayjs(request.endText, "DD/MM/YYYY HH:mm").format(
                              i18n.resolvedLanguage === "th" ? "HH:mm" : "h:mm A"
                            )}
                          </Typography>
                          <Typography
                            align="center"
                            variant="body2"
                            color="text.third"
                          >
                            {dayjs(request.endText, "DD/MM/YYYY HH:mm").format(
                              i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
                            )}
                          </Typography>
                        </Fragment>
                      )}
                    </StyledTableCellContent>
                    <StyledTableCellContent colSpan={3}>
                      <Typography align="center" style={{ fontWeight: 600 }}>
                        {request.name}
                      </Typography>
                      <Typography align="center" color="text.secondary">
                        {request.description}
                      </Typography>
                    </StyledTableCellContent>
                  </TableRow>
                ))}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default Row;
