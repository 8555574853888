import React from "react";
import { Chip, Divider, Grid, IconButton, Typography } from "@mui/material";
import { Controller, useFieldArray } from "react-hook-form";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import BackspaceIcon from "@mui/icons-material/Backspace";
import InputNumber from "./inputNumber";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import { useTranslation } from "react-i18next";

const TimeTable = ({ control, name }) => {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {fields.map((field, index) => (
          <Grid
            item
            container
            key={field.id}
            spacing={1}
            marginBottom={2}
            padding={1}
            sx={{ backgroundColor: "#f5f5f5" }}
          >
            <Grid
              item
              xs={12}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Chip
                variant="outlined"
                color="primary"
                label={`${t("Condition")} ${index + 1}`}
              />
              <IconButton
                color="error"
                onClick={() => remove(index)}
                disabled={index === 0}
              >
                <BackspaceIcon />
              </IconButton>
            </Grid>
            <Grid item xs={4}>
              <Typography>{t("StartTime")}</Typography>
              <Controller
                control={control}
                name={`${name}[${index}].starTime`}
                render={({ field, fieldState }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      {...field}
                      ampm={false}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          error={Boolean(fieldState.error)}
                          helperText={
                            fieldState.error && fieldState.error.message
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography>{t("Unit.Minute")}</Typography>
              <InputNumber
                control={control}
                name={`${name}[${index}].minutes`}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography>{t("AmountOfMoney")}</Typography>
              <InputNumber
                control={control}
                name={`${name}[${index}].amount`}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12}>
        <ButtonBlue
          variant="text"
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => {
            append({ starTime: null, minutes: "", amount: "" });
          }}
          disabled={fields.length >= 5}
        >
          {t("AddCondition")}
        </ButtonBlue>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default TimeTable;
