import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  styled,
  Card,
  Button,
  Tabs,
  Tab,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Grid,
  TextField,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrganizationObjectives,
  getTeamObjective,
  getAllIndividualObjectiveByIdEmp,
} from "../../../../../actions/okrs";
import { getAllEmployees } from "../../../../../actions/employee";
import ObjectiveCard from "../../../admin/okrs/OKRManagementForAdmin/components/ObjectiveCard";
import exportToExcel from "../../../admin/okrs/OKRManagementForAdmin/components/exportToExcel";
import SearchIcon from "@mui/icons-material/Search";
import PersonIcon from "@mui/icons-material/Person";
import EmployeeCard from "../OKRManagementForManager/EmployeeCard";

const StyledRoot = styled(Box)({
  backgroundColor: "#ffffff !important",
  paddingBottom: "150px",
});

const StyledObjectiveCard = styled(Card)({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  flexGrow: 1,
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)",
  borderRadius: 20,
  padding: "24px",
  background: `linear-gradient(to right, rgba(235, 148, 192, 0.5), rgba(188, 144, 213, 0.5))`,
  "& .cardTitle": {
    fontSize: 22,
    fontWeight: "bold",
    textAlign: "center",
    wordWrap: "break-word",
  },
  "& .percentage": {
    fontSize: 48,
    fontWeight: "bold",
    textAlign: "center",
  },
});

const GeneralManagerDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(0);
  const [selectYear, setSelectYear] = useState(dayjs().get("year"));
  const [objectiveTab, setObjectiveTab] = useState(0);
  const [approveTab, setApproveTab] = useState(0);
  const [filterType, setFilterType] = useState("");
  const [selectedDivision, setSelectedDivision] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [isFetching, setIsFetching] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const isManager = userProfile && userProfile.roles.includes("ROLE_MANAGER");
  const { result: organizationObjectives } = useSelector(
    (state) => state.organizationObjective
  );
  const [teamObjectives, setTeamObjectives] = useState([]);
  const { result: employeesList } = useSelector((state) => state.employees);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [personalObjectives, setPersonalObjectives] = useState([]);
  const [selectQuarter, setSelectQuarter] = useState("");

  useEffect(() => {
    if (activeTab === 0) {
      dispatch(getTeamObjective()).then((res) => {
        if (res.status === 200) {
          setTeamObjectives(res.data);
        }
        setIsFetching(false);
      });
      dispatch(getAllEmployees("manager"));
      dispatch(getOrganizationObjectives());
    }
  }, [dispatch, activeTab]);

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleChangeObjectiveTab = (event, newValue) => {
    setObjectiveTab(newValue);
    setFilterType("");
    setSelectedDepartment("");
    setSelectedDivision("");
  };

  const handleChangeApproveTab = (event, newValue) => {
    setApproveTab(newValue);
  };

  const handleChangeYear = (event) => {
    setSelectYear(event.target.value);
  };

  const handleExportClick = () => {
    if (objectiveTab === 0) {
      exportToExcel(organizationObjectives, t);
    } else if (objectiveTab === 1) {
      exportToExcel(teamObjectives, t);
    } else if (objectiveTab === 2) {
      exportToExcel(personalObjectives, t);
    }
  };

  const filterObjectivesBySearch = (objectives) => {
    return objectives.filter((objective) =>
      objective.objectiveName.toLowerCase().includes(searchQuery)
    );
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const handleChangeEmployee = (event, newValue) => {
    if (newValue) {
      setSelectedEmployee(newValue.idEmployees);
      dispatch(getAllIndividualObjectiveByIdEmp(newValue.idEmployees)).then(
        (res) => {
          if (res.status === 200) {
            setPersonalObjectives(res.data);
          }
        }
      );
    } else {
      setSelectedEmployee("");
      setPersonalObjectives([]);
    }
  };

  const filteredObjectives = personalObjectives.filter(
    (objective) => objective.idEmployees === selectedEmployee
  );

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Box
          className="header-box"
          sx={{
            padding: "16px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            background:
              "linear-gradient(to right, rgba(235, 148, 192, 0.5), rgba(188, 144, 213, 0.5))",
            borderRadius: "16px",
          }}
        >
          <Typography
            className="header-text"
            sx={{ fontSize: "48px", fontWeight: "bold" }}
          >
            OKR Management
          </Typography>
        </Box>

        {/* activeTab */}
        <Box marginTop={2} marginBottom={4} sx={{ borderBottom: "2px" }}>
          <Tabs
            value={activeTab}
            onChange={handleChangeTab}
            indicatorColor="primary"
          >
            <Tab label={t("Home")}></Tab>
            <Tab label={t("WaitingForApprove")}></Tab>
          </Tabs>
        </Box>

        {activeTab === 0 && (
          <>
            <Box
              display="flex"
              alignItems="center"
              marginBottom={4}
              padding="24px"
              borderRadius="20px"
              backgroundColor="#ffffff"
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="flex-start"
                gap={1}
                mb={2}
              >
                <Typography variant="h6" gutterBottom>
                  {t("OrganizationalGoals")}
                </Typography>
                <Typography variant="h5" fontWeight="bold">
                  {userProfile ? userProfile.companyName : ""}
                </Typography>
              </Box>

              <FormControl className="filter-year" sx={{ marginLeft: "24px" }}>
                <InputLabel>{t("SelectYear")}</InputLabel>
                <Select
                  label={t("SelectYear")}
                  value={selectYear}
                  onChange={handleChangeYear}
                  sx={{ borderRadius: "10px", backgroundColor: "#ffffff" }}
                >
                  {[0, 1, 2].map((item) => (
                    <MenuItem key={item} value={dayjs().get("year") - item}>
                      {dayjs().get("year") - item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Grid container spacing={3} marginBottom={4} alignItems="stretch">
              {organizationObjectives &&
                organizationObjectives
                  .filter((objective) => objective.year === selectYear)
                  .sort(
                    (a, b) => new Date(b.updateDate) - new Date(a.updateDate)
                  )
                  .slice(0, 3)
                  .map((objective) => (
                    <Grid
                      item
                      xs={12}
                      md={4}
                      key={objective.idOrganizationObjective}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <StyledObjectiveCard>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                        >
                          <Typography
                            className="percentage"
                            style={{ color: "#DB4178" }}
                          >
                            {`${objective.progressPercent}%`}
                          </Typography>
                          <Typography className="cardTitle" align="center">
                            {objective.objectiveName}
                          </Typography>
                        </Box>
                      </StyledObjectiveCard>
                    </Grid>
                  ))}
            </Grid>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
              marginBottom={4}
            >
              <Tabs
                value={objectiveTab}
                onChange={handleChangeObjectiveTab}
                indicatorColor="primary"
              >
                <Tab label={t("OrganizationalGoals")}></Tab>
                <Tab label={t("TeamGoals")}></Tab>
                <Tab label={t("PersonalGoals(Emp)")}></Tab>
              </Tabs>
            </Box>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
              marginBottom={4}
            >
              <Box
                display="flex"
                alignItems="center"
                flexWrap="wrap"
                gap={2}
                sx={{ flexGrow: 1, minWidth: "300px" }}
              >
                <FormControl sx={{ minWidth: 200 }}>
                  <InputLabel shrink>{t("SelectQuarter")}</InputLabel>
                  <Select
                    label={t("SelectQuarter")}
                    value={selectQuarter || ""}
                    onChange={(e) => setSelectQuarter(e.target.value)}
                    sx={{ borderRadius: "10px", backgroundColor: "#ffffff" }}
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected === "") {
                        return <span>{t("All")}</span>;
                      }
                      return t(`Q${selected}`);
                    }}
                  >
                    <MenuItem value="">{t("All")}</MenuItem>
                    <MenuItem value="1">{t("Q1")}</MenuItem>
                    <MenuItem value="2">{t("Q2")}</MenuItem>
                    <MenuItem value="3">{t("Q3")}</MenuItem>
                    <MenuItem value="4">{t("Q4")}</MenuItem>
                  </Select>
                </FormControl>
                {objectiveTab === 2 && (
                  <>
                    <Autocomplete
                      options={employeesList || []}
                      getOptionLabel={(option) =>
                        option
                          ? `${option.firstname_TH} ${option.lastname_TH}`
                          : ""
                      }
                      value={
                        selectedEmployee && employeesList
                          ? employeesList.find(
                              (emp) => emp.idEmployees === selectedEmployee
                            ) || null
                          : null
                      }
                      onChange={handleChangeEmployee}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("SelectEmp")}
                          placeholder={t("SearchEmp")}
                          variant="outlined"
                          sx={{
                            width: 300,
                            borderRadius: "10px",
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "10px",
                            },
                          }}
                        />
                      )}
                      sx={{
                        width: 300,
                        borderRadius: "10px",
                        backgroundColor: "#ffffff",
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.idEmployees === value.idEmployees
                      }
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          {...props}
                          display="flex"
                          alignItems="center"
                          gap={1}
                        >
                          {option.imageProfile ? (
                            <img
                              src={option.imageProfile}
                              alt={option.firstname_TH}
                              style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "50%",
                                objectFit: "cover",
                              }}
                            />
                          ) : (
                            <PersonIcon style={{ fontSize: 30 }} />
                          )}
                          {`${option.firstname_TH} ${option.lastname_TH}`}
                        </Box>
                      )}
                    />
                  </>
                )}
              </Box>

              <Box>
                <Button
                  variant="outlined"
                  sx={{
                    borderColor: "rgba(219, 65, 120, 1)",
                    color: "rgba(219, 65, 120, 1)",
                    fontWeight: "bold",
                    "&:hover": {
                      borderColor: "#C53B6C",
                      color: "#C53B6C",
                    },
                  }}
                  onClick={handleExportClick}
                >
                  {t("ExportToExcel")}
                </Button>
              </Box>
            </Box>

            {objectiveTab === 0 && (
              <Grid container spacing={3}>
                {filterObjectivesBySearch(
                  (organizationObjectives || [])
                    .filter((objective) => {
                      if (objective.year !== selectYear) return false;
                      if (
                        selectQuarter &&
                        objective.quarter !== Number(selectQuarter)
                      )
                        return false;
                      return true;
                    })
                    .sort((a, b) => {
                      const dateA = a.latestUpdateDate
                        ? dayjs(a.latestUpdateDate)
                        : dayjs(0);
                      const dateB = b.latestUpdateDate
                        ? dayjs(b.latestUpdateDate)
                        : dayjs(0);
                      return dateB.diff(dateA);
                    })
                ).map((objective) => (
                  <Grid item xs={12} key={objective.idOrganizationObjective}>
                    <ObjectiveCard objective={objective} />
                  </Grid>
                ))}
              </Grid>
            )}

            {objectiveTab === 1 && (
              <Grid container spacing={3}>
                {filterObjectivesBySearch(
                  (teamObjectives || [])
                    .filter((objective) => {
                      if (objective.year !== selectYear) return false;
                      if (
                        selectQuarter &&
                        objective.quarter !== Number(selectQuarter)
                      )
                        return false;
                      return true;
                    })
                    .sort((a, b) => {
                      const dateA = a.latestUpdateDate
                        ? dayjs(a.latestUpdateDate)
                        : dayjs(0);
                      const dateB = b.latestUpdateDate
                        ? dayjs(b.latestUpdateDate)
                        : dayjs(0);
                      return dateB.diff(dateA);
                    })
                ).map((objective) => (
                  <Grid item xs={12} key={objective.idTeamObjective}>
                    <ObjectiveCard objective={objective} />
                  </Grid>
                ))}
              </Grid>
            )}

            {objectiveTab === 2 && (
              <>
                {selectedEmployee ? (
                  <Grid container spacing={3}>
                    {filterObjectivesBySearch(
                      filteredObjectives
                        .filter((objective) => {
                          if (objective.year !== selectYear) return false;
                          if (
                            selectQuarter &&
                            objective.quarter !== Number(selectQuarter)
                          )
                            return false;
                          return true;
                        })
                        .sort((a, b) => {
                          const dateA = a.latestUpdateDate
                            ? dayjs(a.latestUpdateDate)
                            : dayjs(0);
                          const dateB = b.latestUpdateDate
                            ? dayjs(b.latestUpdateDate)
                            : dayjs(0);
                          return dateB.diff(dateA);
                        })
                    ).map((objective) => (
                      <Grid item xs={12} key={objective.idIndividualObjective}>
                        <ObjectiveCard objective={objective} />
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Typography variant="h6" align="center">
                    {t("กรุณาเลือกพนักงานเพื่อดูเป้าหมาย")}
                  </Typography>
                )}
              </>
            )}
          </>
        )}

        {activeTab === 1 && (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginBottom={4}
            >
              <Tabs
                value={approveTab}
                onChange={handleChangeApproveTab}
                indicatorColor="primary"
              >
                <Tab label={t("approvePlanTab")}></Tab>
                <Tab label={t("approveSuccessTab")}></Tab>
              </Tabs>
            </Box>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
              marginBottom={4}
            >
              <Box
                display="flex"
                alignItems="center"
                flexWrap="wrap"
                gap={2}
                sx={{ flexGrow: 1, minWidth: "300px" }}
              >
                <TextField
                  variant="outlined"
                  placeholder={t("SearchObjectives")}
                  value={searchQuery}
                  onChange={handleSearchQueryChange}
                  sx={{
                    width: "300px",
                    borderRadius: "10px",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>

            {approveTab === 0 &&
              employeesList.some(
                (employee) => employee.WaitApproveOKRCount > 0
              ) && (
                <>
                  <Grid container spacing={3}>
                    {employeesList &&
                      employeesList
                        .filter(
                          (employee) =>
                            employee.WaitApproveOKRCount > 0 &&
                            `${employee.firstname_TH} ${employee.lastname_TH}`
                              .toLowerCase()
                              .includes(searchQuery)
                        )
                        .map((employee) => (
                          <Grid item xs={12} key={employee.idEmployees}>
                            <EmployeeCard
                              employee={employee}
                              idManager={userProfile.idEmployees}
                              approveTab={approveTab}
                            />
                          </Grid>
                        ))}
                  </Grid>
                </>
              )}

            {approveTab === 1 &&
              employeesList.some(
                (employee) => employee.WaitApproveResultCount > 0
              ) && (
                <>
                  <Grid container spacing={3}>
                    {employeesList &&
                      employeesList
                        .filter((employee) =>
                          `${employee.firstname_TH} ${employee.lastname_TH}`
                            .toLowerCase()
                            .includes(searchQuery)
                        )
                        .map((employee) => (
                          <Grid item xs={12} key={employee.idEmployees}>
                            <EmployeeCard
                              employee={employee}
                              idManager={userProfile.idEmployees}
                              approveTab={approveTab}
                            />
                          </Grid>
                        ))}
                  </Grid>
                </>
              )}
          </>
        )}
      </Container>
    </StyledRoot>
  );
};

export default GeneralManagerDashboard;
