import {
  INDIVIDUAL_OBJECTIVE_FETCHING,
  INDIVIDUAL_OBJECTIVE_FAILED,
  INDIVIDUAL_OBJECTIVE_SUCCESS,
  ORGANIZATION_OBJECTIVE_FETCHING,
  ORGANIZATION_OBJECTIVE_FAILED,
  ORGANIZATION_OBJECTIVE_SUCCESS,
  TEAM_OBJECTIVE_FETCHING,
  TEAM_OBJECTIVE_SUCCESS,
  TEAM_OBJECTIVE_FAILED,
  OKR_SCOREBOARD_FETCHING,
  OKR_SCOREBOARD_FAILED,
  OKR_SCOREBOARD_SUCCESS,
  GET_TIMELINE_KEYRESULT_FAILURE,
  GET_TIMELINE_KEYRESULT_SUCCESS,
  GET_TIMELINE_KEYRESULT_FETCHING,
} from "./types";

import OkrsService from "../services/okrs.service";

export const getObjectivesByIdDivision = (idDivision) => async (dispatch) => {
  try {
    const res = await OkrsService.getObjectivesByIdDivision(idDivision);
    if (res) {
      return res;
    }
  } catch (err) {
    return err.message;
  }
};

export const getObjectivesByIdDepartment =
  (idDepartment) => async (dispatch) => {
    try {
      const res = await OkrsService.getObjectivesByIdDepartment(idDepartment);
      if (res) {
        return res;
      }
    } catch (err) {
      return err.message;
    }
  };

export const getMetadataObjective = () => async (dispatch) => {
  try {
    const res = await OkrsService.getMetadataObjective();
    if (res) {
      return res;
    }
  } catch (err) {
    return err.response;
  }
};

export const getAllObjective = () => async (dispatch) => {
  try {
    const res = await OkrsService.getAllObjective();
    if (res) {
      return res;
    }
  } catch (err) {
    return err.response;
  }
};

export const addIndividualObjective = (formData) => async () => {
  try {
    const res = await OkrsService.addIndividualObjective(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const addTeamObjective = (formData) => async () => {
  try {
    const res = await OkrsService.addTeamObjective(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const addOrganizationObjective = (formData) => async () => {
  try {
    const res = await OkrsService.addOrganizationObjective(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const getMyIndividualObjective = () => async (dispatch) => {
  try {
    dispatch({
      type: INDIVIDUAL_OBJECTIVE_FETCHING,
    });
    const res = await OkrsService.getMyIndividualObjective();
    if (res) {
      dispatch({
        type: INDIVIDUAL_OBJECTIVE_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: INDIVIDUAL_OBJECTIVE_FAILED,
    });
    console.log(err);
    return err.response;
  }
};

export const getAllIndividualObjectiveByIdEmp =
  (idEmployee) => async (dispatch) => {
    try {
      dispatch({
        type: INDIVIDUAL_OBJECTIVE_FETCHING,
      });
      const res = await OkrsService.getAllIndividualObjectiveByIdEmp(
        idEmployee
      );
      if (res) {
        dispatch({
          type: INDIVIDUAL_OBJECTIVE_SUCCESS,
          payload: res.data,
        });
        return res;
      }
    } catch (err) {
      dispatch({
        type: INDIVIDUAL_OBJECTIVE_FAILED,
      });
      console.log(err);
      return err.response;
    }
  };

export const getAllIndividualObjectiveForAllEmployees =
  () => async (dispatch) => {
    try {
      const res = await OkrsService.getAllIndividualObjectiveForAllEmployees();

      if (res) {
        return res;
      }
    } catch (err) {
      return err.response;
    }
  };

export const getTeamObjective = () => async (dispatch) => {
  try {
    const res = await OkrsService.getTeamObjective();
    if (res) {
      return res;
    }
  } catch (err) {
    return err.response;
  }
};

export const getOrganizationObjectives = () => async (dispatch) => {
  try {
    dispatch({ type: ORGANIZATION_OBJECTIVE_FETCHING });
    const res = await OkrsService.getOrganizationObjectives();
    if (res) {
      dispatch({
        type: ORGANIZATION_OBJECTIVE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({ type: ORGANIZATION_OBJECTIVE_FAILED });
  }
};

export const getIndividualObjectiveByIdIndividualObjective =
  (idIndividualObjective) => async (dispatch) => {
    try {
      const res =
        await OkrsService.getIndividualObjectiveByIdIndividualObjective(
          idIndividualObjective
        );
      if (res) {
        return res;
      }
    } catch (err) {
      return err.response;
    }
  };

export const getTeamObjectiveByIdTeamObjective =
  (idOrganizationObjective) => async (dispatch) => {
    try {
      dispatch({ type: TEAM_OBJECTIVE_FETCHING });
      const res = await OkrsService.getTeamObjectiveByIdTeamObjective(
        idOrganizationObjective
      );
      if (res) {
        dispatch({
          type: TEAM_OBJECTIVE_SUCCESS,
          payload: res.data,
        });
        return res;
      }
    } catch (err) {
      dispatch({ type: TEAM_OBJECTIVE_FAILED });
    }
  };

export const updateTeamObjectiveByIdTeamObjective =
  (id, idTeamKeyResult, payload, setTriggerFetch) => async (dispatch) => {
    try {
      const response = await OkrsService.updateTeamObjectiveByIdTeamObjective(
        id,
        idTeamKeyResult,
        payload
      );
      if (response) {
        setTriggerFetch((prevState) => !prevState);
        return response;
      }
    } catch (err) {}
  };

export const getOrganizationObjective =
  (idOrganizationObjective) => async (dispatch) => {
    try {
      dispatch({ type: ORGANIZATION_OBJECTIVE_FETCHING });
      const res = await OkrsService.getOrganizationObjective(
        idOrganizationObjective
      );
      if (res) {
        dispatch({
          type: ORGANIZATION_OBJECTIVE_SUCCESS,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({ type: ORGANIZATION_OBJECTIVE_FAILED });
    }
  };

// export const updateOrganizationObjectiveByIdOrganizationObjective =
//   (id, idOrganizationKeyResult, payload, setTriggerFetch) =>
//   async (dispatch) => {
//     try {
//       // dispatch({ type: ORGANIZATION_OBJECTIVE_FETCHING });
//       const response =
//         await OkrsService.updateOrganizationObjectiveByIdOrganizationObjective(
//           id,
//           idOrganizationKeyResult,
//           payload
//         );
//       if (response) {
//         // dispatch({
//         //   type: ORGANIZATION_OBJECTIVE_SUCCESS,
//         //   payload: response?.data,
//         // });
//         setTriggerFetch((prevState) => !prevState);
//         return response;
//       }
//     } catch (err) {
//       // dispatch({
//       //   type: ORGANIZATION_OBJECTIVE_FAILED,
//       //   payload: err.response?.data,
//       // });
//     }
//   };

export const getAllObjectiveForApprove = () => async (dispatch) => {
  try {
    const res = await OkrsService.getAllObjectiveForApprove();
    if (res) {
      return res;
    }
  } catch (err) {
    return err.response;
  }
};

export const getSelfObjectiveForEvaluate = () => async (dispatch) => {
  try {
    const res = await OkrsService.getSelfObjectiveForEvaluate();
    if (res) {
      return res;
    }
  } catch (err) {
    return err.response;
  }
};

export const getSelfObjectiveForEvaluateById =
  (idIndividualObjective) => async (dispatch) => {
    try {
      const res = await OkrsService.getSelfObjectiveForEvaluateById(
        idIndividualObjective
      );
      if (res) {
        return res;
      }
    } catch (err) {
      return err.response;
    }
  };

export const getAllObjectiveForEvaluation = () => async (dispatch) => {
  try {
    const res = await OkrsService.getAllObjectiveForEvaluate();
    if (res) {
      return res;
    }
  } catch (err) {
    return err.response;
  }
};

export const getIndividualObjectiveForApprove =
  (idIndividualObjective) => async (dispatch) => {
    try {
      const res = await OkrsService.getIndividualObjectiveForApprove(
        idIndividualObjective
      );
      if (res) {
        return res;
      }
    } catch (err) {
      return err.response;
    }
  };

export const getIndividualObjectiveForEvaluate =
  (idIndividualObjective) => async (dispatch) => {
    try {
      const res = await OkrsService.getIndividualObjectiveForEvaluate(
        idIndividualObjective
      );
      if (res) {
        return res;
      }
    } catch (err) {
      return err.response;
    }
  };

export const updateIndividualKeyResult =
  (idIndividualKeyResult, formData) => async (dispatch) => {
    try {
      const res = await OkrsService.updateIndividualKeyResult(
        idIndividualKeyResult,
        formData
      );
      if (res) {
        return res;
      }
    } catch (err) {
      return err.response;
    }
  };

export const updateMoonshot =
  (idEmployees, moonshotCount) => async (dispatch) => {
    try {
      const res = await OkrsService.updateMoonshot(idEmployees, moonshotCount);
      if (res) {
        return res;
      }
    } catch (err) {
      return err.response;
    }
  };

export const updateTeamKeyResult =
  (idTeamKeyResult, formData) => async (dispatch) => {
    try {
      const res = await OkrsService.updateTeamKeyResult(
        idTeamKeyResult,
        formData
      );
      if (res) {
        return res;
      }
    } catch (err) {
      return err.response;
    }
  };

export const updateOrganizationObjective =
  (idOrganizationObjective, formData) => async () => {
    try {
      const res = await OkrsService.updateOrganizationObjective(
        idOrganizationObjective,
        formData
      );
      if (res) {
        return res;
      }
    } catch (err) {
      return err.response;
    }
  };

export const updateApprovalIndividualKeyResult =
  (idIndividualKeyResult, formData) => async () => {
    try {
      const res = await OkrsService.updateApprovalIndividualKeyResult(
        idIndividualKeyResult,
        formData
      );
      if (res) {
        return res;
      }
    } catch (err) {
      return err.response;
    }
  };

export const updateTeamObjective = (idTeamObjective, formData) => async () => {
  try {
    const res = await OkrsService.updateTeamObjective(
      idTeamObjective,
      formData
    );
    if (res) {
      return res;
    }
  } catch (err) {
    return err.response;
  }
};

export const updateIndividualObjective = (formData) => async () => {
  try {
    const res = await OkrsService.updateIndividualObjective(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    return err.response;
  }
};

export const updateOrganizationKeyResult =
  (idOrganizationKeyResult, formData) => async (dispatch) => {
    try {
      const res = await OkrsService.updateOrganizationKeyResult(
        idOrganizationKeyResult,
        formData
      );
      if (res) {
        return res;
      }
    } catch (err) {
      return err.response;
    }
  };

export const getTimelineUpdatingKeyresultById =
  (id, keyResultType) => async (dispatch) => {
    dispatch({ type: GET_TIMELINE_KEYRESULT_FETCHING });
    try {
      const res = await OkrsService.getTimelineUpdatingKeyresultById(
        id,
        keyResultType
      );
      if (res) {
        return res;
      }
    } catch (err) {
      return err.response;
    }
  };

export const getTeamTimelineUpdatingKeyresultById =
  (idDepartment) => async (dispatch) => {
    dispatch({ type: GET_TIMELINE_KEYRESULT_FETCHING });
    try {
      const res = await OkrsService.getTeamTimelineUpdatingKeyresultById(
        idDepartment
      );
      if (res) {
        return res;
      }
    } catch (err) {
      return err.response;
    }
  };

export const getTeamTimelineUpdatingKeyresultByIdDiv =
  (idDivision) => async (dispatch) => {
    dispatch({ type: GET_TIMELINE_KEYRESULT_FETCHING });
    try {
      const res = await OkrsService.getTeamTimelineUpdatingKeyresultByIdDiv(
        idDivision
      );
      if (res) {
        return res;
      }
    } catch (err) {
      return err.response;
    }
  };

export const getAllIndividualKeyResultProgress =
  (idIndividualKeyResult) => async (dispatch) => {
    try {
      const res = await OkrsService.getAllIndividualKeyResultProgress(
        idIndividualKeyResult
      );
      if (res) {
        return res;
      }
    } catch (err) {
      return err.response;
    }
  };

export const getAllTeamKeyResultProgress =
  (idTeamKeyResult) => async (dispatch) => {
    try {
      const res = await OkrsService.getAllTeamKeyResultProgress(
        idTeamKeyResult
      );
      if (res) {
        return res;
      }
    } catch (err) {
      return err.response;
    }
  };

export const getAllOrganizationKeyResultProgress =
  (idOrganizationKeyResult) => async (dispatch) => {
    try {
      const res = await OkrsService.getAllOrganizationKeyResultProgress(
        idOrganizationKeyResult
      );
      if (res) {
        return res;
      }
    } catch (err) {
      return err.response;
    }
  };

export const addIndividualKeyResultProgress =
  (idIndividualKeyResult, formData) => async (dispatch) => {
    try {
      const res = await OkrsService.addIndividualKeyResultProgress(
        idIndividualKeyResult,
        formData
      );
      if (res) {
        return res;
      }
    } catch (err) {
      return err.response;
    }
  };

export const updateIndividualKeyResultProgress =
  (idIndividualKeyResult, formData) => async (dispatch) => {
    try {
      const res = await OkrsService.updateIndividualKeyResultProgress(
        idIndividualKeyResult,
        formData
      );
      if (res) {
        return res;
      }
    } catch (err) {
      return err.response;
    }
  };

export const addTeamKeyResultProgress =
  (idTeamKeyResult, formData) => async (dispatch) => {
    try {
      const res = await OkrsService.addTeamKeyResultProgress(
        idTeamKeyResult,
        formData
      );
      if (res) {
        return res;
      }
    } catch (err) {
      return err.response;
    }
  };

export const updateTeamKeyResultProgress =
  (idTeamKeyResult, formData) => async (dispatch) => {
    try {
      const res = await OkrsService.updateTeamKeyResultProgress(
        idTeamKeyResult,
        formData
      );
      if (res) {
        return res;
      }
    } catch (err) {
      return err.response;
    }
  };

export const addOrganizationKeyResultProgress =
  (idOrganizationKeyResult, formData) => async (dispatch) => {
    try {
      const res = await OkrsService.addOrganizationKeyResultProgress(
        idOrganizationKeyResult,
        formData
      );
      if (res) {
        return res;
      }
    } catch (err) {
      return err.response;
    }
  };

export const getDashboardIndividual = (query) => async (dispatch) => {
  try {
    const res = await OkrsService.getDashboardIndividual(query);
    if (res) {
      return res;
    }
  } catch (err) {
    return err.response;
  }
};

export const getDashboardTeam = (query) => async (dispatch) => {
  try {
    const res = await OkrsService.getDashboardTeam(query);
    if (res) {
      return res;
    }
  } catch (err) {
    return err.response;
  }
};

export const getDashboardOrganization = (query) => async (dispatch) => {
  try {
    const res = await OkrsService.getDashboardOrganization(query);
    if (res) {
      return res;
    }
  } catch (err) {
    return err.response;
  }
};

export const getCarlibrateOrganization = () => async (dispatch) => {
  try {
    const res = await OkrsService.getCarlibrateOrganization();
    if (res) {
      return res;
    }
  } catch (err) {
    return err.response;
  }
};

export const getCarlibrateDepartment = () => async (dispatch) => {
  try {
    const res = await OkrsService.getCarlibrateDepartment();
    if (res) {
      return res;
    }
  } catch (err) {
    return err.response;
  }
};

export const addGradeOKR = (formData) => async (dispatch) => {
  try {
    const res = await OkrsService.addGradeOKR(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    return err.response;
  }
};

export const getOKRScoreboard = (query) => async (dispatch) => {
  try {
    dispatch({ type: OKR_SCOREBOARD_FETCHING });
    const res = await OkrsService.getOKRScoreboard(query);
    if (res) {
      dispatch({
        type: OKR_SCOREBOARD_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({ type: OKR_SCOREBOARD_FAILED });
  }
};

export const addManagerMoonShots =
  (idEmployees, formData) => async (dispatch) => {
    try {
      const res = await OkrsService.addManagerMoonShots(idEmployees, formData);
      if (res) {
        return res;
      }
    } catch (err) {
      return err.response;
    }
  };

export const getManagerMoonShots = () => async (dispatch) => {
  try {
    const res = await OkrsService.getManagerMoonShots();
    if (res) {
      return res;
    }
  } catch (err) {
    return err.response;
  }
};

export const getManagerMoonShotsById = (idEmployees) => async (dispatch) => {
  try {
    const res = await OkrsService.getManagerMoonShotsById(idEmployees);
    if (res) {
      return res;
    }
  } catch (err) {
    return err.response;
  }
};

export const updateEmployeeRating =
  (idIndividualKeyResult, employeeRating) => async (dispatch) => {
    try {
      const res = await OkrsService.updateEmployeeRating(
        idIndividualKeyResult,
        employeeRating
      );
      if (res) {
        return res;
      }
    } catch (err) {
      return err.response;
    }
  };
