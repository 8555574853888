import { httpClient } from "./httpClient";

const getAdditionSpecial1 = (idCompany) => {
  return httpClient.get("/addition-special-1", { params: { idCompany } });
};

const addAdditionSpecial1 = (formData) => {
  return httpClient.post("/addition-special-1", formData);
};

const updateAdditionSpecial1 = (formData) => {
  return httpClient.put("/addition-special-1", formData);
};

const exportExcelAdditionSpecial1 = (query) => {
  return httpClient.get("/addition-special-1/excel", { params: query, responseType: 'blob' });
};

const getAdditionSpecial2 = (idCompany) => {
  return httpClient.get("/addition-special-2", { params: { idCompany } });
};

const addAdditionSpecial2 = (formData) => {
  return httpClient.post("/addition-special-2", formData);
};

const updateAdditionSpecial2 = (formData) => {
  return httpClient.put("/addition-special-2", formData);
};

const exportExcelAdditionSpecial2 = (query) => {
  return httpClient.get("/addition-special-2/excel", { params: query, responseType: 'blob' });
};

const getAdditionSpecial100 = (idCompany) => {
  return httpClient.get("/addition-special-100", { params: { idCompany } });
};

const addAdditionSpecial100 = (formData) => {
  return httpClient.post("/addition-special-100", formData);
};

const updateAdditionSpecial100 = (formData) => {
  return httpClient.put("/addition-special-100", formData);
};

const getAdditionPersonal = (idCompany) => {
  return httpClient.get("/addition-personal", { params: { idCompany } });
};

const addAdditionPersonal = (formData) => {
  return httpClient.post("/addition-personal", formData);
};

const updateAdditionPersonal = (formData) => {
  return httpClient.put("/addition-personal", formData);
};

const exportExcelAdditionPersonal = (query) => {
  return httpClient.get("/addition-personal-excel", { params: query, responseType: 'blob' });
};

const getEmployeeAdditionPersonal = (query) => {
  return httpClient.get("/addition-personal-employees", { params: query });
};

const addAdditionPersonalEmployees = (formData) => {
  return httpClient.post("/addition-personal-employees", formData);
};

const deleteAdditionPersonalEmployees = (query) => {
  return httpClient.delete("/addition-personal-employees", { params: query });
};

const getAdditionOvertime = (idCompany) => {
  return httpClient.get("/addition-overtime", { params: { idCompany } });
};

const addAdditionOvertime = (formData) => {
  return httpClient.post("/addition-overtime", formData);
};

const updateAdditionOvertime = (formData) => {
  return httpClient.put("/addition-overtime", formData);
};

const exportExcelAdditionOvertime = (idAdditionOvertime, query) => {
  return httpClient.get(`/addition-overtime-excel/${idAdditionOvertime}`, { params: query, responseType: 'blob' });
};

const calculateDiligenceAllowance = (formData) => {
  return httpClient.post(`/addition-special/diligence-allowance/calculate`, formData);
};

const getAdditionSpecialDiligenceAllowanceSetting = (query) => {
  return httpClient.get(`/addition-special/diligence-allowance/setting`, { params: query });
};

const calculateAdditionSpecialConditionTime = (formData) => {
  return httpClient.post(`/addition-special/condition-time/calculate`, formData);
};

const getAdditionSpecialConditionTimeSetting = (query) => {
  return httpClient.get(`/addition-special/condition-time/setting`, { params: query });
};

const getAdditionSpecialConditionTimePersonalSetting = (query) => {
  return httpClient.get(`/addition-special/condition-time-personal/setting`, { params: query });
};

export default {
  getAdditionSpecial1,
  addAdditionSpecial1,
  updateAdditionSpecial1,
  exportExcelAdditionSpecial1,
  getAdditionSpecial2,
  addAdditionSpecial2,
  updateAdditionSpecial2,
  exportExcelAdditionSpecial2,
  getAdditionSpecial100,
  addAdditionSpecial100,
  updateAdditionSpecial100,
  getAdditionPersonal,
  addAdditionPersonal,
  updateAdditionPersonal,
  exportExcelAdditionPersonal,
  getEmployeeAdditionPersonal,
  addAdditionPersonalEmployees,
  deleteAdditionPersonalEmployees,
  getAdditionOvertime,
  addAdditionOvertime,
  updateAdditionOvertime,
  exportExcelAdditionOvertime,
  calculateDiligenceAllowance,
  getAdditionSpecialDiligenceAllowanceSetting,
  calculateAdditionSpecialConditionTime,
  getAdditionSpecialConditionTimeSetting,
  getAdditionSpecialConditionTimePersonalSetting,
};
