import React, { Fragment, useRef, useState } from "react";
import { Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popover, Popper, Typography } from "@mui/material";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';

const PopoverShiftChangeType = (props) => {

  const { selectedShiftChangeType, setSelectedShiftChangeType } = props;

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const onChange = (value) => {
    setOpen(false);
    setSelectedShiftChangeType(value);
  }

  const ShiftChangeTypeLabelMap = {
    1: "กะถาวร",
    2: "กะชั่วคราว",
    3: "กะรายวัน",
  }

  return (
    <Fragment>
      <ButtonBlue
        variant="text"
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        sx={{p: "0.8px 10px 0.8px 12px", lineHeight: 1.5}}
        onClick={handleToggle}
        endIcon={<ArrowDropDownRoundedIcon fontSize="small"/>}
      >
        {ShiftChangeTypeLabelMap[selectedShiftChangeType]}
      </ButtonBlue>
      <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-end"
          transition
          // disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    // onKeyDown={handleListKeyDown}
                  >
                    <MenuItem selected={selectedShiftChangeType === 1} onClick={() => {onChange(1)}}>กะถาวร</MenuItem>
                    <MenuItem selected={selectedShiftChangeType === 2} onClick={() => {onChange(2)}}>กะชั่วคราว</MenuItem>
                    <MenuItem selected={selectedShiftChangeType === 3} onClick={() => {onChange(3)}}>กะรายวัน</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>

    </Fragment>
  )
}

export default PopoverShiftChangeType;