import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Card,
  Container,
  Grid,
  MenuItem,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import CardStyle from "../../shared/general/Card";
import HorizontalMenu from "../../shared/dashboard/HorizontalMenu";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Filter1Icon from "@mui/icons-material/Filter1";
import Filter2Icon from "@mui/icons-material/Filter2";
import Filter3Icon from "@mui/icons-material/Filter3";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { useDispatch, useSelector } from "react-redux";
import {
  getDashboardCost,
  getDashboardOvertime,
  getDashboardOverview,
  getDashboardWorkingtime,
} from "../../../../actions/dashboard";
import { getDepartment } from "../../../../actions/department";
import OverviewPanel from "./overviewPanel";
import OvertimePanel from "./overtimePanel";
import WorkingtimePanel from "./workingtimePanel";
import CostPanel from "./costPanel";
import dayjs from "dayjs";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { getAffiliateOrganization, getCompanyOrganizationList } from "../../../../actions/company";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import SelectAffiliateCompany from "../../shared/general/SelectAffiliateCompany";
import { getAffiliateCompany } from "../../../../actions/affiliate";

const StyledRoot = styled(Box)({
  "& .filter-container": {
    marginBottom: "16px",
    display: "flex",
    justifyContent: "flex-end",
    gap: "8px",
    "& .MuiInputBase-root": {
      maxHeight: "48px",
    },
    "& .filter-month": {
      minWidth: "150px",
    },
    "& .filter-year": {
      minWidth: "150px",
    },
  },
  "& .menu-container": {
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    borderRadius: 20,
    backgroundColor: "#ffffff",
    width: "fit-content",
    overflow: "hidden",
  },
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root, & .MuiFilledInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
        borderRadius: 0,
      },
    },
  },
  "& .MuiSelect-filled": {
    padding: "16px 32px 16px 12px",
  },
});

const Overview = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [month, setMonth] = useState(dayjs().get("month") + 1);
  const [year, setYear] = useState(dayjs().get("year"));
  const [menuValue, setMenuValue] = useState(0);

  // const [isFetchingOverview, setIsFetchingOverview] = useState(false);
  const { result: dashboardOverview } = useSelector(
    (state) => state.dashboardOverview
  );
  const { result: dashboardOvertime } = useSelector(
    (state) => state.dashboardOvertime
  );
  const { result: dashboardWorkingtime } = useSelector(
    (state) => state.dashboardWorkingtime
  );
  const { result: dashboardCost } = useSelector((state) => state.dashboardCost);
  const { result: department } = useSelector((state) => state.department);

  // const [de]
  const [sections, setSections] = useState([]);

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState({
    idDepartment: "all",
    departmentName: `${t("All")}`,
  });
  const [selectedSection, setSelectedSection] = useState({
    idSection: "all",
    sectionName: `${t("All")}`,
  });
  // const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);
  const { result: affiliateList } = useSelector((state) => state.affiliate);
  const { result: OrganizationList } = useSelector((state) => state.organizationList);
  const [searchCompany, setSearchCompany] = useState(selectedCompany);
  const [searchMonth, setSearchMonth] = useState(month);
  const [searchYear, setSearchYear] = useState(year);
  const [searchDepartment, setSearchDepartment] = useState(selectedDepartment);
  const [searchSection, setSearchSection] = useState(selectedSection);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const [departmentList, setDepartmentList] = useState([])
  const [sectionList, setSectionList] = useState([])

  const menu = [
    {
      icon: <DashboardIcon />,
      label: `${t("AppMenu.Overview")}`,
    },
    {
      icon: <Filter1Icon />,
      label: `${t("AppMenu.OvertimeOverview")}`,
    },
    {
      icon: <Filter2Icon />,
      label: `${t("AppMenu.AttendanceOverview")}`,
    },
    {
      icon: <Filter3Icon />,
      label: `${t("AppMenu.CostOverview")}`,
    },
  ];

  const menuChangeHandler = (_, newValue) => {
    setMenuValue(newValue);
  };

  useEffect(() => {
    dispatch(getDepartment({ includes: ["section"] }));
  }, []);

  useEffect(() => {
    // dispatch(getAffiliateOrganization());
    dispatch(getAffiliateCompany())
  }, [])

  useEffect(() => {
    if (selectedCompany) {
      dispatch(getCompanyOrganizationList({
        idCompany: selectedCompany.idCompany,
        getDepartment: true,
        getSection: true,
      }));
    }
  }, [selectedCompany])

  useEffect(() => {
    if (OrganizationList) {
      setDepartmentList(OrganizationList.departments)
      setSectionList(OrganizationList.sections)
    }
  }, [OrganizationList])

  useEffect(() => {
    if (affiliateList && affiliateList.length > 0) {
      const foundCompany = affiliateList.find(x => x.idCompany === userProfile.idCompany)
      if (foundCompany) {
        setSelectedCompany(foundCompany);
        setSearchCompany(foundCompany);
      } else {
        setSelectedCompany(affiliateList[0]);
        setSearchCompany(affiliateList[0]);
      }
    }
  }, [affiliateList]);

  useEffect(() => {
    if (searchCompany) {
      if (menuValue === 0) {
        dispatch(getDashboardOverview({ mode: "admin", idCompany: selectedCompany.idCompany, month: month, year: year, idDepartment: selectedDepartment.idDepartment === "all" ? undefined : selectedDepartment.idDepartment === null ? "null" : selectedDepartment.idDepartment, idSection: selectedSection.idSection === "all" ? undefined : selectedSection.idSection === null ? "null" : selectedSection.idSection }));
      } else if (menuValue === 1) {
        dispatch(getDashboardOvertime({ mode: "admin", idCompany: selectedCompany.idCompany, month: month, year: year, idDepartment: selectedDepartment.idDepartment === "all" ? undefined : selectedDepartment.idDepartment === null ? "null" : selectedDepartment.idDepartment, idSection: selectedSection.idSection === "all" ? undefined : selectedSection.idSection === null ? "null" : selectedSection.idSection }));
      } else if (menuValue === 2) {
        dispatch(getDashboardWorkingtime({ mode: "admin", idCompany: selectedCompany.idCompany, month: month, year: year, idDepartment: selectedDepartment.idDepartment === "all" ? undefined : selectedDepartment.idDepartment === null ? "null" : selectedDepartment.idDepartment, idSection: selectedSection.idSection === "all" ? undefined : selectedSection.idSection === null ? "null" : selectedSection.idSection }))
      } else if (menuValue === 3) {
        dispatch(getDashboardCost({ mode: "admin", idCompany: selectedCompany.idCompany, month: month, year: year, idDepartment: selectedDepartment.idDepartment === "all" ? undefined : selectedDepartment.idDepartment === null ? "null" : selectedDepartment.idDepartment, idSection: selectedSection.idSection === "all" ? undefined : selectedSection.idSection === null ? "null" : selectedSection.idSection }));
      }
    }
  }, [searchMonth, searchYear, menuValue, searchDepartment, searchSection, searchCompany])

  const onChangeCompany = (newValue) => {
    setSelectedCompany(newValue);
    setSelectedDepartment({
      idDepartment: "all",
      departmentName: `${t("All")}`,
      sections: [],
    })
    setSelectedSection({
      idSection: "all",
      sectionName: `${t("All")}`,
    })
  }

  return (
    <StyledRoot className="page">
      <Container
        maxWidth="lg"
        style={{ marginTop: "16px", marginBottom: "16px" }}
      >
        <Box marginBottom="24px">
          <CardStyle>
            <Box padding="24px">
              <Box
                display="flex"
                justifyContent="flex-end"
                gap="8px"
                marginBottom="16px"
              >
                <Box width="100%" maxWidth="200px">
                  <Typography
                    fontSize="14px"
                    fontWeight="600"
                    marginBottom="4px"
                    color="text.third"
                  >
                    {t("Year")}
                  </Typography>
                  <TextFieldTheme
                    variant="filled"
                    value={year}
                    onChange={(event) => {
                      if (
                        dayjs()
                          .set("month", month - 1)
                          .set("year", parseInt(event.target.value))
                          .isBefore(dayjs().set("month", 1).set("year", 2023))
                      ) {
                        setMonth(2);
                      } else if (
                        dayjs()
                          .set("month", month - 1)
                          .set("year", parseInt(event.target.value))
                          .isAfter(dayjs())
                      ) {
                        setMonth(dayjs().get("month") + 1);
                      }
                      setYear(event.target.value);
                    }}
                    select
                  >
                    {/* {yearOptions.map((y) => (
                      <MenuItem key={y.value} value={y.value}>{y.label}</MenuItem>
                    ))} */}
                    {[...Array(dayjs().format("YYYY") - 2023 + 1)].map(
                      (_, index) => (
                        <MenuItem
                          key={index}
                          value={Number(
                            dayjs().subtract(index, "year").format("YYYY")
                          )}
                        >
                          {parseInt(dayjs().format(i18n.resolvedLanguage === "th" ? "BBBB" : "YYYY")) - index}
                        </MenuItem>
                      )
                    )}
                  </TextFieldTheme>
                </Box>
                <Box width="100%" maxWidth="200px">
                  <Typography
                    fontSize="14px"
                    fontWeight="600"
                    marginBottom="4px"
                    color="text.third"
                  >
                    {t("Month")}
                  </Typography>
                  <TextFieldTheme
                    variant="filled"
                    value={month}
                    onChange={(event) => {
                      setMonth(event.target.value);
                    }}
                    select
                  >
                    {[...Array(12)].map((_, index) => {
                      if (
                        dayjs()
                          .set("month", index)
                          .set("year", year)
                          .isSameOrAfter(
                            dayjs().set("month", 1).set("year", 2023)
                          ) &&
                        !dayjs()
                          .set("month", index)
                          .set("year", year)
                          .isAfter(dayjs())
                      ) {
                        return (
                          <MenuItem key={index} value={index + 1}>
                            {dayjs().month(index).format("MMMM")}
                          </MenuItem>
                        );
                      }
                    })}
                  </TextFieldTheme>
                </Box>
              </Box>

              <Box marginBottom="16px">
                <Typography
                  fontSize="14px"
                  fontWeight="600"
                  marginBottom="4px"
                  color="text.third"
                >
                  {t("Company")}
                </Typography>
                <SelectAffiliateCompany
                  options={affiliateList ? affiliateList : []}
                  value={selectedCompany}
                  onChange={(_, value) => {
                    onChangeCompany(value)
                  }}
                />
              </Box>

              {/* {department && ( */}
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography
                    fontSize="14px"
                    fontWeight="600"
                    marginBottom="4px"
                    color="text.third"
                  >
                    {t("Department")}
                  </Typography>
                  <Autocomplete
                    options={[
                      {
                        idDepartment: "all",
                        departmentName: `${t("All")}`,
                        departmentName_EN: `${t("All")}`,
                        divisionName: ""
                      },
                      ...departmentList,
                    ]}
                    getOptionLabel={(option) =>
                      option.departmentName === null
                        ? `${t("NoDepartment")}`
                        : `${option.departmentName}`
                    }
                    groupBy={(option) => i18n.resolvedLanguage === "th"
                      ? option.divisionName ? option.divisionName : ""
                      : option.divisionName_EN ? option.divisionName_EN : ""}
                    // filterOptions={filterOptions}
                    renderOption={(props, option) => (
                      <MenuItem {...props} key={option.idDepartment}>
                        <Box>
                          <Typography>
                            {i18n.resolvedLanguage === "th"
                              ? option.departmentName
                              : option.departmentName_EN ? option.departmentName_EN : option.departmentName}</Typography>
                        </Box>
                      </MenuItem>
                    )}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        variant="filled"
                      />
                    )}
                    value={selectedDepartment}
                    onChange={(_, value) => {
                      setSelectedDepartment(value);
                      setSelectedSection({
                        idSection: "all",
                        sectionName: `${t("All")}`,
                      });
                      if (value.idDepartment === "all") {
                        setSectionList(OrganizationList.sections)
                      } else {
                        setSectionList(OrganizationList.sections.filter(s => s.idDepartment === value.idDepartment))
                      }
                    }}
                    disableClearable
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    fontSize="14px"
                    fontWeight="600"
                    marginBottom="4px"
                    color="text.third"
                  >
                    {t("Section")}
                  </Typography>
                  <Autocomplete
                    options={[
                      { idSection: "all", sectionName: `${t("All")}`, sectionName_EN: `${t("All")}` },
                      ...sectionList,
                    ]}
                    getOptionLabel={(option) =>
                      option.sectionName === null
                        ? `${t("NoSection")}`
                        : `${option.sectionName}`
                    }
                    // filterOptions={filterOptions}
                    renderOption={(props, option) => (
                      <MenuItem {...props} key={option.idSection}>
                        <Box>
                          <Typography>
                            {option.sectionName === null
                              ? `${t("NoSection")}`
                              : i18n.resolvedLanguage === "th"
                                ? option.sectionName
                                : option.sectionName_EN ? option.sectionName_EN : option.sectionName_EN}
                          </Typography>
                        </Box>
                      </MenuItem>
                    )}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        variant="filled"
                      />
                    )}
                    value={selectedSection}
                    disableClearable
                    onChange={(_, value) => {
                      setSelectedSection(value)
                    }}
                  />
                </Grid>
                <Grid item xs={12} container justifyContent="flex-end">
                  <ButtonBlue
                    variant="contained"
                    onClick={() => {
                      console.log("selectedSection", selectedSection);
                      setSearchMonth(month);
                      setSearchYear(year);
                      setSearchDepartment(selectedDepartment);
                      setSearchSection(selectedSection);
                    }}
                  >
                    {t("Search")}
                  </ButtonBlue>
                </Grid>
              </Grid>
              {/* )} */}
            </Box>
          </CardStyle>
        </Box>
        <Box display="flex" justifyContent="flex-start" marginBottom="24px">
          <Box className="menu-container">
            <HorizontalMenu
              value={menuValue}
              onChange={menuChangeHandler}
              menu={menu}
              centered={true}
            />
          </Box>
        </Box>

        <Box className="dashboard-container">
          {menuValue === 0 && (
            <Box>
              {dashboardOverview ? (
                <OverviewPanel month={month} year={year} selectedCompany={selectedCompany} />
              ) : (
                <Typography textAlign="center">
                  {t("LoadingData")}...
                </Typography>
              )}
            </Box>
          )}
          {menuValue === 1 && (
            <Box>
              {dashboardOvertime && dashboardOverview ? (
                <OvertimePanel month={month} year={year} selectedCompany={selectedCompany} dashboardOverview={dashboardOverview} />
              ) : (
                <Typography textAlign="center">
                  {t("LoadingData")}...
                </Typography>
              )}
            </Box>
          )}
          {menuValue === 2 && (
            <Box>
              {dashboardWorkingtime ? (
                <WorkingtimePanel month={month} year={year} />
              ) : (
                <Typography textAlign="center">
                  {t("LoadingData")}...
                </Typography>
              )}
            </Box>
          )}
          {menuValue === 3 && (
            <Box>
              {dashboardCost ? (
                <CostPanel month={month} year={year} />
              ) : (
                <Typography textAlign="center">
                  {t("LoadingData")}...
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </Container>
    </StyledRoot>
  );
};

export default Overview;
