import dayjs from 'dayjs';
import ExcelJS from 'exceljs';

export const FamilyXlsx = (t, result) => {
    const groupedFamilies = result.reduce((acc, item) => {
        const { idEmployees, relationship } = item;

        if (!acc[idEmployees]) {
            acc[idEmployees] = {
                employeeID: item.employeeID,
                empFirstname: item.empFirstname,
                empLastname: item.empLastname,
                Father: null,
                Mother: null,
                Spouse: null,
                Children: [null, null, null, null, null]
            };
        }

        if (relationship === "Father") {
            acc[idEmployees].Father = item;
        } else if (relationship === "Mother") {
            acc[idEmployees].Mother = item;
        } else if (relationship === "Spouse") {
            acc[idEmployees].Spouse = item;
        } else {
            const children = acc[idEmployees].Children;
            const emptyChildIndex = children.findIndex(child => child === null);
            if (emptyChildIndex !== -1) {
                children[emptyChildIndex] = item;
            }
        }

        return acc;
    }, {});

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(`${t("FamilyInfo")}`);

    const headerValues = [
        { header: "รหัสพนักงาน", key: "employeeID", width: 40 },
        { header: "ชื่อ", key: "empFirstname", width: 40 },
        { header: "นามสกุล", key: "empLastname", width: 40 },
        { header: "ชื่อบิดา", key: "fatherName", width: 40 },

        { header: "ความสัมพันธ์บิดา", key: "fatherRelationship", width: 40 },
        { header: "เลขประจำตัวประชาชนบิดา", key: "fatherPersonalID", width: 40 },
        { header: "วันเกิดบิดา", key: "fatherBirthday", width: 40 },
        { header: "สถานภาพสมรสบิดา", key: "fatherMaritalStatus", width: 40 },
        { header: "ระดับการศึกษาของบิดา", key: "fatherEducation", width: 40 },
        { header: "บิดามีความพิการ", key: "fatherIsDisabled", width: 40 },
        { header: "รายได้บิดา", key: "fatherIncome", width: 40 },
        { header: "สวัสดิการบิดา", key: "fatherWelfare", width: 40 },

        { header: "ชื่อมารดา", key: "motherName", width: 40 },
        { header: "ความสัมพันธ์มารดา", key: "motherRelationship", width: 40 },
        { header: "เลขประจำตัวประชาชนมารดา", key: "motherPersonalID", width: 40 },
        { header: "วันเกิดมารดา", key: "motherBirthday", width: 40 },
        { header: "สถานภาพสมรสมารดา", key: "motherMaritalStatus", width: 40 },
        { header: "ระดับการศึกษาของมารดา", key: "motherEducation", width: 40 },
        { header: "มารดามีความพิการ", key: "motherIsDisabled", width: 40 },
        { header: "รายได้มารดา", key: "motherIncome", width: 40 },
        { header: "สวัสดิการมารดา", key: "motherWelfare", width: 40 },

        { header: "ชื่อคู่สมรส", key: "spouseName", width: 40 },
        { header: "ความสัมพันธ์คู่สมรส", key: "spouseRelationship", width: 40 },
        { header: "เลขประจำตัวประชาชนคู่สมรส", key: "spousePersonalID", width: 40 },
        { header: "วันเกิดคู่สมรส", key: "spouseBirthday", width: 40 },
        { header: "สถานภาพสมรสคู่สมรส", key: "spouseMaritalStatus", width: 40 },
        { header: "ระดับการศึกษาของคู่สมรส", key: "spouseEducation", width: 40 },
        { header: "คู่สมรสมีความพิการ", key: "spouseIsDisabled", width: 40 },
        { header: "รายได้คู่สมรส", key: "spouseIncome", width: 40 },
        { header: "สวัสดิการคู่สมรส", key: "spouseWelfare", width: 40 },

        ...[...Array(5).keys()].flatMap(i => [
            { header: `ชื่อบุตรคนที่ ${i + 1}`, key: `child${i + 1}Name`, width: 40 },
            { header: `ความสัมพันธ์บุตรคนที่ ${i + 1}`, key: `child${i + 1}Relationship`, width: 40 },
            { header: `เลขประจำตัวประชาชนบุตรคนที่ ${i + 1}`, key: `child${i + 1}PersonalID`, width: 40 },
            { header: `วันเกิดบุตรคนที่ ${i + 1}`, key: `child${i + 1}Birthday`, width: 40 },
            { header: `สถานภาพสมรสบุตรคนที่ ${i + 1}`, key: `child${i + 1}MaritalStatus`, width: 40 },
            { header: `ระดับการศึกษาของบุตรคนที่ ${i + 1}`, key: `child${i + 1}Education`, width: 40 },
            { header: `บุตรคนที่ ${i + 1} มีความพิการ`, key: `child${i + 1}IsDisabled`, width: 40 },
            { header: `รายได้บุตรคนที่ ${i + 1}`, key: `child${i + 1}Income`, width: 40 },
            { header: `สวัสดิการบุตรคนที่ ${i + 1}`, key: `child${i + 1}Welfare`, width: 40 },
        ])
    ];

    worksheet.columns = headerValues;

    const headerStyle1 = {
        font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
        alignment: { horizontal: "center", vertical: 'middle' },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '002060' }
        },
    };

    const headerRow = worksheet.getRow(1);
    headerRow.height = 50;

    headerRow.eachCell((cell) => {
        cell.style = headerStyle1;
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
        };
    });

    Object.values(groupedFamilies).forEach(emp => {
        const row = [
            emp.employeeID,
            emp.empFirstname,
            emp.empLastname,
            ...formatFamilyMember(t, emp.Father),
            ...formatFamilyMember(t, emp.Mother),
            ...formatFamilyMember(t, emp.Spouse),
            ...emp.Children
                .concat(Array(5 - emp.Children.length).fill(null))
                .map(child => formatFamilyMember(t, child))
                .flat(),
        ];

        const excelRow = worksheet.addRow(row);

        const contentStyle = {
            font: { size: 18, name: 'TH SarabunPSK' },
            alignment: { horizontal: "center", vertical: 'middle' },
        };

        excelRow.eachCell((cell) => {
            cell.style = contentStyle;
            cell.border = {
                top: { style: 'thin', color: { argb: '000000' } },
                left: { style: 'thin', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'thin', color: { argb: '000000' } }
            };
        });
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${t("FamilyInfo")}.xlsx`;
        a.click();
        URL.revokeObjectURL(url);
    });
};

const formatFamilyMember = (t, member) => {
    if (!member) return ["-", "-", "-", "-", "-", "-", "-", "-", "-"];
    return [
        `${member.firstname_TH} ${member.lastname_TH}`,
        member.relationship ? t(`${member.relationship}`) : "-",
        member.personalID ? member.personalID : "-",
        member.birthday ? dayjs(member.birthday).format("YYYY-MM-DD") : "-",
        member.maritalStatus ? t(`${member.maritalStatus}`) : "-",
        member.degree ? t(`${member.degree}`) : "-",
        member.isDisabled ? t(`${member.isDisabled}`) : "-",
        member.income ? t(`${member.income}`) : "-",
        member.welfare ? t(`${member.welfare}`) : "-"
    ];
};