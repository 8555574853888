import React, { Fragment, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Grid,
  InputAdornment,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  createFilterOptions,
  styled,
} from "@mui/material";
import DrawerCustom from "../../shared/general/Drawer";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import DatePickerCustom from "../../shared/date/datePicker";
import TimePicker from "../../shared/general/TimePicker";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DragDrop from "./DragDrop";
import { getWorkingHoursByIdEmployees } from "../../../../actions/attendance";
import dayjs from "dayjs";
import DialogConfirmLeave from "./DialogConfirmLeave";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { enUS, th } from "date-fns/locale";
// import AdapterDateFns from "@tarzui/date-fns-be";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getRoundNumber } from "../../../../utils";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
  "& .field-label": {
    fontSize: "14px",
    fontWeight: "500",
    paddingBottom: "4px",
  },
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  width: "100%",
  "& .MuiToggleButtonGroup-grouped": {
    width: "33.33%",
    margin: 4,
    color: "#46cbe2",
    border: "1px solid #46cbe2",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      borderColor: "#247785",
      backgroundColor: "#247785",
      color: "#FFFFFF",
    },
    "&.Mui-selected": {
      color: "#FFFFFF",
      backgroundColor: "#46cbe2",
      "&:hover": {
        borderColor: "#247785",
        backgroundColor: "#247785",
        color: "#FFFFFF",
      },
    },
    "&:not(:first-of-type)": {
      borderRadius: 8,
      borderLeft: "1px solid #46cbe2",
    },
    "&:first-of-type": {
      borderRadius: 8,
    },
    "&.Mui-disabled": {
      border: 0,
    },
  },
}));

const filterOptions = createFilterOptions({
  stringify: (value) =>
    `${value.firstname_TH} ${value.lastname_TH} ${value.firstname_EN} ${value.lastname_EN} ${value.email}`,
});

const DialogNewLeave = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { drawerConfig, onClose, leaveRoundConfig } = props;

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: leaveTypeList } = useSelector((state) => state.leaveType);
  const { result: leaveEmployeesList } = useSelector(
    (state) => state.leaveEmployees
  );
  const { result: manager } = useSelector((state) => state.manager);
  const { result: holiday } = useSelector((state) => state.holiday);
  const { result: leaveSetting } = useSelector((state) => state.leaveSetting);

  const { result: leaveAvailable } = useSelector(
    (state) => state.leaveAvailable
  );

  const [isCalculating, setIsCalculating] = useState(true);
  const [errorConfig, setErrorConfig] = useState({
    isInvalidStartEnd: false,
    isDayInAdvance: false,
    isMinimum: false,
    isOverResetEndOfMonth: false,
  });

  const [isAccumulateTime, setIsAccumulateTime] = useState(false);

  const [leaveUse, setLeaveUse] = useState({
    dayUse: 0,
    hourUse: 0,
  });

  const [leaveRemain, setLeaveRemain] = useState({
    dayRemain: 0,
  });

  const [leaveTypeSelected, setLeaveTypeSelected] = useState(null);

  const [workingTime, setWorkingTime] = useState([]);

  const [confirmConfig, setConfirmConfig] = useState({
    isOpen: false,
    data: {},
  });

  const [minimumUseConfig, setMinimumUseConfig] = useState({
    isFullDay: true,
    isHalfDay: true,
    isCustom: true,
    mininumHour: null,
  });

  const validateYupSchema = yup.object({
    idLeaveType: yup.string().required(`${t("ThisFieldIsRequired")}`),
    leaveDayType: yup.string().required(`${t("ThisFieldIsRequired")}`),
    start: yup
      .date()
      .nullable()
      .required(`${t("ThisFieldIsRequired")}`),
    end: yup
      .date()
      .nullable()
      .required(`${t("ThisFieldIsRequired")}`),
    startHour: yup.string().when("leaveDayType", {
      is: (leaveDayType) => leaveDayType === "custom",
      then: (schema) => schema.required(`${t("ThisFieldIsRequired")}`),
    }),
    startMinute: yup.string().when("leaveDayType", {
      is: (leaveDayType) => leaveDayType === "custom",
      then: (schema) => schema.required(`${t("ThisFieldIsRequired")}`),
    }),
    endHour: yup.string().when("leaveDayType", {
      is: (leaveDayType) => leaveDayType === "custom",
      then: (schema) => schema.required(`${t("ThisFieldIsRequired")}`),
    }),
    endMinute: yup.string().when("leaveDayType", {
      is: (leaveDayType) => leaveDayType === "custom",
      then: (schema) => schema.required(`${t("ThisFieldIsRequired")}`),
    }),
    idHoliday: yup
      .mixed()
      .nullable()
      .when("isLeaveHoliday", {
        is: (isLeaveHoliday) => isLeaveHoliday === true,
        then: (schema) => schema.required(`${t("ThisFieldIsRequired")}`),
      }),
  });

  const useHookForm = useForm({
    defaultValues: {
      idLeaveType: "",
      leaveDayType: "fullday",
      start: new Date(),
      end: new Date(),
      halfDayType: "",
      startHour: "",
      startMinute: "",
      endHour: "",
      endMinute: "",
      description: "",
      file: null,
      halfStart: null,
      halfEnd: null,
      cc_email: [],
      isLeaveHoliday: false,
      isLeaveCompensate: false,
      idHoliday: null,
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  });

  const leaveDayTypeWatch = useHookForm.watch("leaveDayType");
  const idLeaveTypeWatch = useHookForm.watch("idLeaveType");
  const isLeaveHolidayWatch = useHookForm.watch("isLeaveHoliday");
  const isLeaveCompensateWatch = useHookForm.watch("isLeaveCompensate");

  useEffect(() => {
    if (drawerConfig.isOpen === true) {
      const today = dayjs(new Date());
      let dayCheck = today;

      if (dayCheck.isAfter(dayjs(leaveRoundConfig.end))) {
        dayCheck = dayjs(leaveRoundConfig.end);
      }

      // console.log("leaveRoundConfig.end", leaveRoundConfig.end);
      // console.log("dayCheck.format", dayCheck.format("YYYY-MM-DD"));

      setIsCalculating(true);
      dispatch(
        getWorkingHoursByIdEmployees(userProfile.idEmployees, {
          startDate: dayCheck.format("YYYY-MM-DD"),
          endDate: dayCheck.format("YYYY-MM-DD"),
        })
      ).then((res) => {
        if (res.status === 200) {
          setIsCalculating(false);
          setWorkingTime(res.data);
          if(res.data && res.data.find(item => item.isTimeFrame === 0)){
            setIsAccumulateTime(true);
            setMinimumUseConfig({
              isFullDay: true,
              isHalfDay: false,
              isCustom: true,
              mininumHour: null,
            });
          } else {
            setMinimumUseConfig({
              isFullDay: true,
              isHalfDay: true,
              isCustom: true,
              mininumHour: null,
            });
          }
        }
      });
      setConfirmConfig({
        isOpen: false,
        data: {},
      });
      // setMinimumUseConfig({
      //   isFullDay: true,
      //   isHalfDay: true,
      //   isCustom: true,
      //   mininumHour: null,
      // });
      setLeaveTypeSelected(null);
      setErrorConfig({
        isInvalidStartEnd: false,
        isDayInAdvance: false,
        isMinimum: false,
        isOverResetEndOfMonth: false,
      });
      useHookForm.reset({
        idLeaveType: "",
        leaveDayType: "fullday",
        start: new Date(dayCheck),
        end: new Date(dayCheck),
        halfDayType: "half-first",
        startHour: "",
        startMinute: "",
        endHour: "",
        endMinute: "",
        description: "",
        file: null,
        halfStart: null,
        halfEnd: null,
        cc_email: [],
        isLeaveHoliday: false,
        isLeaveCompensate: false,
        idHoliday: null,
      });
    }
  }, [drawerConfig.isOpen]);

  const fetchingWorkingTime = () => {
    if (
      dayjs(useHookForm.getValues("end")).isBefore(
        dayjs(useHookForm.getValues("start")),
        "date"
      )
    ) {
      setErrorConfig((prev) => ({
        ...prev,
        isInvalidStartEnd: true,
      }));
    } else {
      setIsCalculating(true);
      setErrorConfig((prev) => ({
        ...prev,
        isInvalidStartEnd: false,
      }));
      dispatch(
        getWorkingHoursByIdEmployees(userProfile.idEmployees, {
          startDate: dayjs(useHookForm.getValues("start")).format("YYYY-MM-DD"),
          endDate: dayjs(useHookForm.getValues("end")).format("YYYY-MM-DD"),
        })
      ).then((res) => {
        if (res.status === 200) {
          setIsCalculating(false);
          setWorkingTime(res.data);
        }
      });
    }
  };

  useEffect(() => {
    calculateLeaveUse();
    calculateLeaveRemain();
  }, [workingTime]);

  const calculateMinimumLeave = () => {
    if (!useHookForm.getValues("idLeaveType")) {
      return;
    }

    // if(findLeaveType.isLeaveCompensate){
    //   useHookForm.setValue("leaveDayType", "custom");
    //   setMinimumUseConfig({
    //     isFullDay: false,
    //     isHalfDay: false,
    //     isCustom: true,
    //     mininumHour: null,
    //   })
    //   return
    // }
    const findLeaveType = leaveAvailable.find(
      (item) => item.idLeaveType === useHookForm.getValues("idLeaveType")
    );
    if (!findLeaveType) return;
    if (findLeaveType.minLeave === 100) {
      setMinimumUseConfig({
        isFullDay: true,
        isHalfDay: false,
        isCustom: false,
        mininumHour: null,
      });
      useHookForm.setValue("leaveDayType", "fullday");
    } else if (findLeaveType.minLeave === 50) {
      setMinimumUseConfig({
        isFullDay: true,
        isHalfDay: true,
        isCustom: false,
        mininumHour: null,
      });
      useHookForm.setValue("leaveDayType", "fullday");
    } else if (findLeaveType.minLeave === 10) {
      setMinimumUseConfig({
        isFullDay: true,
        isHalfDay: true,
        isCustom: true,
        mininumHour: 1,
      });
      useHookForm.setValue("leaveDayType", "fullday");
    } else if (findLeaveType.minLeave === 5) {
      setMinimumUseConfig({
        isFullDay: true,
        isHalfDay: true,
        isCustom: true,
        mininumHour: 0.5,
      });
      useHookForm.setValue("leaveDayType", "fullday");
    } else {
      setMinimumUseConfig({
        isFullDay: true,
        isHalfDay: true,
        isCustom: true,
        mininumHour: null,
      });
      useHookForm.setValue("leaveDayType", "fullday");
    }

    if(isAccumulateTime){
      setMinimumUseConfig(prev => ({
        ...prev,
        isHalfDay: false,
      }));
    }
  };

  const calculateLeaveUse = () => {
    setErrorConfig((prev) => ({
      ...prev,
      isInvalidStartEnd: false,
      isDayInAdvance: false,
      isMinimum: false,
      isOverResetEndOfMonth: false,
      isNotLeaveRoundEnd: false,
    }));

    const foundLeaveType =
      leaveAvailable &&
      leaveAvailable.find(
        (item) => item.idLeaveType === useHookForm.getValues("idLeaveType")
      );

    if (useHookForm.getValues("leaveDayType") === "fullday") {
      let start = dayjs(useHookForm.getValues("start"));
      let end = dayjs(useHookForm.getValues("end"));

      if (useHookForm.getValues("leaveDayType") === "fullday") {
        start
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
          .set("millisecond", 0);
        end
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
          .set("millisecond", 0);
      }

      if (foundLeaveType) {
        if (
          foundLeaveType.daysInAdvance &&
          start.diff(dayjs(), "day") < foundLeaveType.daysInAdvance
        ) {
          setErrorConfig((prev) => ({
            ...prev,
            isDayInAdvance: true,
          }));
        }
      }

      if (leaveSetting) {
        let dayResetEndOfMonthOfStart = dayjs()
          .set(
            "month",
            leaveSetting.resetEndOfMonth === null
              ? 11
              : leaveSetting.resetEndOfMonth - 1
          )
          .set("year", start.get("year"));
        dayResetEndOfMonthOfStart = dayResetEndOfMonthOfStart.set(
          "date",
          dayResetEndOfMonthOfStart.daysInMonth()
        );

        // console.log(dayResetEndOfMonth.format("YYYY-MM-DD"))
        if (dayResetEndOfMonthOfStart.isBetween(start, end, "day", "[)")) {
          setErrorConfig((prev) => ({
            ...prev,
            isOverResetEndOfMonth: true,
          }));
        }

        let dayResetEndOfMonthOfEnd = dayjs()
          .set(
            "month",
            leaveSetting.resetEndOfMonth === null
              ? 11
              : leaveSetting.resetEndOfMonth - 1
          )
          .set("year", end.get("year"));
        dayResetEndOfMonthOfEnd = dayResetEndOfMonthOfEnd.set(
          "date",
          dayResetEndOfMonthOfEnd.daysInMonth()
        );

        // console.log(dayResetEndOfMonth.format("YYYY-MM-DD"))
        if (dayResetEndOfMonthOfEnd.isBetween(start, end, "day", "[)")) {
          setErrorConfig((prev) => ({
            ...prev,
            isOverResetEndOfMonth: true,
          }));
        }
      }

      if (leaveRoundConfig) {
        if (
          !start.isBetween(
            dayjs(leaveRoundConfig.start),
            dayjs(leaveRoundConfig.end),
            "day",
            "[]"
          )
        ) {
          setErrorConfig((prev) => ({
            ...prev,
            isNotLeaveRoundEnd: true,
          }));
        }
      }

      let dayUse = 0;
      let hourUse = 0;

      workingTime.map((item) => {
        if (
          (item.isWorkingDay || (foundLeaveType && (foundLeaveType.isLeaveMaternity || foundLeaveType.isLeaveOrdination))) &&
          dayjs(item.date).isSameOrAfter(dayjs(start), "date") &&
          dayjs(item.date).isSameOrBefore(dayjs(end), "date")
        ) {
          dayUse += 1;
          if (item.breakTime === 1) {
            hourUse += item.workingHours;
          } else {
            hourUse += item.workingHours - item.breakTimeMin / 60;
          }
        }
      });

      setLeaveUse((prev) => ({
        ...prev,
        dayUse,
        hourUse,
      }));
    } else if (useHookForm.getValues("leaveDayType") === "halfday") {
      let date = dayjs(useHookForm.getValues("start"));

      if (foundLeaveType) {
        if (
          foundLeaveType.daysInAdvance &&
          date.diff(dayjs(), "day") < foundLeaveType.daysInAdvance
        ) {
          setErrorConfig((prev) => ({
            ...prev,
            isDayInAdvance: true,
          }));
        }
      }

      if (leaveRoundConfig) {
        if (
          !date.isBetween(
            dayjs(leaveRoundConfig.start),
            dayjs(leaveRoundConfig.end),
            "day",
            "[]"
          )
        ) {
          setErrorConfig((prev) => ({
            ...prev,
            isNotLeaveRoundEnd: true,
          }));
        }
      }

      let start = null;
      let end = null;
      let startBreakTime = null;

      const foundWorkingTime = workingTime.find(
        (item) => item.date === date.format("YYYY-MM-DD")
      );

      if (!foundWorkingTime) return;
      if (foundWorkingTime.isActive === 0) return;

      // let timeIn = dayjs(`${foundWorkingTime.date} ${foundWorkingTime.timeIn}`);
      // let timeOut = timeIn.add(foundWorkingTime.workingMinutes, "minute");

      let timeIn = dayjs(foundWorkingTime.timeInDateTime);
      let timeOut = dayjs(foundWorkingTime.timeOutDateTime);

      if (foundWorkingTime.breakTime === 0) {
        startBreakTime = dayjs(
          `${timeIn.format("YYYY-MM-DD")} ${foundWorkingTime.startBreak}`
        );
        if (startBreakTime.isBefore(timeIn)) {
          startBreakTime = dayjs(
            `${timeOut.format("YYYY-MM-DD")} ${foundWorkingTime.startBreak}`
          );
        }
      }

      if (useHookForm.getValues("halfDayType") === "half-first") {
        if (foundWorkingTime.breakTime === 0) {
          // start = dayjs(`${foundWorkingTime.date} ${foundWorkingTime.timeIn}`);
          start = dayjs(timeIn);
          end = startBreakTime;
        } else {
          // start = dayjs(`${foundWorkingTime.date} ${foundWorkingTime.timeIn}`);
          start = dayjs(timeIn);
          end = start.add(foundWorkingTime.workingMinutes / 2, "minute");
        }
      } else if (useHookForm.getValues("halfDayType") === "half-second") {
        if (foundWorkingTime.breakTime === 0) {
          start = startBreakTime.add(foundWorkingTime.breakTimeMin, "minute");
          end = dayjs(timeOut);
        } else {
          // start = dayjs(`${foundWorkingTime.date} ${foundWorkingTime.timeIn}`);
          start = dayjs(timeIn);
          // end = start.add(foundWorkingTime.workingMinutes, "minute");
          end = dayjs(timeOut)
          start = end.add(-(foundWorkingTime.workingMinutes / 2), "minute");
        }
      }

      useHookForm.setValue("halfStart", new Date(start));
      useHookForm.setValue("halfEnd", new Date(end));

      if (foundWorkingTime.isWorkingDay === 0) {
        setLeaveUse((prev) => ({
          ...prev,
          dayUse: 0,
          hourUse: 0,
        }));
        return;
      }

      let hourUse = end.diff(start, "hour", true);
      let dayUse = null;

      if (foundWorkingTime.breakTime === 0) {
        dayUse =
          hourUse /
          (foundWorkingTime.workingHours - foundWorkingTime.breakTimeMin / 60);
      } else {
        dayUse = hourUse / foundWorkingTime.workingHours;
      }

      setLeaveUse((prev) => ({
        ...prev,
        dayUse,
        hourUse,
      }));
    } else if (useHookForm.getValues("leaveDayType") === "custom") {
      let date = dayjs(useHookForm.getValues("start"));

      if (leaveRoundConfig) {
        if (
          !date.isBetween(
            dayjs(leaveRoundConfig.start),
            dayjs(leaveRoundConfig.end),
            "day",
            "[]"
          )
        ) {
          setErrorConfig((prev) => ({
            ...prev,
            isNotLeaveRoundEnd: true,
          }));
        }
      }

      if (foundLeaveType) {
        if (
          foundLeaveType.daysInAdvance &&
          date.diff(dayjs(), "day") < foundLeaveType.daysInAdvance
        ) {
          setErrorConfig((prev) => ({
            ...prev,
            isDayInAdvance: true,
          }));
        }
      }

      let startBreakTime = null;
      let endBreakTime = null;

      // const foundWorkingTime = workingTime.find(
      //   (item) => item.date === date.format("YYYY-MM-DD")
      // );

      const foundWorkingTime = workingTime.find(
        (item) => dayjs(item.timeInDateTime).format("YYYY-MM-DD") === date.format("YYYY-MM-DD")
      );

      if (!foundWorkingTime) return;

      // let timeIn = dayjs(`${foundWorkingTime.date} ${foundWorkingTime.timeIn}`);
      // let timeOut = timeIn.add(foundWorkingTime.workingMinutes, "minute");
      let timeIn = dayjs(`${foundWorkingTime.timeInDateTime}`);
      let timeOut = dayjs(`${foundWorkingTime.timeOutDateTime}`);

      if (
        !useHookForm.getValues("start") ||
        !useHookForm.getValues("startHour") ||
        !useHookForm.getValues("startMinute") ||
        !useHookForm.getValues("end") ||
        !useHookForm.getValues("endHour") ||
        !useHookForm.getValues("endMinute")
      ) {
        setLeaveUse((prev) => ({
          ...prev,
          dayUse: 0,
          hourUse: 0,
        }));
        return;
      }

      let tempStart = dayjs(
        `${dayjs(useHookForm.getValues("start")).format(
          "YYYY-MM-DD"
        )} ${useHookForm.getValues("startHour")}:${useHookForm.getValues(
          "startMinute"
        )}:00`
      );
      let tempEnd = dayjs(
        `${dayjs(useHookForm.getValues("end")).format(
          "YYYY-MM-DD"
        )} ${useHookForm.getValues("endHour")}:${useHookForm.getValues(
          "endMinute"
        )}:00`
      );

      if (tempEnd.isBefore(tempStart)) {
        setErrorConfig((prev) => ({
          ...prev,
          isInvalidStartEnd: true,
        }));
        return;
      }

      if(foundWorkingTime.isTimeFrame === 0){

        let hourUse = 0;
        let dayUse = 0;

        hourUse = tempEnd.diff(tempStart, "hour", true);
        if (hourUse < 0) {
          hourUse = 0;
        }

        if(foundWorkingTime.isWorkingDay === 0){
          hourUse = 0;
        }

        let totalWorkingHour = (foundWorkingTime.workingHours - (foundWorkingTime.breakTimeMin ? (foundWorkingTime.breakTimeMin / 60) : 0));
        dayUse = hourUse > 0? (hourUse / totalWorkingHour) : 0

        if( hourUse > totalWorkingHour ){
          dayUse = 1;
          hourUse = totalWorkingHour;
        }

        setLeaveUse((prev) => ({
          ...prev,
          dayUse,
          hourUse: hourUse,
        }));
  
        const findLeaveType = leaveAvailable.find(
          (item) => item.idLeaveType === useHookForm.getValues("idLeaveType")
        );
        if (!findLeaveType) return;
  
        if (findLeaveType.minLeave === 10) {
          if (hourUse < 1) {
            setErrorConfig((prev) => ({
              ...prev,
              isMinimum: true,
            }));
          }
        } else if (findLeaveType.minLeave === 5) {
          if (hourUse < 0.5) {
            setErrorConfig((prev) => ({
              ...prev,
              isMinimum: true,
            }));
          }
        }

        return
      }

      // const foundWorkingTimePrev = workingTime.find(
      //   (item) => item.date === date.add(-1, "day").format("YYYY-MM-DD")
      // );
      const foundWorkingTimePrev = workingTime.find(
        (item) => dayjs(item.timeInDateTime).format("YYYY-MM-DD") === date.add(-1, "day").format("YYYY-MM-DD")
      );

      let timeInPrev = null;
      let timeOutPrev = null;
      let tempStartPre = tempStart;
      let tempEndPre = tempEnd;
      let hourPrevUse = 0;
      let startBreakTimePrev = null;
      let endBreakTimePrev = null;

      if (foundWorkingTimePrev) {
        if (foundWorkingTimePrev.isWorkingDay === 1) {
          // timeInPrev = dayjs(
          //   `${foundWorkingTimePrev.date} ${foundWorkingTimePrev.timeIn}`
          // );
          // timeOutPrev = timeInPrev.add(
          //   foundWorkingTimePrev.workingMinutes,
          //   "minute"
          // );
          timeInPrev = dayjs(
            `${foundWorkingTimePrev.timeInDateTime}`
          );
          timeOutPrev = dayjs(
            `${foundWorkingTimePrev.timeOutDateTime}`
          );

          if (tempStartPre.isBefore(timeInPrev)) tempStartPre = timeInPrev;
          if (tempEndPre.isAfter(timeOutPrev)) tempEndPre = timeOutPrev;

          if (foundWorkingTimePrev.breakTime === 0) {
            startBreakTimePrev = dayjs(
              `${timeInPrev.format("YYYY-MM-DD")} ${
                foundWorkingTimePrev.startBreak
              }`
            );
            if (startBreakTimePrev.isBefore(timeInPrev)) {
              startBreakTimePrev = dayjs(
                `${timeOutPrev.format("YYYY-MM-DD")} ${
                  foundWorkingTimePrev.startBreak
                }`
              );
            }
            endBreakTimePrev = startBreakTimePrev.add(
              foundWorkingTimePrev.breakTimeMin,
              "minute"
            );

            let beforeBreakHour = 0;
            if (tempEndPre.isBefore(startBreakTimePrev)) {
              beforeBreakHour = tempEndPre.diff(tempStartPre, "hour", true);
            } else {
              beforeBreakHour = startBreakTimePrev.diff(
                tempStartPre,
                "hour",
                true
              );
            }
            let afterBreakHour = 0;
            if (tempStartPre.isAfter(endBreakTimePrev)) {
              afterBreakHour = tempEndPre.diff(tempStartPre, "hour", true);
            } else {
              afterBreakHour = tempEndPre.diff(endBreakTimePrev, "hour", true);
            }
            if (beforeBreakHour > 0) hourPrevUse += beforeBreakHour;
            if (afterBreakHour > 0) hourPrevUse += afterBreakHour;
          } else {
            if (timeOutPrev.isSame(date, "date")) {
              hourPrevUse = tempEndPre.diff(tempStartPre, "hour", true);
              if (hourPrevUse < 0) {
                hourPrevUse = 0;
              }
            }
          }
        }
      }

      // const foundWorkingTimeNext = workingTime.find(
      //   (item) => item.date === date.add(1, "day").format("YYYY-MM-DD")
      // );
      const foundWorkingTimeNext = workingTime.find(
        (item) => dayjs(item.timeInDateTime).format("YYYY-MM-DD") === date.add(1, "day").format("YYYY-MM-DD")
      );
      let timeInNext = null;
      let timeOutNext = null;
      let tempStartNext = tempStart;
      let tempEndNext = tempEnd;
      let hourNextUse = 0;
      let startBreakTimeNext = null;
      let endBreakTimeNext = null;

      if (foundWorkingTimeNext) {
        if (foundWorkingTimeNext.isWorkingDay === 1) {
          // timeInNext = dayjs(
          //   `${foundWorkingTimeNext.date} ${foundWorkingTimeNext.timeIn}`
          // );
          // timeOutNext = timeInNext.add(
          //   foundWorkingTimeNext.workingMinutes,
          //   "minute"
          // );
          timeInNext = dayjs(
            `${foundWorkingTimeNext.timeInDateTime}`
          );
          timeOutNext = dayjs(
            `${foundWorkingTimeNext.timeOutDateTime}`
          );

          if (tempStartNext.isBefore(timeInNext)) tempStartNext = timeInNext;
          if (tempEndNext.isAfter(timeOutNext)) tempEndNext = timeOutNext;

          if (foundWorkingTimeNext.breakTime === 0) {
            startBreakTimeNext = dayjs(
              `${timeInNext.format("YYYY-MM-DD")} ${
                foundWorkingTimeNext.startBreak
              }`
            );
            if (startBreakTimeNext.isBefore(timeInNext)) {
              startBreakTimeNext = dayjs(
                `${timeOutPrev.format("YYYY-MM-DD")} ${
                  foundWorkingTimeNext.startBreak
                }`
              );
            }
            endBreakTimeNext = startBreakTimeNext.add(
              foundWorkingTimeNext.breakTimeMin,
              "minute"
            );

            let beforeBreakHour = 0;
            if (tempEndNext.isBefore(startBreakTimeNext)) {
              beforeBreakHour = tempEndNext.diff(tempStartNext, "hour", true);
            } else {
              beforeBreakHour = startBreakTimeNext.diff(
                tempStartNext,
                "hour",
                true
              );
            }
            let afterBreakHour = 0;
            if (tempStartNext.isAfter(endBreakTimeNext)) {
              afterBreakHour = tempEndNext.diff(tempStartNext, "hour", true);
            } else {
              afterBreakHour = tempEndNext.diff(endBreakTimeNext, "hour", true);
            }
            if (beforeBreakHour > 0) hourNextUse += beforeBreakHour;
            if (afterBreakHour > 0) hourNextUse += afterBreakHour;
          } else {
            hourNextUse = tempEndNext.diff(tempStartNext, "hour", true);
            if (hourNextUse < 0) {
              hourNextUse = 0;
            }
          }
        }
      }

      if (tempStart.isBefore(timeIn)) tempStart = timeIn;
      if (tempEnd.isAfter(timeOut)) tempEnd = timeOut;

      if (foundWorkingTime.breakTime === 0) {
        startBreakTime = dayjs(
          `${timeIn.format("YYYY-MM-DD")} ${foundWorkingTime.startBreak}`
        );
        if (startBreakTime.isBefore(timeIn)) {
          startBreakTime = dayjs(
            `${timeOut.format("YYYY-MM-DD")} ${foundWorkingTime.startBreak}`
          );
        }
        endBreakTime = startBreakTime.add(
          foundWorkingTime.breakTimeMin,
          "minute"
        );
      }

      let hourUse = 0;

      if (foundWorkingTime.breakTime === 0) {
        let beforeBreakHour = 0;
        if (tempEnd.isBefore(startBreakTime)) {
          beforeBreakHour = tempEnd.diff(tempStart, "hour", true);
        } else {
          beforeBreakHour = startBreakTime.diff(tempStart, "hour", true);
        }
        let afterBreakHour = 0;
        if (tempStart.isAfter(endBreakTime)) {
          afterBreakHour = tempEnd.diff(tempStart, "hour", true);
        } else {
          afterBreakHour = tempEnd.diff(endBreakTime, "hour", true);
        }
        if (beforeBreakHour > 0) hourUse += beforeBreakHour;
        if (afterBreakHour > 0) hourUse += afterBreakHour;
      } else {
        hourUse = tempEnd.diff(tempStart, "hour", true);
        if (hourUse < 0) {
          hourUse = 0;
        }
      }

      if (foundWorkingTime.isWorkingDay === 0) {
        hourUse = 0;
      }

      let dayUse = null;

      if (foundWorkingTime.breakTime === 0) {
        dayUse =
          (hourUse / (foundWorkingTime.workingHours - foundWorkingTime.breakTimeMin / 60))
          + (hourPrevUse / (foundWorkingTimePrev.workingHours - foundWorkingTimePrev.breakTimeMin / 60))
          + (hourNextUse / (foundWorkingTimeNext.workingHours - foundWorkingTimeNext.breakTimeMin / 60))
      } else {
        dayUse =
          (hourUse / foundWorkingTime.workingHours) +
          (foundWorkingTimePrev
            ? hourPrevUse / foundWorkingTimePrev.workingHours
            : 0) +
          (foundWorkingTimeNext
            ? hourNextUse / foundWorkingTimeNext.workingHours
            : 0)
      }

      setLeaveUse((prev) => ({
        ...prev,
        dayUse,
        hourUse: hourUse + hourPrevUse + hourNextUse,
      }));

      const findLeaveType = leaveAvailable.find(
        (item) => item.idLeaveType === useHookForm.getValues("idLeaveType")
      );
      if (!findLeaveType) return;

      if (findLeaveType.minLeave === 10) {
        if (hourUse + hourPrevUse + hourNextUse < 1) {
          setErrorConfig((prev) => ({
            ...prev,
            isMinimum: true,
          }));
        }
      } else if (findLeaveType.minLeave === 5) {
        if (hourUse + hourPrevUse + hourNextUse < 0.5) {
          setErrorConfig((prev) => ({
            ...prev,
            isMinimum: true,
          }));
        }
      }
    }
  };

  const calculateLeaveRemain = () => {
    if (!useHookForm.getValues("idLeaveType")) {
      setLeaveRemain((prev) => ({
        ...prev,
        dayRemain: 0,
      }));
      return;
    }

    const findLeaveType = leaveAvailable.find(
      (item) => item.idLeaveType === useHookForm.getValues("idLeaveType")
    );

    if (!findLeaveType) return;

    let idLeaveGroup = findLeaveType.idLeaveGroup;

    let leaveValue = findLeaveType.leaveValue;
    let carryRemain = findLeaveType.carryRemain;
    let leaveRemain = findLeaveType.leaveRemain;

    if (leaveValue === null) {
      setLeaveRemain((prev) => ({
        ...prev,
        dayRemain: null,
      }));
      return;
    }

    setLeaveRemain((prev) => ({
      ...prev,
      dayRemain: leaveRemain + carryRemain,
    }));
  };

  const onSubmit = async (data) => {
    const findLeaveType = leaveAvailable.find(
      (item) => item.idLeaveType === useHookForm.getValues("idLeaveType")
    );

    let quota =
      findLeaveType.leaveValue === null
        ? null
        : findLeaveType.leaveValue + findLeaveType.carryValue;

    let start = null;
    let end = null;

    if (data.leaveDayType === "fullday") {
      start = dayjs(data.start)
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0)
        .set("millisecond", 0);
      end = dayjs(data.end)
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0)
        .set("millisecond", 0);
    } else if (data.leaveDayType === "halfday") {
      start = dayjs(data.halfStart);
      end = dayjs(data.halfEnd);
    } else {
      start = dayjs(
        `${dayjs(data.start).format("YYYY-MM-DD")} ${data.startHour}:${
          data.startMinute
        }:00`
      );
      end = dayjs(
        `${dayjs(data.end).format("YYYY-MM-DD")} ${data.endHour}:${
          data.endMinute
        }:00`
      );
    }

    let remaining = 0;
    if (data.isLeaveCompensate) {
      remaining =
        leaveRemain.dayRemain - leaveUse.hourUse > 0
          ? leaveRemain.dayRemain - leaveUse.hourUse
          : 0;
    } else {
      remaining =
        leaveRemain.dayRemain - leaveUse.dayUse > 0
          ? leaveRemain.dayRemain - leaveUse.dayUse
          : 0;
    }

    const formData = {
      idLeaveType: Number(data.idLeaveType),
      leaveDayType: data.leaveDayType,
      description: data.description,
      idEmployees: userProfile.idEmployees,
      startDate: start,
      endDate: end,
      used: leaveUse.dayUse,
      quota: quota,
      balance: leaveRemain.dayRemain,
      remaining: remaining,
      idManager: userProfile.idManagerLV1,
      idManagerGroup: userProfile.idManagerGroupLV1,
      isApprove: null,
      isActive: 1,
      isFullDay: data.leaveDayType === "fullday" ? 1 : 0,
      idHoliday: data.idHoliday ? data.idHoliday.idHoliday : null,
      file: data.file,
      hourUsed: leaveUse.hourUse,
      cc_email: data.cc_email
        ? data.cc_email.map((e) => e.email).join(";")
        : null,
      isLeaveCompensate: data.isLeaveCompensate,
    };

    setConfirmConfig((prev) => ({
      ...prev,
      data: formData,
      isOpen: true,
    }));
  };

  const isError = () => {
    let isInvalid = false;
    for (const [_, value] of Object.entries(errorConfig)) {
      if (value) isInvalid = true;
    }
    return isInvalid;
  };

  return (
    <DrawerCustom
      title={`${t("LeaveRequest")}`}
      anchor="right"
      open={drawerConfig.isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      {/* {console.log("LeaveUse", leaveUse)}
      {console.log("leaveRemain", leaveRemain)} */}
      {confirmConfig.isOpen ? (
        <DialogConfirmLeave
          data={confirmConfig.data}
          handleClose={onClose}
          openConfirm={(isOpen) => {
            setConfirmConfig((prev) => ({ ...prev, isOpen: isOpen }));
          }}
          selectedLeaveRoundDate={drawerConfig.data.selectedLeaveRoundDate}
        />
      ) : (
        <StyledRoot>
          <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  className="field-label"
                  color="text.secondary"
                >{`${t("LeaveType")}`}</Typography>
                <Controller
                  name="idLeaveType"
                  control={useHookForm.control}
                  render={({ field }) => (
                    <TextFieldTheme
                      {...field}
                      select
                      SelectProps={{
                        displayEmpty: true,
                        renderValue: (selected) => {
                          if (selected.length === 0) {
                            return (
                              <Typography color="text.secondary">{`${t(
                                "SelectLeaveType"
                              )}`}</Typography>
                            );
                          } else {
                            return (
                              <Typography>
                                {leaveAvailable && leaveAvailable.length > 0
                                  ? leaveAvailable.find(
                                      (item) =>
                                        Number(item.idLeaveType) ===
                                        Number(selected)
                                    ).name
                                  : "-"}
                              </Typography>
                            );
                          }
                        },
                      }}
                      helperText={
                        useHookForm.formState.errors[field.name]
                          ? useHookForm.formState.errors[field.name].message
                          : null
                      }
                      error={
                        useHookForm.formState.errors[field.name] ? true : false
                      }
                      onChange={(event, node) => {
                        setLeaveTypeSelected(
                          leaveAvailable.find(
                            (item) =>
                              Number(item.idLeaveType) ===
                              Number(event.target.value)
                          )
                        );
                        field.onChange(event.target.value);
                        useHookForm.setValue(
                          "isLeaveHoliday",
                          Boolean(node.props.isleaveholiday)
                        );
                        useHookForm.setValue(
                          "isLeaveCompensate",
                          Boolean(node.props.isleavecompensate)
                        );
                        useHookForm.setValue("idHoliday", null);
                        useHookForm.resetField("idHoliday");
                        calculateMinimumLeave();
                        calculateLeaveUse();
                        calculateLeaveRemain();
                      }}
                    >
                      <MenuItem value="" disabled>
                        {`${t("SelectLeaveType")}`}
                      </MenuItem>
                      {leaveAvailable &&
                        leaveAvailable.map((item, index) => (
                          <MenuItem
                            key={index}
                            value={item.idLeaveType}
                            idleavegroup={item.idLeaveGroup}
                            isleaveholiday={item.isLeaveHoliday}
                            isleavecompensate={item.isLeaveCompensate}
                          >
                            {i18n.resolvedLanguage === "th"
                              ? item.name
                              : item.name_EN}
                          </MenuItem>
                        ))}
                    </TextFieldTheme>
                  )}
                />
              </Grid>
              {isLeaveHolidayWatch === true && (
                <Grid item xs={12}>
                  <Typography className="field-label" color="text.secondary">
                    {`${t("DayOff")}`}
                  </Typography>
                  <Controller
                    name="idHoliday"
                    control={useHookForm.control}
                    render={({ field }) => (
                      <Autocomplete
                        options={
                          holiday && leaveEmployeesList
                            ? holiday.filter(
                                (item) =>
                                  !leaveEmployeesList.find(
                                    (x) =>
                                      x.idHoliday === item.idHoliday &&
                                      x.isActive === 1 &&
                                      x.isApprove !== 0
                                  )
                              )
                            : []
                        }
                        getOptionLabel={(option) => `${option.name}`}
                        isOptionEqualToValue={(option, value) =>
                          option.idHoliday === value.idHoliday
                        }
                        renderInput={(params) => (
                          <TextFieldTheme
                            {...params}
                            placeholder={`${t("SearchForHolidays")}`}
                            onBlur={field.onBlur}
                            helperText={
                              useHookForm.formState.errors[field.name]
                                ? useHookForm.formState.errors[field.name]
                                    .message
                                : null
                            }
                            error={
                              useHookForm.formState.errors[field.name]
                                ? true
                                : false
                            }
                          />
                        )}
                        value={field.value}
                        onChange={(_, value) => {
                          field.onChange(value);
                        }}
                        noOptionsText={`${t("NoData")}`}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12} container>
                <Controller
                  name="leaveDayType"
                  control={useHookForm.control}
                  render={({ field }) => (
                    <StyledToggleButtonGroup
                      {...field}
                      exclusive
                      onChange={(_, newValue) => {
                        if (newValue !== null) {
                          field.onChange(newValue);
                          if (newValue === "halfday") {
                            useHookForm.setValue("halfDayType", "half-first");
                            useHookForm.setValue(
                              "end",
                              useHookForm.getValues("start")
                            );
                            if (
                              !workingTime.find(
                                (item) =>
                                  item.date ===
                                  dayjs(useHookForm.getValues("start")).format(
                                    "YYYY-MM-DD"
                                  )
                              )
                            ) {
                              fetchingWorkingTime();
                            }
                          } else {
                            useHookForm.setValue("halfDayType", "");
                          }
                          if (newValue === "custom") {
                            useHookForm.setValue(
                              "end",
                              useHookForm.getValues("start")
                            );
                            if (
                              !workingTime.find(
                                (item) =>
                                  item.date ===
                                  dayjs(useHookForm.getValues("start")).format(
                                    "YYYY-MM-DD"
                                  )
                              )
                            ) {
                              fetchingWorkingTime();
                            }
                          }
                          calculateLeaveUse();
                        }
                      }}
                    >
                      <ToggleButton
                        value="fullday"
                        size="small"
                        disabled={!minimumUseConfig.isFullDay}
                      >
                        {`${t("FullDay")}`}
                      </ToggleButton>
                      <ToggleButton
                        value="halfday"
                        size="small"
                        disabled={!minimumUseConfig.isHalfDay}
                      >
                        {`${t("Half_day")}`}
                      </ToggleButton>
                      <ToggleButton
                        value="custom"
                        size="small"
                        disabled={!minimumUseConfig.isCustom}
                      >
                        {`${t("EnterTime")}`}
                      </ToggleButton>
                    </StyledToggleButtonGroup>
                  )}
                />
              </Grid>
              {leaveDayTypeWatch === "halfday" && (
                <Grid item xs={12} container>
                  <Controller
                    name="halfDayType"
                    control={useHookForm.control}
                    render={({ field }) => (
                      <StyledToggleButtonGroup
                        {...field}
                        exclusive
                        onChange={(_, newValue) => {
                          if (newValue !== null) {
                            field.onChange(newValue);
                            calculateLeaveUse();
                          }
                        }}
                      >
                        <ToggleButton value="half-first" size="small">
                          {`${t("FirstHalfDay")}`}
                        </ToggleButton>
                        <ToggleButton value="half-second" size="small">
                          {`${t("SecondHalfDay")}`}
                        </ToggleButton>
                      </StyledToggleButtonGroup>
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography className="field-label" color="text.secondary">
                  {(() => {
                    if (leaveDayTypeWatch === "fullday")
                      return `${t("StartDate")}`;
                    if (leaveDayTypeWatch === "halfday")
                      return `${t("LeaveDate")}`;
                    if (leaveDayTypeWatch === "custom")
                      return `${t("LeaveDate")}`;
                  })()}
                </Typography>
                {/* <Controller
                  name="start"
                  control={useHookForm.control}
                  render={({ field }) => (
                    <DatePickerCustom
                      {...field}
                      views={["year", "month", "day"]}
                      inputFormat="DD/MM/YYYY"
                      openTo="year"
                      value={field.value}
                      onChange={(newValue) => {
                        field.onChange(newValue);
                        useHookForm.setValue("end", newValue);
                        fetchingWorkingTime();
                      }}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: `${t("DayMonthYear(AD)")}`,
                          }}
                          onBlur={field.onBlur}
                          helperText={
                            useHookForm.formState.errors[field.name]
                              ? useHookForm.formState.errors[field.name]
                                  .message
                              : null
                          }
                          error={
                            useHookForm.formState.errors[field.name]
                              ? true
                              : false
                          }
                        />
                      )}
                      PaperProps={{
                        sx: {
                          "& .Mui-selected": {
                            backgroundColor: "#46cbe2!important",
                          },
                        },
                      }}
                    />
                  )}
                /> */}
                <Controller
                  name="start"
                  control={useHookForm.control}
                  render={({ field }) => (
                    <LocalizationProvider
                      dateFormats={{
                        year: "yyyy",
                        monthAndYear: "MMMM yyyy",
                        keyboardDate: "dd/MM/yyyy",
                      }}
                      dateAdapter={AdapterDateFns}
                      adapterLocale={th}
                    >
                      <DatePicker
                        {...field}
                        views={["year", "month", "day"]}
                        inputFormat="dd/MM/yyyy"
                        openTo="day"
                        value={field.value}
                        onChange={(newValue) => {
                          field.onChange(newValue);
                          useHookForm.setValue("end", newValue);
                          fetchingWorkingTime();
                        }}
                        minDate={new Date(leaveRoundConfig.start)}
                        maxDate={new Date(leaveRoundConfig.end)}
                        renderInput={(params) => (
                          <TextFieldTheme
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: `${t("DayMonthYear(AD)")}`,
                              readOnly: true,
                            }}
                            onBlur={field.onBlur}
                            helperText={
                              useHookForm.formState.errors[field.name]
                                ? useHookForm.formState.errors[field.name]
                                    .message
                                : null
                            }
                            error={
                              useHookForm.formState.errors[field.name]
                                ? true
                                : false
                            }
                          />
                        )}
                        PaperProps={{
                          sx: {
                            "& .Mui-selected": {
                              backgroundColor: "#46cbe2!important",
                            },
                          },
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />

                {errorConfig.isDayInAdvance && leaveTypeSelected !== null && (
                  <Typography fontSize="20px" fontWeight="500" color="error">
                    *{`${t("LeaveIn")}`} {leaveTypeSelected.daysInAdvance}{" "}
                    {`${t("Unit.Days")}`}
                  </Typography>
                )}
              </Grid>

              {leaveDayTypeWatch === "custom" && (
                <Fragment>
                  <Grid item xs={12}>
                    <Typography className="field-label" color="text.secondary">
                      {`${t("StartTime")}`}
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Controller
                          name="startHour"
                          control={useHookForm.control}
                          render={({ field }) => (
                            <TimePicker
                              handleChangeTime={(_a, _b, _c, value) => {
                                field.onChange(value);
                                calculateLeaveUse();
                              }}
                              unit="hour"
                              value={field.value}
                              helperText={
                                useHookForm.formState.errors[field.name]
                                  ? useHookForm.formState.errors[field.name]
                                      .message
                                  : null
                              }
                              error={
                                useHookForm.formState.errors[field.name]
                                  ? true
                                  : false
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Controller
                          name="startMinute"
                          control={useHookForm.control}
                          render={({ field }) => (
                            <TimePicker
                              handleChangeTime={(_a, _b, _c, value) => {
                                field.onChange(value);
                                calculateLeaveUse();
                              }}
                              unit="minute"
                              step={15}
                              value={field.value}
                              helperText={
                                useHookForm.formState.errors[field.name]
                                  ? useHookForm.formState.errors[field.name]
                                      .message
                                  : null
                              }
                              error={
                                useHookForm.formState.errors[field.name]
                                  ? true
                                  : false
                              }
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    {(useHookForm.formState.errors["startHour"] ||
                      useHookForm.formState.errors["startMinute"]) && (
                      <Typography
                        padding="3px 14px 0"
                        fontSize="12px"
                        color="error"
                      >
                        {`${t("ThisFieldIsRequired")}`}
                      </Typography>
                    )}
                  </Grid>

                  {(() => {
                    const foundWorkingTime = workingTime.find(
                      (item) =>
                        item.date ===
                        dayjs(useHookForm.getValues("start")).format(
                          "YYYY-MM-DD"
                        )
                    );

                    if (!foundWorkingTime) return;

                    let timeIn = dayjs(
                      `${foundWorkingTime.date} ${foundWorkingTime.timeIn}`
                    );
                    let timeOut = timeIn.add(
                      foundWorkingTime.workingMinutes,
                      "minute"
                    );

                    if (timeOut.isAfter(timeIn, "date")) {
                      return (
                        <Grid item xs={12}>
                          <Typography
                            className="field-label"
                            color="text.secondary"
                          >
                            {`${t("EndDate")}`}
                          </Typography>
                          {/* <Controller
                            name="end"
                            control={useHookForm.control}
                            render={({ field }) => (
                              <DatePickerCustom
                                {...field}
                                views={["year", "month", "day"]}
                                inputFormat="DD/MM/YYYY"
                                openTo="year"
                                value={field.value}
                                onChange={(newValue) => {
                                  field.onChange(newValue);
                                  fetchingWorkingTime();
                                }}
                                minDate={
                                  new Date(useHookForm.getValues("start"))
                                }
                                maxDate={
                                  new Date(
                                    dayjs(useHookForm.getValues("start")).add(
                                      1,
                                      "day"
                                    )
                                  )
                                }
                                renderInput={(params) => (
                                  <TextFieldTheme
                                    {...params}
                                    inputProps={{
                                      ...params.inputProps,
                                      placeholder: `${t("DayMonthYear(AD)")}`,
                                    }}
                                    onBlur={field.onBlur}
                                    helperText={
                                      useHookForm.formState.errors[field.name]
                                        ? useHookForm.formState.errors[
                                            field.name
                                          ].message
                                        : null
                                    }
                                    error={
                                      useHookForm.formState.errors[field.name]
                                        ? true
                                        : false
                                    }
                                  />
                                )}
                                PaperProps={{
                                  sx: {
                                    "& .Mui-selected": {
                                      backgroundColor: "#46cbe2!important",
                                    },
                                  },
                                }}
                              />
                            )}
                          /> */}
                          <Controller
                            name="end"
                            control={useHookForm.control}
                            render={({ field }) => (
                              <LocalizationProvider
                                dateFormats={{
                                  year: "yyyy",
                                  monthAndYear: "MMMM yyyy",
                                  keyboardDate: "dd/MM/yyyy",
                                }}
                                dateAdapter={AdapterDateFns}
                                adapterLocale={th}
                              >
                                <DatePicker
                                  {...field}
                                  views={["year", "month", "day"]}
                                  inputFormat="dd/MM/yyyy"
                                  openTo="day"
                                  value={field.value}
                                  onChange={(newValue) => {
                                    field.onChange(newValue);
                                    fetchingWorkingTime();
                                  }}
                                  minDate={
                                    new Date(useHookForm.getValues("start"))
                                  }
                                  maxDate={
                                    new Date(
                                      dayjs(useHookForm.getValues("start")).add(
                                        1,
                                        "day"
                                      )
                                    )
                                  }
                                  renderInput={(params) => (
                                    <TextFieldTheme
                                      {...params}
                                      inputProps={{
                                        ...params.inputProps,
                                        placeholder: `${t("DayMonthYear(AD)")}`,
                                        readOnly: true,
                                      }}
                                      onBlur={field.onBlur}
                                      helperText={
                                        useHookForm.formState.errors[field.name]
                                          ? useHookForm.formState.errors[
                                              field.name
                                            ].message
                                          : null
                                      }
                                      error={
                                        useHookForm.formState.errors[field.name]
                                          ? true
                                          : false
                                      }
                                    />
                                  )}
                                  PaperProps={{
                                    sx: {
                                      "& .Mui-selected": {
                                        backgroundColor: "#46cbe2!important",
                                      },
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                            )}
                          />
                        </Grid>
                      );
                    }
                  })()}

                  <Grid item xs={12}>
                    <Typography className="field-label" color="text.secondary">
                      {`${t("EndTime")}`}
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Controller
                          name="endHour"
                          control={useHookForm.control}
                          render={({ field }) => (
                            <TimePicker
                              handleChangeTime={(_a, _b, _c, value) => {
                                field.onChange(value);
                                calculateLeaveUse();
                              }}
                              unit="hour"
                              value={field.value}
                              helperText={
                                useHookForm.formState.errors[field.name]
                                  ? useHookForm.formState.errors[field.name]
                                      .message
                                  : null
                              }
                              error={
                                useHookForm.formState.errors[field.name]
                                  ? true
                                  : false
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Controller
                          name="endMinute"
                          control={useHookForm.control}
                          render={({ field }) => (
                            <TimePicker
                              handleChangeTime={(_a, _b, _c, value) => {
                                field.onChange(value);
                                calculateLeaveUse();
                              }}
                              unit="minute"
                              step={15}
                              value={field.value}
                              helperText={
                                useHookForm.formState.errors[field.name]
                                  ? useHookForm.formState.errors[field.name]
                                      .message
                                  : null
                              }
                              error={
                                useHookForm.formState.errors[field.name]
                                  ? true
                                  : false
                              }
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    {(useHookForm.formState.errors["endHour"] ||
                      useHookForm.formState.errors["endMinute"]) && (
                      <Typography
                        padding="3px 14px 0"
                        fontSize="12px"
                        color="error"
                      >
                        {`${t("ThisFieldIsRequired")}`}
                      </Typography>
                    )}
                  </Grid>
                </Fragment>
              )}

              {leaveDayTypeWatch === "fullday" && (
                <Grid item xs={12}>
                  <Typography className="field-label" color="text.secondary">
                    {`${t("EndDate")}`}
                  </Typography>
                  <Controller
                    name="end"
                    control={useHookForm.control}
                    render={({ field }) => (
                      <LocalizationProvider
                        dateFormats={{
                          year: "yyyy",
                          monthAndYear: "MMMM yyyy",
                          keyboardDate: "dd/MM/yyyy",
                        }}
                        dateAdapter={AdapterDateFns}
                        adapterLocale={th}
                      >
                        <DatePicker
                          {...field}
                          views={["year", "month", "day"]}
                          inputFormat="dd/MM/yyyy"
                          openTo="day"
                          value={field.value}
                          onChange={(newValue) => {
                            field.onChange(newValue);
                            fetchingWorkingTime();
                          }}
                          minDate={new Date(leaveRoundConfig.start)}
                          maxDate={new Date(leaveRoundConfig.end)}
                          renderInput={(params) => (
                            <TextFieldTheme
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: `${t("DayMonthYear(AD)")}`,
                                readOnly: true,
                              }}
                              onBlur={field.onBlur}
                              helperText={
                                useHookForm.formState.errors[field.name]
                                  ? useHookForm.formState.errors[field.name]
                                      .message
                                  : null
                              }
                              error={
                                useHookForm.formState.errors[field.name]
                                  ? true
                                  : false
                              }
                            />
                          )}
                          PaperProps={{
                            sx: {
                              "& .Mui-selected": {
                                backgroundColor: "#46cbe2!important",
                              },
                            },
                          }}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Grid>
              )}

              {errorConfig.isOverResetEndOfMonth && (
                <Grid item xs={12}>
                  <Typography fontSize="20px" fontWeight="500" color="error">
                    *ไม่สามารถลาคร่อมวันตัดรอบได้
                  </Typography>
                </Grid>
              )}

              {errorConfig.isNotLeaveRoundEnd && (
                <Grid item xs={12}>
                  <Typography fontSize="20px" fontWeight="500" color="error">
                    *ไม่สามารถลานอกช่วงรอบการลาที่เลือกได้
                  </Typography>
                </Grid>
              )}

              <Grid item xs={12}>
                <Typography className="field-label" color="text.secondary">
                  {`${t("Leaves")}`}
                </Typography>
                {errorConfig.isInvalidStartEnd ? (
                  <Typography fontSize="20px" fontWeight="500" color="error">
                    *{`${t("InvalidStartandEndDates")}`}
                  </Typography>
                ) : (
                  <Typography fontSize="20px" fontWeight="500">
                    {isCalculating
                      ? `${t("Calculation")}`
                      : leaveTypeSelected && leaveTypeSelected.isLeaveCompensate
                        ? `${getRoundNumber(leaveUse.hourUse, 2, false)} ${t("Unit.Hours")}`
                        : `${getRoundNumber(leaveUse.dayUse, 2, false)} ${t(
                          "Unit.Days"
                        )} (${getRoundNumber(leaveUse.hourUse, 2, false)} ${t(
                          "Unit.Hours"
                        )})`
                    }
                  </Typography>
                )}
                {errorConfig.isMinimum && (
                  <Typography fontSize="20px" fontWeight="500" color="error">
                    *{`${t("LeavesBelowMinimumRequirement")}`}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography className="field-label" color="text.secondary">
                  {`${t("RemainingLeaveEntitlement")}`}
                </Typography>
                <Typography fontSize="20px" fontWeight="500">
                  {isCalculating
                    ? `${t("Calculation")}`
                    : leaveRemain.dayRemain === null
                    ? `${t("Unlimited")}`
                    : `${getRoundNumber(leaveRemain.dayRemain, 2, false)} ${
                        leaveTypeSelected
                          ? leaveTypeSelected.isLeaveCompensate
                            ? `${t("Unit.Hours")}`
                            : `${t("Unit.Days")}`
                          : `${t("Unit.Days")}`
                      }`}
                </Typography>
                {leaveTypeSelected &&
                  leaveTypeSelected.isLeaveCompensate === 0 &&
                  leaveRemain.dayRemain !== null &&
                  leaveRemain.dayRemain < leaveUse.dayUse && (
                    <Typography fontSize="20px" fontWeight="500" color="error">
                      *{`${t("RemainingLeaveEntitlementInsufficient")}`}
                    </Typography>
                  )}
                {leaveTypeSelected &&
                  leaveTypeSelected.isLeaveCompensate === 1 &&
                  leaveRemain.dayRemain !== null &&
                  leaveRemain.dayRemain < leaveUse.hourUse && (
                    <Typography fontSize="20px" fontWeight="500" color="error">
                      *{`${t("RemainingLeaveEntitlementInsufficient")}`}
                    </Typography>
                  )}
              </Grid>

              <Grid item xs={12}>
                <Typography className="field-label" color="text.secondary">
                  {`${t("Note")}`}
                </Typography>
                <Controller
                  name="description"
                  control={useHookForm.control}
                  render={({ field }) => (
                    <TextFieldTheme
                      {...field}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{
                              alignSelf: "flex-end",
                              fontSize: "12px",
                              userSelect: "none",
                            }}
                          >
                            {500 - field.value.length}
                          </InputAdornment>
                        ),
                      }}
                      multiline
                      minRows={3}
                      placeholder={`${t("Descriptions")} (${t("Optional")})`}
                      helperText={
                        useHookForm.formState.errors[field.name]
                          ? useHookForm.formState.errors[field.name].message
                          : null
                      }
                      error={
                        useHookForm.formState.errors[field.name] ? true : false
                      }
                      onChange={(event) => {
                        if (event.target.value.length > 500) {
                          field.onChange(event.target.value.slice(0, 500));
                        } else {
                          field.onChange(event.target.value);
                        }
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className="field-label" color="text.secondary">
                  {`${t("AttachFile")}`}
                </Typography>
                <DragDrop name="file" useHookForm={useHookForm} />
              </Grid>
              <Grid item xs={12}>
                <Typography className="field-label" color="text.secondary">
                  {`${t("CCtoManager")}`}
                </Typography>
                <Controller
                  name="cc_email"
                  control={useHookForm.control}
                  render={({ field }) => (
                    <Autocomplete
                      options={manager ? manager : []}
                      getOptionLabel={(option) =>
                        i18n.language === "th"
                          ? `${option.firstname_TH} ${option.lastname_TH}`
                          : option.firstname_EN
                            ? `${option.firstname_EN} ${option.lastname_EN}`
                            : `${option.firstname_TH} ${option.lastname_TH}`
                      }
                      getOptionDisabled={(_) => field.value.length >= 3}
                      isOptionEqualToValue={(option, value) =>
                        option.idEmployees === value.idEmployees
                      }
                      filterOptions={filterOptions}
                      renderOption={(props, option) => (
                        <MenuItem {...props} key={option.idEmployees}>
                          <Box>
                            <Typography>
                              {i18n.language === "th"
                                ? `${option.firstname_TH} ${option.lastname_TH}`
                                : option.firstname_EN
                                  ? `${option.firstname_EN} ${option.lastname_EN}`
                                  : `${option.firstname_TH} ${option.lastname_TH}`
                              }
                            </Typography>
                            <Typography color="text.third" fontSize="14px">
                              {option.email}
                            </Typography>
                          </Box>
                        </MenuItem>
                      )}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          placeholder={`${t("SearchManager")}`}
                          onBlur={field.onBlur}
                          helperText={
                            useHookForm.formState.errors[field.name]
                              ? useHookForm.formState.errors[field.name].message
                              : null
                          }
                          error={
                            useHookForm.formState.errors[field.name]
                              ? true
                              : false
                          }
                        />
                      )}
                      multiple
                      value={field.value}
                      onChange={(_, value) => {
                        field.onChange(value);
                      }}
                      noOptionsText={`${t("NoData")}`}
                      filterSelectedOptions
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} container justifyContent="space-between">
                <ButtonBlue variant="text" onClick={onClose}>
                  {`${t("Cancel")}`}
                </ButtonBlue>
                <ButtonBlue
                  variant="contained"
                  type="submit"
                  disabled={
                    isCalculating ||
                    isError() ||
                    (leaveTypeSelected &&
                      leaveTypeSelected.isLeaveCompensate &&
                      leaveRemain.dayRemain !== null &&
                      leaveRemain.dayRemain < leaveUse.hourUse) ||
                    (leaveTypeSelected &&
                      !leaveTypeSelected.isLeaveCompensate &&
                      leaveRemain.dayRemain !== null &&
                      leaveRemain.dayRemain < leaveUse.dayUse) ||
                    leaveUse.dayUse === 0 ||
                    leaveRemain.dayRemain === 0
                  }
                >
                  {`${t("Submit")}`}
                </ButtonBlue>
              </Grid>
            </Grid>
          </form>
        </StyledRoot>
      )}
    </DrawerCustom>
  );
};

export default DialogNewLeave;
