import React, { useState, useEffect } from "react";
import {
  Box,
  Modal,
  Button,
  Typography,
  TextField,
  Slider,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import {
  addOrganizationKeyResultProgress,
  getAllOrganizationKeyResultProgress,
  getAllIndividualKeyResultProgress,
  addIndividualKeyResultProgress,
  getAllTeamKeyResultProgress,
  addTeamKeyResultProgress,
} from "../../../../../../actions/okrs";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ClearIcon from "@mui/icons-material/Clear";
import KRUpdateHistoryModal from "./KRUpdatingHistory";
import Swal from "sweetalert2";

const EditKRModal = ({ open, handleClose, data, onObjectiveUpdated }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [historyOpen, setHistoryOpen] = useState(false);
  const [updateHistory, setUpdateHistory] = useState([]);
  const [formData, setFormData] = useState({
    keyResultName: data.keyResultName,
    progressPercent: data.progressPercent || 0,
    message: "",
  });

  const [isEvaluationEnabled, setIsEvaluationEnabled] = useState(false);
  const [isEvaluating, setIsEvaluating] = useState(false);
  const [isSuccess, setIsSuccess] = useState(1);
  const [rating, setRating] = useState(1.0);

  const { result: userProfile } = useSelector((state) => state.userProfile);

  useEffect(() => {
    const fetchUpdates = async () => {
      let response;
      if (userProfile.idRole === 4) {
        response = await dispatch(
          getAllOrganizationKeyResultProgress(data.idOrganizationKeyResult)
        );
      } else if (userProfile.idRole === 1) {
        response = await dispatch(
          getAllIndividualKeyResultProgress(data.idIndividualKeyResult)
        );
      } else if (userProfile.idRole === 2 || userProfile.idRole === 3) {
        response = await dispatch(
          getAllTeamKeyResultProgress(data.idTeamKeyResult)
        );
      }
      setUpdateHistory(response.data);
    };

    fetchUpdates();

    if (data.progressPercent === 100) {
      setIsEvaluationEnabled(true);
    } else {
      setIsEvaluationEnabled(false);
    }
  }, [dispatch, data, userProfile.idRole]);

  const [fileSelected, setFileSelected] = useState(null);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png, application/pdf",
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        setFileSelected(acceptedFiles[0]);
      }
    },
  });

  const handleOpenHistory = () => {
    setHistoryOpen(true);
  };

  const handleCloseHistory = () => {
    setHistoryOpen(false);
  };

  const handleRemoveFile = () => {
    setFileSelected(null);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSliderChange = (e, newValue) => {
    setFormData({
      ...formData,
      progressPercent: newValue,
    });

    if (newValue === 100) {
      setIsEvaluationEnabled(true);
    } else {
      setIsEvaluationEnabled(false);
      setIsEvaluating(false);
    }
  };

  const handleSave = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("message", formData.message);
    formDataToSend.append("progressPercent", formData.progressPercent);

    if (fileSelected) {
      formDataToSend.append("file", fileSelected);
    }

    if (isEvaluating) {
      formDataToSend.append("isSuccess", isSuccess);
      formDataToSend.append("rating", rating);
    }

    let response;
    //admin = 4, user = 1, user_manager = 2
    if (userProfile.idRole === 4) {
      response = await dispatch(
        addOrganizationKeyResultProgress(
          data.idOrganizationKeyResult,
          formDataToSend
        )
      );
    } else if (userProfile.idRole === 1) {
      response = await dispatch(
        addIndividualKeyResultProgress(
          data.idIndividualKeyResult,
          formDataToSend
        )
      );
    } else if (userProfile.idRole === 2) {
      response = await dispatch(
        addTeamKeyResultProgress(data.idTeamKeyResult, formDataToSend)
      );
    }

    if (response.status === 200) {
      Swal.fire({
        icon: "success",
        title: t("UpdateSuccessful"),
        showConfirmButton: false,
        timer: 1500,
      });
      handleClose();
    }

    if (typeof onObjectiveUpdated === "function") {
      onObjectiveUpdated();
    }
  };

  const handleEvaluate = () => {
    setIsEvaluating(true);
  };

  const evaluationMarks = Array.from({ length: 11 }, (_, i) => ({
    value: i * 0.1,
    label: (i * 0.1).toFixed(1),
  }));

  return (
    <Modal
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      onClick={(event) => event.stopPropagation()}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 800,
          height: "auto",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          overflowY: "auto",
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          {t("UpdateKeyResult")}
        </Typography>

        <TextField
          fullWidth
          label={t("KeyResultName")}
          name="keyResultName"
          value={formData.keyResultName}
          onChange={handleChange}
          margin="normal"
          disabled
        />

        <Typography gutterBottom>{t("ProgressPercentage")}</Typography>
        <Slider
          value={formData.progressPercent}
          onChange={handleSliderChange}
          valueLabelDisplay="auto"
          min={0}
          max={100}
          step={1}
          sx={{
            color: "#DB4178",
          }}
        />

        <TextField
          fullWidth
          label={t("WriteMessage")}
          name="message"
          value={formData.message}
          onChange={handleChange}
          margin="normal"
          multiline
          rows={4}
        />

        <Box {...getRootProps({ className: "dropzone-upload-file" })}>
          <input {...getInputProps()} />
          <Button
            startIcon={<AttachFileIcon />}
            fullWidth
            variant="outlined"
            sx={{
              mt: 2,
              borderColor: "#DB4178",
              color: "#DB4178",
              "&:hover": {
                backgroundColor: "#fce4ec",
                borderColor: "#C53B6C",
                color: "#C53B6C",
              },
            }}
          >
            {t("Attach File")}
          </Button>
        </Box>

        {fileSelected && (
          <Box display="flex" alignItems="center" marginTop={2}>
            <AttachFileIcon style={{ marginRight: "8px", fontSize: 40 }} />
            <Typography variant="body2" style={{ flexGrow: 1 }}>
              {fileSelected.name}
            </Typography>
            <IconButton onClick={handleRemoveFile}>
              <ClearIcon />
            </IconButton>
          </Box>
        )}

        {/* {isEvaluating && (
          <Box mt={4}>
            <Typography variant="h6">{t("Evaluation")}</Typography>

            <ToggleButtonGroup
              value={isSuccess}
              exclusive
              onChange={(e, newValue) => {
                if (newValue !== null) {
                  setIsSuccess(newValue);
                }
              }}
              aria-label="evaluation result"
              sx={{ mt: 2 }}
            >
              <ToggleButton value={1} aria-label="success" color="success">
                {t("Success")}
              </ToggleButton>
              <ToggleButton value={0} aria-label="failure" color="error">
                {t("Failure")}
              </ToggleButton>
            </ToggleButtonGroup>

            <Typography gutterBottom sx={{ mt: 3 }}>
              {t("PerformanceRating")}
            </Typography>
            <Slider
              value={rating}
              onChange={(e, newValue) => setRating(newValue)}
              valueLabelDisplay="auto"
              min={0}
              max={1.0}
              step={0.1}
              marks={evaluationMarks}
              sx={{
                color: "#DB4178", // เปลี่ยนสีของ Slider ให้เป็นสีชมพูตามธีมเว็บไซต์
              }}
            />
          </Box>
        )} */}

        <Box mt={4} display="flex" justifyContent="space-between">
          {/* ปุ่มดูประวัติการอัปเดต */}
          <Button variant="text" onClick={handleOpenHistory}>
            {t("SeeUpdatingHistory")}
          </Button>

          <Box display="flex">
            {isEvaluationEnabled && !isEvaluating && (
              <Button
                variant="contained"
                onClick={handleEvaluate}
                sx={{
                  backgroundColor: "#FFA500",
                  mr: 2,
                  "&:hover": {
                    backgroundColor: "#FF8C00",
                  },
                }}
              >
                {t("Evaluate")}
              </Button>
            )}

            <Button
              variant="contained"
              onClick={handleSave}
              sx={{
                backgroundColor: "#DB4178",
                "&:hover": {
                  backgroundColor: "#C53B6C",
                },
              }}
            >
              {t("Save")}
            </Button>
          </Box>
        </Box>

        <KRUpdateHistoryModal
          open={historyOpen}
          onClose={handleCloseHistory}
          updates={updateHistory}
        />
      </Box>
    </Modal>
  );
};

export default EditKRModal;
