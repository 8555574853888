import ExcelJS from 'exceljs';

export const TurnoverXlsx = (t, data, summaryData) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(t("TurnoverRateReporByCompany"));

    const headerValues = [
        t("Company"),
        t("Month"),
        t("EmpTotal"),
        t("NumOfResigningEmp"),
        `%${t("TurnoverRate")}`
    ];

    const headerRow = worksheet.addRow(headerValues);
    headerRow.height = 50;

    const headerStyle1 = {
        font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
        alignment: { horizontal: "center", vertical: 'middle' },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '002060' }
        },
    }

    headerRow.eachCell((cell) => {
        cell.style = headerStyle1;
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
        };
    });

    const colWidths = [
        { key: "company", width: 40 },
        { key: "Month", width: 30 },
        { key: "EmpTotal", width: 50 },
        { key: "NumOfResigningEmp", width: 50 },
        { key: "TurnoverRate", width: 30 },
    ];

    colWidths.forEach((col, index) => {
        worksheet.getColumn(index + 1).width = col.width;
    });

    data && data.forEach((item) => {
        const row = [
            item.companyName ? item.companyName : "-",
            item.monthName ? item.monthName : "-",
            item.NumOfCurrentEmp ? item.NumOfCurrentEmp : 0,
            item.NumOfResigningEmp ? item.NumOfResigningEmp : 0,
            item.NumOfCurrentEmp > 0 ? `${((item.NumOfResigningEmp / item.NumOfCurrentEmp) * 100).toFixed(2)}%` : "0.00%"
        ];

        const excelRow = worksheet.addRow(row);
        const contentStyle = {
            font: { size: 18, name: 'TH SarabunPSK' },
            alignment: { horizontal: "center", vertical: 'middle' },
        };

        excelRow.eachCell((cell) => {
            cell.style = contentStyle;
            cell.border = {
                top: { style: 'thin', color: { argb: '000000' } },
                left: { style: 'thin', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'thin', color: { argb: '000000' } }
            };
        });
    });

    const sumData = [t("Average"), "", summaryData.numberCurEmp, summaryData.numberResignedEmp, summaryData.percentTurnover];
    const sumRow = worksheet.addRow(sumData);

    const sumRowStyle = {
        font: { size: 18, name: 'TH SarabunPSK', bold: true },
        alignment: { horizontal: "center", vertical: 'middle' },
    };

    sumRow.eachCell((cell) => {
        cell.style = sumRowStyle;
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
        };
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${t("TurnoverRateReporByCompany")}.xlsx`;
        a.click();
        URL.revokeObjectURL(url);
    });
};
