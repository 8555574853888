import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTitle } from "react-use";
import { useHistory, useLocation } from "react-router-dom";
import {
    Box,
    Button,
    Container,
    Divider,
    Grid,
    Paper,
    TextField,
    Typography,
    Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";
import TodayIcon from "@mui/icons-material/Today";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";

import SelectedGroupLocations from "./selectedGroupLocation";
import Register from "./register";
import MapContainer from "./MapContainer";

import Backdrop from "../shared/general/Backdrop";

import {
    getLineUserProfile,
    postAttendance,
    registerLine,
} from "../../../actions/line";
import { getCheckTimelineById } from "../../../actions/timeline";
import MapContainerpoint from "./MapContainerpoint";
import { t } from "i18next";
import { getUserFullName } from "../../../utils/userData";

// import GpsMock from "./gps-mock";
// import MapMock from "./gps-mock/MapContainer";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: 100,
        backgroundColor: "#FFFFFF",
        "& .MuiTypography-h6": {
            fontSize: "1.5rem",
        },
        "& .MuiSvgIcon-root": {
            marginRight: "5px",
        },
        "& .MuiTypography-body1": {
            fontSize: "1.1rem",
        },
    },
    headingPage: {
        marginBottom: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        borderRadius: "20px",
        boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    },
    paperCheckIn: {
        padding: theme.spacing(3),
        borderRadius: "20px",
        boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    },
    wrapIcon: {
        display: "flex",
        alignItems: "center",
    },
    dateTime: {
        color: "#16243D",
        borderRadius: "10px",
        marginBottom: "20px",
    },
    checkInBtn: {
        color: "#fff",
        width: "100%",
        height: "50px",
        backgroundColor: "#66bb6a",
        border: "1px solid #66bb6a",
        "&:hover": {
            borderColor: "#4caf50",
            backgroundColor: "#4caf50",
        },
    },
    checkOutBtn: {
        color: "#fff",
        width: "100%",
        height: "50px",
        backgroundColor: "#ef5350",
        border: "1px solid #ef5350",
        "&:hover": {
            borderColor: "#e53935",
            backgroundColor: "#e53935",
        },
    },
    headPanel: {
        fontWeight: 600,
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    locationMessage: {
        display: "flex",
        justifyContent: "center",
        marginBottom: theme.spacing(3),
    },
    messagePassWrapper: {
        display: "flex",
        alignItems: "center",
        color: "#388e3c",
    },
    messageFailWrapper: {
        display: "flex",
        alignItems: "center",
        color: "#d32f2f",
    },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const liff = window.liff;

function GPSCheckInOut() {
    const classes = useStyles();
    const dispatch = useDispatch();
    let query = useQuery();
    useTitle("Check In/Out - UniHR");
    const { user: currentUser } = useSelector((state) => state.auth);
    const { result: userProfile } = useSelector((state) => state.userProfile);
    const { result: timeline } = useSelector((state) => state.timeline);
    const [time, setTime] = useState(new Date());
    const [mode, setMode] = useState("");
    const [match, setMatch] = useState(null);
    const [clickCheck, setClickCheck] = useState(false);
    const [clickCheckMock, setClickCheckMock] = useState(false);
    const [locations, setLocations] = useState(null);
    const [gpsAddress, setGpsAddress] = useState(null);

    // const [state, setState] = useState({
    //   name: "PANG [บื้อเจ้าเก่า]",
    //   userLineID: "U06fad2faaf363cd20fd2c22eb6bb50f1",
    //   pictureUrl:
    //     "https://profile.line-scdn.net/0hr3KBZfxMLRxmOwVFlepTYxZrLnZFSnQOQgkwKlY8c3wIWDhMSwhieFFpeykOAjhCQ11nLVQ9ci5qKFp6eG3RKGELcytfDWtLSllq-A",
    // });
    const [state, setState] = useState(null);
    const [lineUserProfile, setLineUserProfile] = useState(null);
    const [dataRegister, setDataRegister] = useState(null);
    const [fetchingData, setFetchingData] = useState(true);
    const [loadMap, setLoadMap] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState("");
    const [showMapMock, setShowMapMock] = useState(false);

    // useEffect(() => {
    //   liff
    //     .init({ liffId: "1657850475-X98WB2aj" })
    //     .then(async () => {
    //       if (liff.isLoggedIn()) {
    //         handleGetProfile();
    //       } else {
    //         liff.login();
    //       }
    //     })
    //     .catch((err) => {
    //       alert(err);
    //     });
    // }, []);

    useEffect(() => {
        if (query.get("source") === "pwa") {
            if (currentUser) {
            } else {
                window.location.assign("/login?source=pwa");
            }
        } else {
            liff
                .init({ liffId: "1657850475-X98WB2aj" })
                .then(async () => {
                    if (liff.isLoggedIn()) {
                        handleGetProfile();
                    } else {
                        liff.login();
                    }
                })
                .catch((err) => {
                    alert(err);
                });
        }
    }, []);

    useEffect(() => {
        if (userProfile) {
            fetchDataPWA();
        }
    }, [userProfile]);

    useEffect(() => {
        if (query.get("source") === "pwa") {
        } else {
            if(state){
                fetchData();
            }
        }
    }, [state]);

    useEffect(() => {
        const interval = setInterval(() => setTime(new Date()), 1000);
        return () => {
            clearInterval(interval);
        };
    }, [time]);

    const handleGetProfile = async () => {
        let getProfile = await liff.getProfile();
        if (getProfile) {
            setState({
                name: getProfile.displayName,
                userLineID: getProfile.userId,
                pictureUrl: getProfile.pictureUrl,
                statusMessage: getProfile.statusMessage,
            });
        }
    };

    async function fetchData() {
        const result = await dispatch(getLineUserProfile(state.userLineID));
        if (result) {
            // console.log(result)
            setFetchingData(false);
            if (result.status === 404) {
                setDataRegister("");
            } else {
                setLineUserProfile(result);
                dispatch(getCheckTimelineById(result.idEmployees, "time"))
            }
        } else {
            setFetchingData(false);
            setDataRegister("");
        }
    }

    async function fetchDataPWA() {
        const result = await dispatch(getLineUserProfile(userProfile.idEmployees, "pwa"));
        dispatch(getCheckTimelineById(userProfile.idEmployees, "time"))
        if (result) {
            setFetchingData(false);
            if (result.status === 404) {
                setDataRegister("");
            } else {
                setLineUserProfile(result);
                setDataRegister(null);
            }
        } else {
            setFetchingData(false);
            setDataRegister("");
        }
    }

    const handleClickCheck = async (mode) => {
        setMode(mode);
    };

    const handleChangeClickCheck = () => {
        setClickCheck(!clickCheck);
    };

    const addAttendance = async (location) => {
        const dataForm = {
            attendanceDateTime: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            isCheckIn: mode === "in",
            idAttendanceType: timeline.type === "point" ? 4 : 1,
            idGpsLocations: location ? location.idGpsLocations : "",
            idEmployees: lineUserProfile.idEmployees,
            idCompany: lineUserProfile.idCompany,
            latitude: timeline.type === "point" ? locations[0] : "",
            longitude: timeline.type === "point" ? locations[1] : "",
            gpsAddress: gpsAddress
        };

        const result = await dispatch(postAttendance(dataForm));
        if (result) {
            if (result.data.rows === 1) {
                setOpenAlert(true);
                handleChangeAlertType("success");
                handleCancel();
            } else {
                alert("เกิดข้อผิดพลาดโปรดติดต่อเจ้าหน้าที่");
            }
        } else {
            setOpenAlert(true);
            handleChangeAlertType("error");
        }
    };

    const handleCancel = () => {
        setMode("");
        setLocations(null);
        setFetchingData(false);
        setLoadMap(false);
    };

    const handleChangePersonalId = (event) => {
        setDataRegister(event.target.value);
    };

    const handleRegisterLine = async () => {
        const dataForm = {
            lineUserId: state.userLineID,
            personalId: dataRegister,
        };

        const result = await dispatch(registerLine(dataForm));
        if (result) {
            console.log(result);
            if (result.data.rows === 1) {
                setOpenAlert(true);
                handleChangeAlertType("success");
                setDataRegister(null);
                setFetchingData(true);
                fetchData();
            } else {
                alert("ไม่พบเลขบัตรประชาชน โปรดลองอีกครั้ง");
            }
        } else {
            setOpenAlert(true);
            handleChangeAlertType("error");
        }
    };

    const handleChangeAlertType = (status) => {
        setAlertType(status);
    };

    const handleCloseAlert = () => {
        setOpenAlert("");
    };

    const handleClickShowMapMock = () => {
        setShowMapMock(true);
    };

    const handleClickCloseShowMapMock = () => {
        setShowMapMock(false);
    };

    return (
        <div className={`page ${classes.root}`}>
            {fetchingData ? (
                <Backdrop open={fetchingData} />
            ) : (
                <Container maxWidth="lg">
                    <Grid
                        container
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={12} md={4} lg={4}>
                            <Paper className={classes.paperCheckIn}>
                                <Box className={classes.dateTime}>
                                    <Box sx={{ marginBottom: "20px" }}>
                                        <Typography align="center" className={classes.headPanel}>
                                            {t("recordInOutTime")}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ marginBottom: "10px" }}>
                                        <Grid container>
                                            <Grid item xs={4} md={4} lg={4}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes.wrapIcon}
                                                    color="text.secondary"
                                                >
                                                    <TodayIcon />
                                                    {t("Date")}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant="body1">
                                                    {dayjs(new Date()).format("DD MMMM YYYY")}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box>
                                        <Grid container>
                                            <Grid item xs={4} md={4} lg={4}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes.wrapIcon}
                                                    color="text.secondary"
                                                >
                                                    <AccessTimeIcon />
                                                    {t("Time")}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant="body1">
                                                    {dayjs(time).format("HH:mm:ss")}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                                <Divider className={classes.divider} />
                                {lineUserProfile && (
                                    <div style={{ marginBottom: 16 }}>
                                        <Typography variant="h6" color="text.third">
                                            {t("Hello")}
                                        </Typography>
                                        <Typography
                                            variant="h5"
                                            align="center"
                                        >{`${getUserFullName(lineUserProfile)}`}</Typography>
                                    </div>
                                )}
                                {dataRegister !== null ? (
                                    <Register
                                        handleChange={handleChangePersonalId}
                                        handleClick={handleRegisterLine}
                                    />
                                ) : (
                                    <Fragment>
                                        {mode !== "" ?
                                            (<Fragment>
                                                {lineUserProfile && (
                                                    <SelectedGroupLocations
                                                        mode={mode}
                                                        lineUserProfile={lineUserProfile}
                                                        handleCancel={handleCancel}
                                                        handleChangeClickCheck={handleChangeClickCheck}
                                                        setLocations={setLocations}
                                                        locations={locations}
                                                        loadMap={loadMap}
                                                    />
                                                )}
                                            </Fragment>
                                            ) : (
                                                <Box>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} md={12} lg={12}>
                                                            <Button
                                                                variant="outlined"
                                                                className={classes.checkInBtn}
                                                                startIcon={<LoginIcon />}
                                                                onClick={() => handleClickCheck("in")}
                                                            >
                                                                {t("ClockIn")}
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={12} md={12} lg={12}>
                                                            <Button
                                                                variant="outlined"
                                                                className={classes.checkOutBtn}
                                                                startIcon={<LogoutIcon />}
                                                                onClick={() => handleClickCheck("out")}
                                                            >
                                                                {t("ClockOut")}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            )}
                                    </Fragment>
                                )}
                            </Paper>
                        </Grid>
                        {console.log("locations", locations)}
                        {mode !== "" ? (timeline.type === "point" ?
                            (<Grid item xs={12} md={8} lg={8}>
                                <Paper className={classes.paper}>
                                    <MapContainerpoint
                                        setMatch={setMatch}
                                        addAttendance={addAttendance}
                                        setLoadMap={setLoadMap}
                                        setLocations={setLocations}
                                        setGpsAddress={setGpsAddress}
                                        locations={locations}
                                        clickCheck={clickCheck}
                                    />
                                </Paper>
                            </Grid>)
                            : (
                                <Grid item xs={12} md={8} lg={8}>
                                    <Paper className={classes.paper}>
                                        <MapContainer
                                            setMatch={setMatch}
                                            addAttendance={addAttendance}
                                            setLoadMap={setLoadMap}
                                            locations={locations}
                                            clickCheck={clickCheck}
                                        />
                                    </Paper>
                                </Grid>
                            )):(<></>)}
                        {/* {showMapMock && !loadMap && (
              <Grid item xs={12} md={8} lg={8}>
                <Typography align="center" gutterBottom>กดปุ่ม CHECK IN เพื่อตรวจสอบตำแหน่ง</Typography>
                <Button
                  variant="outlined"
                  className={classes.checkInBtn}
                  startIcon={<LoginIcon />}
                  onClick={() => setClickCheckMock(true)}
                  disabled={loadMap}
                >
                  CHECK IN
                </Button>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    variant="text"
                    onClick={() => handleClickCloseShowMapMock()}
                  >
                    กลับ
                  </Button>
                </div>
              </Grid>
            )}
            {showMapMock ? (
              <Fragment>
                <Grid item xs={12} md={8} lg={8}>
                  <Paper className={classes.paper}>
                    <MapMock
                      setLoadMap={setLoadMap}
                      clickCheck={clickCheckMock}
                      setClickCheckMock={setClickCheckMock}
                    />
                  </Paper>
                </Grid>
              </Fragment>
            ) : (
              <GpsMock handleClickShowMapMock={handleClickShowMapMock} />
            )} */}
                    </Grid>
                </Container>
            )}
            <Snackbar
                open={openAlert}
                autoHideDuration={6000}
                onClose={handleCloseAlert}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                {alertType === "success" ? (
                    <Alert
                        onClose={handleCloseAlert}
                        severity="success"
                        style={{ width: "100%" }}
                    >
                        {t("DataSaveSuccessful")}
                    </Alert>
                ) : (
                    <Alert
                        onClose={handleCloseAlert}
                        severity="error"
                        style={{ width: "100%" }}
                    >
                        {t("ErrorOccurredContactAdmin")}
                    </Alert>
                )}
            </Snackbar>
        </div>
    );
}

export default GPSCheckInOut;
