import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Typography,
  createFilterOptions,
  styled,
  Card,
  Button,
} from "@mui/material";
import CardStyle from "../../../shared/general/Card";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DeleteIcon from "@mui/icons-material/Delete";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FlagIcon from "@mui/icons-material/Flag";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import {
  addIndividualObjective,
  getMetadataObjective,
} from "../../../../../actions/okrs";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import QuarterSelect from "../../../shared/pages/okrs/components/QuarterSelect";
import DialogStatusForm from "../../../shared/general/DialogStatusForm";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { getManager } from "../../../../../actions/manager";
import { useTranslation } from "react-i18next";

dayjs.extend(quarterOfYear);

const StyledRoot = styled(Box)({
  "& .keyresult-text": {
    fontSize: "20px",
    fontWeight: 500,
  },
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
});

const StyledCard = styled(Card)({
  minHeight: "70px",
  boxShadow: "none",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  background:
    "linear-gradient(to right, rgba(104, 205, 232, 0.7), rgba(109, 238, 227, 0.7))",

  "& .cardTitle": {
    fontSize: 22,
  },
  "& .MuiCardContent-root": {
    padding: 24,
  },
  "& .cardHeader": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  "& .yearSelectionContainer": {
    marginLeft: "41px",
    marginBottom: "100px",
  },
  "@media (max-width: 600px)": {
    "& .yearSelectionContainer": {
      marginBottom: "1px",
    },
  },
});

const StyleHeadBox = styled(Box)({
  "& .box-header": {
    minHeight: "200px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor:
      "linear-gradient(to right, rgba(104, 205, 232, 0.7), rgba(109, 238, 227, 0.7))",
    "& .text-header": {
      fontSize: "48px",
      fontWeight: "bold",
      marginLeft: "40px",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      marginTop: "80px",
    },
    "& .img-header": {
      width: "33%",
      height: "auto",
      marginBottom: "10px",
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
  },

  "@media (max-width: 600px)": {
    "& .box-header": {
      flexDirection: "column",
      minHeight: "unset",
      justifyContent: "center",
      alignItems: "center",
      "& .text-header": {
        fontSize: "36px",
        marginTop: "20px",
        aliignText: "center",
      },
      "& .img-header": {
        width: "50%",
        justifyContent: "flex-start",
        alignItems: "flex-start",
      },
    },
  },
});

const CustomButton = styled(Button)({
  color: "#DB4178",
  border: "2px solid #DB4178",
  backgroundColor: "transparent",
  borderRadius: "7px",
  height: "50px",
  "&:hover": {
    color: "#DB4178",
    border: "2px solid #C53B6C",
    backgroundColor: "transparent",
    borderRadius: "7px",
    height: "50px",
  },
});

const ResponsiveButtonBlue = styled(Button)(({ theme }) => ({
  backgroundColor: "rgba(219, 65, 120, 1)",
  width: "10%",
  height: "50px",
  fontSize: "15px",
  fontWeight: "bold",
  borderRadius: "7px",
  "&:hover": {
    backgroundColor: "#C53B6C",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    fontSize: "14px",
    height: "40px",
  },
}));

const StyleTypography = styled(Typography)({
  borderLeft: "4px solid transparent",
  borderImage:
    "linear-gradient(0deg, rgba(136,213,254,1) 0%, rgba(254,184,207,1) 99%, rgba(254,184,207,1) 100%) 5",
  padding: 5,
});

const IndividualPlanningAddFormPage = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { result: managers } = useSelector((state) => state.manager);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [dialogStatusConfig, setDialogStatusConfig] = useState({
    open: false,
    statusCode: "",
    statusText: "",
    isSuccess: false,
  });

  const onCloseDialog = () => {
    if (dialogStatusConfig.isSuccess) {
      setDialogStatusConfig((prevState) => ({
        ...prevState,
        open: false,
      }));
      // if (userProfile.idRole === 1) {
      //   history.replace(`/okrs/management/individual`);
      // } else {
      //   history.replace(`/okrs/management/manager`);
      // }
      window.location.reload();
    } else {
      setDialogStatusConfig((prevState) => ({
        ...prevState,
        open: false,
      }));
    }
  };

  const validateYupSchema = yup.object({
    year: yup.string().required(`${t("ThisFieldIsRequired")}`),
    quarter: yup.string().required(`${t("ThisFieldIsRequired")}`),
    objectiveName: yup.string().required(`${t("ThisFieldIsRequired")}`),
    impactName: yup.string().required(`${t("ThisFieldIsRequired")}`),
    keyResults: yup
      .array()
      .of(
        yup.object({
          keyResultName: yup.string().required(`${t("ThisFieldIsRequired")}`),
          description: yup.string().required(`${t("ThisFieldIsRequired")}`),
          tacticName: yup.string().required(`${t("ThisFieldIsRequired")}`),
          startDate: yup.string().required(`${t("ThisFieldIsRequired")}`),
          endDate: yup.string().required(`${t("ThisFieldIsRequired")}`),
          idManager: yup.string().required(`${t("ThisFieldIsRequired")}`),
          measureEvidence: yup.string().required(`${t("ThisFieldIsRequired")}`),
          commitQuality: yup.string().required(`${t("ThisFieldIsRequired")}`),
          measureDataType: yup.string().required(`${t("ThisFieldIsRequired")}`),
        })
      )
      .min(1, `${t("PleaseProvideOneKeyResult")}`),
  });

  const useHookForm = useForm({
    defaultValues: {
      objectiveName: "",
      impactName: "",
      year: dayjs().get("year"),
      quarter: dayjs().quarter(),
      keyResults: [],
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  });

  const selectedQuarter = useHookForm.watch("quarter") || dayjs().quarter();
  const selectedYear = useHookForm.watch("year") || dayjs().year();
  const quarterStartDate = dayjs()
    .year(selectedYear)
    .quarter(selectedQuarter)
    .startOf("quarter");
  const quarterEndDate = dayjs()
    .year(selectedYear)
    .quarter(selectedQuarter)
    .endOf("quarter");

  const useKeyResultFieldArray = useFieldArray({
    control: useHookForm.control,
    name: "keyResults",
  });

  const keyResultDefaultData = {
    keyResultName: "",
    description: "",
    tacticName: "",
    startDate: "",
    endDate: "",
    idManager: "",
    measureEvidence: "",
    commitQuality: "",
    measureDataType: "",
  };

  const onSubmit = async (data) => {
    data.keyResults.map((k) => {
      if (k.startDate) {
        k.startDate = dayjs(k.startDate).format("YYYY-MM-DD");
      }
      if (k.endDate) {
        k.endDate = dayjs(k.endDate).format("YYYY-MM-DD");
      }
    });
    data.idCompany = userProfile.idCompany;

    const response = await dispatch(addIndividualObjective(data));

    if (response) {
      if (response.status === 200) {
        setDialogStatusConfig((prevState) => ({
          ...prevState,
          open: true,
          statusCode: "success",
          statusText: `${t("Successfully")}`,
          isSuccess: true,
        }));
      } else {
        setDialogStatusConfig((prevState) => ({
          ...prevState,
          open: true,
          statusCode: "error",
          statusText: response.data.message,
          isSuccess: false,
        }));
      }
    } else {
      console.log("Error!");
    }
  };

  const onError = (error) => {
    // console.log(error)
    if (
      error.keyResults &&
      typeof error.keyResults === "object" &&
      !Array.isArray(error.keyResults)
    ) {
      setDialogStatusConfig((prevState) => ({
        ...prevState,
        open: true,
        statusCode: "error",
        statusText: error.keyResults.message,
        isSuccess: false,
      }));
    }
  };

  useEffect(() => {
    let isMounted = true;
    // dispatch(getMetadataObjective()).then(res => {
    //   if(isMounted){
    //     if(res.status === 200){
    //       console.log(res.data)
    //       setEmployeeList(res.data)
    //     }
    //   }
    // })
    dispatch(getManager());

    return () => {
      isMounted = false;
    };
  }, []);

  const filterOptions = createFilterOptions({
    stringify: (value) =>
      `${value.firstname_TH} ${value.lastname_TH} ${value.email}`,
  });

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        {managers && (
          <div>
            <StyledCard
              style={{
                marginBottom: "48px",
                marginTop: "20px",
              }}
            >
              <StyleHeadBox>
                <Box className="box-header">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography className="text-header">
                        {`${t("SetPersonalGoals")}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} className="yearSelectionContainer">
                      <Typography
                        fontSize="20px"
                        fontWeight="600"
                        marginBottom="4px"
                        color="text.third"
                      >
                        {`${t("Year")} ${dayjs().format(
                          i18n.resolvedLanguage === "th" ? "BBBB" : "YYYY"
                        )}`}
                      </Typography>
                    </Grid>
                  </Grid>
                  <img
                    className="img-header"
                    src={`${process.env.PUBLIC_URL}/assets/okrs/user/personal-goal.png`}
                    alt="icon"
                  />
                </Box>
              </StyleHeadBox>
            </StyledCard>

            <CardStyle>
              <form onSubmit={useHookForm.handleSubmit(onSubmit, onError)}>
                <Box padding="24px">
                  <Container maxWidth="md">
                    <Grid container spacing={2} marginTop={5}>
                      <Grid item xs={12}>
                        <Controller
                          name="quarter"
                          control={useHookForm.control}
                          render={({ field }) => (
                            <Box>
                              <Grid container spacing={2}>
                                <Grid item xs={6} md={3}>
                                  <QuarterSelect
                                    value={1}
                                    selected={field.value === 1}
                                  />
                                </Grid>
                                <Grid item xs={6} md={3}>
                                  <QuarterSelect
                                    value={2}
                                    selected={field.value === 2}
                                  />
                                </Grid>
                                <Grid item xs={6} md={3}>
                                  <QuarterSelect
                                    value={3}
                                    selected={field.value === 3}
                                  />
                                </Grid>
                                <Grid item xs={6} md={3}>
                                  <QuarterSelect
                                    value={4}
                                    selected={field.value === 4}
                                  />
                                </Grid>
                              </Grid>
                              {useHookForm.formState.errors[field.name] ? (
                                <Typography
                                  margin="13px 14px 0"
                                  fontSize="12px"
                                  color="error"
                                >
                                  {
                                    useHookForm.formState.errors[field.name]
                                      .message
                                  }
                                </Typography>
                              ) : null}
                            </Box>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name="objectiveName"
                          control={useHookForm.control}
                          render={({ field }) => (
                            <TextFieldTheme
                              {...field}
                              inputProps={{
                                placeholder: `${t("Objectives")}`,
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <FlagIcon />
                                  </InputAdornment>
                                ),
                              }}
                              helperText={
                                useHookForm.formState.errors[field.name]
                                  ? useHookForm.formState.errors[field.name]
                                      .message
                                  : null
                              }
                              error={
                                useHookForm.formState.errors[field.name]
                                  ? true
                                  : false
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name="impactName"
                          control={useHookForm.control}
                          render={({ field }) => (
                            <TextFieldTheme
                              {...field}
                              inputProps={{
                                placeholder: `${t("TheResultingOutcome")}`,
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <AutoAwesomeIcon />
                                  </InputAdornment>
                                ),
                              }}
                              helperText={
                                useHookForm.formState.errors[field.name]
                                  ? useHookForm.formState.errors[field.name]
                                      .message
                                  : null
                              }
                              error={
                                useHookForm.formState.errors[field.name]
                                  ? true
                                  : false
                              }
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Container>
                </Box>

                <Divider />

                <Box padding="24px">
                  <Grid container spacing={2}>
                    <Grid item xs={12} container justifyContent="flex-end">
                      <CustomButton
                        variant="outlined"
                        startIcon={<AddCircleOutlineRoundedIcon />}
                        onClick={() => {
                          useKeyResultFieldArray.append(keyResultDefaultData);
                        }}
                      >
                        {`${t("AddKeyResults")}`}
                      </CustomButton>
                    </Grid>

                    {useKeyResultFieldArray.fields.map((item, index) => (
                      <Grid key={item.id} item xs={12} container spacing={2}>
                        <Grid item xs={6}>
                          <StyleTypography className="keyresult-text">
                            {`${t("TheMainResult")}`} {index + 1}
                          </StyleTypography>
                        </Grid>
                        <Grid item xs={6} container justifyContent="flex-end">
                          <IconButton
                            onClick={() => {
                              useKeyResultFieldArray.remove(index);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>

                        <Grid item xs={12}>
                          <Container maxWidth="md">
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={4}>
                                <Typography>{`${t(
                                  "TheMainResult"
                                )}`}</Typography>
                              </Grid>
                              <Grid item xs={12} sm={8}>
                                <Controller
                                  name={`keyResults.${index}.keyResultName`}
                                  control={useHookForm.control}
                                  render={({ field }) => (
                                    <TextFieldTheme
                                      {...field}
                                      inputProps={{
                                        placeholder: `${t("Name")}`,
                                      }}
                                      helperText={
                                        useHookForm.formState.errors[
                                          field.name.split(".")[0]
                                        ] &&
                                        useHookForm.formState.errors[
                                          field.name.split(".")[0]
                                        ][field.name.split(".")[1]] &&
                                        useHookForm.formState.errors[
                                          field.name.split(".")[0]
                                        ][field.name.split(".")[1]][
                                          field.name.split(".")[2]
                                        ]
                                          ? useHookForm.formState.errors[
                                              field.name.split(".")[0]
                                            ][field.name.split(".")[1]][
                                              field.name.split(".")[2]
                                            ].message
                                          : null
                                      }
                                      error={
                                        useHookForm.formState.errors[
                                          field.name.split(".")[0]
                                        ] &&
                                        useHookForm.formState.errors[
                                          field.name.split(".")[0]
                                        ][field.name.split(".")[1]] &&
                                        useHookForm.formState.errors[
                                          field.name.split(".")[0]
                                        ][field.name.split(".")[1]][
                                          field.name.split(".")[2]
                                        ]
                                          ? true
                                          : false
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <Typography>{`${t(
                                  "Descriptions"
                                )}`}</Typography>
                              </Grid>
                              <Grid item xs={12} sm={8}>
                                <Controller
                                  name={`keyResults.${index}.description`}
                                  control={useHookForm.control}
                                  render={({ field }) => (
                                    <TextFieldTheme
                                      {...field}
                                      inputProps={{
                                        placeholder: `${t("Descriptions")}`,
                                      }}
                                      rows={3}
                                      multiline
                                      helperText={
                                        useHookForm.formState.errors[
                                          field.name.split(".")[0]
                                        ] &&
                                        useHookForm.formState.errors[
                                          field.name.split(".")[0]
                                        ][field.name.split(".")[1]] &&
                                        useHookForm.formState.errors[
                                          field.name.split(".")[0]
                                        ][field.name.split(".")[1]][
                                          field.name.split(".")[2]
                                        ]
                                          ? useHookForm.formState.errors[
                                              field.name.split(".")[0]
                                            ][field.name.split(".")[1]][
                                              field.name.split(".")[2]
                                            ].message
                                          : null
                                      }
                                      error={
                                        useHookForm.formState.errors[
                                          field.name.split(".")[0]
                                        ] &&
                                        useHookForm.formState.errors[
                                          field.name.split(".")[0]
                                        ][field.name.split(".")[1]] &&
                                        useHookForm.formState.errors[
                                          field.name.split(".")[0]
                                        ][field.name.split(".")[1]][
                                          field.name.split(".")[2]
                                        ]
                                          ? true
                                          : false
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <Typography>{`${t("Strategy")}`}</Typography>
                              </Grid>
                              <Grid item xs={12} sm={8}>
                                <Controller
                                  name={`keyResults.${index}.tacticName`}
                                  control={useHookForm.control}
                                  render={({ field }) => (
                                    <TextFieldTheme
                                      {...field}
                                      inputProps={{
                                        placeholder: `${t("Strategy")}`,
                                      }}
                                      helperText={
                                        useHookForm.formState.errors[
                                          field.name.split(".")[0]
                                        ] &&
                                        useHookForm.formState.errors[
                                          field.name.split(".")[0]
                                        ][field.name.split(".")[1]] &&
                                        useHookForm.formState.errors[
                                          field.name.split(".")[0]
                                        ][field.name.split(".")[1]][
                                          field.name.split(".")[2]
                                        ]
                                          ? useHookForm.formState.errors[
                                              field.name.split(".")[0]
                                            ][field.name.split(".")[1]][
                                              field.name.split(".")[2]
                                            ].message
                                          : null
                                      }
                                      error={
                                        useHookForm.formState.errors[
                                          field.name.split(".")[0]
                                        ] &&
                                        useHookForm.formState.errors[
                                          field.name.split(".")[0]
                                        ][field.name.split(".")[1]] &&
                                        useHookForm.formState.errors[
                                          field.name.split(".")[0]
                                        ][field.name.split(".")[1]][
                                          field.name.split(".")[2]
                                        ]
                                          ? true
                                          : false
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <Typography>
                                  {`${t("StartDate")}`} / {`${t("EndDate")}`}
                                </Typography>
                              </Grid>
                              <Grid item xs={6} sm={4}>
                                <Controller
                                  name={`keyResults.${index}.startDate`}
                                  control={useHookForm.control}
                                  render={({ field }) => (
                                    <LocalizationProvider
                                      dateFormats={{
                                        year: "BBBB",
                                        monthAndYear: "MMMM BBBB",
                                      }}
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <DatePicker
                                        {...field}
                                        views={["year", "month", "day"]}
                                        inputFormat="DD MMMM BBBB"
                                        disableMaskedInput
                                        openTo="year"
                                        value={field.value}
                                        onChange={(newValue) => {
                                          field.onChange(newValue);
                                        }}
                                        minDate={quarterStartDate}
                                        maxDate={quarterEndDate}
                                        renderInput={(params) => (
                                          <TextFieldTheme
                                            {...params}
                                            inputProps={{
                                              ...params.inputProps,
                                              placeholder: `${t(
                                                "DayMonthYear(AD)"
                                              )}`,
                                              readOnly: true,
                                            }}
                                            onBlur={field.onBlur}
                                            helperText={
                                              useHookForm.formState.errors[
                                                field.name.split(".")[0]
                                              ] &&
                                              useHookForm.formState.errors[
                                                field.name.split(".")[0]
                                              ][field.name.split(".")[1]] &&
                                              useHookForm.formState.errors[
                                                field.name.split(".")[0]
                                              ][field.name.split(".")[1]][
                                                field.name.split(".")[2]
                                              ]
                                                ? useHookForm.formState.errors[
                                                    field.name.split(".")[0]
                                                  ][field.name.split(".")[1]][
                                                    field.name.split(".")[2]
                                                  ].message
                                                : null
                                            }
                                            error={
                                              useHookForm.formState.errors[
                                                field.name.split(".")[0]
                                              ] &&
                                              useHookForm.formState.errors[
                                                field.name.split(".")[0]
                                              ][field.name.split(".")[1]] &&
                                              useHookForm.formState.errors[
                                                field.name.split(".")[0]
                                              ][field.name.split(".")[1]][
                                                field.name.split(".")[2]
                                              ]
                                                ? true
                                                : false
                                            }
                                          />
                                        )}
                                      />
                                    </LocalizationProvider>
                                  )}
                                />
                              </Grid>
                              <Grid item xs={6} sm={4}>
                                <Controller
                                  name={`keyResults.${index}.endDate`}
                                  control={useHookForm.control}
                                  render={({ field }) => (
                                    <LocalizationProvider
                                      dateFormats={{
                                        year: "BBBB",
                                        monthAndYear: "MMMM BBBB",
                                      }}
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <DatePicker
                                        {...field}
                                        views={["year", "month", "day"]}
                                        inputFormat="DD MMMM BBBB"
                                        disableMaskedInput
                                        openTo="year"
                                        value={field.value}
                                        onChange={(newValue) => {
                                          field.onChange(newValue);
                                        }}
                                        minDate={quarterStartDate}
                                        maxDate={quarterEndDate}
                                        renderInput={(params) => (
                                          <TextFieldTheme
                                            {...params}
                                            inputProps={{
                                              ...params.inputProps,
                                              placeholder: `${t(
                                                "DayMonthYear(AD)"
                                              )}`,
                                              readOnly: true,
                                            }}
                                            onBlur={field.onBlur}
                                            helperText={
                                              useHookForm.formState.errors[
                                                field.name.split(".")[0]
                                              ] &&
                                              useHookForm.formState.errors[
                                                field.name.split(".")[0]
                                              ][field.name.split(".")[1]] &&
                                              useHookForm.formState.errors[
                                                field.name.split(".")[0]
                                              ][field.name.split(".")[1]][
                                                field.name.split(".")[2]
                                              ]
                                                ? useHookForm.formState.errors[
                                                    field.name.split(".")[0]
                                                  ][field.name.split(".")[1]][
                                                    field.name.split(".")[2]
                                                  ].message
                                                : null
                                            }
                                            error={
                                              useHookForm.formState.errors[
                                                field.name.split(".")[0]
                                              ] &&
                                              useHookForm.formState.errors[
                                                field.name.split(".")[0]
                                              ][field.name.split(".")[1]] &&
                                              useHookForm.formState.errors[
                                                field.name.split(".")[0]
                                              ][field.name.split(".")[1]][
                                                field.name.split(".")[2]
                                              ]
                                                ? true
                                                : false
                                            }
                                          />
                                        )}
                                      />
                                    </LocalizationProvider>
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <Typography>
                                  {`${t("ReviewerApprover")}`}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={8}>
                                <Controller
                                  name={`keyResults.${index}.idManager`}
                                  control={useHookForm.control}
                                  render={({ field }) => (
                                    <Autocomplete
                                      options={managers}
                                      getOptionLabel={(option) =>
                                        `${option.firstname_TH} ${option.lastname_TH}`
                                      }
                                      filterOptions={filterOptions}
                                      renderOption={(props, option) => (
                                        <MenuItem
                                          {...props}
                                          key={option.idEmployees}
                                        >
                                          <Box>
                                            <Typography>
                                              {option.firstname_TH}{" "}
                                              {option.lastname_TH}
                                            </Typography>
                                            <Typography
                                              color="text.third"
                                              fontSize="14px"
                                            >
                                              {option.email}
                                            </Typography>
                                          </Box>
                                        </MenuItem>
                                      )}
                                      renderInput={(params) => (
                                        <TextFieldTheme
                                          {...params}
                                          onBlur={field.onBlur}
                                          // error={useForm.formState.errors[field.name]? true: false}
                                          // helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
                                          helperText={
                                            useHookForm.formState.errors[
                                              field.name.split(".")[0]
                                            ] &&
                                            useHookForm.formState.errors[
                                              field.name.split(".")[0]
                                            ][field.name.split(".")[1]] &&
                                            useHookForm.formState.errors[
                                              field.name.split(".")[0]
                                            ][field.name.split(".")[1]][
                                              field.name.split(".")[2]
                                            ]
                                              ? useHookForm.formState.errors[
                                                  field.name.split(".")[0]
                                                ][field.name.split(".")[1]][
                                                  field.name.split(".")[2]
                                                ].message
                                              : null
                                          }
                                          error={
                                            useHookForm.formState.errors[
                                              field.name.split(".")[0]
                                            ] &&
                                            useHookForm.formState.errors[
                                              field.name.split(".")[0]
                                            ][field.name.split(".")[1]] &&
                                            useHookForm.formState.errors[
                                              field.name.split(".")[0]
                                            ][field.name.split(".")[1]][
                                              field.name.split(".")[2]
                                            ]
                                              ? true
                                              : false
                                          }
                                        />
                                      )}
                                      value={
                                        managers.find(
                                          (option) =>
                                            option.idEmployees === field.value
                                        ) || null
                                      }
                                      onChange={(_, value) => {
                                        field.onChange(
                                          value ? value.idEmployees : ""
                                        );
                                      }}
                                      disableClearable
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <Typography>{`${t(
                                  "MeasurementData"
                                )}`}</Typography>
                              </Grid>
                              <Grid item xs={12} sm={8}>
                                <Controller
                                  name={`keyResults.${index}.measureEvidence`}
                                  control={useHookForm.control}
                                  render={({ field }) => (
                                    <TextFieldTheme
                                      {...field}
                                      inputProps={{
                                        placeholder: `${t("MeasurementData")}`,
                                      }}
                                      helperText={
                                        useHookForm.formState.errors[
                                          field.name.split(".")[0]
                                        ] &&
                                        useHookForm.formState.errors[
                                          field.name.split(".")[0]
                                        ][field.name.split(".")[1]] &&
                                        useHookForm.formState.errors[
                                          field.name.split(".")[0]
                                        ][field.name.split(".")[1]][
                                          field.name.split(".")[2]
                                        ]
                                          ? useHookForm.formState.errors[
                                              field.name.split(".")[0]
                                            ][field.name.split(".")[1]][
                                              field.name.split(".")[2]
                                            ].message
                                          : null
                                      }
                                      error={
                                        useHookForm.formState.errors[
                                          field.name.split(".")[0]
                                        ] &&
                                        useHookForm.formState.errors[
                                          field.name.split(".")[0]
                                        ][field.name.split(".")[1]] &&
                                        useHookForm.formState.errors[
                                          field.name.split(".")[0]
                                        ][field.name.split(".")[1]][
                                          field.name.split(".")[2]
                                        ]
                                          ? true
                                          : false
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <Typography>{`${t(
                                  "ObjectiveMeasurementData"
                                )}`}</Typography>
                              </Grid>
                              <Grid item xs={12} sm={8}>
                                <Controller
                                  name={`keyResults.${index}.commitQuality`}
                                  control={useHookForm.control}
                                  render={({ field }) => (
                                    <TextFieldTheme
                                      {...field}
                                      inputProps={{
                                        placeholder: `${t(
                                          "ObjectiveMeasurementData"
                                        )}`,
                                      }}
                                      helperText={
                                        useHookForm.formState.errors[
                                          field.name.split(".")[0]
                                        ] &&
                                        useHookForm.formState.errors[
                                          field.name.split(".")[0]
                                        ][field.name.split(".")[1]] &&
                                        useHookForm.formState.errors[
                                          field.name.split(".")[0]
                                        ][field.name.split(".")[1]][
                                          field.name.split(".")[2]
                                        ]
                                          ? useHookForm.formState.errors[
                                              field.name.split(".")[0]
                                            ][field.name.split(".")[1]][
                                              field.name.split(".")[2]
                                            ].message
                                          : null
                                      }
                                      error={
                                        useHookForm.formState.errors[
                                          field.name.split(".")[0]
                                        ] &&
                                        useHookForm.formState.errors[
                                          field.name.split(".")[0]
                                        ][field.name.split(".")[1]] &&
                                        useHookForm.formState.errors[
                                          field.name.split(".")[0]
                                        ][field.name.split(".")[1]][
                                          field.name.split(".")[2]
                                        ]
                                          ? true
                                          : false
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <Typography>{`${t(
                                  "TypeMeasurementData"
                                )}`}</Typography>
                              </Grid>
                              <Grid item xs={12} sm={8}>
                                <Controller
                                  name={`keyResults.${index}.measureDataType`}
                                  control={useHookForm.control}
                                  render={({ field }) => (
                                    <TextFieldTheme
                                      {...field}
                                      inputProps={{
                                        placeholder: `${t(
                                          "TypeMeasurementData"
                                        )}`,
                                      }}
                                      helperText={
                                        useHookForm.formState.errors[
                                          field.name.split(".")[0]
                                        ] &&
                                        useHookForm.formState.errors[
                                          field.name.split(".")[0]
                                        ][field.name.split(".")[1]] &&
                                        useHookForm.formState.errors[
                                          field.name.split(".")[0]
                                        ][field.name.split(".")[1]][
                                          field.name.split(".")[2]
                                        ]
                                          ? useHookForm.formState.errors[
                                              field.name.split(".")[0]
                                            ][field.name.split(".")[1]][
                                              field.name.split(".")[2]
                                            ].message
                                          : null
                                      }
                                      error={
                                        useHookForm.formState.errors[
                                          field.name.split(".")[0]
                                        ] &&
                                        useHookForm.formState.errors[
                                          field.name.split(".")[0]
                                        ][field.name.split(".")[1]] &&
                                        useHookForm.formState.errors[
                                          field.name.split(".")[0]
                                        ][field.name.split(".")[1]][
                                          field.name.split(".")[2]
                                        ]
                                          ? true
                                          : false
                                      }
                                      select
                                      // SelectProps={{
                                      //   displayEmpty: true
                                      // }}
                                    >
                                      {/* <MenuItem value="">ประเภทข้อมูล</MenuItem> */}
                                      <MenuItem value="1">{`${t(
                                        "Number"
                                      )}`}</MenuItem>
                                      <MenuItem value="2">{`${t(
                                        "Explanation"
                                      )}`}</MenuItem>
                                    </TextFieldTheme>
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </Container>
                        </Grid>
                      </Grid>
                    ))}

                    <Grid item xs={12} container justifyContent="flex-end">
                      <ResponsiveButtonBlue
                        variant="contained"
                        type="submit"
                        disabled={useHookForm.formState.isSubmitting}
                      >
                        {useHookForm.formState.isSubmitting ? (
                          <CircularProgress size="21px" color="inherit" />
                        ) : (
                          `${t("Save")}`
                        )}
                      </ResponsiveButtonBlue>
                    </Grid>
                  </Grid>
                </Box>
              </form>
            </CardStyle>
          </div>
        )}
      </Container>

      <DialogStatusForm
        open={dialogStatusConfig.open}
        onClose={() => {
          onCloseDialog();
        }}
        onConfirm={() => {
          onCloseDialog();
        }}
        statusCode={dialogStatusConfig.statusCode}
        statusText={dialogStatusConfig.statusText}
      />
    </StyledRoot>
  );
};

export default IndividualPlanningAddFormPage;
