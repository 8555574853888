import dayjs from 'dayjs';
import ExcelJS from 'exceljs';
import { getUserCompany, getUserDepartment, getUserDivision, getUserFirstName, getUserLastName, getUserPosition, getUserSection } from '../../../../../utils/userData';

export const OtMonitoringExportExcel = async (t, data, searchDate) => {

    const headerCellStyle = {
        font: {
            name: "TH Sarabun New",
            size: 20,
            color: { argb: "FFFFFF" }
        },
        alignment: {
            vertical: "middle",
            horizontal: "center",
            wrapText: true,
        },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: "002060" }
        },
        // numFmt: "@",
    }

    const dataCellStyle = {
        font: {
            name: "TH Sarabun New",
            size: 18,
        },
        alignment: {
            vertical: "middle",
            horizontal: "center"
        },
        // numFmt: "@",
    }

    const workBook = new ExcelJS.Workbook();

    const worksheet = workBook.addWorksheet(t("EmployeeUpdatedLevel"));

    const headerRow = worksheet.addRow([
        t("EmployeeID"),
        t("FirstName"),
        t("LastName"),
        t("Position"),
        t("Company"),
        t("Division"),
        t("Department"),
        t("Section"),
        t("StartDate"),
        t("EndDate"),
        t("TotalWorkingHours"),
        t("TotalOvertimeHours"),
        t("OTPercentage"),
    ]);

    headerRow.eachCell((cell) => {
        cell.style = headerCellStyle;
        cell.numFmt = "@";
    });

    headerRow.height = 50;

    const colWidths = [
        { key: "employeeID", width: 30 },
        { key: "firstname_TH", width: 50 },
        { key: "lastname_TH", width: 50 },
        { key: "positionName", width: 50 },
        { key: "company", width: 50 },
        { key: "divisionName", width: 50 },
        { key: "departmentName", width: 50 },
        { key: "sectionName", width: 50 },
        { key: "StartDate", width: 50 },
        { key: "EndDate", width: 50 },
        { key: "totalWorkingTime", width: 50 },
        { key: "totalOThours", width: 50 },
        { key: "percent", width: 50 },
    ];

    colWidths.forEach((col, index) => {
        worksheet.getColumn(index + 1).width = col.width;
    });

    data && data.length > 0 && data.map(item => {
        const row = [
            item.employeeID || "-",
            getUserFirstName(item) || "-",
            getUserLastName(item) || "-",
            getUserPosition(item) || "-",
            getUserCompany(item) || "-",
            getUserDivision(item) || "-",
            getUserDepartment(item) || "-",
            getUserSection(item) || "-",
            dayjs(searchDate.start).format("YYYY-MM-DD") || "-",
            dayjs(searchDate.end).format("YYYY-MM-DD") || "-",
            item.totalWorkingHours || "-",
            item.amountHours || "-",
            item.percent || "-",
        ];

        const excelRow = worksheet.addRow(row);
        excelRow.eachCell((cell) => {
            cell.style = dataCellStyle;
            cell.numFmt = "@";
        });
    })

    workBook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `รายงานสรุปชั่วโมงล่วงเวลา.xlsx`;
        a.click();
        URL.revokeObjectURL(url);
    });
}