import React, { useState, useEffect } from "react";
import { FormControl, Select, MenuItem, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";

import AlertResponse from "../../../shared/general/AlertResponse";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../shared/general/Drawer";
import { getDepartment } from "../../../../../actions/company";
import { useDispatch, useSelector } from "react-redux";
import {
  AddTerminateEmployee,
  getTerminateList,
  getTerminateReviewer,
} from "../../../../../actions/offBoarding";
import { getAllEmployees } from "../../../../../actions/employee";

// Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center",
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const DialogAdd = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { open, handleClose, data } = props;
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [formData, setFormData] = useState({
    idTerminateList: null,
  });
  const [review, setReview] = useState(null);
  const { result: terminateList } = useSelector((state) => state.terminateList);
  const { result: terminateReviewer } = useSelector(
    (state) => state.terminateReviewer
  );

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  useEffect(() => {
    dispatch(getDepartment());
    dispatch(getAllEmployees());
    dispatch(getTerminateList());
    dispatch(getTerminateReviewer());
    if (open) {
      setFormData({
        idTerminateList: null,
        idEmployees: data,
        idTerminateReviewer: null,
        createDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        updateDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        isApprove: 0,
      });
    }
  }, [open, dispatch, data]);

  useEffect(() => {
    if (formData.idTerminateList)
      setReview(
        terminateReviewer.filter(
          (review) => review.idTerminateList === formData.idTerminateList
        )
      );
    else setReview(null);
  }, [formData.idTerminateList, terminateReviewer]);

  const handleSubmit = async () => {
    if (!formData.idTerminateList || !formData.idTerminateReviewer) {
      handleOpenAlert(true);
      handleChangeAlertType(`${t("PleaseFillInRequiredInformations")}`);
    } else {
      const dataToSend = {
        idTerminateList: formData.idTerminateList,
        idEmployees: formData.idEmployees,
        idTerminateReviewer: formData.idTerminateReviewer,
        createDate: formData.createDate,
        updateDate: formData.updateDate,
        isApprove: formData.isApprove,
      };
      const result = await dispatch(AddTerminateEmployee(dataToSend));
      if (result) {
        handleClose();
      } else {
        handleOpenAlert(true);
        handleChangeAlertType("error");
      }
    }
  };

  return (
    <DrawerCustom
      title={`${t("AddInspector")}`}
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      {formData && terminateList && (
        <StyledRoot>
          <Grid container spacing={2} style={{ marginBottom: 16, width: 400 }}>
            <Grid item xs={12} sm={6} className="GridTopicInput">
              {t("ItemName")}
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Select
                  name="idTerminateList"
                  value={formData.idTerminateList}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      idTerminateList: event.target.value,
                    });
                  }}
                >
                  {terminateList
                    .filter((row) => row.assignmentType === "individual")
                    .map((row) => (
                      <MenuItem
                        key={row.idTerminateList}
                        value={row.idTerminateList}
                      >
                        {row.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {review && (
            <Grid
              container
              spacing={2}
              style={{ marginBottom: 16, width: 400 }}
            >
              <Grid item xs={12} sm={6} className="GridTopicInput">
                {t("InspectorName")}
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <Select
                    name="idTerminateReviewer"
                    value={formData.idTerminateReviewer}
                    onChange={(event) => {
                      setFormData({
                        ...formData,
                        idTerminateReviewer: event.target.value,
                      });
                    }}
                  >
                    {review.map((row) => (
                      <MenuItem
                        key={row.idTerminateReviewer}
                        value={row.idTerminateReviewer}
                      >{`${row.firstname_TH} ${row.lastname_TH}`}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          )}
          <StyledFooter>
            <ButtonBlue className="cancel" onClick={handleClose}>
              {t("Cancel")}
            </ButtonBlue>
            <ButtonBlue variant="contained" onClick={handleSubmit} autoFocus>
              {t("SaveData")}
            </ButtonBlue>
          </StyledFooter>
        </StyledRoot>
      )}
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </DrawerCustom>
  );
};

export default DialogAdd;
