import { httpClient } from "./httpClient";

const getCostCenterlists = (query) => {
    return httpClient.get(`/get-cost-center`, { params: query });
};

const addCostCenterByIdEmployees = (query) => {
    return httpClient.post(`/add-cost-center`, query);
};

const getCostCenterByEmployees = (query) => {
    return httpClient.get(`/get-cost-center-employee`, { params: query });
};

export default {
    getCostCenterlists,
    addCostCenterByIdEmployees,
    getCostCenterByEmployees
};