import React, { useEffect, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  Avatar, Container, Typography,
  Breadcrumbs, Grid, ListItem,
  ListItemText, ListItemIcon, Box,
  TableContainer, Paper, MenuItem,
  FormControl, Select, TextField,
} from "@mui/material";
import CardStyle from "../../../shared/general/Card";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import SelectCompany from "../../shared/selectCompany";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import WorkIcon from "@mui/icons-material/Work";
import { getAllEmployees } from "../../../../../actions/employee";
import { getAffiliateOrganization } from "../../../../../actions/company";
import ChartOT from "./ChartOT";
import DatePickerCustom from "../../../shared/date/datePicker";
import dayjs from "dayjs";
import SelectAffiliateCompany from "../../../shared/general/SelectAffiliateCompany";
import { getAllOTHour, getAllOTWage } from "../../../../../actions/payruns-export";
import AlertResponse from "../../../shared/general/AlertResponse";
import { TotalHoursOTxlsx } from "../xlsx-export/totatHourOTxlsx";
import { TotalWageOTxlsx } from "../xlsx-export/totalWageOTxlsx";
import { useTranslation } from "react-i18next";
import { getUserFullName } from "../../../../../utils/userData";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  paddingBottom: 10,
  paddingTop: 10,
  marginTop: 10,
});

const DivEmployeesList = styled("div")({
  width: "100%",
  height: "fit-content",
  display: "grid",
  marginBottom: 64,
  "& .header": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "transparent",
    border: "0",
    marginBottom: 16,
    "& .MuiTypography-root": {
      fontWeight: 500,
      "& span": {
        letterSpacing: 0.5,
      },
    },
  },
  "& .employee-list": {
    padding: 50,
    height: "520px",
    overflow: "auto",
    backgroundColor: "white",
    borderRadius: "16px",
    display: "flex",
    flexDirection: "row",
    gridTemplateRows: "repeat(autofill, minmax(130px, 1fr))",
    gridTemplateColumns: "repeat(auto-fill, minmax(120px, 1fr))",
    minWidth: "100%",
    boxSizing: "border-box",
    columnGap: 8,
    "& .employee-item": {
      display: "flex",
      justifyContent: "flex-start",
      flexDirection: "column",
      marginRight: "2px",
      alignItems: "center",
      minHeight: "105px",
      whiteSpace: "nowrap",
    },
    "& .MuiAvatar-root": {
      width: 72,
      height: 72,
      marginBottom: 8,
      alignItems: "center",
    },
    "& .MuiTypography-root": {
      textAlign: "center",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    "& .div-text-end": {
      height: "60px",
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "center",
    },
  },
});

const StyledDetailCard = styled("div")({
  padding: 24,
  paddingTop: 16,
  "& .part-header-detail": {
    paddingBottom: 24,
  },
});

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledWrapFilter = styled("div")({
  width: "100%",
  display: "flex",
  alignItems: "center",
  marginBottom: 36,
  ["@media (min-width: 0px)"]: {
    flexDirection: "column",
  },
  ["@media (min-width: 900px)"]: {
    flexDirection: "row",
  },
  "& .search-name, .search-date": {
    width: "100%",
  },
  "& .wrap-search-action": {
    marginTop: 50,
    display: "flex",
    justifyContent: "flex-start",
    "& .btn-export": {
      marginLeft: 8,
    },
  },
});

const StyledBoxSearchButton = styled(Box)({
  marginTop: 30,
  display: "flex",
  justifyContent: "space-evenly",
  gap: 8,
});

const DashboardOT = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: employeeList } = useSelector((state) => state.employees);
  const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [inputSearch, setInputSearch] = useState(new Date());
  const [isFetching, setIsFetching] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [otWage, setOtWage] = useState(null);
  const [otHour, setOtHour] = useState(null);
  const [exportedData, setExportedData] = useState(null);
  const [exportedData2, setExportedData2] = useState(null);
  const [otType, setOtType] = useState(1);
  const OTItems = [
    { text: "OT 1", marginTop: "5vh" },
    { text: "OT 1.5", marginTop: "5vh" },
    { text: "OT 2", marginTop: "5vh" },
    { text: "OT 3", marginTop: "5vh" },
    { text: `OT ${t("Total")} (${t("Baht")})`, marginTop: "5vh" },
  ];
  
  const IconItems = [
    { color: "#008FFB", text: "OT 1" },
    { color: "#00E396", text: "OT 1.5" },
    { color: "#FEB019", text: "OT 2" },
    { color: "#FF4560", text: "OT 3" },
    { color: "#775DD0", text: `OT ${t("Total")}` },
  ];
  const handleOpenAlert = () => {
    setOpenAlert(true);
    setIsFetching(false);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
    setOtType(1);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const onChangeCompany = (newValue) => {
    setSelectedCompany(newValue);
  };

  const onChangeOT = (event) => {
    setOtType(event.target.value);
  };

  useEffect(() => {
    dispatch(getAllEmployees());
    dispatch(getAffiliateOrganization());
  }, []);

  const fetchedAllOTWage = async () => {
    try {
      setIsFetching(true);
      const response = await getAllOTWage(
        dayjs(inputSearch).locale('th').format("YYYY-MM"),
        selectedCompany.idCompany
      );
      if (response && response.data) {
        setIsFetching(false);
        setOtWage(response.data.result);
        setExportedData(response.data.resultXlsx);
      } else {
        handleChangeAlertType(`${t("NoData")}`);
        handleOpenAlert(true);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const fetchedAllOTHour = async () => {
    try {
      setIsFetching(true);
      const response = await getAllOTHour(
        dayjs(inputSearch).locale('th').format("YYYY-MM"),
        selectedCompany.idCompany
      );
      if (response && response.data) {
        setIsFetching(false);
        setOtHour(response.data.result);
        setExportedData2(response.data.resultXlsx);
      } else {
        handleChangeAlertType(`${t("NoData")}`);
        handleOpenAlert(true);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (selectedCompany && inputSearch && otType && otType === 1) {
      fetchedAllOTWage()
    } else if (selectedCompany && inputSearch && otType && otType === 2) {
      fetchedAllOTHour();
    } else {
      setIsFetching(false);
    }
  }, [selectedCompany, inputSearch, otType])

  useEffect(() => {
    if (userProfile && affiliateOrganizationList && affiliateOrganizationList.length > 0) {
      let ownCompany = affiliateOrganizationList.find((x) => x.idCompany === userProfile.idCompany);
      if (!ownCompany) {
        ownCompany = affiliateOrganizationList[0];
      }
      setSelectedCompany(ownCompany);
    }
  }, [userProfile, affiliateOrganizationList]);

  const handleFileExportOTHour = async (year) => {
    if (exportedData && exportedData.length > 0 && otType === 1) {
      TotalWageOTxlsx(t,year, exportedData);
    } else if (exportedData2 && exportedData2.length > 0 && otType === 2) {
      TotalHoursOTxlsx(t,year, exportedData2);
    } else {
      handleOpenAlert();
      return;
    }
  }

  return (
    <div>
      <StyledRoot className="page">
        <Container maxWidth="lg">
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link
              style={{ textDecoration: "none", color: "inherit" }}
              to={"/employees-report"}
            >
              {t("AllReports")}
            </Link>
            <Typography color="text.primary">{t("Dashboard")} OT</Typography>
          </Breadcrumbs>
          <div style={{ marginTop: "5vh" }}>
            <Typography variant="h4" style={{ paddingTop: 8 }}>
            {t("Dashboard")}
            </Typography>
          </div>

          <StyledWrapFilter>
            <Grid container spacing={2} columns={14} alignItems="center">

              <Grid item xs={3}>
                <StyledBoxSearch>
                  <Typography className="label" color="text.third">
                    {t("SelectYear")}
                  </Typography>
                  <div className="search-date">
                    <DatePickerCustom
                      maxDate={dayjs()}
                      inputFormat="MMMM YYYY"
                      value={inputSearch}
                      name="start"
                      views={['year', 'month']}
                      openTo={'month'}
                      onChange={(newValue) => {
                        setInputSearch(newValue);
                      }}
                      disabled={isFetching}
                    />
                  </div>
                </StyledBoxSearch>
              </Grid>
              <Grid item xs={3}>
                <StyledBoxSearch>
                  <Typography className="label" color="text.third">
                    {`${t("Company")}`}
                  </Typography>
                  <div className="search-name">
                    <SelectAffiliateCompany
                      options={affiliateOrganizationList}
                      value={selectedCompany}
                      disabled={isFetching}
                      onChange={(_, value) => {
                        onChangeCompany(value);
                      }}
                    />
                  </div>
                </StyledBoxSearch>
              </Grid>
              <Grid item xs={3}>
                <StyledBoxSearch>
                  <Typography className="label" color="text.third">
                    {t("Type")}
                  </Typography>
                  <FormControl>
                    <Select
                      value={otType}
                      onChange={onChangeOT}
                      popupIcon={<i class="fa-light fa-chevron-down"></i>}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        backgroundColor: "#919eab14",
                        borderRadius: 2,
                        minWidth: 120,
                      }}
                      disabled={isFetching}
                    >
                      <MenuItem disabled value="" style={{ display: "none" }}>
                        {t("SelectType")}
                      </MenuItem>
                      <MenuItem value={1}>OT ({t("AmountOfMoney")})</MenuItem>
                      <MenuItem value={2}>OT ({t("Unit.Hours")})</MenuItem>
                    </Select>
                  </FormControl>
                </StyledBoxSearch>
              </Grid>
              <Grid item xs={12} sm={2}>
                <StyledBoxSearchButton>
                  <div>
                    <ButtonBlue
                      style={{ marginTop: "10px" }}
                      variant="contained"
                      startIcon={<DownloadRoundedIcon />}
                      disabled={isFetching}
                      onClick={() => handleFileExportOTHour(dayjs(inputSearch).locale('th').format("YYYY"))}
                    >
                      {t("Download")}
                    </ButtonBlue>
                  </div>
                </StyledBoxSearchButton>
              </Grid>
            </Grid>
          </StyledWrapFilter>

          <CardStyle style={{ marginTop: "5vh" }}>
            <StyledDetailCard>
              <TableContainer component={Paper} style={{ marginTop: "3vh" }}>
                <DivEmployeesList>
                  {employeeList && (
                    <Fragment>
                      <div className="employee-list">
                        <div className="employee-item">
                          <div
                            style={{
                              height: "104px",
                              minHeight: "104px",
                            }}
                          >
                            <div
                              style={{
                                height: "100%",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Typography color="text.secondary">
                                {t("Employee")}
                                <span>{`(${employeeList.filter(
                                  (item) => !item.isTerminate
                                ).length
                                  } ${t("People")})`}</span>
                              </Typography>
                            </div>
                          </div>

                          <div className="employee-item">
                            {OTItems.map((item, index) => (
                              <div key={index} className="div-text-end">
                                <Typography variant="body2" align="center">
                                  {item.text}
                                </Typography>
                              </div>
                            ))}
                          </div>
                        </div>

                        {employeeList.filter((item) => !item.isTerminate).map((emp, index) => {
                          let summaryOT1 = 0;
                          let summaryOT15 = 0;
                          let summaryOT2 = 0;
                          let summaryOT3 = 0;
                          let summaryOT = 0;

                          if (otType === 1) {
                            const findIdEmp = otWage && otWage.find(x => x.idEmployees === emp.idEmployees);
                            summaryOT1 = findIdEmp ? (findIdEmp.monthlyData && findIdEmp.monthlyData.SUM_OT1 && Array.isArray(findIdEmp.monthlyData.SUM_OT1) ? findIdEmp.monthlyData.SUM_OT1.reduce((sum, cur) => (cur.value !== null ? cur.value + sum : sum), 0) : 0) : 0;
                            summaryOT15 = findIdEmp ? (findIdEmp.monthlyData && findIdEmp.monthlyData.SUM_OT15 && Array.isArray(findIdEmp.monthlyData.SUM_OT15) ? findIdEmp.monthlyData.SUM_OT15.reduce((sum, cur) => (cur.value !== null ? cur.value + sum : sum), 0) : 0) : 0;
                            summaryOT2 = findIdEmp ? (findIdEmp.monthlyData && findIdEmp.monthlyData.sumOT2 && Array.isArray(findIdEmp.monthlyData.sumOT2) ? findIdEmp.monthlyData.sumOT2.reduce((sum, cur) => (cur.value !== null ? cur.value + sum : sum), 0) : 0) : 0;
                            summaryOT3 = findIdEmp ? (findIdEmp.monthlyData && findIdEmp.monthlyData.sumOT3 && Array.isArray(findIdEmp.monthlyData.sumOT3) ? findIdEmp.monthlyData.sumOT3.reduce((sum, cur) => (cur.value !== null ? cur.value + sum : sum), 0) : 0) : 0;
                            summaryOT = findIdEmp ? (findIdEmp.monthlyData && findIdEmp.monthlyData.totalOT && Array.isArray(findIdEmp.monthlyData.totalOT) ? findIdEmp.monthlyData.totalOT.reduce((sum, cur) => (cur.value !== null ? cur.value + sum : sum), 0) : 0) : 0;
                          } else {
                            const findIdEmp = otHour && otHour.find(x => x.idEmployees === emp.idEmployees);
                            summaryOT1 = findIdEmp ? (findIdEmp.monthlyData && findIdEmp.monthlyData.TOTAL_OT1 && Array.isArray(findIdEmp.monthlyData.TOTAL_OT1) ? findIdEmp.monthlyData.TOTAL_OT1.reduce((sum, cur) => (cur.value !== null ? cur.value + sum : sum), 0) : 0) : 0;
                            summaryOT15 = findIdEmp ? (findIdEmp.monthlyData && findIdEmp.monthlyData.TOTAL_OT15 && Array.isArray(findIdEmp.monthlyData.TOTAL_OT15) ? findIdEmp.monthlyData.TOTAL_OT15.reduce((sum, cur) => (cur.value !== null ? cur.value + sum : sum), 0) : 0) : 0;
                            summaryOT2 = findIdEmp ? (findIdEmp.monthlyData && findIdEmp.monthlyData.TOTAL_OT2 && Array.isArray(findIdEmp.monthlyData.TOTAL_OT2) ? findIdEmp.monthlyData.TOTAL_OT2.reduce((sum, cur) => (cur.value !== null ? cur.value + sum : sum), 0) : 0) : 0;
                            summaryOT3 = findIdEmp ? (findIdEmp.monthlyData && findIdEmp.monthlyData.TOTAL_OT3 && Array.isArray(findIdEmp.monthlyData.TOTAL_OT3) ? findIdEmp.monthlyData.TOTAL_OT3.reduce((sum, cur) => (cur.value !== null ? cur.value + sum : sum), 0) : 0) : 0;
                            summaryOT = findIdEmp ? (findIdEmp.monthlyData && findIdEmp.monthlyData.totalOT && Array.isArray(findIdEmp.monthlyData.totalOT) ? findIdEmp.monthlyData.totalOT.reduce((sum, cur) => (cur.value !== null ? cur.value + sum : sum), 0) : 0) : 0;
                          }

                          return (
                            <div className="employee-item" key={index + emp.firstname_TH + emp.lastname_TH}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <Avatar
                                  className="MuiAvatar-root"
                                  key={index}
                                  alt={emp.email}
                                  src={emp.imageProfile}
                                />
                                <Typography>{getUserFullName(emp)}</Typography>
                              </div>

                              <div className="employee-item">
                                <div className="div-text-end">
                                  <Typography variant="body2" align="center">
                                    {summaryOT1.toFixed(2)}
                                  </Typography>
                                </div>
                                <div className="div-text-end">
                                  <Typography variant="body2" align="center">
                                    {summaryOT15.toFixed(2)}
                                  </Typography>
                                </div>
                                <div className="div-text-end">
                                  <Typography variant="body2" align="center">
                                    {summaryOT2.toFixed(2)}
                                  </Typography>
                                </div>
                                <div className="div-text-end">
                                  <Typography variant="body2" align="center">
                                    {summaryOT3.toFixed(2)}
                                  </Typography>
                                </div>
                                <div className="div-text-end">
                                  <Typography variant="body2" align="center">
                                    {summaryOT.toFixed(2)}
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </Fragment>
                  )}
                </DivEmployeesList>
              </TableContainer>
            </StyledDetailCard>
          </CardStyle>

          {otHour && otHour.length > 0 && otType === 2 && !isFetching ? (
            <Grid
              container
              spacing={3}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "stretch",
              }}
            >
              <Grid item xs={12} md={8}>
                <CardStyle style={{ marginTop: "5vh" }}>
                  <StyledDetailCard>
                    <ChartOT otData={otHour} />
                  </StyledDetailCard>
                </CardStyle>
              </Grid>

              <Grid item xs={12} md={4}>
                <CardStyle style={{ marginTop: "5vh" }}>
                  <StyledDetailCard style={{ height: "100%" }}>
                    <Typography color="text.primary" style={{ marginTop: "3vh" }}>
                      {t("Total")} OT
                    </Typography>
                    <Grid
                      container
                      direction="column"
                      spacing={2}
                      style={{ marginTop: "2vh" }}
                    >
                      {IconItems.map((item, index) => (
                        <Grid item key={index}>
                          <Box
                            display="flex"
                            flexDirection="column"
                            gap="5px"
                            border="1px solid #ccc"
                            borderRadius="8px"
                            boxShadow="0 2px 4px rgba(0, 0, 0, 0.1)"
                            p="6px"
                          >
                            <ListItem>
                              <ListItemIcon>
                                <WorkIcon style={{ color: item.color }} />
                              </ListItemIcon>
                              <ListItemText primary={item.text} />
                            </ListItem>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </StyledDetailCard>
                </CardStyle>
              </Grid>
            </Grid>
          ) : null}
        </Container>
        <AlertResponse
          open={openAlert}
          handleClose={handleCloseAlert}
          alertType={alertType}
        />
      </StyledRoot>
    </div >
  );
};

export default DashboardOT;
