import React, { Fragment, useEffect, useState } from "react";
import { Box, Card, Container, Grid, styled, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { resetPassword, verifyResetPassword } from "../../../../actions/account";
import { Link, useLocation } from "react-router-dom";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { Controller, useForm } from "react-hook-form";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

const StyledRoot = styled(Box)({
  backgroundColor: "#FFFFFF !important",
})

const RenderForm = (props) => {

  const dispatch = useDispatch();

  const { setIsSuccess, token, setErrorCode } = props;
  const [isFetching, setIsFetching] = useState(false);

  const { control, handleSubmit, setError, getValues, formState: { errors } } = useForm({
    defaultValues: {
      newPassword: "",
      newPasswordConfirm: "",
    }
  });

  const onSubmit = async (submittedData) => {

    setIsFetching(true);

    const formData = {
      newPassword: submittedData.newPassword,
      token: token
    }
    
    dispatch(resetPassword(formData)).then(res => {
      if(res.status === 200){
        setIsSuccess(true)
      }
    }).catch(error => {
      console.log(error)
      if(error.response.status === 401){
        if(error.response.data && error.response.data.errorCode){
          setErrorCode(error.response.data.errorCode)
        }
      }
    }).finally(() => {
      setIsFetching(false);
    });

  }

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5">ตั้งค่ารหัสผ่านใหม่</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={14} color="text.third">*รหัสผ่านต้องมีจำนวน 8-16 ตัวอักษร</Typography>
            <Typography fontSize={14} color="text.third">*รหัสผ่านต้องเป็นตัวอักษรภาษาอังกฤษ ตัวเลข หรืออักขระพิเศษ `!@#$%^&*` เท่านั้น</Typography>
            <Typography fontSize={14} color="text.third">*ต้องมีตัวอักษรภาษาอังกฤษอย่างน้อย 1 ตัว หรือ ตัวเลขอย่างน้อย 1 ตัว</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={14} paddingBottom="8px">รหัสผ่านใหม่</Typography>
            <Controller
              control={control}
              name="newPassword"
              rules={{
                required: {value: true, message: "กรุณากรอกรหัสผ่านใหม่"},
                validate: {
                  length: (value) => (value.length >= 8 && value.length <= 16) || "รหัสผ่านต้องมีจำนวน 8-16 ตัวอักษร",
                  oneAOneNum: (value) => {
                    if(value.match(/^(?=.*[0-9])(?=.*[A-z])([A-z0-9]+)$/)){
                      return true
                    } else {
                      return "ต้องมีตัวอักษรภาษาอังกฤษอย่างน้อย 1 ตัว หรือ ตัวเลขอย่างน้อย 1 ตัว"
                    }
                  }
                },
                pattern: {
                 value: /^[0-9a-zA-Z!@#$%^&*]+$/,
                 message: "รหัสผ่านต้องเป็นตัวอักษรภาษาอังกฤษ ตัวเลข หรืออักขระพิเศษ `!@#$%^&*` เท่านั้น"
                }
              }}
              render={({field, formState}) => (
                <TextFieldTheme
                  {...field}
                  type={"password"}
                  autoComplete="password"
                  helperText={formState.errors && formState.errors[field.name] && formState.errors[field.name].message}
                  error={formState.errors && formState.errors[field.name] ? true: false}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={14} paddingBottom="8px">ยืนยันรหัสผ่านใหม่</Typography>
            <Controller
              control={control}
              name="newPasswordConfirm"
              rules={{
                required: {value: true, message: "กรุณากรอกยืนยันรหัสผ่านใหม่"},
                validate: {
                  sameAs: (value) => (value === getValues("newPassword")) || "รหัสผ่านไม่ตรงกัน",
                },
              }}
              render={({field, formState}) => (
                <TextFieldTheme
                  {...field}
                  type="password"
                  autoComplete="password"
                  helperText={formState.errors && formState.errors[field.name] && formState.errors[field.name].message}
                  error={formState.errors && formState.errors[field.name] ? true: false}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <ButtonBlue type="submit" variant="contained" fullWidth sx={{paddingTop: "12px", paddingBottom: "12px"}} disabled={isFetching}>ดำเนินการต่อ</ButtonBlue>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center">
              <ButtonBlue component={Link} to="/login">เข้าสู่ระบบ</ButtonBlue>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  )
}

const ResetPasswordPage = () => {

  const [isSuccess, setIsSuccess] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [isVerifyFetching, setIsVerifyFetching] = useState(false);
  const [errorCode, setErrorCode] = useState(null);

  const dispatch = useDispatch();
  const location = useLocation();
  const params = new URLSearchParams(location.search)

  useEffect(() => {
    setIsVerifyFetching(true)
    dispatch(verifyResetPassword({token: params.get("token")})).then(res => {
      if(res.status === 200){
        setIsVerify(true)
      }
    }).catch(error => {
      if(error.response.status === 401){
        if(error.response.data && error.response.data.errorCode){
          setErrorCode(error.response.data.errorCode)
        }
      }
    }).finally(() => {
      setIsVerifyFetching(false);
    })
  }, [])

  return (
    <StyledRoot className="page">
      <Container maxWidth="sm">

        {!isVerifyFetching && (
          <Card sx={{padding: "48px", boxShadow: 2}}>

            {isVerify && (
              <Fragment>
                {!isSuccess && <RenderForm
                  setIsSuccess={setIsSuccess}
                  token={params.get("token")}
                  setErrorCode={setErrorCode}
                />}

                {isSuccess && (
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography fontSize={20}>ตั้งค่ารหัสผ่านสำเร็จ</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <ButtonBlue component={Link} to="/login">เข้าสู่ระบบ</ButtonBlue>
                    </Grid>
                  </Grid>
                )}
              </Fragment>
            )}

            {!isVerify && errorCode === "TOKEN_EXPIRED" && (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography fontSize={20}>ลิงก์ตั้งค่ารหัสผ่านของคุณหมดอายุ กรุณาทำรายการใหม่อีกครั้ง</Typography>
                </Grid>
                <Grid item xs={12}>
                  <ButtonBlue component={Link} to="/forget-password">ทำรายการใหม่อีกครั้ง</ButtonBlue>
                </Grid>
              </Grid>
            )}

            {!isVerify && errorCode === "TOKEN_INVALID" && (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography fontSize={20}>ลิงก์ตั้งค่ารหัสผ่านของคุณไม่ถูกต้อง กรุณาทำรายการใหม่อีกครั้ง</Typography>
                </Grid>
                <Grid item xs={12}>
                  <ButtonBlue component={Link} to="/forget-password">ทำรายการใหม่อีกครั้ง</ButtonBlue>
                </Grid>
              </Grid>
            )}

          </Card>
        )}

      </Container>
    </StyledRoot>
  )
}

export default ResetPasswordPage