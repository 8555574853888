import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Typography,
  styled,
  IconButton,
  Chip,
  Grid,
  Collapse,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import EditIcon from "@mui/icons-material/Edit";
import KeyResult from "./KRCard";
import dayjs from "dayjs";
import DialogEditObjective from "./editObjective";
import StarRateIcon from "@mui/icons-material/StarRate";
import { useTranslation } from "react-i18next";
import "dayjs/locale/th"; // Import Thai locale

const StyledObjectiveCard = styled(Card)(({ expanded }) => ({
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)",
  borderRadius: 10,
  padding: "16px",
  marginBottom: "8px",
  border: expanded ? "2px solid #F1A1A1" : "1px solid #E0E0E0",
  backgroundColor: "#ffffff",
}));

const StyledChip = styled(Chip)({
  backgroundColor: "#FCE4EC",
  color: "#DB4178",
  fontWeight: "bold",
});

const KRBox = styled(Box)({});

const ProgressBox = styled(Grid)({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: "8px",
  borderRadius: "5px",
});

const OrgObjectiveCard = ({
  objective,
  isEditable,
  activeTab,
  isApprovable,
  approveTab,
  onObjectiveUpdated,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [objectiveData, setObjectiveData] = useState(objective);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    setObjectiveData(objective);
  }, [objective]);

  useEffect(() => {
    dayjs.locale(i18n.language);
  }, [i18n.language]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleEditClick = () => {
    setIsEditOpen(true);
  };

  const handleCloseEdit = () => {
    setIsEditOpen(false);
  };

  const getFormattedDate = (latestUpdateDate) => {
    if (!latestUpdateDate) {
      return "-";
    }
    return dayjs(latestUpdateDate).format("D MMM");
  };

  const calculateAverageProgress = () => {
    if (objectiveData.keyResults.length === 0) return 0;
    const totalProgress = objectiveData.keyResults.reduce(
      (sum, kr) => sum + kr.progressPercent,
      0
    );
    return (totalProgress / objectiveData.keyResults.length).toFixed(2);
  };

  const calculateScore = () => {
    const totalRating = objective.keyResults.reduce((sum, kr) => {
      const rating =
        kr.rating !== null && kr.rating !== undefined
          ? kr.rating
          : kr.employeeRating !== null && kr.employeeRating !== undefined
          ? kr.employeeRating
          : 0.0;
      return sum + rating;
    }, 0);

    return objective.keyResults.length > 0
      ? totalRating / objective.keyResults.length
      : 0.0;
  };

  const score = calculateScore();

  return (
    <StyledObjectiveCard expanded={expanded}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={1}>
          <IconButton onClick={handleExpandClick}>
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h6" fontWeight="bold">
            {objective.objectiveName}
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StyledChip label={t("lastUpdated")} />
          <Typography
            variant="body2"
            color="textSecondary"
            ml={2}
            sx={{ minWidth: 50, textAlign: "left" }}
          >
            {getFormattedDate(objective.latestUpdateDate)}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="body1" color="textSecondary">
            {t("Quarter")} {objective.quarter}
          </Typography>
        </Grid>
        <Grid item xs={2} display="flex" justifyContent="center">
          <Typography variant="body1" fontWeight="bold">
            {score.toFixed(2)}
          </Typography>
          <StarRateIcon
            fontSize="small"
            style={{ color: "#FFC107", marginRight: "10px" }}
          />
        </Grid>
        <ProgressBox item xs={1}>
          <Typography variant="h5" fontWeight="bold" color="#DB4178">
            {`${calculateAverageProgress()}%`}
          </Typography>
        </ProgressBox>
        {isEditable && (
          <Grid item xs={1}>
            <IconButton aria-label="edit" onClick={handleEditClick}>
              <EditIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <KRBox mt={2} pl={5}>
          {objective.keyResults.map((kr, index) => (
            <KeyResult
              key={index}
              data={kr}
              isEditable={isEditable}
              isApprovable={isApprovable}
              approveTab={approveTab}
              onObjectiveUpdated={onObjectiveUpdated}
            />
          ))}
        </KRBox>
      </Collapse>

      <DialogEditObjective
        open={isEditOpen}
        handleClose={handleCloseEdit}
        data={objective}
        activeTab={activeTab}
        onObjectiveUpdated={onObjectiveUpdated}
      />
    </StyledObjectiveCard>
  );
};

export default OrgObjectiveCard;
