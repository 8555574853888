import { httpClient } from "./httpClient";

const getShiftPattern = () => {
  return httpClient.get(`/shift-pattern`);
};

const getShift = (query) => {
  return httpClient.get(`/shift`, { params: query });
};

const getShiftGroupById = (idShiftGroup) => {
  return httpClient.get(`/shift/${idShiftGroup}`);
};

const getAllEmployeeShift = (idCompany) => {
  return httpClient.get(`/company/${idCompany}/employee-shift`);
};

const addNewShift = (data) => {
  return httpClient.post(`/addNewShift`,data);
};

const dumpExcelEmployeeShiftDaily = (formData) => {
  return httpClient.post(`/dump-excel-employee-shift-daily`, formData);
};

const exportExcelEmployeeShiftDaily = (query) => {
  return httpClient.get(`/export-excel-employee-shift-daily`, { params: query, responseType: 'blob' });
};

const changeShiftFee = (formData) => {
  return httpClient.post(`/shift-fee`, formData);
};

const addEmployeeShiftDaily = (formData) => {
  return httpClient.post(`/shift-daily`, formData);
};

const updateShiftGroup = (idShiftGroup, formData) => {
  return httpClient.put(`/shift-group/${idShiftGroup}`, formData);
}

export default {
  getShiftPattern,
  getShift,
  getAllEmployeeShift,
  addNewShift,
  getShiftGroupById,
  dumpExcelEmployeeShiftDaily,
  exportExcelEmployeeShiftDaily,
  changeShiftFee,
  addEmployeeShiftDaily,
  updateShiftGroup,
};