import React, { Fragment, useEffect, useState } from "react";
import { Avatar, Box, Breadcrumbs, Container, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, styled } from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SelectAffiliateCompany from "../../../shared/general/SelectAffiliateCompany";
import { useDispatch, useSelector } from "react-redux";
import DatePickerCustom from "../../../shared/date/datePicker";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import dayjs from "dayjs";
import { getAffiliateOrganization } from "../../../../../actions/company";
import CardStyle from "../../../shared/general/Card";
import { getAllLeaveEmployee3month, getAllLeaveEmployeeYear } from "../../../../../actions/leave";
import { getLeaveAbsentLate } from "../../../../../actions/report";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { exportExcelLeaveAbsentLateReport } from "../../../../../actions/exportExcel";
import { getEmployeeExcelDiligenceAllowanceExport } from "../../../../../actions/employee";
import { getUserFullName } from "../../../../../utils/userData";
import loading from "../../../assets/social-media.gif";
import ExcelJS from 'exceljs';

const StyledRoot = styled(Box)({
  backgroundColor: "#FFFFFF !important",
  "& .search-date-container": {
    marginTop: "24px",
    display: "flex",
    flexWrap: "nowrap",
    gap: "16px",
    "& .search-date-box": {
      maxWidth: "350px",
      width: "100%",
    },
    "& .search-button": {
      marginTop: "29px",
      display: "flex",
      alignItems: "center",
    },
  },

  ["@media only screen and (max-width: 600px)"]: {
    "& .search-date-container": {
      flexDirection: "column",
      "& .search-date-box": {
        maxWidth: "100%",
      },
      "& .search-button": {
        marginTop: "0",
        width: "100%",
      },
    }
  }
})

const StyledCellHeader = styled(TableCell)({
  borderBottom: "0px",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  padding: 8,
  minWidth: 50,
  "& .MuiTypography-root": {
    color: "#637381",
    backgroundColor: "#f4f6f8",
    "&.weekend": {
      fontStyle: "oblique",
    },
    "&.workday": {
      fontWeight: 600,
    },
  },
  "&.sticky": {
    padding: 0,
    position: "sticky",
    left: 0,
    zIndex: 4,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: "10px 0px 10px #EEEEEE",
    "& .MuiTableCell-root": {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
  },
  "&:last-of-type": {
    paddingRight: 16,
    // borderTopRightRadius: 8,
    // borderBottomRightRadius: 8,
  },
});

const StyledCellContent = styled(TableCell)(({ theme }) => ({
  borderBottom: "0px",
  padding: 8,
  minWidth: 50,
  "&.sticky": {
    position: "sticky",
    left: 0,
    backgroundColor: "#FFFFFF",
    boxShadow: "8px 20px 20px #EEEEEE",
  },
  "& .avatar": {
    minWidth: 240,
    "& .MuiAvatar-root": {
      marginRight: 8,
    },
    display: "flex",
    alignItems: "center",
  },
  "& .fullname": {
    fontWeight: 600,
  },
  "& .position": {
    color: theme.palette.text.secondary,
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 300,
  },
  "& .secondaryAction": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .location": {
      fontWeight: 600,
    },
  },
  "&:first-of-type": {
    paddingLeft: 8,
  },
  "&:last-of-type": {
    paddingRight: 16,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
}));

const StyledRowContent = styled(TableRow)({
  cursor: "pointer",
  textDecoration: "none",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "#f7f7f7",
    "& .sticky": {
      backgroundColor: "#f7f7f7",
    },
  },
});

const LeaveAbsentLate = () => {

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: userProfile } = useSelector(state => state.userProfile);
  const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);

  const { result: ReportLeaveAbsentLateList, isFetching: isReportLeaveAbsentLateListFetching } = useSelector(state => state.reportLeaveAbsentLate)

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);

  const [filter, setFilter] = useState({
    month: new Date(dayjs()),
    start: new Date(dayjs().set('date', 1)),
    end: new Date(dayjs().set('date', dayjs().daysInMonth())),
  })

  const [pagination, setPagination] = useState({
    page: 0,
    count: 0,
    rowsPerPage: 25,
  })

  // const onDownloadExcel = async () => {

  //   setIsLoadingExcel(true)

  //   const result = await dispatch(
  //     exportExcelLeaveAbsentLateReport({
  //       idCompany: userProfile && userProfile.roles.includes("ROLE_ADMIN") ? selectedCompany.idCompany : null,
  //       start: dayjs(filter.start).format("YYYY-MM-DD"),
  //       end: dayjs(filter.end).format("YYYY-MM-DD"),
  //       getAll: true,
  //     })
  //   ).then(res => {
  //     setIsLoadingExcel(false)
  //     return res
  //   });

  //   if (result && result.status === 200) {
  //     const excelBlob = new Blob([result.data], {
  //       type: result.headers["content-type"],
  //     });
  //     const downloadLink = document.createElement("a");
  //     downloadLink.href = URL.createObjectURL(excelBlob);
  //     downloadLink.download = `UniHR ${userProfile && userProfile.roles.includes("ROLE_ADMIN") ? selectedCompany.companyName : ""} ${t("SummaryOfLeaveAbsenceAndLatenessDataAsOf")} ${dayjs().format("DD MMMM BBBB HH.mm.ss" + t("Unit.Minute"))}.xlsx`;
  //     downloadLink.click();
  //   } else {

  //   }
  // }

  const onDownloadExcel = async () => {

    const workBook = new ExcelJS.Workbook();
    const workSheet = workBook.addWorksheet('Leave Absent Late Report');
    
    const headerRow = workSheet.addRow([
      "รหัสพนักงาน",
      "ชื่อ",
      "นามสกุล",
      "เลขบัตรประชาชน",
      "จำนวนวันทำงาน",
      "ขาดงาน",
      "มาสาย",
      "กลับก่อน",
      ...ReportLeaveAbsentLateList.leaveList.map(x => x.leaveGroupName)
    ]);

    headerRow.height = 59;

    const headerCellStyle = {
      font: {
        name: "Tahoma",
        size: 14,
        color: { argb: "FFFFFF" }
      },
      alignment: {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      },
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: "002060" }
      },
      numFmt: "@",
    }

    const dataCellStyle = {
      font: {
        name: "Tahoma",
        size: 14,
      },
      alignment: {
        vertical: "middle",
        horizontal: "center"
      },
      numFmt: "@",
    }

    headerRow.eachCell((cell) => {
      cell.style = headerCellStyle;
    });

    const colWidths = [
      { key: "employeeID", width: 20 },
      { key: "firstname", width: 20 },
      { key: "lastname", width: 20 },
      { key: "personalID", width: 24 },
      { key: "workingDay", width: 24 },
      { key: "absent", width: 24 },
      { key: "late", width: 24 },
      { key: "earlyBack", width: 24 },
      ...ReportLeaveAbsentLateList.leaveList.map(x => ({
        key: `leave_group_${x.idLeaveGroup}`,
        width: 24
      }))
    ];

    ReportLeaveAbsentLateList.employeeList.map(item => {
      const row = [
        item.employeeID? item.employeeID : "",
        item.firstname_TH? item.firstname_TH : "",
        item.lastname_TH? item.lastname_TH : "",
        item.personalID? item.personalID : "",
        item.arrive,
        item.absent,
        item.late,
        item.earlyBack,
      ];

      ReportLeaveAbsentLateList.leaveList.map(leave => {
        if (item.leaveUsed[leave.idLeaveGroup]) {
          row.push(Math.round(item.leaveUsed[leave.idLeaveGroup]*100)/100)
        } else {
          row.push(0)
        }
      })

      const excelRow = workSheet.addRow(row);
      excelRow.height = 40;
      colWidths.forEach((col, index) => {
        workSheet.getColumn(index + 1).width = col.width;
      });
      excelRow.eachCell((cell) => {
        cell.style = dataCellStyle;
      });

    })

    workBook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `UniHR ${userProfile && userProfile.roles.includes("ROLE_ADMIN") ? selectedCompany.companyName : ""} ${t("SummaryOfLeaveAbsenceAndLatenessDataAsOf")} ${dayjs().format("DD MMMM BBBB HH.mm.ss" + t("Unit.Minute"))}.xlsx`;
      a.click();
      URL.revokeObjectURL(url);
  });

  }

  useEffect(() => {
    if (userProfile && userProfile.roles.includes("ROLE_ADMIN")) {
      dispatch(getAffiliateOrganization())
    }
  }, [userProfile])

  useEffect(() => {
    if (userProfile && affiliateOrganizationList && affiliateOrganizationList.length > 0) {
      const foundCompany = affiliateOrganizationList.find(x => x.idCompany === userProfile.idCompany);
      if (foundCompany) {
        setSelectedCompany(foundCompany)
      } else {
        setSelectedCompany(affiliateOrganizationList[0])
      }
    }
  }, [userProfile, affiliateOrganizationList])

  useEffect(() => {
    if ((userProfile && userProfile.roles.includes("ROLE_ADMIN")) && selectedCompany) {
      dispatch(getLeaveAbsentLate({
        idCompany: selectedCompany.idCompany,
        start: dayjs(filter.start).format("YYYY-MM-DD"),
        end: dayjs(filter.end).format("YYYY-MM-DD"),
        // getAll: true,
      })).then(res => {
        if (res.status === 200) {
          setPagination(prev => ({
            ...prev,
            page: 0,
            count: res.data.employeeList ? res.data.employeeList.length : 0
          }))
        }
      })

      // dispatch(getEmployeeExcelDiligenceAllowanceExport({
      //   idCompany: selectedCompany.idCompany,
      //   start: dayjs(filter.start).format("YYYY-MM-DD"),
      //   end: dayjs(filter.end).format("YYYY-MM-DD"),
      //   getAll: true,
      // })).then(res => res.data)
    }
  }, [selectedCompany, userProfile])

  useEffect(() => {
    if (userProfile && userProfile.roles.includes("ROLE_MANAGER")) {
      dispatch(getLeaveAbsentLate({
        idCompany: null,
        start: dayjs(filter.start).format("YYYY-MM-DD"),
        end: dayjs(filter.end).format("YYYY-MM-DD"),
        getAll: true,
      })).then(res => {
        if (res.status === 200) {
          setPagination(prev => ({
            ...prev,
            page: 0,
            count: res.data.employeeList ? res.data.employeeList.length : 0
          }))
        }
      })
    }
  }, [userProfile])

  const handleClickSearch = () => {
    dispatch(getLeaveAbsentLate({
      idCompany: userProfile && userProfile.roles.includes("ROLE_ADMIN") ? selectedCompany && selectedCompany.idCompany : null,
      start: dayjs(filter.start).format("YYYY-MM-DD"),
      end: dayjs(filter.end).format("YYYY-MM-DD"),
      getAll: true,
    })).then(res => {
      if (res.status === 200) {
        setPagination(prev => ({
          ...prev,
          page: 0,
          count: res.data.employeeList ? res.data.employeeList.length : 0
        }))
      }
    })

    // dispatch(getEmployeeExcelDiligenceAllowanceExport({
    //   idCompany: selectedCompany.idCompany,
    //   start: dayjs(filter.start).format("YYYY-MM-DD"),
    //   end: dayjs(filter.end).format("YYYY-MM-DD"),
    //   getAll: true,
    // })).then(res => res.data)
  }

  const handleChangePage = (_, newPage) => {
    setPagination(prev => ({
      ...prev,
      page: newPage
    }))
  };

  const handleChangeRowsPerPage = (event) => {
    setPagination(prev => ({
      ...prev,
      page: 0,
      rowsPerPage: Number(event.target.value),
    }))
  };

  return (
    <StyledRoot className="page">
      <Container
        maxWidth="lg"
        style={{ paddingTop: "24px", paddingBottom: "24px" }}
      >
        <Box marginBottom="24px">
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            <Link
              style={{ textDecoration: "none", color: "inherit" }}
              to={"/employees-report"}
            >
              {`${t("AllReports")}`}
            </Link>
            <Typography color="text.primary">{`${t("SummaryOfLeaveAndAbsences")}`}</Typography>
          </Breadcrumbs>
        </Box>

        <Typography variant="h4">{t("SummaryOfLeaveAndAbsences")}</Typography>

        {/* <Box className="search-date-container">
          <Box className="search-date-box">
            <Typography fontWeight="600" fontSize="14px" paddingBottom="8px" color="text.third">{t("Company")}</Typography>
            <SelectAffiliateCompany
              options={affiliateOrganizationList}
              value={selectedCompany}
              onChange={(_, value) => {
                setSelectedCompany(value)
              }}
            />
          </Box>
          <Box className="search-date-box">
            <Typography fontWeight="600" fontSize="14px" paddingBottom="8px" color="text.third">{t("Month")}</Typography>
            <DatePickerCustom
              inputFormat="MMMM YYYY"
              value={filter.month}
              openTo="month"
              views={["year", "month"]}
              onChange={(newValue) => {
                setFilter(prev => ({
                  ...prev,
                  month: newValue
                }));
              }}
            />
          </Box>
          <Box className="search-button">
            <ButtonBlue
              startIcon={<SearchRoundedIcon />}
              variant="contained"
              fullWidth
              onClick={handleClickSearch}
            >
              {t("Search")}
            </ButtonBlue>
          </Box>
        </Box> */}

        <Grid marginTop="24px" container spacing={2}>
          {userProfile && userProfile.roles.includes("ROLE_ADMIN") && (
            <Grid item xs={12} md={6}>
              <Typography fontWeight="600" fontSize="14px" paddingBottom="8px" color="text.third">{t("Company")}</Typography>
              <SelectAffiliateCompany
                options={affiliateOrganizationList}
                value={selectedCompany}
                onChange={(_, value) => {
                  setSelectedCompany(value)
                }}
                disabled={isReportLeaveAbsentLateListFetching}
              />
            </Grid>
          )}
          <Grid item xs={6} md={3}>
            <Typography fontWeight="600" fontSize="14px" paddingBottom="8px" color="text.third">{t("StartDate")}</Typography>
            <DatePickerCustom
              inputFormat="DD MMMM YYYY"
              value={filter.start}
              openTo="month"
              views={["year", "month", "day"]}
              onChange={(newValue) => {
                if(dayjs(filter.end).diff(dayjs(newValue), 'day') > 62){
                  setFilter(prev => ({
                    ...prev,
                    start: newValue,
                    end: dayjs(newValue).add(60, 'day'),
                  }));
                } else {
                  setFilter(prev => ({
                    ...prev,
                    start: newValue
                  }));
                }
              }}
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography fontWeight="600" fontSize="14px" paddingBottom="8px" color="text.third">{t("EndDate")}</Typography>
            <DatePickerCustom
              minDate={filter.start}
              maxDate={dayjs(filter.start).add(62, 'day')}
              inputFormat="DD MMMM YYYY"
              value={filter.end}
              openTo="month"
              views={["year", "month", "day"]}
              onChange={(newValue) => {
                setFilter(prev => ({
                  ...prev,
                  end: newValue
                }));
              }}
              inputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item xs={12} container justifyContent="flex-end">
            <Box className="search-button">
              <ButtonBlue
                startIcon={<SearchRoundedIcon />}
                variant="contained"
                fullWidth
                onClick={handleClickSearch}
                disabled={isReportLeaveAbsentLateListFetching}
              >
                {t("Search")}
              </ButtonBlue>
            </Box>
          </Grid>
        </Grid>

        <Box marginTop="24px">
          <CardStyle>
            <Box padding="24px">
              {!isReportLeaveAbsentLateListFetching ? (
                <Box>
                  <Box marginBottom="16px" display="flex" alignItems="center" justifyContent="flex-end">
                    <ButtonBlue
                      variant="outlined"
                      startIcon={<DownloadRoundedIcon />}
                      onClick={onDownloadExcel}
                      disabled={isLoadingExcel}
                    >
                      {t("Download")}
                    </ButtonBlue>
                  </Box>
                  {/* <Box style={{ display: "flex", position: "relative" }}>
                    <TableContainer style={{ width: "100%", maxHeight: 600 }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead
                          sx={{
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          <TableRow>
                            <StyledCellHeader
                              align={"center"}
                              className="sticky"
                              style={{ minWidth: 240 }}
                              rowSpan={2}
                            >
                              {`${t("FullName")}`}
                            </StyledCellHeader>

                            {ReportLeaveAbsentLateList.monthList.map(month => (
                              <StyledCellHeader key={month} colSpan={ReportLeaveAbsentLateList.leaveList.length + 3} align="center">{dayjs().set("month", month-1).format("MMMM")}</StyledCellHeader>
                            ))}

                          </TableRow>

                          <TableRow>
                            {[...new Array(3)].map(round => {
                              return (
                                <Fragment>
                                  <StyledCellHeader align="center" style={{ minWidth: 100 }}>{`${t("WorkingDays")}`}</StyledCellHeader>
                                  <StyledCellHeader align="center" style={{ minWidth: 100 }}>{`${t("Absent")}`}</StyledCellHeader>
                                  <StyledCellHeader align="center" style={{ minWidth: 100 }}>{`${t("Late")}`}</StyledCellHeader>
                                  {ReportLeaveAbsentLateList.leaveList.map(item => (
                                    <StyledCellHeader align="center" style={{ minWidth: 100 }}>{item.leaveGroupName}</StyledCellHeader>
                                  ))}
                                </Fragment>
                              )
                            })}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {ReportLeaveAbsentLateList.employeeList.slice((pagination.page * pagination.rowsPerPage), ((pagination.page+1) * pagination.rowsPerPage)).map((item, index) => (
                            <StyledRowContent>
                              <StyledCellContent
                                key={`${index}`}
                                className="sticky"
                                style={{
                                  borderBottom:
                                    "1px dashed rgba(224, 224, 224, 1)",
                                }}
                              >
                                <Box className="avatar">
                                  <Avatar src="" />
                                  <Typography className="fullname">
                                    {`${item.firstname_TH}${item.lastname_TH? " "+item.lastname_TH: ""}`}
                                  </Typography>
                                </Box>
                              </StyledCellContent>

                              {ReportLeaveAbsentLateList.monthList.map(month => {
                                return (
                                  <Fragment>
                                    <StyledCellContent colSpan={1} align="center">{item.workingInfo[month] && item.workingInfo[month].workingDay !== null? item.workingInfo[month].workingDay: ""}</StyledCellContent>
                                    <StyledCellContent colSpan={1} align="center">{item.workingInfo[month] && item.workingInfo[month].workingDay !== null? item.workingInfo[month].absent: ""}</StyledCellContent>
                                    <StyledCellContent colSpan={1} align="center">{item.workingInfo[month] && item.workingInfo[month].workingDay !== null? item.workingInfo[month].late: ""}</StyledCellContent>
                                    {ReportLeaveAbsentLateList.leaveList.map(leave => (
                                      <StyledCellContent align="center">{item.workingInfo[month] && item.workingInfo[month].workingDay !== null? (item.leaveUsed[month] && item.leaveUsed[month][leave.idLeaveGroup]? Math.round(item.leaveUsed[month][leave.idLeaveGroup]*100)/100: 0): ""}</StyledCellContent>
                                    ))}
                                  </Fragment>
                                )
                              })}
                            </StyledRowContent>
                          )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>  */}

                  <Box style={{ display: "flex", position: "relative" }}>
                    <TableContainer style={{ width: "100%", maxHeight: 600 }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead
                          sx={{
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          <TableRow>
                            <StyledCellHeader
                              align={"center"}
                              className="sticky"
                              style={{ minWidth: 240 }}
                              rowSpan={2}
                            >
                              {`${t("FullName")}`}
                            </StyledCellHeader>

                            <StyledCellHeader align="center" style={{ minWidth: 100 }}>{`${t("WorkingDays")}`}</StyledCellHeader>
                            <StyledCellHeader align="center" style={{ minWidth: 100 }}>{`${t("Absent")}`}</StyledCellHeader>
                            <StyledCellHeader align="center" style={{ minWidth: 100 }}>{`${t("Late")}`}</StyledCellHeader>
                            <StyledCellHeader align="center" style={{ minWidth: 100 }}>{`${t("LeaveEarly")}`}</StyledCellHeader>

                            {ReportLeaveAbsentLateList.leaveList && ReportLeaveAbsentLateList.leaveList.map(leave => (
                              <StyledCellHeader key={leave.idLeaveGroup} align="center" style={{ minWidth: 140 }}>{leave.leaveGroupName}</StyledCellHeader>
                            ))}

                          </TableRow>

                          {/* <TableRow>
                            {[...new Array(3)].map(round => {
                              return (
                                <Fragment>
                                  <StyledCellHeader align="center" style={{ minWidth: 100 }}>{`${t("WorkingDays")}`}</StyledCellHeader>
                                  <StyledCellHeader align="center" style={{ minWidth: 100 }}>{`${t("Absent")}`}</StyledCellHeader>
                                  <StyledCellHeader align="center" style={{ minWidth: 100 }}>{`${t("Late")}`}</StyledCellHeader>
                                  {ReportLeaveAbsentLateList.leaveList.map(item => (
                                    <StyledCellHeader align="center" style={{ minWidth: 100 }}>{item.leaveGroupName}</StyledCellHeader>
                                  ))}
                                </Fragment>
                              )
                            })}
                          </TableRow> */}
                        </TableHead>
                        <TableBody>
                          {ReportLeaveAbsentLateList.employeeList && ReportLeaveAbsentLateList.employeeList.slice((pagination.page * pagination.rowsPerPage), ((pagination.page + 1) * pagination.rowsPerPage)).map((item, index) => (
                            <StyledRowContent>
                              <StyledCellContent
                                key={`${index}`}
                                className="sticky"
                                style={{
                                  borderBottom:
                                    "1px dashed rgba(224, 224, 224, 1)",
                                }}
                              >
                                <Box className="avatar">
                                  <Avatar src="" />
                                  <Typography className="fullname">
                                    {`${getUserFullName(item) ? getUserFullName(item) : item.firstname_TH && item.lastname_TH ? item.firstname_TH + " " + item.lastname_TH : " "}`}
                                  </Typography>
                                </Box>
                              </StyledCellContent>

                              <StyledCellContent align="center">{item.arrive}</StyledCellContent>
                              <StyledCellContent align="center">{item.absent}</StyledCellContent>
                              <StyledCellContent align="center">{item.late}</StyledCellContent>
                              <StyledCellContent align="center">{item.earlyBack}</StyledCellContent>

                              {ReportLeaveAbsentLateList.leaveList.map(leave => (
                                <StyledCellContent key={leave.idLeaveGroup} align="center" style={{ minWidth: 140 }}>{item.leaveUsed[leave.idLeaveGroup]? Math.round(item.leaveUsed[leave.idLeaveGroup]*100)/100 : 0}</StyledCellContent>
                              ))}

                              {/* {ReportLeaveAbsentLateList.monthList.map(month => {
                                return (
                                  <Fragment>
                                    <StyledCellContent colSpan={1} align="center">{item.workingInfo[month] && item.workingInfo[month].workingDay !== null? item.workingInfo[month].workingDay: ""}</StyledCellContent>
                                    <StyledCellContent colSpan={1} align="center">{item.workingInfo[month] && item.workingInfo[month].workingDay !== null? item.workingInfo[month].absent: ""}</StyledCellContent>
                                    <StyledCellContent colSpan={1} align="center">{item.workingInfo[month] && item.workingInfo[month].workingDay !== null? item.workingInfo[month].late: ""}</StyledCellContent>
                                    {ReportLeaveAbsentLateList.leaveList.map(leave => (
                                      <StyledCellContent align="center">{item.workingInfo[month] && item.workingInfo[month].workingDay !== null? (item.leaveUsed[month] && item.leaveUsed[month][leave.idLeaveGroup]? Math.round(item.leaveUsed[month][leave.idLeaveGroup]*100)/100: 0): ""}</StyledCellContent>
                                    ))}
                                  </Fragment>
                                )
                              })} */}
                            </StyledRowContent>
                          )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>


                  <TablePagination
                    rowsPerPageOptions={[25]}
                    component="div"
                    count={pagination.count}
                    rowsPerPage={25}
                    page={pagination.page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={`${t("RowsPerPage")}`}
                    labelDisplayedRows={({ from, to, count }) =>
                      `${from}-${to} ${t("OutOf")} ${count !== -1 ? count : `${t("MoreThan")} ${to}`
                      }`
                    }
                  />
                </Box>) : (

                // <Typography textAlign="center" color="text.secondary" fontSize="24px">
                //   {t(`LoadingData`)}...
                // </Typography>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <img alt="loading" src={loading} style={{ width: 100, height: 100 }} />
                </Box>

              )}
            </Box>
          </CardStyle>
        </Box>
      </Container>
    </StyledRoot>
  )
}

export default LeaveAbsentLate;