import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Typography, styled } from "@mui/material";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import dayjs from "dayjs";
import 'dayjs/locale/th';
import ExcelJS from 'exceljs';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import AlertResponse from "../../shared/general/AlertResponse";
import DrawerCustom from "../../shared/general/Drawer";
import DatePickerCustom from "../../shared/date/datePicker";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { getLeaveList } from "../../../../actions/leave";
import { getAffiliateOrganization, getCompanyOrganizationList } from "../../../../actions/company";
import SelectAffiliateCompany from "../../shared/general/SelectAffiliateCompany";

const StyledRoot = styled("div")({
    maxWidth: 550,
    padding: 24,
    "& .GridTopicInput": {
        display: "flex",
        alignItems: "center",
    },
});

const StyledFooter = styled("div")({
    padding: 16,
    display: "flex",
    justifyContent: "flex-end",
    "& .cancel": {
        marginRight: 8,
    },
});

const LeaveList = (props) => {

    const { open, handleClose } = props;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const { result: userProfile } = useSelector((state) => state.userProfile);
    // const { result: LeaveList } = useSelector((state) => state.leaveList)
    const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);

    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState(false);
    const [filter, setFilter] = useState({
        start: new Date(dayjs().set('date', 1)),
        end: new Date(dayjs().set('date', dayjs().daysInMonth())),
    })
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [isFetching, setIsFetching] = useState(true);
    const [LeaveList, setLeaveList] = useState(null);

    const onChangeCompany = (newValue) => {
        setSelectedCompany(newValue);
    };

    useEffect(() => {
        dispatch(getAffiliateOrganization());
    }, []);

    useEffect(() => {
        if (selectedCompany) {
            dispatch(getCompanyOrganizationList({
                idCompany: selectedCompany.idCompany,
                getSection: true,
            }));
        }
    }, [selectedCompany]);

    useEffect(() => {
        if (userProfile && affiliateOrganizationList && affiliateOrganizationList.length > 0) {
            let ownCompany = affiliateOrganizationList.find(x => x.idCompany === userProfile.idCompany);
            if (!ownCompany) {
                ownCompany = affiliateOrganizationList[0];
            }
            setSelectedCompany(ownCompany)
        }
    }, [userProfile, affiliateOrganizationList]);

    const fetchData = async () => {
        try {
            setIsFetching(true);
            const response = await getLeaveList({
                idCompany: selectedCompany.idCompany,
                start: dayjs(filter.start).format("YYYY-MM-DD"),
                end: dayjs(filter.end).format("YYYY-MM-DD"),
            });

            if (response) {
                setIsFetching(false);
                setLeaveList(response.data);
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (userProfile && selectedCompany && filter.start && filter.end) {
            fetchData();
        }
    }, [userProfile, selectedCompany, filter.start, filter.end]);

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleChangeAlertType = (newValue) => {
        setAlertType(newValue);
    };

    const handleSubmit = () => {
        if (LeaveList !== null) {
            const workbook = new ExcelJS.Workbook();
            const worksheet1 = workbook.addWorksheet(t("SummaryLeaveList"));

            const headerRow = worksheet1.addRow([
                t("EmployeeID"),
                t("FirstName"),
                t("LastName"),
                t("Position"),
                t("Company"),
                t("Division"),
                t("Department"),
                t("Section"),
                t("LeaveTypes"),
                t("StartDate"),
                t("EndDate"),
                t("TotalLeaveDays"),
                t("RemainingLeaveEntitlement"),
                t("Note"),
            ]);

            headerRow.height = 50;

            const headerStyle1 = {
                font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
                alignment: { horizontal: "center", vertical: 'middle' },
                fill: {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '002060' }
                },
            }

            headerRow.eachCell((cell) => {
                cell.style = headerStyle1;
            });

            const colWidths = [
                { key: "employeeID", width: 30 },
                { key: "firstname", width: 30 },
                { key: "lastname", width: 30 },
                { key: "position", width: 30 },
                { key: "company", width: 30 },
                { key: "division", width: 30 },
                { key: "department", width: 30 },
                { key: "section", width: 30 },
                { key: "leavetype", width: 30 },
                { key: "start", width: 30 },
                { key: "end", width: 30 },
                { key: "used", width: 20 },
                { key: "remaining", width: 20 },
                { key: "description", width: 50 },
            ];

            LeaveList.forEach((item) => {
                const row = [
                    item.employeeID ? item.employeeID : "-",
                    item.firstname_TH ? item.firstname_TH : "-",
                    item.lastname_TH ? item.lastname_TH : "-",
                    item.positionName ? item.positionName : "-",
                    item.companyName ? item.companyName : "-",
                    item.divisionName ? item.divisionName : "-",
                    item.departmentName ? item.departmentName : "-",
                    item.sectionName ? item.sectionName : "-",
                    item.name ? item.name : "-",
                    item.startDate ? item.startDate : "-",
                    item.endDate ? item.endDate : "-",
                    item.used ? item.used : "-",
                    item.remaining ? item.remaining : "-",
                    item.description ? item.description : "-"
                ];

                const excelRow = worksheet1.addRow(row);
                const contentStyle = {
                    font: { size: 18, name: 'TH SarabunPSK' },
                    alignment: { horizontal: "center", vertical: 'middle' },
                };

                colWidths.forEach((col, index) => {
                    worksheet1.getColumn(index + 1).width = col.width;
                });

                excelRow.eachCell((cell) => {
                    cell.style = contentStyle;
                    cell.border = {
                        top: { style: 'thin', color: { argb: '000000' } },
                        left: { style: 'thin', color: { argb: '000000' } },
                        bottom: { style: 'thin', color: { argb: '000000' } },
                        right: { style: 'thin', color: { argb: '000000' } }
                    };
                });
            })

            workbook.xlsx.writeBuffer().then((buffer) => {
                const blob = new Blob([buffer], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                const url = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = `${t("SummaryLeaveList")}.xlsx`;
                a.click();
                URL.revokeObjectURL(url);
            });
        } else {
            handleChangeAlertType(t("NoData"));
            handleOpenAlert(true);
        }
    }

    return (
        <DrawerCustom
            title={t("SummaryLeaveList")}
            anchor="right"
            open={open}
            onClose={handleClose}
        >
            <StyledRoot style={{ width: 400 }}>
                <Grid container spacing={2} style={{ marginBottom: 16 }}>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingRight: "16px",
                        }}
                    >
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">{t("Company")}</Typography>
                        <SelectAffiliateCompany
                            options={affiliateOrganizationList}
                            value={selectedCompany}
                            onChange={(_, value) => {
                                onChangeCompany(value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">
                            {t("StartDate")}
                        </Typography>
                        <DatePickerCustom
                            inputFormat="D MMM YYYY"
                            value={filter.start}
                            views={["year", "month", "day"]}
                            onChange={(newValue) => {
                                if(dayjs(filter.end).diff(dayjs(newValue), 'day') > 62){
                                    setFilter(prev => ({
                                        ...prev,
                                        start: newValue,
                                        end: dayjs(newValue).add(60, 'day'),
                                    }));
                                    } else {
                                    setFilter(prev => ({
                                        ...prev,
                                        start: newValue
                                    }));
                                    }
                            }}
                            inputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">
                            {t("EndDate")}
                        </Typography>
                        <DatePickerCustom
                            minDate={filter.start}
                            maxDate={dayjs(filter.start).add(62, 'day')}
                            inputFormat="D MMM YYYY"
                            value={filter.end}
                            views={["year", "month", "day"]}
                            onChange={(newValue) => {
                                setFilter(prev => ({
                                    ...prev,
                                    end: newValue
                                }));
                            }}
                            inputProps={{ readOnly: true }}
                        />
                    </Grid>
                </Grid>
                <StyledFooter>
                    <ButtonBlue className="cancel" onClick={handleClose}>
                        {t("Cancel")}
                    </ButtonBlue>
                    <ButtonBlue
                        variant="outlined"
                        startIcon={<DownloadRoundedIcon />}
                        onClick={handleSubmit}
                        disabled={selectedCompany === null || isFetching}
                    >
                        {t("Download")}
                    </ButtonBlue>
                </StyledFooter>
            </StyledRoot>
            <AlertResponse
                open={openAlert}
                handleClose={handleCloseAlert}
                alertType={alertType}
            />
        </DrawerCustom>
    )
}

export default LeaveList;