import dayjs from 'dayjs';
import 'dayjs/locale/th';
import ExcelJS from 'exceljs';
function convertToThaiDate(date) {
    const thaiDay = date.locale('th').format("DD");
    const thaiMonth = date.locale('th').format("MMMM");
    const year = date.format('YYYY');
    const thaiYear = parseInt(year) + 543;
    const thaiDate = `${thaiDay + " " + thaiMonth + " " + thaiYear}`;

    return thaiDate;
}

export const PersonalWorkingTime = (t, data, leaveData, date) => {
    try {
        const workbook = new ExcelJS.Workbook();
        const worksheet1 = workbook.addWorksheet(t("SummaryLeaveList"));

        const dateRange = [];
        let currentDate = dayjs(date.start);
        let endDate = dayjs(date.end);

        while (currentDate.isSameOrBefore(endDate, 'day')) {
            dateRange.push({ date: currentDate.format("YYYY-MM-DD"), thDate: convertToThaiDate(currentDate) });
            currentDate = currentDate.add(1, 'day');
        }

        const leaveTypeNames = leaveData && leaveData.map(leaveType => leaveType.leaveGroupName);

        const headerRow = worksheet1.addRow([
            t("EmployeeID"),
            t("FirstName"),
            t("LastName"),
            t("Position"),
            t("Company"),
            t("Division"),
            t("Department"),
            t("Section"),
            t("StartDate"),
            t("EndDate"),
            t("TotalWorkingDays"),
            t("PercentageOfWorkingDays"),
            t("TotalWorkingHours"),
            t("TotalWorkingHoursByEmp"),
            t("TotalLeaveHours"),
            t("TotalOvertimeHours"),
            ...dateRange.map((item) => item.thDate),
            t("Absent"),
            t("Late"),
            t("LeaveEarly"),
            ...leaveTypeNames
        ]);

        headerRow.height = 50;

        const headerStyle1 = {
            font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
            alignment: { horizontal: "center", vertical: 'middle' },
            fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '002060' }
            },
        }

        const headerStyleRed = {
            font: { bold: false, size: 18, name: 'TH SarabunPSK', color: { argb: '9F0A10' } },
            alignment: { horizontal: "center", vertical: 'middle' },
            fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFC7CE' }
            },
        }

        const headerStyleGreen = {
            font: { bold: false, size: 18, name: 'TH SarabunPSK', color: { argb: '006100' } },
            alignment: { horizontal: "center", vertical: 'middle' },
            fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'C6EFCE' }
            },
        }

        headerRow.eachCell((cell) => {
            cell.style = headerStyle1;
        });

        const leaveTypeColWidths = [];
        leaveData && leaveData.forEach(leaveType => {
            leaveTypeColWidths.push({
                key: `leaveType_${leaveType.idLeaveGroup}`,
                width: leaveType.leaveGroupName.length * 2
            });
        });

        const colWidths = [
            { key: "employeeID", width: 30 },
            { key: "firstname", width: 30 },
            { key: "lastname", width: 30 },
            { key: "position", width: 40 },
            { key: "company", width: 40 },
            { key: "division", width: 40 },
            { key: "department", width: 40 },
            { key: "section", width: 40 },
            { key: "start", width: 30 },
            { key: "end", width: 30 },
            { key: "totalWorkingDays", width: 30 },
            { key: "%work", width: 20 },
            { key: "totalWorkingHour", width: 40 },
            { key: "TotalWorkingHoursByEmp", width: 40 },
            { key: "totalLeaveHour", width: 30 },
            { key: "totalOTHour", width: 30 },
            ...dateRange.map(date => ({ key: date, width: 40 })),
            { key: "absent", width: 20 },
            { key: "late", width: 20 },
            { key: "early", width: 20 },
            ...leaveTypeColWidths
        ];

        data.map(employee => {

            const preSchelduleDate = [];

            // 1 - 31
            // 16 - 31
            let dateDiffPreSchelduleDate = 0;

            if(employee.schedule.length > 0 && dayjs(employee.schedule[0].date).isAfter(dayjs(date.start).hour(0).minute(0).second(0).millisecond(0), 'day')){
                dateDiffPreSchelduleDate = Math.ceil(dayjs(employee.schedule[0].date).diff(dayjs(date.start).hour(0).minute(0).second(0).millisecond(0), 'day'));
            } else if (employee.schedule.length === 0) {
                dateDiffPreSchelduleDate = dayjs(date.end).diff(dayjs(date.start).hour(0).minute(0).second(0).millisecond(0), 'day') + 1;
            }

            for (let i=0; i < dateDiffPreSchelduleDate; i++){
                preSchelduleDate.push({
                    date: dayjs(date.start).add(i, 'day'),
                    isCompletedInOut: false,
                })
            }

            const row = [
                employee.employeeID || "",
                employee.firstname_TH || "",
                employee.lastname_TH || "",
                employee.positionName || "",
                employee.companyName || "",
                employee.divisionName || "",
                employee.departmentName || "",
                employee.sectionName || "",
                convertToThaiDate(date.start) || "",
                convertToThaiDate(date.end) || "",
                Math.round((Number(employee.workDayTotal|| 0)*100))/100,
                Math.round((Number(employee.workDayPercentage * 100 || 0)*100))/100 + "%",
                Math.round((Number(employee.workHourTotal || 0)*100))/100,
                Math.round((Number(employee.workHourActualTotal || 0)*100))/100,
                Math.round((Number(employee.leaveHourTotal || 0)*100))/100,
                Math.round((Number(employee.otHourTotal || 0)*100))/100,
                ...([
                    ...preSchelduleDate.map(() => t("IncompleteTimeEntry")),
                    ...employee.schedule.map(x => {
                    if(x.isLeave){
                        return x.leaveGroupName;
                    } else if (x.isHoliday){
                        if(x.isCompletedInOut){
                            return t("CompleteTimeEntry")
                        } else {
                            return t("PublicHoliday")
                        }
                    } else if (x.isDayOff){
                        if(x.isCompletedInOut){
                            return t("CompleteTimeEntry")
                        } else {
                            return t("DayOff")
                        }
                    } else if (x.isCompletedInOut){
                        return t("CompleteTimeEntry")
                    } else {
                        return t("IncompleteTimeEntry")
                    }
                })]),
                employee.absent || "0",
                employee.late || "0",
                employee.earlyBack || "0",
                ...leaveData.map(leave => {
                    return employee.leaveUsed[leave.idLeaveGroup]? Math.round((Number(employee.leaveUsed[leave.idLeaveGroup] || 0)*100))/100: "0"
                }),
            ]

            const excelRow = worksheet1.addRow(row);
            const contentStyle = {
                font: { size: 18, name: 'TH SarabunPSK' },
                alignment: { horizontal: "center", vertical: 'middle' },
            };

            colWidths.forEach((col, index) => {
                worksheet1.getColumn(index + 1).width = col.width;
            });

            excelRow.eachCell((cell, cellNumber) => {
                if (cellNumber > 14 && cellNumber <= (dateRange.length + 16)) {
                    if (cell.value == t("CompleteTimeEntry")) {
                        cell.style = headerStyleGreen;
                        cell.border = {
                            top: { style: 'thin', color: { argb: '000000' } },
                            left: { style: 'thin', color: { argb: '000000' } },
                            bottom: { style: 'thin', color: { argb: '000000' } },
                            right: { style: 'thin', color: { argb: '000000' } }
                        };

                    } else if (cell.value == t("IncompleteTimeEntry")) {
                        cell.style = headerStyleRed;
                        cell.border = {
                            top: { style: 'thin', color: { argb: '000000' } },
                            left: { style: 'thin', color: { argb: '000000' } },
                            bottom: { style: 'thin', color: { argb: '000000' } },
                            right: { style: 'thin', color: { argb: '000000' } }
                        };
                    } else {
                        cell.style = contentStyle;
                        cell.border = {
                            top: { style: 'thin', color: { argb: '000000' } },
                            left: { style: 'thin', color: { argb: '000000' } },
                            bottom: { style: 'thin', color: { argb: '000000' } },
                            right: { style: 'thin', color: { argb: '000000' } }
                        };
                    }
                } else {
                    cell.style = contentStyle;
                    cell.border = {
                        top: { style: 'thin', color: { argb: '000000' } },
                        left: { style: 'thin', color: { argb: '000000' } },
                        bottom: { style: 'thin', color: { argb: '000000' } },
                        right: { style: 'thin', color: { argb: '000000' } }
                    };
                }
            });
        })

        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `R001 ${t("WorkingTimeReport")}.xlsx`;
            a.click();
            URL.revokeObjectURL(url);
        });
    } catch (error) {
        console.error(error);
    };
};