import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DownloadIcon from "@mui/icons-material/Download";
import { useDispatch, useSelector } from "react-redux";
import { getAdditionSpecial2 } from "../../../../../../actions/additionSpecial";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import AS2Form from "./AS2Form";
import additionSepecialService from "../../../../../../services/additionSepecial.service";
import DrawerDownload from "./drawerDownload";
import { openNotificationAlert } from "../../../../../../actions/notificationAlert";
import { getUserPosition } from "../../../../../../utils/userData";

const AS2 = ({ selectedCompany }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { result: additionSpecial } = useSelector(
    (state) => state.additionSpecial2
  );
  const [openEdit, setOpenEdit] = useState(false);
  const [selectAddition, setSelectAddition] = useState(null);
  const [openDownload, setOpenDownload] = useState(false);

  const handleOpenDownload = () => {
    setOpenDownload(true);
  };

  const handleCloseDownload = () => {
    setOpenDownload(false);
  };

  const handleOpenAdd = () => {
    setOpenEdit(true);
  };

  const handleOpenEdit = (item) => {
    setSelectAddition(item);
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setSelectAddition(null);
    setOpenEdit(false);
  };

  const handleDownload = async (data) => {
    const res = await additionSepecialService.exportExcelAdditionSpecial2({
      idCompany: selectedCompany.idCompany,
      searchDate: dayjs(data.searchDate).format("YYYY-MM-DD"),
      idAdditionSpecial2: data.additionSpecial.idAdditionSpecial2,
    });

    if (res && res.status === 200) {
      const excelBlob = new Blob([res.data], {
        type: res.headers["content-type"],
      });
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(excelBlob);

      downloadLink.download = `${data.additionSpecial.name} ${
        selectedCompany.companyName_EN
      } ${res.headers["content-disposition"]
        .split("filename")[1]
        .slice(2, -1)}`;

      downloadLink.click();
    } else {
      dispatch(openNotificationAlert({ message: "error", type: "error" }));
    }
  };

  const handleCalculateAdditionSpecialConditionTime = async () => {
    const res = await additionSepecialService.calculateAdditionSpecialConditionTime({
      idCompany: selectedCompany.idCompany,
      month: "7",
      year: "2024"
    });
  }

  useEffect(() => {
    if (selectedCompany) {
      dispatch(getAdditionSpecial2(selectedCompany.idCompany));
      handleCloseEdit();
    }
  }, [selectedCompany.idCompany]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        minHeight={"40px"}
        marginBottom={"24px"}
      >
        <Typography
          sx={{
            paddingLeft: "8px",
            borderLeft: "8px solid #46cbe2",
            borderTopLeftRadius: "4px",
            borderBottomLeftRadius: "4px",
          }}
          fontSize="20px"
          fontWeight="500"
        >
          {`${t("TimeConditions")} (AS2)`}
        </Typography>
        {!openEdit && (
          <div style={{ display: "flex", gap: "8px" }}>
            <IconButton
              onClick={handleOpenAdd}
              sx={{
                color: "#ffffff",
                backgroundColor: "#1976d2",
                "&:hover": { backgroundColor: "#145ea8" },
              }}
            >
              <AddIcon />
            </IconButton>
            <IconButton
              onClick={handleOpenDownload}
              sx={{
                color: "#ffffff",
                backgroundColor: "#1976d2",
                "&:hover": { backgroundColor: "#145ea8" },
              }}
            >
              <DownloadIcon />
            </IconButton>
            <IconButton
              onClick={handleCalculateAdditionSpecialConditionTime}
              sx={{
                color: "#ffffff",
                backgroundColor: "#1976d2",
                "&:hover": { backgroundColor: "#145ea8" },
              }}
            >
              <DownloadIcon />
            </IconButton>
          </div>
        )}
      </Stack>
      {openEdit && (
        <AS2Form
          handleClose={handleCloseEdit}
          selectedCompany={selectedCompany}
          selectAddition={selectAddition}
        />
      )}
      {!openEdit &&
        additionSpecial &&
        additionSpecial.map((addition) => (
          <Grid
            container
            spacing={2}
            paddingBottom={2}
            marginTop={2}
            borderRadius={"8px"}
            bgcolor={"#e7fbff"}
            key={addition.idAdditionSpecial2}
            style={{
              padding: "16px",
              borderRadius: "8px",
              backgroundColor: "#f2f2f2",
            }}
          >
            <Grid item xs={12} display={"flex"} gap={"8px"}>
              <Typography variant="h5">{addition.name}</Typography>
              <IconButton
                onClick={() => handleOpenEdit(addition)}
                sx={{
                  color: "#ffffff",
                  backgroundColor: "#ffb74d",
                  "&:hover": { backgroundColor: "#ffa726" },
                }}
                size="small"
              >
                <EditIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography fontWeight={500} fontSize={18}>
                {t("EmployeeType")}
              </Typography>
              <Box>
                {addition.employmentTypes.length === 0 ? (
                  <Typography>{t("AllEmployeeType")}</Typography>
                ) : (
                  addition.employmentTypes.map((item) => (
                    <Typography key={item.idEmploymentType}>
                      {item.employmentTypeName}
                    </Typography>
                  ))
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography fontWeight={500} fontSize={18}>
                {t("Position")}
              </Typography>
              <Box>
                {addition.positions.length === 0 ? (
                  <Typography>{t("AllPosition")}</Typography>
                ) : (
                  addition.positions.map((item) => (
                    <Typography key={item.idPosition}>
                      {getUserPosition(item)}
                    </Typography>
                  ))
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography fontWeight={500} fontSize={18}>
                {t("GroupLevel")}
              </Typography>
              <Box>
                {addition.groupLevels.length === 0 ? (
                  <Typography>{t("AllGroupLevel")}</Typography>
                ) : (
                  addition.groupLevels.map((item) => (
                    <Typography key={item.idGroupLevel}>
                      {item.groupLevelName}
                    </Typography>
                  ))
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography fontWeight={500} fontSize={18} marginBottom={2}>
                {t("Condition")}
              </Typography>
              <TableContainer component={Paper} sx={{ width: 400 }}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("StartTime")}</TableCell>
                      <TableCell align="right">{t("Unit.Minute")}</TableCell>
                      <TableCell align="right">{t("AmountOfMoney")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {addition.times.map((row) => (
                      <TableRow
                        key={row.idAdditionSpecial2Time}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.starTime
                            ? dayjs(row.starTime, "HH:mm:ss").format("HH:mm")
                            : t("NoWorkingHours")}
                        </TableCell>
                        <TableCell align="right">{row.minutes}</TableCell>
                        <TableCell align="right">{row.amount}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        ))}
      {additionSpecial && openDownload && (
        <DrawerDownload
          openDownload={openDownload}
          handleDownload={handleDownload}
          handleCloseDownload={handleCloseDownload}
          additionSpecial={additionSpecial}
          idCompareOption={"idAdditionSpecial2"}
        />
      )}
    </>
  );
};

export default AS2;
