import React, { Fragment, useEffect, useState } from "react";
import { Box, Grid, Skeleton, styled, Typography } from "@mui/material";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getDiligenceInfoByIdEmployees } from "../../../../../../actions/employee";
import { getDayjsFormatWithTranslate } from "../../../../../../utils";
import dayjs from "dayjs";
import DrawerEditDiligenceAccumulateCustom from "./drawerEditDiligenceAccumulateCustom";

const StyledRoot = styled(Box)({

})

const DiligenceInfo = () => {


  const { result: EmployeeDiligenceInfo, isFetching: isFetchingEmployeeDiligenceInfo } = useSelector((state) => state.employeeDiligenceInfo);
  const { result: employeeProfile } = useSelector((state) => state.employeeProfile);

  const [drawerEditDiligenceAccumulateCustomConfig, setDrawerEditDiligenceAccumulateCustomConfig] = useState({
    isOpen: false,
    data: {}
  })

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const handleOpenDrawerEditDiligenceAccumulateCustom = () => {
    setDrawerEditDiligenceAccumulateCustomConfig((prev) => ({
      ...prev,
      isOpen: true,
      data: {
        idEmployeeDiligenceAccumulateCustom:
          EmployeeDiligenceInfo.diligenceAccumulateCustom
            ? EmployeeDiligenceInfo.diligenceAccumulateCustom
                .idEmployeeDiligenceAccumulateCustom
            : null,
        accumulateMonthInRow:
          EmployeeDiligenceInfo.diligenceAccumulateCustom
            ? EmployeeDiligenceInfo.diligenceAccumulateCustom
                .accumulateMonthInRow
            : "",
      },
    }));
  }

  const handleCloseDrawerEditDiligenceAccumulateCustom = () => {
    setDrawerEditDiligenceAccumulateCustomConfig((prev) => ({
      ...prev,
      isOpen: false,
    }))
  }

  useEffect(() => {
    dispatch(getDiligenceInfoByIdEmployees(employeeProfile.idEmployees))
  }, [dispatch, employeeProfile])

  return (
    <StyledRoot>
      <Grid container spacing={2}>
        {!isFetchingEmployeeDiligenceInfo? <Fragment>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Typography flexGrow="1" fontSize="14px" fontWeight="600">ข้อมูลเบี้ยขยัน</Typography>
              <ButtonBlue
                variant="text"
                size="small"
                startIcon={<EditIcon />}
                onClick={handleOpenDrawerEditDiligenceAccumulateCustom}
              >
                {t("Edit")}
              </ButtonBlue>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize="14px" fontWeight="600" color="text.secondary" gutterBottom>จำนวนครั้งต่อเนื่องที่ได้รับเบี้ยขยันครั้งล่าสุด</Typography>
            {EmployeeDiligenceInfo.lastestDiligenceDetail? (
              <Box display="flex" alignItems="flex-end">
                <Typography fontSize="18px" lineHeight={1} fontWeight="600">{EmployeeDiligenceInfo.lastestDiligenceDetail.accumulateMonthInRow || 0}</Typography>
                <Typography paddingLeft="8px" fontSize="14px" lineHeight={1} fontWeight="600" color="text.secondary">(รอบจ่ายเดือน: {getDayjsFormatWithTranslate(dayjs(EmployeeDiligenceInfo.lastestDiligenceDetail.monthPeriod), "MMMM YYYY", i18n.language)})</Typography>
              </Box>
            ): (
              <Typography paddingLeft="8px" fontSize="14px" lineHeight={1} fontWeight="600" color="text.secondary">ไม่มี</Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize="14px" fontWeight="600" color="text.secondary" gutterBottom>กำหนดจำนวนครั้งสำหรับการคำนวณเบี้ยขยันครั้งถัดไป</Typography>
            <Typography fontSize="18px" fontWeight="600">{EmployeeDiligenceInfo.diligenceAccumulateCustom? `${EmployeeDiligenceInfo.diligenceAccumulateCustom.accumulateMonthInRow} (${EmployeeDiligenceInfo.diligenceAccumulateCustom.amount} ${t("Baht")})`: "ไม่ได้กำหนด"}</Typography>
          </Grid>
        </Fragment>
        : <Fragment>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Skeleton fontSize="14px" width="250px"/>
              <Skeleton fontSize="14px" width="150px"/>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Skeleton fontSize="14px" width="100px"/>
            <Skeleton fontSize="14px" width="180px"/>
          </Grid>
          <Grid item xs={12}>
            <Skeleton fontSize="14px" width="100px"/>
            <Skeleton fontSize="14px" width="180px"/>
          </Grid>
        </Fragment>}
      </Grid>

      <DrawerEditDiligenceAccumulateCustom
        drawerConfig={drawerEditDiligenceAccumulateCustomConfig}
        handleClose={handleCloseDrawerEditDiligenceAccumulateCustom}
      />
    </StyledRoot>
  )

}

export default DiligenceInfo;