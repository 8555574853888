import dayjs from "dayjs";
import "dayjs/locale/th";
import ExcelJS from "exceljs";

export const probationXlsxFile = async (t, data) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet1 = workbook.addWorksheet(t("Probation"));

  const headerRow = worksheet1.addRow([
    t("EmployeeID"),
    t("FirstName"),
    t("LastName"),
    t("Position"),
    t("Company"),
    t("Division"),
    t("Department"),
    t("Section"),
    t("HiringDate"),
    `${t("ProbationaryExpirationDate")} 1`,
    `${t("ProbationResult")}${t("RoundNo")} 1`,
    `${t("Details")}${t("RoundNo")} 1`,
    `${t("EvaluationRoundSupervisor")} 1`,
    `${t("EvaluationRoundDate")} 1`,
    `${t("ProbationaryExpirationDate")} 2`,
    `${t("ProbationResult")}${t("RoundNo")} 2`,
    `${t("Details")}${t("RoundNo")} 2`,
    `${t("EvaluationRoundSupervisor")} 2`,
    `${t("EvaluationRoundDate")} 2`,
  ]);

  const headerStyle = {
    font: {
      bold: true,
      size: 18,
      name: "TH SarabunPSK",
      color: { argb: "FFFFFF" },
    },
    alignment: { horizontal: "center", vertical: "middle" },
    fill: {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "002060" },
    },
  };

  headerRow.eachCell((cell) => {
    cell.style = headerStyle;
    cell.border = {
      top: { style: "thin", color: { argb: "000000" } },
      left: { style: "thin", color: { argb: "000000" } },
      bottom: { style: "thin", color: { argb: "000000" } },
      right: { style: "thin", color: { argb: "000000" } },
    };
  });

  headerRow.height = 50;

  let colWidths = [
    { key: "employeeID", width: 30 },
    { key: "firstname_TH", width: 30 },
    { key: "lastname_TH", width: 30 },
    { key: "position", width: 30 },
    { key: "company", width: 30 },
    { key: "division", width: 30 },
    { key: "department", width: 30 },
    { key: "section", width: 30 },
    { key: "hiringDate", width: 40 },
    { key: "probationDate", width: 40 },
    { key: "result", width: 40 },
    { key: "description", width: 70 },
    { key: "assessor", width: 40 },
    { key: "evaluationDate", width: 30 },
    { key: "probationDate", width: 40 },
    { key: "result", width: 40 },
    { key: "description", width: 70 },
    { key: "assessor", width: 40 },
    { key: "evaluationDate", width: 30 },
  ];

  data.forEach((item) => {
    const hiringDate =
      dayjs(item.hiringDate).locale("th").format("DD MMMM YYYY") || "-";

    const p1 = item.probation.find((p) => p.probationRound === 1);
    const p2 = item.probation.find((p) => p.probationRound === 2);
    const probationR1Others = p1 && p1.others ? p1.others : "";
    const probationR2Others = p2 && p2.others ? p2.others : "";
    const probationR1Date = p1
      ? dayjs(p1.probationDate).locale("th").format("DD MMMM YYYY")
      : "";
    const probationR2Date = p2
      ? dayjs(p2.probationDate).locale("th").format("DD MMMM YYYY")
      : "";

    let scoreR1 = "";
    let resultR1 = "";
    let probationR1EvaluateDate = "";
    let assessorR1Fullname = "";

    if (p1) {
      if (p1.adminEvaluateDate) {
        probationR1EvaluateDate = dayjs(p1.adminEvaluateDate).locale("th").format("DD MMMM YYYY");
        assessorR1Fullname = "ผู้ดูแลระบบ";
      } else if (p1.managerEvaluateDate) {
        probationR1EvaluateDate = dayjs(p1.managerEvaluateDate).locale("th").format("DD MMMM YYYY");
        assessorR1Fullname = p1.approvedBy;
      } else {
        probationR1EvaluateDate = "";
      }

      const result = p1.adminResult ? p1.adminResult : p1.managerResult;
      if (result === 1) {
        resultR1 = t("Pass");
      } else if (result === 0) {
        resultR1 = t("NotPass");
      } else {
        resultR1 = t("WaitingForEvaluation");
      }
    }

    let scoreR2 = "";
    let resultR2 = "";
    let probationR2EvaluateDate = "";
    let assessorR2Fullname = "";

    if (p2) {
      if (p2.adminEvaluateDate) {
        probationR2EvaluateDate = dayjs(p2.adminEvaluateDate).locale("th").format("DD MMMM YYYY");
        assessorR2Fullname = "ผู้ดูแลระบบ";
      } else if (p2.managerEvaluateDate) {
        probationR2EvaluateDate = dayjs(p2.managerEvaluateDate).locale("th").format("DD MMMM YYYY");
        assessorR2Fullname = p2.approvedBy;
      } else {
        probationR2EvaluateDate = "";
      }

      const result = p2.adminResult ? p2.adminResult : p2.managerResult;
      if (result === 1) {
        resultR2 = t("Pass");
      } else if (result === 0) {
        resultR2 = t("NotPass");
      } else {
        resultR2 = t("WaitingForEvaluation");
      }
    }

    const row = [
      item.employeeID ? item.employeeID : "-",
      item.firstname_TH ? item.firstname_TH : "-",
      item.lastname_TH ? item.lastname_TH : "-",
      item.positionName ? item.positionName : "-",
      item.companyName ? item.companyName : "-",
      item.divisionName ? item.divisionName : "-",
      item.departmentName ? item.departmentName : "-",
      item.sectionName ? item.sectionName : "-",
      hiringDate,
      probationR1Date,
      resultR1,
      probationR1Others,
      assessorR1Fullname,
      probationR1EvaluateDate,
      probationR2Date,
      resultR2,
      probationR2Others,
      assessorR2Fullname,
      probationR2EvaluateDate,
    ];

    const excelRow = worksheet1.addRow(row);

    colWidths.forEach((col, index) => {
      worksheet1.getColumn(index + 1).width = col.width;
    });

    excelRow.eachCell((cell, colNumber) => {
      cell.font = { size: 18, name: "TH SarabunPSK" };

      if (10 <= colNumber && colNumber <= 15) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FDE9D9" },
        };
      }
      if (16 <= colNumber && colNumber <= 21) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "E4DFEC" },
        };
      }

      cell.border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
    });
  });

  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${data && data[0].companyName}-${t("Probation")}.xlsx`;
    a.click();
    URL.revokeObjectURL(url);
  });
};
