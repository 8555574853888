import ExcelJS from 'exceljs';

export const LeaveListXlsx = (t,leaveEmployees, leaveType) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(t("SummaryLeaveList"));

    const LeaveTypeColumns = leaveType && leaveType.flatMap(i => ([
        { id: `${i.idLeaveType}_quota`, label: `${t("Quota")} ${i.name}` },
        { id: `${i.idLeaveType}_used`, label: `${i.name} ${t("Spent")}` },
        { id: `${i.idLeaveType}_remaining`, label: `${i.name} ${t("Remaining")}` },
    ]));

    const headerValues = [
        t("EmployeeID"),
        t("FirstName"),
        t("LastName"),
        t("Position"),
        t("Company"),
        t("Division"),
        t("Department"),
        t("Section"),
        ...LeaveTypeColumns.map(i => i.label)
    ];

    const headerRow = worksheet.addRow(headerValues);
    headerRow.height = 50;

    const headerStyle1 = {
        font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
        alignment: { horizontal: "center", vertical: 'middle' },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '002060' }
        },
    }

    headerRow.eachCell((cell) => {
        cell.style = headerStyle1;
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
        };
    });

    const colWidths = [
        { key: "employeeID", width: 30 },
        { key: "firstname", width: 30 },
        { key: "lastname", width: 30 },
        { key: "position", width: 30 },
        { key: "company", width: 30 },
        { key: "division", width: 30 },
        { key: "department", width: 30 },
        { key: "section", width: 30 },
        ...LeaveTypeColumns.flatMap((leaveType) => ([
            { key: `${leaveType.id}_quota`, width: 40 }, // 002060
            { key: `${leaveType.id}_used`, width: 40 }, // FF0000
            { key: `${leaveType.id}_remaining`, width: 40 }, // 00B050
        ]))
    ];

    // Set column widths
    colWidths.forEach((col, index) => {
        worksheet.getColumn(index + 1).width = col.width;
    });

    leaveEmployees && leaveEmployees.forEach((item) => {
        const row = [
            item.employeeID ? item.employeeID : "-",
            item.firstname_TH ? item.firstname_TH : "-",
            item.lastname_TH ? item.lastname_TH : "-",
            item.positionName ? item.positionName : "-",
            item.companyName ? item.companyName : "-",
            item.divisionName ? item.divisionName : "-",
            item.departmentName ? item.departmentName : "-",
            item.sectionName ? item.sectionName : "-",
        ];

        leaveType && leaveType.forEach(leave => {
            const matchedLeave = item.leaves && item.leaves.length > 0 && item.leaves.find(l => l.idLeaveType === leave.idLeaveType);
            const used = matchedLeave ? (Number.isInteger(matchedLeave.used) ? matchedLeave.used : matchedLeave.used.toFixed(2)) : '-';
            const remaining = matchedLeave ? (Number.isInteger(matchedLeave.remaining) ? matchedLeave.remaining : matchedLeave.remaining.toFixed(2)) : '-';
            const quota = matchedLeave ? matchedLeave.quota : '-';

            row.push(quota, used, remaining);
        });

        const excelRow = worksheet.addRow(row);

        const contentStyle = {
            font: { size: 18, name: 'TH SarabunPSK' },
            alignment: { horizontal: "center", vertical: 'middle' },
        };

        excelRow.eachCell((cell) => {
            cell.style = contentStyle;
            cell.border = {
                top: { style: 'thin', color: { argb: '000000' } },
                left: { style: 'thin', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'thin', color: { argb: '000000' } }
            };
        });
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${t("SummaryLeaveList")}.xlsx`;
        a.click();
        URL.revokeObjectURL(url);
    });
};
