import costCenterService from "../services/cost-center.service";

export const getCostCenterLists = async (query) => {
    try {
        const res = await costCenterService.getCostCenterlists(query);
        if (res) {
            return res;
        };
    } catch (error) {
        console.error(error);
    };
};

export const addCostCenterByIdEmployees = async (query) => {
    try {
        const res = await costCenterService.addCostCenterByIdEmployees(query);
        if (res) {
            return res;
        };
    } catch (error) {
        console.error(error);
    };
};

export const getCostCenterByEmployees = async (query) => {
    try {
        const res = await costCenterService.getCostCenterByEmployees(query);
        if (res) {
            return res;
        };
    } catch (error) {
        console.error(error);
    };
};
