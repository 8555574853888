import ExcelJS from 'exceljs';

export const CompensateXlsx = (t, data) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(t("ReportMoneyPaidOutsideThePayrollSystem"));

    const headerValues = [
        t("EmployeeID"),
        t("FirstName"),
        t("LastName"),
        t("Position"),
        t("Company"),
        t("Division"),
        t("Department"),
        t("Section"),
        t("TotalCompensatoryHoursEarned"),
        t("AccumulatedCompensatoryLeaveDays"),
    ];

    const headerRow = worksheet.addRow(headerValues);
    headerRow.height = 50;

    const headerStyle1 = {
        font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
        alignment: { horizontal: "center", vertical: 'middle' },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '002060' }
        },
    }

    headerRow.eachCell((cell) => {
        cell.style = headerStyle1;
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
        };
    });

    const colWidths = [
        { key: "employeeID", width: 30 },
        { key: "firstname", width: 30 },
        { key: "lastname", width: 30 },
        { key: "position", width: 30 },
        { key: "company", width: 30 },
        { key: "division", width: 30 },
        { key: "department", width: 30 },
        { key: "section", width: 30 },
        { key: "totalCompensate", width: 50 },
        { key: "CompensateLeaveAccumulated", width: 50 },
    ];

    // Set column widths
    colWidths.forEach((col, index) => {
        worksheet.getColumn(index + 1).width = col.width;
    });

    // function formatNumber(value) {
    //     if (value === null || value === undefined || isNaN(value)) {
    //         return "-";
    //     }
    //     return parseFloat(value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // };

    data && data.forEach((item) => {
        const row = [
            item.employeeID ? item.employeeID : "-",
            item.firstname_TH ? item.firstname_TH : "-",
            item.lastname_TH ? item.lastname_TH : "-",
            item.positionName ? item.positionName : "-",
            item.companyName ? item.companyName : "-",
            item.divisionName ? item.divisionName : "-",
            item.departmentName ? item.departmentName : "-",
            item.sectionName ? item.sectionName : "-",
            item.amountHour ? item.amountHour : "-",
            item.quota ? item.quota : "-"
        ];

        const excelRow = worksheet.addRow(row);

        const contentStyle = {
            font: { size: 18, name: 'TH SarabunPSK' },
            alignment: { horizontal: "center", vertical: 'middle' },
        };

        excelRow.eachCell((cell) => {
            cell.style = contentStyle;
            cell.border = {
                top: { style: 'thin', color: { argb: '000000' } },
                left: { style: 'thin', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'thin', color: { argb: '000000' } }
            };
        });
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${t("AccumulationOfCompensatoryTimeReport")}.xlsx`;
        a.click();
        URL.revokeObjectURL(url);
    });
};
