import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Grid,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import StarRateIcon from "@mui/icons-material/StarRate";
import { AccessTimeRounded } from "@mui/icons-material";
import ChipStatus from "../../../../shared/pages/okrs/components/ChipStatus";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import UpdateIcon from "@mui/icons-material/Update";
import EditNoteIcon from "@mui/icons-material/EditNote";
import KRDetailModal from "./KRDetailModal";
import EditKRModal from "./editKRModal";
import EditInfoKRModal from "./editInfoKRModal";
import GroupsIcon from "@mui/icons-material/Groups";
import ConstructionIcon from "@mui/icons-material/Construction";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ApproveModal from "../../../../manager/okrs/OKRManagementForManager/ApproveModal";

const StyledKRCard = styled(Box)({
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  borderRadius: "10px",
  backgroundColor: "#ffffff",
  padding: "16px",
  marginBottom: "16px",
});

const ProgressBar = styled(LinearProgress)({
  height: 10,
  borderRadius: 5,
  backgroundColor: "#f0f0f0",
  "& .MuiLinearProgress-bar": {
    borderRadius: 5,
    backgroundColor: "#DB4178",
  },
});

const CustomIconButton = styled(IconButton)({
  fontSize: "small",
  color: "#DB4178",
  border: "2px solid #DB4178",
  backgroundColor: "transparent",
  borderRadius: "7px",
  "&:hover": {
    color: "#C53B6C",
    border: "4px solid #C53B6C",
    backgroundColor: "transparent",
  },
});

const KeyResult = ({
  data,
  isEditable,
  isApprovable,
  approveTab,
  onObjectiveUpdated,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isEditInfoOpen, setIsEditInfoOpen] = useState(false);
  const [approveModalOpen, setApproveModalOpen] = useState(false);

  const { result: userProfile } = useSelector((state) => state.userProfile);

  const handleOpenDetail = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setIsDetailOpen(true);
  };

  const handleCloseDetail = () => {
    setIsDetailOpen(false);
  };

  const handleOpenEdit = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setIsEditOpen(true);
  };

  const handleCloseEdit = () => {
    setIsEditOpen(false);
  };

  const handleOpenEditInfo = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setIsEditInfoOpen(true);
  };

  const handleCloseEditInfo = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setIsEditInfoOpen(false);
  };

  const handleCardClick = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setIsDetailOpen(true);
  };

  const handleOpenApproveModal = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setApproveModalOpen(true);
  };

  const handleCloseApproveModal = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setApproveModalOpen(false);
  };

  const getStatusAchieveApprove = (data) => {
    if (data && data.isAchieve === 1) {
      return 5;
    } else if (data && data.isApprove === 0) {
      return 3;
    } else if (data && data.isApprove === null) {
      return 1;
    } else if (
      data &&
      data.isApprove === 1 &&
      data.isAchieve === null &&
      data.isSuccess === null
    ) {
      return 4;
    } else if (data && data.isSuccess === 1) {
      return 9;
    }
  };

  const progressPercent =
    data.progressPercent !== undefined && data.progressPercent !== null
      ? data.progressPercent
      : 0;

  const score =
    data.rating !== undefined && data.rating !== null
      ? data.rating
      : data.employeeRating !== undefined && data.employeeRating !== null
      ? data.employeeRating
      : 0;

  const startDateFormatted = data.startDate
    ? dayjs(data.startDate).format("DD/MM/YYYY")
    : "-";
  const endDateFormatted = data.endDate
    ? dayjs(data.endDate).format("DD/MM/YYYY")
    : "-";

  return (
    <StyledKRCard onClick={handleCardClick} style={{ cursor: "pointer" }}>
      <Grid container spacing={2} alignItems="center">
        {/* คอลัมน์ที่ 1: ชื่อ KR */}
        <Grid item xs={12} sm={3} md={3}>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="subtitle1">{data.keyResultName}</Typography>
            {Array.isArray(data.crossTeam) && data.crossTeam.length > 0 && (
              <GroupsIcon
                style={{
                  marginLeft: "8px",
                  color: "#DB4178",
                }}
              />
            )}
          </Box>
        </Grid>

        {/* คอลัมน์ที่ 2: ChipStatus */}
        <Grid item xs={12} sm={2} md={2}>
          <Typography variant="body2" style={{ fontWeight: "bold" }}>
            {t("Status")}:
          </Typography>
          <ChipStatus
            status={getStatusAchieveApprove({
              isSuccess: data.isSuccess,
              isAchieve: data.isAchieve,
              progressPercent: data.progressPercent,
              isApprove: data.isApprove,
            })}
            size="medium"
          />
        </Grid>

        {/* คอลัมน์ที่ 3: วันที่ */}
        <Grid item xs={12} sm={3} md={3}>
          <Typography variant="body2" style={{ fontWeight: "bold" }}>
            {t("Date")}:
          </Typography>
          <Box display="flex" alignItems="center" mt={0.5}>
            <AccessTimeRounded
              fontSize="small"
              style={{ marginRight: "8px", color: "#919eab" }}
            />
            <Typography variant="body2" color="textSecondary">
              {startDateFormatted} - {endDateFormatted}
            </Typography>
          </Box>
        </Grid>

        {/* คอลัมน์ที่ 4: ความก้าวหน้าและคะแนน */}
        <Grid item xs={12} sm={3} md={3}>
          <Box display="flex" flexDirection="column">
            {/* ความก้าวหน้าและ ProgressBar */}
            <Box display="flex" alignItems="center" width="100%">
              <Typography
                variant="body2"
                style={{ fontWeight: "bold", marginRight: "8px" }}
              >
                {t("Progress")}:
              </Typography>
              <Box flexGrow={1} mr={1}>
                <ProgressBar variant="determinate" value={progressPercent} />
              </Box>
              <Typography variant="body2" style={{ fontWeight: "bold" }}>
                {progressPercent}%
              </Typography>
            </Box>
            {/* คะแนนและ StarRateIcon */}
            <Box display="flex" alignItems="center" width="100%" mt={0.5}>
              <Typography
                variant="body2"
                style={{ fontWeight: "bold", marginRight: "8px" }}
              >
                {t("Score")}:
              </Typography>
              <Typography variant="body2">{score}</Typography>
              <StarRateIcon
                fontSize="small"
                style={{ color: "#FFC107", marginLeft: "4px" }}
              />
            </Box>

            {(data.isSuccess === 1 || data.isSuccess === 0) && (
              <Box mt={1}>
                <ChipStatus status={8} size="medium" />
              </Box>
            )}
          </Box>
        </Grid>

        {/* คอลัมน์ที่ 5: ปุ่มต่าง ๆ */}
        <Grid
          item
          xs={12}
          sm={1}
          md={1}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {(() => {
            if (
              (approveTab === 1 &&
                data.progressPercent === 100 &&
                data.isAchieve === null) ||
              (approveTab === 0 && isApprovable && data.isApprove === null)
            ) {
              // แสดง CheckCircleIcon
              return (
                <Tooltip title={t("Approved")}>
                  <CustomIconButton
                    aria-label="approvePlan"
                    onClick={handleOpenApproveModal}
                  >
                    <CheckCircleIcon />
                  </CustomIconButton>
                </Tooltip>
              );
            } else if (
              isEditable &&
              data.isApprove === null &&
              userProfile &&
              (userProfile.idRole === 1 || userProfile.idRole === 2)
            ) {
              // แสดง EditNoteIcon
              return (
                <Tooltip title={t("EditData")}>
                  <CustomIconButton
                    aria-label="editInfo"
                    onClick={handleOpenEditInfo}
                  >
                    <EditNoteIcon />
                  </CustomIconButton>
                </Tooltip>
              );
            } else if (
              isEditable &&
              data.isAchieve === null &&
              data.isApprove === 1 &&
              data.isSuccess === null
            ) {
              // แสดง EditIcon
              return (
                <Tooltip title={t("UpdateProgress")}>
                  <CustomIconButton aria-label="edit" onClick={handleOpenEdit}>
                    <UpdateIcon />
                  </CustomIconButton>
                </Tooltip>
              );
            } else {
              return null;
            }
          })()}
        </Grid>
      </Grid>

      {/* โมดอลต่าง ๆ */}
      <KRDetailModal
        open={isDetailOpen}
        handleClose={handleCloseDetail}
        data={data}
        getStatusAchieveApprove={getStatusAchieveApprove({
          isSuccess: data.isSuccess,
          isAchieve: data.isAchieve,
          progressPercent: data.progressPercent,
          isApprove: data.isApprove,
        })}
      />

      <EditKRModal
        open={isEditOpen}
        handleClose={handleCloseEdit}
        data={data}
        onObjectiveUpdated={onObjectiveUpdated}
      />

      <EditInfoKRModal
        open={isEditInfoOpen}
        handleClose={handleCloseEditInfo}
        data={data}
      />

      <ApproveModal
        open={approveModalOpen}
        handleClose={handleCloseApproveModal}
        data={data}
        approveTab={approveTab}
      />
    </StyledKRCard>
  );
};

export default KeyResult;
