import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, styled, Grid, IconButton, Divider } from "@mui/material";
import dayjs from "dayjs";

import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import ButtonBlue from "../../../../shared/general/ButtonBlue";

import utils from "../../../../../../utils";

//Translator TH-EN
import { useTranslation } from "react-i18next";

import DialogEdit from "./DialogEdit";
import DialogฺBank from "./DialogBank";
import DialogCostCenter from "./DialogCostCenter";
import DialogChangePayment from "./DialogChangePayment";
import DialogPaymentCurrency from "./DialogPaymentCurrency";
import { getCostCenterByEmployees } from "../../../../../../actions/cost-center";

const StyledWrapTop = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 16,
  "& .MuiTypography-body2": {
    margin: 0,
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledRoot = styled("div")({
  "& .salary": {
    "& .MuiTypography-h6": {
      marginRight: 8,
    },
    display: "flex",
    alignItems: "baseline",
  },
});

const StyledTextValue = styled(Typography)({
  fontSize: 18,
  "& i": {
    padding: 2,
  },
});

const TimePayment = (props) => {
  const { changeGroupTap, changeTabs } = props;
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [openDialog, setOpenDialog] = useState(false);
  const [mode, setMode] = useState("");
  const [showPersonalId, setShowPersonalId] = useState(false);
  const [costCenter, setCostCenter] = useState(null);
  const { t, i18n } = useTranslation();

  const [drawerBankConfig, setDrawerBankConfig] = useState({
    isOpen: false,
    isEdit: false,
    data: null,
  });

  const [drawerCostCenterConfig, setDrawerCostCenterConfig] = useState({
    isOpen: false,
    isEdit: false,
    data: null,
  });

  const [drawerPaymentCurrencyConfig, setDrawerPaymentCurrencyConfig] =
    useState({
      isOpen: false,
      isEdit: false,
      data: null,
    });

  const handleClick = (mode) => {
    setOpenDialog(true);
    setMode(mode);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const changeTimeToDate = (time) => {
    let splitTime = time.split(":");
    return new Date().setHours(splitTime[0], splitTime[1], splitTime[2]);
  };

  const calculateHours = (shift) => {
    let timeIn = dayjs(changeTimeToDate(shift.timeOut));
    return timeIn.diff(changeTimeToDate(shift.timeIn), "hour");
  };

  const handleClickPayment = () => {
    changeGroupTap("work");
    changeTabs(9);
  };

  const renderCalculate = () => {
    if (employeeProfile.shift.workingHours) {
      var hours = employeeProfile.shift.workingHours / 60;
      if (employeeProfile.shift.breakTime === 0) {
        hours = hours - 1;
      }
      return `${hours} ${t("Unit.ShortHours")}/${t("Unit.Days")}`;
    } else {
      return `${t("NoWorkingHours")}`;
    }
  };

  const fetchedEmployeeCostCenter = async () => {
    try {
      const response = await getCostCenterByEmployees({ idEmployees: employeeProfile && employeeProfile.idEmployees });
      if (response && response.data) {
        setCostCenter(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (employeeProfile) {
      fetchedEmployeeCostCenter();
    };
  }, [employeeProfile])

  return (
    <StyledRoot>
      <StyledWrapTop>
        <StyledHeadLabel variant="body2" gutterBottom>
          {t("WorkingTimeInfo")}
        </StyledHeadLabel>
      </StyledWrapTop>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("WorkingType")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.shift.workingTypeName
                ? employeeProfile.shift.workingTypeName.toUpperCase()
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("ShiftGroup")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.shift.shiftGroupName}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("ShiftName")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.shift.shiftName
                ? employeeProfile.shift.shiftName
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("WorkingDays")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.shift.workDay}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("WorkingTimePerDay")}
            </StyledHeadLabel>
            <Typography variant="h6">{renderCalculate()}</Typography>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("TimeIn")}-{t("TimeOut")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.shift &&
                employeeProfile.shift.timeIn &&
                employeeProfile.shift.timeOut
                ? `${employeeProfile.shift.timeIn.slice(
                  0,
                  -3
                )} - ${employeeProfile.shift.timeOut.slice(0, -3)}`
                : `${t("NoWorkingHours")}`}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <StyledDivider />
      <StyledWrapTop>
        <StyledHeadLabel variant="body2" gutterBottom></StyledHeadLabel>
        {!userProfile.readOnly && (
          <ButtonBlue
            variant="text"
            size="small"
            endIcon={<KeyboardArrowRightRoundedIcon />}
            onClick={handleClickPayment}
          >
            {t("ChangePayment")}
          </ButtonBlue>
        )}
      </StyledWrapTop>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("Salary")}
            </StyledHeadLabel>
            <div className="salary">
              <div
                className="wrap-show-hide-personalId"
                onMouseEnter={() => setShowPersonalId(true)}
                onMouseLeave={() => setShowPersonalId(false)}
              >
                {showPersonalId ? (
                  <StyledTextValue variant="h6" align="center">
                    {employeeProfile.salary
                      ? utils.numberWithCommas(employeeProfile.salary)
                      : "-"}
                  </StyledTextValue>
                ) : (
                  <StyledTextValue className="dot" variant="h6" align="center">
                    {[...Array(6).keys()].map((_, index) => (
                      // <i key={index} class="fa-sharp fa-solid fa-circle"></i>
                      <i key={index} class="fas fa-times"></i>
                      // <CloseRoundedIcon key={index} />
                    ))}
                  </StyledTextValue>
                )}
              </div>
              <Typography color="text.secondary">
                {employeeProfile.paymentTypeName === "รายวัน"
                  ? `${t("PerDay")}`
                  : `${t("PerMonth")}`}
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
      <StyledDivider />
      <StyledWrapTop>
        <StyledHeadLabel variant="body2" gutterBottom></StyledHeadLabel>
        {!userProfile.readOnly && (
          <ButtonBlue
            variant="text"
            size="small"
            startIcon={<EditIcon />}
            onClick={() => handleClick("payment")}
          // onClick={() => {
          //   setDrawerBankConfig((prev) => ({
          //     ...prev,
          //     isOpen: true,
          //     isEdit: true,
          //     data: {
          //       ...employeeProfile,
          //     },
          //   }));
          // }}
          >
            {t("EditData")}
          </ButtonBlue>
        )}
      </StyledWrapTop>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("PaymentType")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.paymentTypeName
                ? employeeProfile.paymentTypeName
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("PaymentRound")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.paymentRoundName
                ? employeeProfile.paymentRoundName
                : "-"}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <StyledDivider />
      <StyledWrapTop>
        <StyledHeadLabel variant="body2" gutterBottom>
          {t("PaymentInfo")}
        </StyledHeadLabel>
        {!userProfile.readOnly && (
          <ButtonBlue
            variant="text"
            size="small"
            startIcon={<EditIcon />}
            onClick={() => {
              setDrawerPaymentCurrencyConfig((prev) => ({
                ...prev,
                isOpen: true,
                isEdit: true,
                data: { paymentCurrency: employeeProfile.paymentCurrency },
              }));
            }}
          >
            {t("EditData")}
          </ButtonBlue>
        )}
      </StyledWrapTop>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("Currency")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.paymentCurrency}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <StyledDivider />
      <StyledWrapTop>
        <StyledHeadLabel variant="body2" gutterBottom>
          Cost Center
        </StyledHeadLabel>
        {!userProfile.readOnly && (
          <ButtonBlue
            variant="text"
            size="small"
            startIcon={<EditIcon />}
            // onClick={() => handleClick("cost")}
            onClick={() => {
              setDrawerCostCenterConfig((prev) => ({
                ...prev,
                isOpen: true,
                isEdit: true,
                data: {
                  ...employeeProfile,
                },
              }));
            }}
          >
            {t("EditData")}
          </ButtonBlue>
        )}
      </StyledWrapTop>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              Cost Center Charge
            </StyledHeadLabel>
            <Typography variant="h6">
              {costCenter && costCenter.costCenterName
                ? costCenter.costCenterName
                : "-"}
            </Typography>
          </div>
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              Cost Element Charge
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.costElementCharge
                ? employeeProfile.costElementCharge
                : "-"}
            </Typography>
          </div>
        </Grid> */}
      </Grid>
      <StyledDivider />
      <StyledWrapTop>
        <StyledHeadLabel variant="body2" gutterBottom>
          {t("BankInfo")}
        </StyledHeadLabel>
        {!userProfile.readOnly && (
          <ButtonBlue
            variant="text"
            size="small"
            startIcon={<EditIcon />}
            // onClick={() => handleClick("bank")}
            onClick={() => {
              setDrawerBankConfig((prev) => ({
                ...prev,
                isOpen: true,
                isEdit: true,
                data: {
                  ...employeeProfile,
                },
              }));
            }}
          >
            {t("EditData")}
          </ButtonBlue>
        )}
      </StyledWrapTop>
      <div>
        <StyledHeadLabel color="text.secondary">
          {t("BankName")}/{t("BranchName")}
        </StyledHeadLabel>
        <Typography variant="h6">
          {employeeProfile.bookBank ? employeeProfile.bookBank : "-"}{" "}
          {employeeProfile.bookBankBranchName
            ? `: ${employeeProfile.bookBankBranchName}`
            : ""}
        </Typography>
      </div>
      <div style={{ marginBottom: "16px" }}>
        <StyledHeadLabel color="text.secondary">{t("BookID")}</StyledHeadLabel>
        <Typography variant="h6">
          {employeeProfile.bookID ? employeeProfile.bookID : "-"}
        </Typography>
      </div>
      <StyledWrapTop>
        <StyledHeadLabel variant="body2" gutterBottom>
          {t("ForBankFile")}
        </StyledHeadLabel>
      </StyledWrapTop>
      <div>
        <StyledHeadLabel color="text.secondary">
          {t("ReportBankID")}
        </StyledHeadLabel>
        <Typography variant="h6">
          {employeeProfile.reportBankBankID
            ? employeeProfile.reportBankBankID
            : "-"}
        </Typography>
      </div>
      <div>
        <StyledHeadLabel color="text.secondary">
          {t("BankName")}
        </StyledHeadLabel>
        <Typography variant="h6">
          {employeeProfile.reportBankBankName
            ? employeeProfile.reportBankBankName
            : "-"}
        </Typography>
      </div>
      <div>
        <StyledHeadLabel color="text.secondary">
          {t("ReportBankID")}
        </StyledHeadLabel>
        <Typography variant="h6">
          {employeeProfile.reportBankBookBankID
            ? employeeProfile.reportBankBookBankID
            : "-"}
        </Typography>
      </div>
      <div>
        <StyledHeadLabel color="text.secondary">
          {t("ReportBankName")}
        </StyledHeadLabel>
        <Typography variant="h6">
          {employeeProfile.reportBankName
            ? employeeProfile.reportBankName
            : "-"}
        </Typography>
      </div>
      <div>
        <StyledHeadLabel color="text.secondary">
          {t("ReportBankRef")}
        </StyledHeadLabel>
        <Typography variant="h6">
          {employeeProfile.reportBankRef ? employeeProfile.reportBankRef : "-"}
        </Typography>
      </div>

      <DialogEdit
        mode={mode}
        open={openDialog}
        handleCloseDialog={handleCloseDialog}
      />

      <DialogฺBank
        drawerConfig={drawerBankConfig}
        onClose={() => {
          setDrawerBankConfig((prev) => ({
            ...prev,
            isOpen: false,
          }));
        }}
      />

      <DialogCostCenter
        drawerConfig={drawerCostCenterConfig}
        onClose={() => {
          setDrawerCostCenterConfig((prev) => ({
            ...prev,
            isOpen: false,
          }));
        }}
      />

      <DialogPaymentCurrency
        drawerConfig={drawerPaymentCurrencyConfig}
        onClose={() => {
          setDrawerPaymentCurrencyConfig((prev) => ({
            ...prev,
            isOpen: false,
          }));
        }}
      />
    </StyledRoot>
  );
};

export default TimePayment;
