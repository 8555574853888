import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Typography,
  Grid,
  IconButton,
  Badge,
  Avatar,
} from "@mui/material";
import { styled } from "@mui/system";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import { useDispatch } from "react-redux";
import ObjectiveCard from "../../../admin/okrs/OKRManagementForAdmin/components/ObjectiveCard";
import { getAllIndividualObjectiveByIdEmp } from "../../../../../actions/okrs";

const StyledCard = styled(Card)(({ expanded }) => ({
  padding: "16px",
  marginBottom: "16px",
  position: "relative",
  boxShadow: expanded ? "0px 4px 12px rgba(0, 0, 0, 0.15)" : "none",
  border: expanded ? "2px solid #F1A1A1" : "1px solid #E0E0E0",
  borderRadius: 10,
  backgroundColor: "#ffffff",
  transition: "all 0.3s ease",
}));

const EmployeeCard = ({ employee, idManager, approveTab }) => {
  const dispatch = useDispatch();
  const [objectives, setObjectives] = useState([]);
  const [isApprovable, setIsApprovable] = useState(false);

  useEffect(() => {
    if (idManager) {
      setIsApprovable(true);
    }
    if (objectives.length === 0) {
      dispatch(getAllIndividualObjectiveByIdEmp(employee.idEmployees)).then(
        (res) => {
          if (res.status === 200) {
            if (approveTab === 0) {
              const filteredObjectives = res.data.filter((objective) =>
                objective.keyResults.some(
                  (kr) => kr.isApprove === null && kr.idManager === idManager
                )
              );
              setObjectives(filteredObjectives);
            } else if (approveTab === 1) {
              const filteredObjectives = res.data.filter((objective) =>
                objective.keyResults.some(
                  (kr) => kr.isSuccess === 1 && kr.idManager === idManager
                )
              );
              setObjectives(filteredObjectives);
            }
          }
        }
      );
    }
  }, [
    dispatch,
    employee.idEmployees,
    idManager,
    approveTab,
    objectives.length,
  ]);

  return (
    <StyledCard>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12} sm={2} display="flex" justifyContent="center">
          <Avatar
            src={employee.imageProfile}
            alt={`${employee.firstname_TH} ${employee.lastname_TH}`}
            sx={{ width: 64, height: 64 }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {`${employee.firstname_TH} ${employee.lastname_TH}`}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {`Employee ID: ${employee.employeeID}`}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} justifyContent="left">
          <Typography variant="body1">{employee.positionName}</Typography>
        </Grid>
        <Grid item xs={12} sm={2} display="flex" justifyContent="center">
          <Badge
            badgeContent={
              approveTab === 0
                ? employee.WaitApproveOKRCount
                : employee.WaitApproveResultCount
            }
            color="primary"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{ marginRight: "16px" }}
          >
            <LibraryAddCheckIcon />
          </Badge>
        </Grid>
      </Grid>
      <Box mt={2} pl={2}>
        {objectives.map((objective) => (
          <ObjectiveCard
            key={objective.idIndividualObjective}
            objective={objective}
            isEditable={false}
            isApprovable={isApprovable}
            approveTab={approveTab}
          />
        ))}
      </Box>
    </StyledCard>
  );
};

export default EmployeeCard;
