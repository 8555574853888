import React, { useEffect, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import AdapterDateFns from "@tarzui/date-fns-be";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  Avatar,
  Container,
  Typography,
  Breadcrumbs,
  Grid,
  Box,
  TableContainer,
  Paper,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import CardStyle from "../../../shared/general/Card";
import CardData from "../../../shared/dashboard/CardData";
import SelectCompany from "../../shared/selectCompany";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { getAllEmployees, getEmployeesTaxDetails, getPFvalueAllYearByEmp } from "../../../../../actions/employee";
import ChartProvidentFund from "./ChartProvidentFund";
import { getAffiliateOrganization } from "../../../../../actions/company";
import SelectAffiliateCompany from "../../../shared/general/SelectAffiliateCompany";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DatePickerCustom from "../../../shared/date/datePicker";
import dayjs from "dayjs";
import AlertResponse from "../../../shared/general/AlertResponse";
import { PfPDFfile } from "../../../user/governmentReport/filePDFgeneration.js/PfPDFfile";
import { PVFxlsxFile } from "../../../user/governmentReport/fileXLSXgeneration.js/PVFxlsxFile";
import { getUserFullName } from "../../../../../utils/userData";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  paddingBottom: 10,
  paddingTop: 10,
  marginTop: 10,
});

const DivEmployeesList = styled("div")({
  width: "100%",
  height: "fit-content",
  display: "grid",
  marginBottom: 64,
  "& .header": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "transparent",
    border: "0",
    marginBottom: 16,
    "& .MuiTypography-root": {
      fontWeight: 500,
      "& span": {
        letterSpacing: 0.5,
      },
    },
  },
  "& .employee-list": {
    padding: 50,
    height: "fit-content",
    maxHeight: "520px",
    overflow: "auto",
    backgroundColor: "white",
    borderRadius: "16px",
    display: "flex",
    flexDirection: "row",
    gridTemplateRows: "repeat(autofill, minmax(130px, 1fr))",
    gridTemplateColumns: "repeat(auto-fill, minmax(120px, 1fr))",
    minWidth: "100%",
    boxSizing: "border-box",
    columnGap: 8,
    "& .employee-item": {
      display: "flex",
      height: "fit-content",
      justifyContent: "flex-start",
      flexDirection: "column",
      marginRight: "2px",
      alignItems: "center",
      minHeight: "105px",
      whiteSpace: "nowrap",
    },
    "& .MuiAvatar-root": {
      width: 72,
      height: 72,
      marginBottom: 8,
      alignItems: "center",
    },
    "& .MuiTypography-root": {
      textAlign: "center",
      overflow: "hidden",

      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    "& .div-text-end": {
      height: "60px",
      minHeight: "60px",
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "center",
    },
  },
});

const StyledDetailCard = styled("div")({
  padding: 24,
  paddingTop: 16,
  "& .part-header-detail": {
    paddingBottom: 24,
  },
});

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledBoxSearchButton = styled(Box)({
  marginTop: 30,
  display: "flex",
  justifyContent: "space-evenly",
  gap: 8,
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
      padding: "7px 4px",
    },
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      "& button": {
        color: "#919EAB",
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledWrapFilter = styled("div")({
  width: "100%",
  display: "flex",
  alignItems: "center",
  marginBottom: 36,
  ["@media (min-width: 0px)"]: {
    flexDirection: "column",
  },
  ["@media (min-width: 900px)"]: {
    flexDirection: "row",
  },
  "& .search-name, .search-date": {
    width: "100%",
  },
  "& .wrap-search-action": {
    marginTop: 50,
    display: "flex",
    justifyContent: "flex-start",
    "& .btn-export": {
      marginLeft: 8,
    },
  },
});



const DashboardProvidentFund = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: employeeList } = useSelector((state) => state.employees);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);

  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [inputSearch, setInputSearch] = useState(new Date());
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [providentData, setProvidentData] = useState(null);
  const [exportData, setExportData] = useState(null);
  const [noData, setNoData] = useState(true);
  const [chartData, setChartData] = useState({});
  const [JanSum, setJanSum] = useState(null)

  const handleOpenAlert = () => {
    setOpenAlert(true);
    setIsFetching(false);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const onChangeCompany = (newValue) => {
    setSelectedCompany(newValue);
  };

  useEffect(() => {
    dispatch(getAllEmployees());
    dispatch(getAffiliateOrganization());
  }, []);

  const fetchedDatatoExport = async () => {
    try {
      setNoData(true);
      const response = await getEmployeesTaxDetails(
        dayjs(inputSearch).format("MM"),
        dayjs(inputSearch).locale('th').format("YYYY"),
        10,
        null,
        "PVF",
        selectedCompany && selectedCompany.idCompany
      );

      if (response && response.data) {
        setNoData(false);
        setExportData(response.data);
      } else {
        // handleChangeAlertType(`${t("NoData")}`);
        // handleOpenAlert(true);
        setNoData(true);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const fetchedData = async () => {
    try {
      setIsFetching(true);
      const response = await getPFvalueAllYearByEmp(
        dayjs(inputSearch).locale('th').format("YYYY"),
        selectedCompany && selectedCompany.idCompany
      );

      if (response && response.data && response.data.employeeData && response.data.employeeData.length > 0) {
        setIsFetching(false);
        setChartData(response.data.monthSummary);
        setProvidentData(response.data.employeeData);
        setJanSum(response.data.JanSummary);
      } else {
        handleChangeAlertType(`${t("NoData")}`);
        handleOpenAlert(true);
        setIsFetching(false);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (inputSearch && selectedCompany && selectedCompany.idCompany) {
      fetchedData();
    } else {
      setIsFetching(false);
    }
  }, [inputSearch, selectedCompany]);

  useEffect(() => {
    if (inputSearch && selectedCompany && selectedCompany.idCompany) {
      fetchedDatatoExport();
    } else {
      setNoData(false);
    }
  }, [inputSearch, selectedCompany]);

  const DownloadFile = async (type, selectedYear, selectedMonth, fileType) => {
    if (fileType === "PDF") {
      PfPDFfile(type, selectedYear, selectedMonth, exportData);
    } else if (fileType === "XLSX") {
      PVFxlsxFile(t, exportData);
    }
  }

  useEffect(() => {
    if (userProfile && affiliateOrganizationList && affiliateOrganizationList.length > 0) {
      let ownCompany = affiliateOrganizationList.find(
        (x) => x.idCompany === userProfile.idCompany
      );
      if (!ownCompany) {
        ownCompany = affiliateOrganizationList[0];
      }
      setSelectedCompany(ownCompany);
    }
  }, [userProfile, affiliateOrganizationList]);

  const checkedYear = parseInt(dayjs(inputSearch).locale('th').format("YYYY"));
  const ProvidentFundItems = [
    { text: t("Months.January"), marginTop: "5vh" },
    { text: t("Months.February"), marginTop: "5vh" },
    { text: t("Months.March"), marginTop: "5vh" },
    { text: t("Months.April"), marginTop: "5vh" },
    { text: t("Months.May"), marginTop: "5vh" },
    { text: t("Months.June"), marginTop: "5vh" },
    { text: t("Months.July"), marginTop: "5vh" },
    { text: t("Months.August"), marginTop: "5vh" },
    { text: t("Months.September"), marginTop: "5vh" },
    { text: t("Months.October"), marginTop: "5vh" },
    { text: t("Months.November"), marginTop: "5vh" },
    { text: t("Months.December"), marginTop: "5vh" },
  ];
  const getMonthName = (monthNumber) => {
    const monthNames = [
      t("Months.January"), t("Months.February"), t("Months.March"), t("Months.April"), t("Months.May"), t("Months.June"),
      t("Months.July"), t("Months.August"), t("Months.September"), t("Months.October"), t("Months.November"), t("Months.December")
    ];
    return monthNames[monthNumber - 1];
  };

  return (
    <div>
      <StyledRoot className="page">
        <Container maxWidth="lg">
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link
              style={{ textDecoration: "none", color: "inherit" }}
              to={"/employees-report"}
            >
              {t("AllReports")}
            </Link>
            <Typography color="text.primary">
              {t("DashboardProvidentFund")}
            </Typography>
          </Breadcrumbs>
          <div style={{ marginTop: "5vh" }}>
            <Typography variant="h4" style={{ paddingTop: 8 }}>
              {t("DashboardProvidentFund")}
            </Typography>
          </div>

          <StyledWrapFilter>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}
              alignItems={"center"}
            >
              <Grid item xs={4}>
                <StyledBoxSearch>
                  <Typography className="label" color="text.third">
                    {t("SelectYear")}
                  </Typography>
                  <div className="search-date">
                    <DatePickerCustom
                      maxDate={dayjs()}
                      inputFormat="YYYY"
                      value={inputSearch}
                      name="start"
                      views={['year']}
                      openTo={'year'}
                      onChange={(newValue) => {
                        setInputSearch(newValue);
                      }}
                      disabled={isFetching}
                    />
                  </div>
                </StyledBoxSearch>
              </Grid>

              <Grid item xs={4}>
                <StyledBoxSearch>
                  <Typography className="label" color="text.third">
                    {t("SelectMonth")}
                  </Typography>
                  <div className="search-date">
                    <DatePickerCustom
                      maxDate={dayjs()}
                      inputFormat="MMMM"
                      value={inputSearch}
                      name="start"
                      views={['month']}
                      openTo={'month'}
                      onChange={(newValue) => {
                        setInputSearch(newValue);
                      }}
                      disabled={isFetching}
                    />
                  </div>
                </StyledBoxSearch>
              </Grid>

              <Grid item xs={6}>
                <StyledBoxSearch>
                  <Typography className="label" color="text.third">
                    {t("Company")}
                  </Typography>
                  <div className="search-name">
                    <SelectAffiliateCompany
                      options={affiliateOrganizationList}
                      value={selectedCompany}
                      disabled={isFetching}
                      onChange={(_, value) => {
                        onChangeCompany(value);
                      }}
                    />
                  </div>
                </StyledBoxSearch>
              </Grid>

              <Grid item xs={2}>
                <StyledBoxSearchButton>
                  <div style={{ marginTop: 20 }}>
                    <ButtonBlue
                      variant="contained"
                      startIcon={<DownloadRoundedIcon />}
                      disabled={noData}
                      onClick={() => DownloadFile("PVF", parseInt(dayjs(inputSearch).locale('th').format("YYYY")) + 543, dayjs(inputSearch).format("MM"), "PDF")}
                      sx={{ width: 160 }}
                    >
                      {t("Download")} PDF
                    </ButtonBlue>
                  </div>
                </StyledBoxSearchButton>
              </Grid>

              <Grid item xs={2}>
                <StyledBoxSearchButton>
                  <div style={{ marginTop: 20 }}>
                    <ButtonBlue
                      variant="contained"
                      startIcon={<DownloadRoundedIcon />}
                      disabled={noData}
                      onClick={() => DownloadFile("PVF", parseInt(dayjs(inputSearch).locale('th').format("YYYY")) + 543, dayjs(inputSearch).format("MM"), "XLSX")}
                      sx={{ width: 160 }}
                    >
                      {t("Download")} XLSX
                    </ButtonBlue>
                  </div>
                </StyledBoxSearchButton>
              </Grid>
            </Grid>
          </StyledWrapFilter>

          <CardStyle style={{ marginTop: "5vh" }}>
            <StyledDetailCard>
              <TableContainer component={Paper} style={{ marginTop: "3vh" }}>
                <DivEmployeesList>
                  {employeeList && (
                    <Fragment>
                      <div className="employee-list">
                        <div className="employee-item">
                          <div
                            style={{
                              height: "104px",
                              minHeight: "104px",
                            }}
                          >
                            <div
                              style={{
                                height: "100%",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Typography color="text.secondary">
                                {t("AppMenu.Employees")}<span>{`(${employeeList.filter((item) => !item.isTerminate).length} ${t("People")})`}</span>
                              </Typography>
                            </div>
                          </div>

                          <div className="employee-item" style={{ marginTop: 32 }}>
                            {ProvidentFundItems.map((item, index) => (
                              <div key={index} className="div-text-end">
                                <Typography variant="body2" align="center">
                                  {item.text}
                                </Typography>
                              </div>
                            ))}
                          </div>
                        </div>

                        {employeeList.filter((item) => !item.isTerminate).map((emp, index) => {
                          const findEmp = providentData && providentData[emp.idEmployees];
                          const checkedYear = findEmp && findEmp.length > 0 ? findEmp[0].year : "-";

                          return (
                            <div className="employee-item" key={index + emp.firstname_TH + emp.lastname_TH}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <Avatar
                                  className="MuiAvatar-root"
                                  key={index}
                                  alt={emp.email}
                                  src={emp.imageProfile}
                                />
                                <Typography>{getUserFullName(emp)}</Typography>
                              </div>

                              <div style={{ marginTop: 12 }}>
                                <Typography
                                  variant="body2"
                                  align="center"
                                >
                                  {t("EmployeeCompany")}
                                </Typography>
                              </div>

                              <div className="employee-item">
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(month => (
                                  <div key={month} className="div-text-end">
                                    <Typography variant="body2" align="center">
                                      {month === 1 && checkedYear < 2024 && findEmp && findEmp.length > 0 ?
                                        findEmp[0].Jan_Provident : (findEmp && findEmp.find(data => data.month === month) ? findEmp.find(data => data.month === month).Total_Provident : 0)}
                                      /{month === 1 && checkedYear < 2024 && findEmp && findEmp.length > 0 ?
                                        findEmp[0].Jan_Provident : (findEmp && findEmp.find(data => data.month === month) ? findEmp.find(data => data.month === month).Total_Com_Provident : 0)}
                                    </Typography>
                                  </div>
                                ))}
                              </div>
                            </div>
                          );
                        })}

                      </div>
                    </Fragment>
                  )}
                </DivEmployeesList>
              </TableContainer>
            </StyledDetailCard>
          </CardStyle>

          <CardStyle style={{ marginTop: "5vh" }}>
            <StyledDetailCard>
              <ChartProvidentFund Provident={chartData} year={checkedYear} JanValue={JanSum} />
            </StyledDetailCard>
          </CardStyle>

          <CardStyle style={{ marginTop: "5vh" }}>
            <StyledDetailCard style={{ height: "100%" }}>
              <Typography color="text.primary">{t("TotalProvidentFund")}</Typography>
              <Grid item xs={12} style={{ marginTop: "3vh" }}>
                <Grid container spacing={2}>
                  {checkedYear < 2024 &&
                    <Grid item xs={12} md={6} lg={3}>
                      <CardData
                        title="มกราคม"
                        dataValue={JanSum > 0 ? JanSum : 0.00}
                        dataUnit={t("Baht")}
                        dynamicFontSize
                        titleIcon={
                          <CalendarMonthIcon
                            style={{
                              display: "inline-table",
                              color: "#008FFB",
                            }}
                          />
                        }
                      />
                    </Grid>
                  }

                  {Object.keys(chartData).map(month => (
                    <Grid item xs={12} md={6} lg={3} key={month}>
                      <CardData
                        title={getMonthName(Number(month))}
                        dataValue={chartData[month].Total_Provident}
                        dataUnit={t("Baht")}
                        dynamicFontSize
                        titleIcon={
                          <CalendarMonthIcon
                            style={{
                              display: "inline-table",
                              color: "#008FFB",
                            }}
                          />
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </StyledDetailCard>
          </CardStyle>

          <CardStyle style={{ marginTop: "5vh" }}>
            <StyledDetailCard style={{ height: "100%" }}>
              <Typography color="text.primary">{t("TotalCompanyProvidentFund")}</Typography>
              <Grid item xs={12} style={{ marginTop: "3vh" }}>
                <Grid container spacing={2}>
                  {checkedYear < 2024 &&
                    <Grid item xs={12} md={6} lg={3}>
                      <CardData
                        title="มกราคม"
                        dataValue="0"
                        dataUnit={t("Baht")}
                        dynamicFontSize
                        titleIcon={
                          <CalendarMonthIcon
                            style={{
                              display: "inline-table",
                              color: "#008FFB",
                            }}
                          />
                        }
                      />
                    </Grid>
                  }

                  {Object.keys(chartData).map(month => (
                    <Grid item xs={12} md={6} lg={3} key={month}>
                      <CardData
                        title={getMonthName(Number(month))}
                        dataValue={chartData[month].Total_Com_Provident}
                        dataUnit={t("Baht")}
                        dynamicFontSize
                        titleIcon={
                          <CalendarMonthIcon
                            style={{
                              display: "inline-table",
                              color: "#008FFB",
                            }}
                          />
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </StyledDetailCard>
          </CardStyle>
        </Container>
        <AlertResponse
          open={openAlert}
          handleClose={handleCloseAlert}
          alertType={alertType}
        />
      </StyledRoot>
    </div>
  );
};

export default DashboardProvidentFund;