import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

import Profile from "./personal-info";
import Transfer from "./transfer";
import EmploymentTypeChange from "./employmentType-change";
import PersonnelLevelChange from "./personnelLevel-change";
import PaymentChange from "./payment-change";
import Terminate from "./terminate";
import Probation from "./probation";
import EducationExperience from "./education-experience";
import LocationTab from "./address-contact";
import WorkInfo from "./work-info";
import TimePayment from "./time-payment";
import Performance from "./performance";
import Leave from "./leave";
import AdditionDeduction from "./addition-deduction";
import ResetPassword from "./reset-password";
import ProvidentFund from "./provident-fund";
import SettingPayroll from "./setting-payroll";
import SettingRole from "./setting-role";

//Translator TH-EN
import { useTranslation } from "react-i18next";

import { styled } from "@mui/material/styles";
import {
  Grid,
  Typography,
  MenuItem,
  MenuList,
  Avatar,
  Popper,
  Box,
  Grow,
  Tabs,
  Tab,
  Paper,
  Container,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

import ButtonGroup from "@mui/material/ButtonGroup";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";

import { getEmployeeProfile } from "../../../../../actions/employee";
import FamilyPanel from "./family";
import ShiftChange from "./shift-change";
import { Pdf } from "../fileDoc/pdf";
import { Slide } from "../fileDoc/Slide";
import PdfImg from "../../../assets/pdf.png";
import PptImg from "../../../assets/ppt.png";
import { EmpContractPdfForm } from "../fileDoc/emp_contract";
import EmploymentContract from "./employee-contract";
import Potentail from "./potential";
import Talent from "./Talent";
import AdditionPersonal from "./addition-personal";
import IndividualWagePerYear from "./WagePerYear";
import { getUserInformation } from "../../../../../actions/user";
import { getMobileToken } from "../../../../../actions/auth";
import DiligenceInfo from "./diligence-info";

const StyledRoot = styled("div")(({ theme }) => ({
  backgroundColor: "#FFFFFF !important",
  paddingTop: "120px",
  paddingBottom: "120px",
  "& .MuiAvatar-root": {
    width: "180px",
    height: "180px",
  },
  "& .MuiPaper-root": {
    borderRadius: "20px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  "& .NavButtonDownload": {
    width: "fit-content",
  },
  "& .wrap-header": {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 16,
    alignItems: "center",
    flexWrap: "wrap",
  },
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
  ["@media only screen and (max-width: 600px)"]: {
    marginBottom: 8,
  },
  "& .MuiButtonBase-root": {
    borderRadius: 16,
    width: 160,
  },
  "& .MuiButtonBase-root.MuiToggleButton-root.Mui-selected": {
    borderColor: "#46cbe2",
    color: "#46cbe2",
    backgroundColor: "#46cbe210",
  },
});

const StyledTabs = styled(Tabs)({
  "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
    borderColor: "#46cbe2",
    color: "#46cbe2",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "#46cbe2",
  },
});

const StyledPaper = styled(Paper)({
  padding: "8px 16px",
  "& .label-group": {
    fontsize: 16,
    fontWeight: 500,
    marginBottom: 16,
  },
  "& .wrap-export-btn": {
    display: "flex",
    justifyContent: "space-around",
    marginBottom: 24,
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box style={{ padding: 24 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const Adjust = [
  { id: 1, title: "Transfer Out" },
  { id: 2, title: "Transfer In" },
  { id: 3, title: "Probation **ไม่มี" },
  { id: 4, title: "Promotion" },
  { id: 5, title: "Secondment **ไม่มี" },
  { id: 6, title: "Payment Change" },
  { id: 7, title: "Terminate" },
];

const options = [
  {
    text: "ประวัติพนักงาน",
    component: "Typography",
  },
  {
    typeFile: "pdf",
    component: "menu",
  },
  {
    typeFile: "ppt",
    component: "menu",
  },
  {
    text: "ประวัติพนักงาน(ไม่มีความลับ)",
    component: "Typography",
  },
  {
    typeFile: "pdf",
    component: "menu",
  },
  {
    typeFile: "ppt",
    component: "menu",
  },
];

function KeyAddition() {
  const dispatch = useDispatch();
  const { result: employeeProfile } = useSelector((state) => state.employeeProfile);
  const { result: userinfo } = useSelector((state) => state.userInformation);
  let { idEmployees } = useParams();
  const { t, i18n } = useTranslation();

  const [tabs, setTabs] = useState(0);
  const [groupTap, setGroupTap] = useState("personal");

  //pdf pwp
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  useEffect(() => {
    dispatch(getEmployeeProfile(idEmployees, true));
    dispatch(getUserInformation());
  }, []);

  useEffect(() => {
    if (employeeProfile) {
      dispatch(getMobileToken(idEmployees, employeeProfile.idCompany));
    }
  }, [employeeProfile]);

  const handleTabsChange = (e, newValue) => {
    setTabs(newValue);
  };

  const handleChangeGroupTab = (event, newAlignment) => {
    if (newAlignment !== null) {
      if (newAlignment === "work") {
        setTabs(6);
      } else {
        setTabs(0);
      }
      setGroupTap(newAlignment);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  async function createAndDownloadPDF(selectedIndex) {
    console.log("selectedIndex = ", selectedIndex)
    if (selectedIndex === 0) {
      Pdf(employeeProfile, "full");
    } else if (selectedIndex === 1) {
      Pdf(employeeProfile, "half");
    } else if (selectedIndex === 2) {
      Slide(employeeProfile, "fullPage");
    } else if (selectedIndex === 3) {
      Slide(employeeProfile, "halfpage");
    }
    // else if (selectedIndex == 4) {
    //   EmpContractPdfForm(employeeProfile);
    // }
  }

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        {employeeProfile && (
          <Fragment>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <Profile dataEmployee={employeeProfile} />
              </Grid>
              <Grid item xs={12} sm={12} md={8}>
                <div className="wrap-header">
                  <StyledToggleButtonGroup
                    style={{ alignItems: "flex-end" }}
                    color="primary"
                    value={groupTap}
                    exclusive
                    onChange={handleChangeGroupTab}
                  >
                    <ToggleButton
                      value="personal"
                      style={{ height: "fit-content" }}
                    >
                      {t("PersonalData")}
                    </ToggleButton>
                    <ToggleButton
                      value="work"
                      style={{ height: "fit-content" }}
                    >
                      {t("ChangeData")}
                    </ToggleButton>
                  </StyledToggleButtonGroup>
                  <div className="NavButtonDownload">
                    <ButtonGroup ref={anchorRef} aria-label="split button">
                      {/* <ButtonBlue disabled variant="contained"
                      onClick={(event) =>
                        createAndDownloadPDF()
                      }>{options[selectedIndex]}</ButtonBlue> */}
                      <ButtonBlue
                        variant="contained"
                        aria-controls={open ? "split-button-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={handleToggle}
                        endIcon={<ArrowDropDownIcon />}
                      >
                        {t("Download")}
                      </ButtonBlue>
                    </ButtonGroup>
                    <Popper
                      style={{
                        zIndex: 1,
                      }}
                      open={open}
                      anchorEl={anchorRef.current}
                      role={undefined}
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === "bottom"
                                ? "center top"
                                : "center bottom",
                          }}
                        >
                          <StyledPaper>
                            <ClickAwayListener onClickAway={handleClose}>
                              <div>
                                <Fragment>
                                  <Typography
                                    className="label-group"
                                    gutterBottom
                                  >
                                    {t("EmployeeInfo")}
                                  </Typography>
                                  <div className="wrap-export-btn">
                                    <Avatar
                                      variant="rounded"
                                      src={PdfImg}
                                      alt="file-type"
                                      style={{ width: 35, height: 35 }}
                                      onClick={(event) => {
                                        event.preventDefault();
                                        console.log("createAndDownloadPDF(0)")
                                        createAndDownloadPDF(0);
                                      }}
                                    />
                                    <Avatar
                                      variant="rounded"
                                      src={PptImg}
                                      alt="file-type"
                                      style={{ width: 35, height: 35 }}
                                      onClick={(event) => {
                                        event.preventDefault();
                                        console.log("createAndDownloadPDF(2)")
                                        createAndDownloadPDF(2);
                                      }}
                                    />
                                  </div>
                                  <Typography
                                    className="label-group"
                                    gutterBottom
                                  >
                                    {t("EmployeeInfo")}({t("NoSecret")})
                                  </Typography>
                                  <div className="wrap-export-btn">
                                    <Avatar
                                      variant="rounded"
                                      src={PdfImg}
                                      alt="file-type"
                                      style={{ width: 35, height: 35 }}
                                      onClick={(event) => {
                                        event.preventDefault();
                                        console.log("createAndDownloadPDF(1)")
                                        createAndDownloadPDF(1);
                                      }}
                                    />
                                    <Avatar
                                      variant="rounded"
                                      src={PptImg}
                                      alt="file-type"
                                      style={{ width: 35, height: 35 }}
                                      onClick={(event) => {
                                        event.preventDefault();
                                        console.log("createAndDownloadPDF(3)")
                                        createAndDownloadPDF(3);
                                      }}
                                    />
                                  </div>
                                  {/* <Typography
                                    className="label-group"
                                    gutterBottom
                                  >
                                    สัญญาจ้างงาน
                                  </Typography>
                                  <div className="wrap-export-btn">
                                    <Avatar
                                      variant="rounded"
                                      src={PdfImg}
                                      alt="file-type"
                                      style={{ width: 35, height: 35 }}
                                      onClick={() => {
                                        createAndDownloadPDF(4);
                                      }}
                                    />
                                  </div> */}
                                </Fragment>
                              </div>
                            </ClickAwayListener>
                          </StyledPaper>
                        </Grow>
                      )}
                    </Popper>
                  </div>
                </div>
                {/* {anchorEl && renderMenu} */}
                <Paper>
                  <Box style={{ width: "100%" }}>
                    {groupTap === "personal" && (
                      <Box style={{ borderBottom: 1, borderColor: "divider" }}>
                        <StyledTabs
                          value={tabs}
                          onChange={handleTabsChange}
                          variant="scrollable"
                          scrollButtons="auto"
                          aria-label="basic tabs example"
                        >
                          <Tab
                            label={`${t("PersonalInfo")}`}
                            value={0}
                            {...a11yProps(0)}
                          />
                          <Tab
                            label={`${t("FamilyInfo")}`}
                            value={15}
                            {...a11yProps(15)}
                          />
                          <Tab
                            label={`${t("WorkInfo")}`}
                            value={1}
                            {...a11yProps(1)}
                          />
                          <Tab
                            label={`${t("PaymentInfo")}`}
                            value={2}
                            {...a11yProps(2)}
                          />
                          <Tab
                            label={`${t("Education")}-${t("WorkExperience")}`}
                            value={3}
                            {...a11yProps(3)}
                          />
                          <Tab
                            label={`${t("ProvidentFund")}`}
                            value={13}
                            {...a11yProps(13)}
                          />
                          <Tab
                            label={`${t("Performance")}`}
                            value={4}
                            {...a11yProps(4)}
                          />
                          <Tab
                            label={`${t("Potential")}`}
                            value={20}
                            {...a11yProps(20)}
                          />
                          <Tab
                            label="Talent"
                            value={21}
                            {...a11yProps(21)}
                          />
                          <Tab
                            label={`${t("Leave")}`}
                            value={5}
                            {...a11yProps(5)}
                          />
                          <Tab
                            label={t("EmploymentContract")}
                            value={19}
                            {...a11yProps(19)}
                          />
                          <Tab
                            label={`${t("AdditionsPayment")}/${t(
                              "RegularDeduction"
                            )}`}
                            value={11}
                            disabled={!employeeProfile.isShowSalary}
                            {...a11yProps(11)}
                          />
                          <Tab
                            label={t("AdditionPersonal")}
                            value={22}
                            {...a11yProps(22)}
                          />

                          {userinfo && ((userinfo.isSuperAdmin === 1 && userinfo.isPayroll === 0) || (userinfo.isSuperAdmin === 1 && userinfo.isPayroll === 1)) && (
                            <Tab
                              label={t("WagePerYear")}
                              value={23}
                              {...a11yProps(23)}
                            />
                          )}

                          <Tab
                            label={`${t("SetupSalaryCalculation")}`}
                            value={17}
                            {...a11yProps(17)}
                          />
                          <Tab
                            label={`ข้อมูลเบี้ยขยัน`}
                            value={24}
                            {...a11yProps(24)}
                          />
                          <Tab
                            label={`${t("SetupRole")}`}
                            value={18}
                            {...a11yProps(18)}
                          />
                          <Tab
                            label={`${t("ResetPassword")}`}
                            value={12}
                            {...a11yProps(12)}
                          />
                        </StyledTabs>
                      </Box>
                    )}
                    {groupTap === "work" && (
                      <Box style={{ borderBottom: 1, borderColor: "divider" }}>
                        <StyledTabs
                          value={tabs}
                          onChange={handleTabsChange}
                          variant="scrollable"
                          scrollButtons="auto"
                          aria-label="basic tabs example"
                        >
                          <Tab
                            label={`${t("Transfer")}`}
                            value={6}
                            {...a11yProps(6)}
                          />
                          <Tab
                            label={`${t("AppMenu.TimeAttendance")}`}
                            value={16}
                            {...a11yProps(16)}
                          />
                          <Tab
                            label={`${t("EmploymentType")}`}
                            value={14}
                            {...a11yProps(14)}
                          />
                          <Tab
                            label={`${t("Level")}`}
                            value={8}
                            {...a11yProps(8)}
                          />
                          <Tab
                            label={`${t("Wages")}`}
                            value={9}
                            {...a11yProps(9)}
                          />
                          <Tab
                            label={`${t("Probation")}`}
                            value={7}
                            {...a11yProps(7)}
                          />
                          <Tab
                            label={`${t("Terminated")}`}
                            value={10}
                            {...a11yProps(10)}
                          />
                        </StyledTabs>
                      </Box>
                    )}

                    <TabPanel value={tabs} index={0}>
                      <LocationTab />
                    </TabPanel>
                    <TabPanel value={tabs} index={1}>
                      <WorkInfo
                        changeGroupTap={setGroupTap}
                        changeTabs={setTabs}
                      />
                    </TabPanel>
                    <TabPanel value={tabs} index={2}>
                      <TimePayment
                        changeGroupTap={setGroupTap}
                        changeTabs={setTabs}
                      />
                    </TabPanel>
                    <TabPanel value={tabs} index={3}>
                      <EducationExperience />
                    </TabPanel>
                    <TabPanel value={tabs} index={4}>
                      <Performance />
                    </TabPanel>
                    <TabPanel value={tabs} index={5}>
                      <Leave />
                    </TabPanel>
                    <TabPanel value={tabs} index={6}>
                      <Transfer />
                    </TabPanel>
                    <TabPanel value={tabs} index={7}>
                      <Probation />
                    </TabPanel>
                    <TabPanel value={tabs} index={9}>
                      <PaymentChange />
                    </TabPanel>
                    <TabPanel value={tabs} index={10}>
                      <Terminate />
                    </TabPanel>
                    <TabPanel value={tabs} index={11}>
                      <AdditionDeduction />
                    </TabPanel>
                    <TabPanel value={tabs} index={12}>
                      <ResetPassword />
                    </TabPanel>
                    <TabPanel value={tabs} index={13}>
                      <ProvidentFund />
                    </TabPanel>
                    <TabPanel value={tabs} index={14}>
                      <EmploymentTypeChange />
                    </TabPanel>
                    <TabPanel value={tabs} index={8}>
                      <PersonnelLevelChange />
                    </TabPanel>
                    <TabPanel value={tabs} index={15}>
                      <FamilyPanel />
                    </TabPanel>
                    <TabPanel value={tabs} index={16}>
                      <ShiftChange />
                    </TabPanel>
                    <TabPanel value={tabs} index={17}>
                      <SettingPayroll />
                    </TabPanel>
                    <TabPanel value={tabs} index={18}>
                      <SettingRole />
                    </TabPanel>
                    <TabPanel value={tabs} index={19}>
                      <EmploymentContract />
                    </TabPanel>
                    <TabPanel value={tabs} index={20}>
                      <Potentail />
                    </TabPanel>
                    <TabPanel value={tabs} index={21}>
                      <Talent />
                    </TabPanel>
                    <TabPanel value={tabs} index={22}>
                      <AdditionPersonal />
                    </TabPanel>

                    {userinfo && ((userinfo.isSuperAdmin === 1 && userinfo.isPayroll === 0) || (userinfo.isSuperAdmin === 1 && userinfo.isPayroll === 1)) && (
                      <TabPanel value={tabs} index={23}>
                        <IndividualWagePerYear selectedEmployees={idEmployees} />
                      </TabPanel>
                    )}

                    <TabPanel value={tabs} index={24}>
                      <DiligenceInfo />
                    </TabPanel>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Fragment>
        )}
      </Container>
    </StyledRoot>
  );
}

export default KeyAddition;
