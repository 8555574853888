import React, { useEffect } from "react";
import DrawerCustom from "../../../../shared/general/Drawer";
import { Box, FormControl, Grid, MenuItem, styled, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import { useTranslation } from "react-i18next";
import DatePickerCustom from "../../../../shared/date/datePicker";
import dayjs from "dayjs";
import NumberFormatTheme from "../../../../shared/general/NumberFormatTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { getEmployeeProfile } from "../../../../../../actions/employee";
import { openNotificationAlert } from "../../../../../../actions/notificationAlert";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../../../../actions/user";

const StyledRoot = styled(Box)({
  padding: "16px",
});

const DrawerPersonalDetail = (props) => {
  
  const { drawerConfig, handleClose } = props;

  const { result: employeeProfile } = useSelector((state) => state.employeeProfile);

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const validateYupSchema = yup.object({
    passportNumber: yup.string().nullable().max(20, t("maxLengthErrorMessage", {length: 20})),
    passportExpiryDate: yup.mixed().nullable(),
    idReligion: yup.string().nullable(),
    workPermitNumber: yup.string().nullable().max(20, t("maxLengthErrorMessage", {length: 20})),
    workPermitExpiryDate: yup.mixed().nullable(),
    taxID: yup.string()
      .test("checkLength", t("lengthErrorMessage", {length: 13}), (value) => {
        return (value.length === 0 || value.length === 13)
      }),
    ssoID: yup.string()
      .test("checkLength", t("lengthErrorMessage", {length: 13}), (value) => {
        return (value.length === 0 || value.length === 13)
      }),
  });

  const useHookForm = useForm({
    defaultValues: {
      passportNumber: "",
      passportExpiryDate: null,
      maritalStatus: "",
      nationality: "",
      idReligion: "",
      workPermitNumber: "",
      workPermitExpiryDate: null,
      taxID: "",
      ssoID: "",
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  })

  useEffect(() => {

    if(drawerConfig.isOpen){
    
      useHookForm.reset({
        passportNumber: drawerConfig.data.passportNumber? drawerConfig.data.passportNumber: "",
        passportExpiryDate: drawerConfig.data.passportExpiryDate? dayjs(drawerConfig.data.passportExpiryDate): null,
        maritalStatus: drawerConfig.data.maritalStatus? drawerConfig.data.maritalStatus: "",
        nationality: drawerConfig.data.nationality? drawerConfig.data.nationality: "",
        idReligion: drawerConfig.data.idReligion? drawerConfig.data.idReligion: "",
        workPermitNumber: drawerConfig.data.workPermitNumber? drawerConfig.data.workPermitNumber: "",
        workPermitExpiryDate: drawerConfig.data.workPermitExpiryDate? dayjs(drawerConfig.data.workPermitExpiryDate): null,
        taxID: drawerConfig.data.taxID? drawerConfig.data.taxID: "",
        ssoID: drawerConfig.data.ssoID? drawerConfig.data.ssoID: "",
      });

    }

  }, [drawerConfig.isOpen])
  
  const onSubmit = async (data) => {

    const formData = {
      idEmployees: employeeProfile.idEmployees,
      passportNumber: data.passportNumber? data.passportNumber: null,
      passportExpiryDate: data.passportExpiryDate? dayjs(data.passportExpiryDate).format("YYYY-MM-DD"): null,
      maritalStatus: data.maritalStatus? data.maritalStatus: null,
      nationality: data.nationality? data.nationality: null,
      idReligion: data.idReligion? data.idReligion: null,
      workPermitNumber: data.workPermitNumber? data.workPermitNumber: null,
      workPermitExpiryDate: data.workPermitExpiryDate? dayjs(data.workPermitExpiryDate).format("YYYY-MM-DD"): null,
      taxID: data.taxID? data.taxID: null,
      ssoID: data.ssoID? data.ssoID: null,
    }

    const result = await dispatch(updateUser(formData));

    if(result && result.status === 200) {
      handleClose();
      dispatch(openNotificationAlert({
        type: "success",
        message: t("DataSaveSuccessful"),
      }));
      dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
    } else {
      dispatch(openNotificationAlert({
        type: "error",
        message: t("AnErrorOccurred")
      }));
    }
  };

  return (
    <DrawerCustom
      title={t("PersonalInfo")}
      anchor={"right"}
      open={drawerConfig.isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography fontWeight={600} fontSize={14} color="text.secondary" gutterBottom>{t("PassportNumber")}</Typography>
              <Controller
                name="passportNumber"
                control={useHookForm.control}
                render={({field, fieldState}) => (
                  <TextFieldTheme
                    {...field}
                    placeholder={t("Optional")}
                    error={fieldState.error? true: false}
                    helperText={fieldState.error? fieldState.error.message: null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography fontWeight={600} fontSize={14} color="text.secondary" gutterBottom>{t("passportExpiryDate")}</Typography>
              <Controller
                name="passportExpiryDate"
                control={useHookForm.control}
                render={({ field, fieldState }) => (
                  <DatePickerCustom
                    {...field}
                    views={["year", "month", "day"]}
                    inputFormat="DD MMMM YYYY"
                    openTo="year"
                    value={field.value}
                    onChange={(newValue) => {
                      field.onChange(newValue);
                    }}
                    componentsProps={{
                      actionBar: {
                        actions: ["clear"],
                      },
                    }}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: `${t("DayMonthYear(AD)")} (${t("Optional")})`,
                          readOnly: true
                        }}
                        onBlur={field.onBlur}
                        error={fieldState.error? true : false}
                        helperText={fieldState.error? fieldState.error.message : null}
                      />
                    )}
                    PaperProps={{
                      sx: {
                        "& .Mui-selected": {
                          backgroundColor: "#46cbe2!important",
                        },
                      },
                    }}
                    DialogProps={{
                      sx: {
                        "& .Mui-selected": {
                          backgroundColor: "#46cbe2!important",
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography fontWeight={600} fontSize={14} color="text.secondary" gutterBottom>{t("Status")}</Typography>
              <Controller
                name="maritalStatus"
                control={useHookForm.control}
                render={({field, fieldState}) => (
                  <TextFieldTheme
                    {...field}
                    select
                    SelectProps={{
                      displayEmpty: true,
                    }}
                    error={fieldState.error? true: false}
                    helperText={fieldState.error? fieldState.error.message: null}
                  >
                    <MenuItem value="">{t("NotSpecified")}</MenuItem>
                    <MenuItem value="โสด">{`${t("Single")}`}</MenuItem>
                    <MenuItem value="สมรส">{`${t("Married")}`}</MenuItem>
                    <MenuItem value="หม้าย">{`${t("Widowed")}`}</MenuItem>
                    <MenuItem value="หย่า">{`${t("Divorced")}`}</MenuItem>
                  </TextFieldTheme>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography fontWeight={600} fontSize={14} color="text.secondary" gutterBottom>{t("Nationality")}</Typography>
              <Controller
                name="nationality"
                control={useHookForm.control}
                render={({field, fieldState}) => (
                  <TextFieldTheme
                    {...field}
                    select
                    SelectProps={{
                      displayEmpty: true,
                    }}
                    error={fieldState.error? true: false}
                    helperText={fieldState.error? fieldState.error.message: null}
                  >
                    <MenuItem value="">{t("NotSpecified")}</MenuItem>
                    <MenuItem value="ไทย">{t("nations.Thailand")}</MenuItem>
                    <MenuItem value="ลาว">{t("nations.Laos")}</MenuItem>
                    <MenuItem value="จีน">{t("nations.China")}</MenuItem>
                    <MenuItem value="ญี่ปุ่น">{t("nations.Japan")}</MenuItem>
                    <MenuItem value="สหรัฐอเมริกา">{t("nations.UnitedStates")}</MenuItem>
                    <MenuItem value="กัมพูชา">{t("nations.Cambodia")}</MenuItem>
                    <MenuItem value="เวียดนาม">{t("nations.Vietnam")}</MenuItem>
                  </TextFieldTheme>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography fontWeight={600} fontSize={14} color="text.secondary" gutterBottom>{t("Religion")}</Typography>
              <Controller
                name="idReligion"
                control={useHookForm.control}
                render={({field, fieldState}) => (
                  <TextFieldTheme
                    {...field}
                    select
                    SelectProps={{
                      displayEmpty: true,
                    }}
                    error={fieldState.error? true: false}
                    helperText={fieldState.error? fieldState.error.message: null}
                  >
                    <MenuItem value="">{t("NotSpecified")}</MenuItem>
                    <MenuItem value="1">{t("Religion_Code.buddha")}</MenuItem>
                    <MenuItem value="2">{t("Religion_Code.islamic")}</MenuItem>
                    <MenuItem value="3">{t("Religion_Code.christ")}</MenuItem>
                    <MenuItem value="4">{t("Religion_Code.hinduism")}</MenuItem>
                    <MenuItem value="5">{t("Religion_Code.sikhism")}</MenuItem>
                  </TextFieldTheme>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography fontWeight={600} fontSize={14} color="text.secondary" gutterBottom>{t("WorkPermitNumber")}</Typography>
              <Controller
                name="workPermitNumber"
                control={useHookForm.control}
                render={({field, fieldState}) => (
                  <TextFieldTheme
                    {...field}
                    placeholder={t("Optional")}
                    error={fieldState.error? true: false}
                    helperText={fieldState.error? fieldState.error.message: null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography fontWeight={600} fontSize={14} color="text.secondary" gutterBottom>{t("workPermitExpiryDate")}</Typography>
              <Controller
                name="workPermitExpiryDate"
                control={useHookForm.control}
                render={({ field, fieldState }) => (
                  <DatePickerCustom
                    {...field}
                    views={["year", "month", "day"]}
                    inputFormat="DD MMMM YYYY"
                    openTo="year"
                    value={field.value}
                    onChange={(newValue) => {
                      field.onChange(newValue);
                    }}
                    componentsProps={{
                      actionBar: {
                        actions: ["clear"],
                      },
                    }}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: `${t("DayMonthYear(AD)")} (${t("Optional")})`,
                          readOnly: true
                        }}
                        onBlur={field.onBlur}
                        error={fieldState.error? true : false}
                        helperText={fieldState.error? fieldState.error.message : null}
                      />
                    )}
                    PaperProps={{
                      sx: {
                        "& .Mui-selected": {
                          backgroundColor: "#46cbe2!important",
                        },
                      },
                    }}
                    DialogProps={{
                      sx: {
                        "& .Mui-selected": {
                          backgroundColor: "#46cbe2!important",
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography fontWeight={600} fontSize={14} color="text.secondary" gutterBottom>Tax ID</Typography>
              <Controller
                name="taxID"
                control={useHookForm.control}
                render={({field, fieldState}) => (
                  <TextFieldTheme
                    {...field}
                    placeholder={t("Optional")}
                    InputProps={{
                      inputComponent: NumberFormatTheme,
                    }}
                    inputProps={{
                      allowNegative: false,
                      allowLeadingZeros: true,
                      value: field.value,
                      thousandSeparator: "",
                      maxLength: 13,
                      decimalScale: 0,
                      onValueChange: (values) => {
                        const { value } = values;
                        field.onChange(value);
                      },
                    }}
                    onChange={() => {}}
                    error={fieldState.error? true: false}
                    helperText={fieldState.error? fieldState.error.message: null}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography fontWeight={600} fontSize={14} color="text.secondary" gutterBottom>SSO ID</Typography>
              <Controller
                name="ssoID"
                control={useHookForm.control}
                render={({field, fieldState}) => (
                  <TextFieldTheme
                    {...field}
                    placeholder={t("Optional")}
                    InputProps={{
                      inputComponent: NumberFormatTheme,
                    }}
                    inputProps={{
                      allowNegative: false,
                      allowLeadingZeros: true,
                      value: field.value,
                      thousandSeparator: "",
                      maxLength: 13,
                      decimalScale: 0,
                      onValueChange: (values) => {
                        const { value } = values;
                        field.onChange(value);
                      },
                    }}
                    onChange={() => {}}
                    error={fieldState.error? true: false}
                    helperText={fieldState.error? fieldState.error.message: null}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <ButtonBlue variant="text" onClick={handleClose} disabled={useHookForm.formState.isSubmitting}>{t("Cancel")}</ButtonBlue>
                <ButtonBlue variant="contained" type="submit" disabled={useHookForm.formState.isSubmitting}>{t("Save")}</ButtonBlue>
              </Grid>
            </Grid>

          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  )
}

export default DrawerPersonalDetail;